import PropTypes from 'prop-types';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { clearAuthTokens } from '../../utils/auth';

class LogOut extends Component {
  onLogOut = async () => {
    try {
      const { client, history } = this.props;
      clearAuthTokens();
      client.resetStore();
      history.push('/');
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return this.props.children({ onLogOut: this.onLogOut });
  }
}

LogOut.propTypes = {
  children: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  withApollo,
);

export default enhance(LogOut);
