import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { scrollToFirstError, initialValuesUpdateRule } from '../../../utils/formHelpers';
import { Separator } from '../../common/form/Separator';
import OccupationPart from './OccupationPart';
import TenantsPart from './TenantsPart';
import ProfilePersonalPart from './ProfilePart';
import { GET_MAX_PLACE_TENANTS } from '../../../graphQL/queries/bookings';
import Loading from '../../common/Loading';
import Error from '../../common/Error';
import ExposeState from '../../common/form/ExposeState';

class PersonalForm extends React.Component {
  shouldComponentUpdate = ({
    initialValues,
    bookableType,
    bookedObjectId,
  }) => {
    const initialValuesRule = initialValuesUpdateRule(initialValues, this.props.initialValues);
    return initialValuesRule
    || bookableType !== this.props.bookableType
    || bookedObjectId !== this.props.bookedObjectId;
  }

  render() {
    const {
      initialValues,
      onSubmit,
      onNext,
      bookableType,
      bookedObjectId,
      exposeState,
    } = this.props;
    return (
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit, submitErrors, errors, form: { getState, change: formChange },
        }) => {
          onNext(async () => {
            await handleSubmit();
            scrollToFirstError(submitErrors, errors);
          });
          const kindOfJob = (get(getState(), 'values.person.kindOfJob'));
          return (
            <form onSubmit={handleSubmit}>
              <ExposeState exposeState={exposeState} />
              <ProfilePersonalPart fieldsRequired />
              <Separator />
              <OccupationPart change={formChange} kindOfJob={kindOfJob} />
              <Separator />
              { bookableType === 'Place' && (
                <Query query={GET_MAX_PLACE_TENANTS} variables={{ bookedObjectId }}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loading />;
                    if (error) return <Error />;
                    return <TenantsPart formChange={formChange} getState={getState} numberOfTenants={get(data, 'place.tenantAllowedCount')} />;
                  }}
                </Query>
              )}
            </form>
          );
        }}
      />
    );
  }
}

PersonalForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  bookableType: PropTypes.oneOf(['Place', 'Room']),
  bookedObjectId: PropTypes.string,
  exposeState: PropTypes.func.isRequired,
};

export default PersonalForm;
