import React from 'react';
import PropTypes from 'prop-types';
import { Image, Flex, Text } from 'rebass';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import Modal from '../../common/Modal';
import downrootsIcon from '../../../assets/img/downroots-icon.webp.png';
import CloseButton from '../../common/buttons/CloseButton';
import { fontSize, fontMixin, BOLD } from '../../../theme/styleUtils';

const ModalContainer = styled(Flex)`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;
const BorderedContainer = styled(Flex)`
  margin: 50px;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(47, 46, 46, 1);
`;

const NextText = styled(Text)`
  ${fontSize(17)}
  letter-spacing: 1.2.em;
  line-height: 1.2em;
`;

const TitleText = styled(Text)`
  ${fontSize(22)}
  ${fontMixin(BOLD)}
  letter-spacing: 0.3em;
`;

const BookingDescriptionText = styled(Text)`
  ${fontSize(17)}
  letter-spacing: 1.2px;
  line-height: 1.2em;
`;


const LandlordModal = ({
  onClose,
  content: { description, title },
  children,
}) => (
  <Modal>
    <ModalContainer>
      <Flex bg="#fff" width={[1, 1, 710, 710]}>
        <BorderedContainer flexDirection="column" pb={40} px={40} pt={10}>
          <Flex
            width={1}
            mb={30}
            justifyContent="space-between"
            alignItems="center"
          >
            <Image src={downrootsIcon} width="auto" height={80} ml={-20} />
            <CloseButton onClick={onClose} />
          </Flex>
          <TitleText mb={30}>
            {title}
          </TitleText>
          <NextText mb="5px">
            Next
          </NextText>
          {(description || []).map((desc, idx) => {
            const createMarkup = () => ({
              __html: desc,
            });
            return (
              <BookingDescriptionText
                key={`modal-complete-booking-${idx}`}
                mb={15}
                dangerouslySetInnerHTML={createMarkup()}
              />
            );
          })}
          {children}
        </BorderedContainer>
      </Flex>
    </ModalContainer>
  </Modal>
);

LandlordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.array.isRequired,
  }).isRequired,
  children: PropTypes.node,
};

export default withNamespaces('tenacies')(LandlordModal);
