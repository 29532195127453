import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import {
  Box, Text, Image, Flex,
} from 'rebass';
import map from 'lodash/map';
import { withNamespaces } from 'react-i18next';
import {
  fontMixin, fontSize, THICK5, NORMAL, THICK, LIGHT, BOLD,
} from '../../theme/styleUtils';
import Subtitle from '../common/text/Subtitle';
import Tooltip from '../common/Tooltip';
import mockImage from '../../assets/img/bedroom.png';
import questionMark from '../../assets/img/question-icon.svg';

const LongBox = styled(Box).attrs({
  width: [1, 1, 330],
  mb: [50, 50, 0],
})`
  background: #fff;
  align-self: flex-start;
  border: 1px solid ${props => props.theme.colors.dustGray};
  overflow: visible;
  letter-spacing: 0.05em;
  
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    border: 0 none;
  }
`;

const BookingBoxAddress = styled(Text)`
  ${fontMixin(THICK5)}
  ${fontSize(16)}
  color: ${props => props.theme.colors.darkBrown};
`;

const ListingTypeText = styled(Text)`
  ${fontMixin(LIGHT)}
  ${fontSize(14)}
  color: ${props => props.theme.colors.navActiveGrey};
  line-height: 1.6em;
`;

const BoxSubTitle = styled(Text)`
  ${fontMixin(THICK)}
  ${fontSize(16)}
  color: #333333;
`;

const PaymentItemText = styled(Text)`
  ${props => fontMixin(props.bold ? BOLD : NORMAL)}
  ${fontSize(15)}
  margin-bottom: 5px;
  &:nth-child(2n) {
    text-align: right;
  }
`;

const TopBorderedBox = styled(Box)`
  border-top: 1px solid ${props => props.theme.colors.dustGray};
`;

const SubHeader = styled(Flex)`
  background-color: ${props => props.theme.colors.whiteGray};
  border-top: 1px solid ${props => props.theme.colors.dustGray};
  border-bottom: 1px solid ${props => props.theme.colors.dustGray};
  background-color: ${props => props.theme.colors.whiteGray};
  
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    border: 1px solid ${props => props.theme.colors.dustGray};
  }
`;

const RowContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`;

const BoxPricePartText = styled(Text)`
  ${fontSize(15)}
`;

const BoxPricePart = ({ title, children }) => children && (
  <TopBorderedBox mb={20} pt={20}>
    {title && <BoxSubTitle mb="5px">{title}</BoxSubTitle>}
    <Flex flexWrap="wrap" justifyContent="space-between">
      {children}
    </Flex>
  </TopBorderedBox>
);

BoxPricePart.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

const renderTooltip = desc => (
  <Tooltip content={desc}>
    <Image src={questionMark} width={15} />
  </Tooltip>
);
const formatPrice = value => (value ? `£${value.toFixed(2)}` : '£0.00');

const Photo = styled(Box)`
  background-size: cover;
  height: 200px;
  background: url(${props => props.src}) no-repeat center center;
`;

const BookingBox = ({
  address,
  listingType,
  monthlyRent,
  monthlyUtilityPrice,
  dueOnLease,
  t,
  startDate,
  stayMonths,
  mainImage,
  dueNow,
}) => (
  <LongBox>
    <Flex width={1} py={30} px={[0, 15, 15]} flexDirection={['column', 'row', 'row']}>
      <Box width={[1, 1 / 2, 1 / 2]} pr={10} order={[1, 0, 0]}>
        <BookingBoxAddress mb={20}>{address}</BookingBoxAddress>
        <ListingTypeText>{t(listingType)}</ListingTypeText>
      </Box>
      <Box width={[1, 1 / 2, 1 / 2]} pl={[0, 10, 10]} mb={[20, 0, 0]} order={[0, 1, 1]}>
        <Photo width={1} src={mainImage || mockImage} />
      </Box>
    </Flex>

    <SubHeader width={1} px={15} py={10}>
      <Subtitle width={0.6}>
        Move in
      </Subtitle>
      <Subtitle width={0.4}>
        Stay for
      </Subtitle>
    </SubHeader>
    <Flex width={1} px={15} mt={15}>
      <Text width={0.6}>{moment(startDate).format('MMMM DD YYYY')}</Text>
      <Text width={0.4}>{`${stayMonths} ${t('months')}`}</Text>
    </Flex>

    <Box p={15} pb={0}>
      <BoxPricePart title={t('monthlyCosts')}>
        <RowContainer>
          <PaymentItemText>{t('rent')}</PaymentItemText>
          <PaymentItemText>{formatPrice(monthlyRent)}</PaymentItemText>
        </RowContainer>
        <RowContainer>
          <PaymentItemText>{t('utilities')}</PaymentItemText>
          <PaymentItemText>{formatPrice(monthlyUtilityPrice)}</PaymentItemText>
        </RowContainer>
        <RowContainer>
          <PaymentItemText>{t('monthlyTotal')}</PaymentItemText>
          <PaymentItemText bold>
            {formatPrice(monthlyRent + monthlyUtilityPrice)}
          </PaymentItemText>
        </RowContainer>
      </BoxPricePart>

      <BoxPricePart title={t('dueNow')}>
        {map(dueNow, (fee, i) => (
          <RowContainer key={`dueNow-${i}`}>
            <Flex>
              <PaymentItemText mr={10}>{fee.name}</PaymentItemText>
              {renderTooltip('Lorem ipsum')}
            </Flex>
            <PaymentItemText bold>{formatPrice(fee.value)}</PaymentItemText>
          </RowContainer>
        ))}
      </BoxPricePart>

      <BoxPricePart>
        <Flex>
          <BoxSubTitle mb="5px" mr={10}>{t('dueOnLease')}</BoxSubTitle>
          {renderTooltip('Lorem ipsum')}
        </Flex>
        {map(dueOnLease, (fee, i) => (
          <RowContainer key={`$dueOnLease-${i}`}>
            <PaymentItemText>{fee.name}</PaymentItemText>
            <PaymentItemText>{formatPrice(fee.value)}</PaymentItemText>
          </RowContainer>
        ))}
      </BoxPricePart>

      <BoxPricePart title={t('freeCancelation')}>
        <BoxPricePartText>{t('cancelationDescription')}</BoxPricePartText>
        <BoxPricePartText letterSpacing="normal" mt={30}>
          {`Downroots concierge  ${t('concierge')}`}
        </BoxPricePartText>
      </BoxPricePart>
    </Box>
  </LongBox>
);

BookingBox.propTypes = {
  address: PropTypes.string.isRequired,
  listingType: PropTypes.string.isRequired,
  monthlyRent: PropTypes.number,
  monthlyUtilityPrice: PropTypes.number,
  dueOnLease: PropTypes.array.isRequired,
  dueNow: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  stayMonths: PropTypes.number,
  mainImage: PropTypes.string,
};

export default withNamespaces('booking')(BookingBox);
