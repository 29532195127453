import set from 'lodash/set';
import get from 'lodash/get';
import map from 'lodash/fp/map';
import flattenDeep from 'lodash/flattenDeep';
import join from 'lodash/fp/join';
import flow from 'lodash/fp/flow';
import reduce from 'lodash/reduce';
import endsWith from 'lodash/endsWith';
import camelCase from 'lodash/camelCase';
import { isPlural, singular } from 'pluralize';
import { FORM_ERROR } from 'final-form';

export const mapApiErrors = errors => reduce(errors, (res, error) => {
  const camelled = flow(
    (s => s.split('.')),
    map((s => s.split('['))),
    flattenDeep,
    map(camelCase),
    join('.'),
  )(get(error, 'attribute', ''));

  if (camelled === '') {
    set(res, FORM_ERROR, error.message);
    return res;
  }

  set(res, camelled, error.message);

  if (!endsWith(camelled, 'Id')) {
    set(res, `${camelled}Id`, error.message);
  }

  if (isPlural(camelled) && !endsWith(camelled, 'Ids')) {
    set(res, `${singular(camelled)}Ids`, error.message);
  }

  return res;
}, {});
