import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { compose, graphql, Query } from 'react-apollo';
import FieldError from '../../common/form/FieldError';
import {
  UPLOAD_DOCUMENT,
  DESTROY_PERSON_IDENTITY,
  DYN_UPLOAD_MULTIPLE_DOCUMENTS,
} from '../../../graphQL/mutations/bookings';
import MultiplePhotoUpload from '../../common/photos/MultiplePhotoUpload';

class Photos extends Component {
  render() {
    const {
      documentKind, destroyPhoto, query, variables, documentsDataPath,
    } = this.props;

    return (
      <Query query={query} variables={variables}>
        {({ data }) => {
          const photos = get(data, documentsDataPath);
          const personId = get(data, 'booking.people[0].id');
          const uploadDisabled = photos.filter(document => document.kind === documentKind).length > 0;
          return (
            <Fragment>
              <FieldError direction="column" name="documents" />
              <MultiplePhotoUpload
                photos={photos}
                photoDataPath="file"
                destroyPhoto={destroyPhoto}
                refetchQueries={[{ query, variables }]}
                additionalUploadVariables={{
                  personId,
                  kind: documentKind,
                }}
                additionalDeleteVariables={{ personId }}
                createPhotosUploadMutation={DYN_UPLOAD_MULTIPLE_DOCUMENTS}
                filterDisplayedPhotos={document => document.kind === documentKind}
                uploadDisabled={uploadDisabled}
              />
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

Photos.propTypes = {
  documentKind: PropTypes.string,
  destroyPhoto: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  documentsDataPath: PropTypes.string.isRequired,
};

Photos.defaultProps = {
  variables: {},
};

const enhance = compose(
  graphql(UPLOAD_DOCUMENT, { name: 'createOrUpdatePhoto' }),
  graphql(DESTROY_PERSON_IDENTITY, { name: 'destroyPhoto' }),
);

export default enhance(Photos);
