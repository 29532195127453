import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { GET_USER_CARD } from '../../../../graphQL/queries/user';
import { withStripeWrapper } from '../../../bookings/Bookings';
import Condition from '../../../common/form/Condition';
import FieldError from '../../../common/form/FieldError';
import Radio from '../../../common/form/Radio';
import Loader from '../../../common/Loader';
import Panel from '../../../common/Panel';
import Text from '../../../common/Text';
import Body from '../../../common/text/Body';
import { FilledRoundedButton } from '../../../common/wizard/Footer';
import CreditCardModal from '../../tenant/CreditCardModal';

const PaymentMethods = ({ t }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  return (
    <Panel title={t('settings.paymentMethods.title')} mb={20}>
      <Body mb={20}>{t('settings.paymentMethods.subText')}</Body>
      <FieldError name="paymentMethod">
        <Radio name="paymentMethod" label={t('settings.paymentMethods.variants.0')} value="bank_transfer" />
        <Radio name="paymentMethod" label={t('settings.paymentMethods.variants.1')} value="credit_card" />
      </FieldError>
      <Condition when="paymentMethod" is="bank_transfer">
        <Body my={20}>Bank Transfer</Body>
        <Body mb={20}>You have selected to make a monthly bank transfer to the property owner or authorised host. You must set up an automated standing order to be paid on the payment date every month for the duration of the lease beginning from the second month. The monthly payment date is the date you have selected for your lease to begin.</Body>
        <Body mb={20}>Payment should be made to the following account:</Body>
        <Body>Bank: Barclays Bank</Body>
        <Body>Account Name: Downroots Client Account</Body>
        <Body>Account Number: 2234567</Body>
        <Body mb={20}>Sort Code: 20-29-87</Body>
        <Body mb={20}>Add the following reference: DR22345</Body>
        <Body mb={20}>If you are sending from a non UK bank account you will need the following:</Body>
        <Body>IBAN: 445RJFIERJOIRTJEOIRTJ45</Body>
        <Body mb={20}>SWIFT: 665500D</Body>
        <Body>Add the following reference: DR22345</Body>
      </Condition>
      <Condition when="paymentMethod" is="credit_card">
        <FilledRoundedButton mt={10} onClick={() => setIsModalOpened(true)}>{t('settings.paymentMethods.addCard')}</FilledRoundedButton>
        {isModalOpened && (
          <CreditCardModal onClose={() => setIsModalOpened(false)} />
        )}
        <Query query={GET_USER_CARD}>
          {({ loading, data }) => {
            if (loading) {
              return <Loader />;
            }

            if (isEmpty(data.user.defaultCreditCard)) {
              return (
                <Text mt={20}>No credit /debit card added</Text>
              );
            }

            return (
              <Text mt={20}>
                Current card: **** **** **** ****
                {' '}
                {data.user.defaultCreditCard.last4}
              </Text>
            );
          }}
        </Query>

      </Condition>
    </Panel>
  );
};

PaymentMethods.propTypes = {
  t: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('account'),
  withStripeWrapper,
);

export default enhance(PaymentMethods);
