import React from 'react';
import { APP_ROUTES } from '../../../constants';
import { NavBar, NavBarItem } from '../../common/NavBar';

const LandlordNavBar = () => (
  <NavBar>
    <NavBarItem to={APP_ROUTES.DASHBOARD.LANDLORD.LISTINGS()}>
      Listings
    </NavBarItem>
    <NavBarItem exact={false} to={APP_ROUTES.DASHBOARD.LANDLORD.TENANCIES()}>
      Bookings
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.DASHBOARD.LANDLORD.TRANSACTIONS()}>
      Transactions
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.DASHBOARD.LANDLORD.PROFILE()}>
      Profile
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.DASHBOARD.LANDLORD.SETTINGS()}>
      Settings
    </NavBarItem>
  </NavBar>
);

export default LandlordNavBar;
