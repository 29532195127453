import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { ErrorBox } from './FieldError';
import { theme } from '../../../theme/globalStyle';

class StripeInput extends React.Component {
  handleChange = (changeObj) => {
    this.props.onChange(changeObj.empty ? undefined : changeObj);
  };

  render() {
    const {
      component: Component, meta, placeholder, label,
    } = this.props;
    return (
      <Fragment>
        {label && <Text mb={10}>{label}</Text>}
        <Component
          placeholder={placeholder}
          style={{
            base: {
              fontSize: '18px',
              fontFamily: 'Nunito Sans',
            },
            invalid: {
              color: theme.colors.darkBrown,
            },
          }}
          className="stripe-input"
          onChange={this.handleChange}
        />
        <ErrorBox meta={meta} />
      </Fragment>
    );
  }
}

StripeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

StripeInput.defaultProps = {
  placeholder: '',
};

export default StripeInput;
