import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import BookingBox from './BookingBox';
import { GET_BOOKING_BOX_DATA } from '../../graphQL/queries/bookings';

const BookingBoxContainer = ({ match }) => (
  <Query query={GET_BOOKING_BOX_DATA} variables={{ id: match.params.id }}>
    {({ data }) => {
      const studentGuarantator = get(data, 'booking.studentGuarantor');
      const moveOutGuarantee = get(data, 'booking.moveOutGuarantee');

      const dueOnLease = [
        {
          name: 'First months rent',
          value: get(data, 'booking.firstMonthsRentValue', 0),
        },
        { name: 'Deposit', value: get(data, 'booking.depositValue', 0) },
        { name: 'Total', value: get(data, 'booking.signTotal', 0) },
      ];

      if (studentGuarantator) {
        dueOnLease.splice(1, 0, {
          name: 'Student guarantor',
          value: get(data, 'booking.studentGuarantorValue', 0),
        });
      }
      if (moveOutGuarantee) {
        dueOnLease.splice(2, 0, {
          name: 'Move out guarantee',
          value: get(data, 'booking.moveOutGuaranteeValue', 0),
        });
      }

      return (
        <BookingBox
          startDate={get(data, 'booking.startDate')}
          mainImage={get(data, 'booking.bookedObject.mainImageUrl')}
          stayMonths={get(data, 'booking.stayMonths')}
          address={get(data, 'booking.bookedObject.location', '')}
          listingType={get(data, 'booking.bookedObject.listingType', '')}
          monthlyRent={get(data, 'booking.rentValue', 0)}
          monthlyUtilityPrice={get(data, 'booking.utilitiesValue', 0)}
          dueNow={[
            {
              name: 'Reservation fee',
              value: get(data, 'booking.reservationFee', 0),
            },
          ]}
          dueOnLease={dueOnLease}
        />
      );
    }}
  </Query>
);

BookingBoxContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BookingBoxContainer;
