import get from 'lodash/get';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import { toast } from 'react-toastify';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '../../../constants';
import { BOOK } from '../../../graphQL/mutations/bookings';
import { GET_BOOKING_BASICS } from '../../../graphQL/queries/bookings';
import { FONT_WEIGHTS } from '../../../theme/globalStyle';
import { mapApiErrors } from '../../../utils/errorHandling';
import { FormPart, FormSubPart } from '../../common/form/FormWrapper';
import { Separator } from '../../common/form/Separator';
import Text from '../../common/Text';
import Footer, { FilledRoundedButton } from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';

const PaymentBox = styled(Flex).attrs({
  width: [1, '300px', '300px'],
})`
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.green};
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 40px;
  min-width: 300px;
`;

const Link = styled.a`
  font-weight: ${props => props.theme.fontWeights[FONT_WEIGHTS.BOLDER]};
  color: ${props => props.theme.colors.darkBlue};
`;

class ViewLeaseAndBook extends Component {
  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.BOOKINGS.PAYMENT(match.params.id));
  };

  onNext = noop;

  onSubmit = async (values) => {
    try {
      const { history, match, mutate } = this.props;

      const { data, errors } = await mutate({
        variables: {
          id: match.params.id,
          ...values,
        },
      });

      if (errors) {
        return mapApiErrors(errors);
      }

      history.push(APP_ROUTES.BOOKINGS.PAYMENT(data.createOrUpdateBooking.id));
    } catch (e) {
      console.log(e);
    }
  };

  onBookNow = async () => {
    try {
      const { history, mutate } = this.props;
      const { errors } = await mutate();

      if (errors) {
        toast.error(JSON.stringify(mapApiErrors(errors), null, 4), {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
      history.push(APP_ROUTES.DASHBOARD.TENANT.BOOKINGS());
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <WizardStep query={GET_BOOKING_BASICS} withoutLayout>
          {({ data }) => (
            <>
              <FormPart title="What happens next">
                <FormSubPart subTitle="The lease" subText="Personal and booking information will be inserted into this lease agreement and emailed to you for digital your signature">
                  <Link rel="noopener noreferrer" target="_blank" href={data.booking.emptyContractLink}>
                    View lease agreement
                  </Link>
                </FormSubPart>
              </FormPart>
              <Separator />
              <FormSubPart subTitle="Extending" subText="The listing will be made available to book 12 weeks before your move out date. You will be notified 13 weeks before and have a one week priority booking period to reserve a new lease with the owners acceptance. If you have selected a 3 month lease the listing remains available to be booked by another party from today." />
              <Separator />
              <FormSubPart subTitle="Move in and out" subText="The Downroots concierge team can liaise with you to help arrange your move in and move out." />
              <Separator />
              <FormSubPart subTitle="After you make this booking" subText="The owner will be asked to accept your booking and any further background checks required will be carried out. You will then be emailed the lease to sign digitally. Then get ready to move! The Downroots concierge team are available for you throughout." />
              <PaymentBox>
                <Text>PAYMENT DUE NOW</Text>
                <Flex justifyContent="space-between">
                  <Text mr="auto">Reservation fee</Text>
                  <Text>£95.00</Text>
                </Flex>
              </PaymentBox>
            </>
          )}
        </WizardStep>
        <Footer
          omitNext
          onPrev={this.onPrev}
          disabledSaveAndExit
        >
          <FilledRoundedButton px={[30, 60, 60]} py={[10, 20, 20]} onClick={this.onBookNow}>Book now</FilledRoundedButton>
        </Footer>
      </Fragment>
    );
  }
}

ViewLeaseAndBook.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = graphql(BOOK, {
  options: props => ({
    variables: {
      id: get(props, 'match.params.id'),
    },
  }),
});

export default enhance(ViewLeaseAndBook);
