import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontSize } from '../../../theme/styleUtils';

const FormLabel = styled('label')`
  ${fontSize(17)}
  line-height: 1.6rem;
  color: ${props => props.theme.colors.darkBrown};
  margin-bottom: 5px;
  ${props => props.required &&
    `
    ::after {
      content: '*';
      margin-left: 3px;
      color: ${props.theme.colors.pinkRed};
    }
  `}
`;

FormLabel.propTypes = {
  required: PropTypes.bool,
};

FormLabel.defaultProps = {
  required: false,
};

export default FormLabel;
