import { Box } from 'rebass';
import styled from 'styled-components';

const ProgressBar = styled(Box)`
  @keyframes progressBar {
    0% {
      width: 0;
    }
  }
  height: 4px;
  background-color: ${props => props.theme.colors.dustGray};
  position: relative;
  &::after {
    content: '';
    width: ${props => props.progress}%;
    height: 4px;
    background-color: ${({ barColor, theme }) => barColor || theme.colors.purplePrimary};
    position: absolute;
    animation: progressBar 1.5s ease-in-out;
  }
`;

export default ProgressBar;
