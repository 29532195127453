import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import Helmet from 'react-helmet-async';

import RegisterForm from '../registration/RegisterForm';
import AuthLayout, { LinkText } from './AuthLayout';
import { fontMixin, LIGHT, fontSize } from '../../theme/styleUtils';

const RegisterTitle = styled(Text)`
  ${fontMixin(LIGHT)}
  ${fontSize(17)}
  text-align: center;
`;


class Register extends Component {
  render() {
    const { t } = this.props;
    return (
      <AuthLayout title="Sign up" link="/signup">
        <Helmet>
          <title>{t('signup.signup')}</title>
        </Helmet>
        <RegisterForm t={t} />
        <RegisterTitle mt={15}>
          {`${t('signup.already_have_an_account')} `}
          <LinkText to="/sign-in">{t('signup.already_have_an_account_link')}</LinkText>
        </RegisterTitle>
      </AuthLayout>
    );
  }
}

Register.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('auth')(Register);
