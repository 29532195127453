import PropTypes from 'prop-types';
import React, { useState } from 'react';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { Flex, Button } from 'rebass';
import styled, { css } from 'styled-components';
import { Portal } from 'react-portal';
import { GET_PLACE_FOR_FOOTER } from '../../../graphQL/queries/getPlace';
import {
  fontMixin, transitionBackgroundColor, BOLD,
} from '../../../theme/styleUtils';
import { APP_ROUTES, LAYOUT_PX_RWD } from '../../../constants';
import Error from '../Error';

export const FooterButton = styled(Button)`
  border-radius: 5px;
  height: 40px;
`;

export const disableMixin = props => props.disabled && css`
  background-color: ${props.theme.colors.lightGray};
  color: ${props.theme.colors.darkBrown};
  border-color: ${props.theme.colors.lightGray};
  cursor: not-allowed;
  :hover {
    background-color: ${props.theme.colors.lightGray};
    color: ${props.theme.colors.darkBrown};
  }
  :active {
    pointer-events: none;
  }
`;

export const RoundedButton = styled(Button)`
  ${transitionBackgroundColor('.3s')}
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.purplePrimary};
  background-color: transparent;
  color: ${props => props.theme.colors.purplePrimary};
  :hover {
    background-color: ${props => props.theme.colors.purplePrimary};
    color: white;
  }
  ${disableMixin}
  &:focus {
    outline: none;
  }
`;
RoundedButton.defaultProps = {
  fontSize: 16,
  py: '3px',
  px: '23px',
};

export const FilledRoundedButton = styled(RoundedButton)`
  ${fontMixin(BOLD)}
  background-color: ${props => props.theme.colors.purplePrimary};
  color: white;
  transition: opacity .3s ease-in-out;
  white-space: nowrap;
  ${disableMixin}
  :hover {
    opacity: 0.6;
  }
`;


const FooterWrapper = styled(Flex)`
  height: 62px;
  border-top: 1px solid rgba(121,121,121,0.32);
  position: fixed;
  background-color: #fff;
  bottom: 0;
`;

export const BaseFooter = ({ children, px }) => (
  <Portal>
    <div style={{ height: '62px' }} />
    <FooterWrapper width={1} justifyContent="space-between" alignItems="center" px={px || LAYOUT_PX_RWD}>
      {children}
    </FooterWrapper>
  </Portal>
);

BaseFooter.propTypes = {
  children: PropTypes.node,
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Footer = ({
  onPrev,
  onNext,
  omitPrev,
  omitNext,
  match,
  history,
  onSaveAndExit,
  disabledSaveAndExit,
  disabledNext,
  children,
  renderNext,
  isPlace,
  virtualTourNeededForDone,
}) => {
  const [hidden, setHidden] = useState(false);
  const placeId = match.params.placeId || match.params.id;
  return (
    <BaseFooter>
      { isPlace && placeId && (
        <Query query={GET_PLACE_FOR_FOOTER} variables={{ id: placeId }}>
          {({ loading, data, error }) => {
            if (loading) return null;
            if (error) return <Error />;

            const additionalDoneCondition = virtualTourNeededForDone ? !!data.place.virtualTourBookedAt : true;
            if (get(data, 'place.filledStep') === 3 && additionalDoneCondition) {
              setHidden(true);

              return (
                <Flex justifyContent="flex-start" width={1}>
                  <FilledRoundedButton
                    type="button"
                    disabled={disabledNext}
                    onClick={onNext}
                    px={30}
                    py={10}
                  >
                    Done
                  </FilledRoundedButton>
                </Flex>
              );
            }

            setHidden(false);
            return null;
          }}
        </Query>
      )}
      {!hidden && (
        <>
          <Flex justifyContent="flex-start" alignItems="center">
            {!omitPrev && <RoundedButton px={30} py={10} mr={15} type="button" onClick={onPrev}>Back</RoundedButton>}
            {!omitNext && (
              renderNext ||
              <FilledRoundedButton px={30} py={10} type="button" disabled={disabledNext} onClick={onNext}>Next</FilledRoundedButton>
            )}
          </Flex>
          {!disabledSaveAndExit && (
            <Flex width={150}>
              <RoundedButton
                px={30}
                py={10}
                onClick={async () => {
                  if (!omitNext) {
                    await onNext();
                  }
                  if (onSaveAndExit) {
                    onSaveAndExit();
                  } else {
                    history.replace(APP_ROUTES.LISTINGS.PLACE_INTRO(match.params.placeId || match.params.id));
                  }
                }}
              >
                Save and Exit
              </RoundedButton>
            </Flex>
          )}
          {children}
        </>
      )}
    </BaseFooter>
  );
};

Footer.propTypes = {
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onSaveAndExit: PropTypes.func,
  omitPrev: PropTypes.bool,
  omitNext: PropTypes.bool,
  disabledSaveAndExit: PropTypes.bool,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  disabledNext: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  renderNext: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isPlace: PropTypes.bool,
  virtualTourNeededForDone: PropTypes.bool,
};

Footer.defaultProps = {
  omitNext: false,
  omitPrev: false,
  disabledSaveAndExit: false,
  virtualTourNeededForDone: false,
};

export default withRouter(Footer);
