import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Flex } from 'rebass';
import TableColumnBox from '../../../common/TableColumn';
import { formatPrice } from '../../../../utils/prices';

const BookingDetailsTable = ({ booking }) => (
  <Flex width={1}>
    <TableColumnBox
      title="Move in"
      content={moment(booking.startDate).format('MMMM DD YYYY')}
    />
    <TableColumnBox title="Term" content={`${booking.stayMonths} months`} />
    <TableColumnBox title="Rent" content={formatPrice(booking.rentValue)} />
    <TableColumnBox
      title="Utilities"
      content={formatPrice(booking.utilitiesValue)}
    />
    <TableColumnBox
      title="Deposit"
      content={formatPrice(booking.depositValue)}
    />
    <TableColumnBox
      title="Move out"
      content={formatPrice(booking.moveOutGuaranteeValue)}
    />
  </Flex>
);

BookingDetailsTable.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default BookingDetailsTable;
