import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import Text from '../common/Text';
import SignInForm from './SignInForm';
import AuthLayout, { LinkText } from './AuthLayout';
import { fontMixin, fontSize, LIGHT } from '../../theme/styleUtils';

const SubLink = styled(LinkText)`
  ${fontMixin(LIGHT)}
  ${fontSize(15)}
  text-align: center;
`;

const SubText = styled(Text)`
  ${fontMixin(LIGHT)}
  ${fontSize(17)}
  text-align: center;
`;

const SignInPage = () => (
  <AuthLayout title="Sign in" link="/sign-in">
    <SignInForm />
    <Flex alignItems="center" flexDirection="column" mt={15}>
      <SubLink to="/signin/sendresetpassword">Forgot password?</SubLink>
      <SubText mt={30}>
        {"Don't yet have an account? "}
        <LinkText to="/signup">Sign up</LinkText>
      </SubText>
    </Flex>
  </AuthLayout>
);

export default SignInPage;
