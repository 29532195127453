import reduce from 'lodash/reduce';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

export default objectToMap => reduce(
  objectToMap,
  (res, val, key) => {
    const parsed = toNumber(val);
    return {
      ...res,
      [key]: isNaN(parsed) ? val : parsed,
    };
  },
  {},
);
