import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { Box, Flex } from 'rebass';
import omit from 'lodash/omit';
import { compose } from 'recompose';
import { graphql, Query } from 'react-apollo';
import { showSuccess } from '../../../utils/toast';
import { NarrowLayout } from '../../common/Layout';
import Title from '../../common/text/Title';
import { FilledRoundedButton } from '../../common/wizard/Footer';
import ChangePasswordForm from '../common/settings/ChangePasswordForm';
import ContactOptions from '../common/settings/ContactOptions';
import PaymentMethods from '../common/settings/PaymentMethods';
import PayoutMethods from '../common/settings/PayoutMethods';
import { SAVE_USER_SETTINGS } from '../../../graphQL/mutations/user';
import { mapApiErrors } from '../../../utils/errorHandling';
import { GET_TENANT_SETTINGS } from '../../../graphQL/queries/getCurrentUser';
import Loading from '../../common/Loading';
import Error from '../../common/Error';

class Settings extends Component {
  onSubmit = async (values) => {
    const { saveUserSettings } = this.props;

    const { errors } = await saveUserSettings({ variables: values });
    if (errors) {
      return mapApiErrors(errors);
    }

    showSuccess('Settings successfully updated');
  };

  getInitialValues = data => ({
    ...data.user,
    payoutMethod: omit(data.user.payoutMethod, '__typename'),
  });

  render() {
    const { t } = this.props;
    return (
      <Flex flexDirection="column">
        <Helmet>
          <title>{t('settings.title')}</title>
        </Helmet>
        <NarrowLayout>
          <Title>{t('settings.title')}</Title>
          <ChangePasswordForm />
          <Query query={GET_TENANT_SETTINGS}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return <Error />;
              return (

                <Form
                  onSubmit={this.onSubmit}
                  initialValues={this.getInitialValues(data)}
                >
                  {({ handleSubmit, submitting }) => (
                    <Flex flexDirection="column">
                      <PaymentMethods />
                      <PayoutMethods />
                      <ContactOptions isTenant />
                      <Box>
                        <FilledRoundedButton py={15} px={60} width={[1, 'initial', 'initial']} onClick={handleSubmit} disabled={submitting}>
                          {t('settings.done')}
                        </FilledRoundedButton>
                      </Box>
                    </Flex>
                  )}
                </Form>
              );
            }}
          </Query>
        </NarrowLayout>
      </Flex>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func.isRequired,
};

const enhance = compose(
  withNamespaces('account'),
  graphql(SAVE_USER_SETTINGS, { name: 'saveUserSettings' }),
);

export default enhance(Settings);
