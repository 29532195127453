import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import { withNamespaces } from 'react-i18next';
import BorderedBox from './BorderedBox';
import BenefitSection from './BenefitSection';
import { ReactComponent as GlobeIcon } from '../../assets/img/globe-icon.svg';
import { ReactComponent as YieldTracker } from '../../assets/img/landlord-benefits/yield-tracker.svg';
import { ReactComponent as ClearMedia } from '../../assets/img/landlord-benefits/clear-online-media.svg';
import { ReactComponent as CoLivingEntirePlace } from '../../assets/img/landlord-benefits/co-living-entire-place.svg';
import { ReactComponent as AutomatedPayments } from '../../assets/img/landlord-benefits/automated-payments.svg';
import { ReactComponent as UkTenancyAggreements } from '../../assets/img/landlord-benefits/uk-tenancy-aggreements.svg';

import targetedSocialAds from '../../assets/img/landlord-benefits/targeted-social-ads.png';
import { ReactComponent as PropertyFollowers } from '../../assets/img/landlord-benefits/property-followers.svg';
import { ReactComponent as MoveInPromotions } from '../../assets/img/landlord-benefits/move-in-promotions.svg';
import { ReactComponent as StunningCampaigns } from '../../assets/img/landlord-benefits/stunning-campaigns.svg';
import { ReactComponent as ZeroDepositPercent } from '../../assets/img/landlord-benefits/percent-sign.svg';
import { ReactComponent as NeverMissABooking } from '../../assets/img/landlord-benefits/never-miss-a-booking.svg';

/* eslint-disable react/no-array-index-key */
class LandlordBenefits extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;
    return (
      <Flex flexDirection="column" mb={15} alignItems="flex-start">
        <BenefitSection
          mt={40}
          title={t('benefits.promote.title')}
          desc={t('benefits.promote.desc')}
          button={t('benefits.promote.button')}
        >
          <BorderedBox title={t('benefits.promote.items.targetedSocialAds.title')} imgSrc={targetedSocialAds} />
          <BorderedBox title={t('benefits.promote.items.propertyFollowers.title')}>
            <PropertyFollowers />
          </BorderedBox>
          <BorderedBox title={t('benefits.promote.items.moveInPromotions.title')}>
            <MoveInPromotions />
          </BorderedBox>
          <BorderedBox title={t('benefits.promote.items.stunningCampaigns.title')}>
            <StunningCampaigns />
          </BorderedBox>
          <BorderedBox title={t('benefits.promote.items.zeroDepositInsurance.title')}>
            <Flex mt={10}>
              <Text color="paleBlue" fontSize={53}>0</Text>
              <ZeroDepositPercent
                style={{
                  height: 40, width: 28, marginTop: '5px', marginLeft: '5px',
                }}
              />
            </Flex>
          </BorderedBox>
          <BorderedBox title={t('benefits.promote.items.neverMissABooking.title')}>
            <NeverMissABooking />
          </BorderedBox>
        </BenefitSection>

        <BenefitSection
          mt={40}
          title={t('benefits.income.title')}
          desc={t('benefits.income.desc')}
          button={t('benefits.income.button')}
        >
          <BorderedBox title={t('benefits.income.items.worldwideDemand.title')}>
            <GlobeIcon />
          </BorderedBox>
          <BorderedBox title={t('benefits.income.items.yieldTracker.title')}>
            <YieldTracker />
          </BorderedBox>
          <BorderedBox title={t('benefits.income.items.clearMedia.title')}>
            <ClearMedia />
          </BorderedBox>
          <BorderedBox title={t('benefits.income.items.coLivingEntirePlace.title')}>
            <CoLivingEntirePlace />
          </BorderedBox>
          <BorderedBox title={t('benefits.income.items.automatedPayments.title')}>
            <AutomatedPayments />
          </BorderedBox>
          <BorderedBox title={t('benefits.income.items.ukTenancyAggreements.title')}>
            <UkTenancyAggreements />
          </BorderedBox>
        </BenefitSection>
      </Flex>
    );
  }
}

export default withNamespaces('owners')(LandlordBenefits);
