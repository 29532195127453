import PropTypes from 'prop-types';
import { Text } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

export const TITLE_VARIANTS = {
  SMALL: 'small',
  LARGE: 'large',
};

const Title = styled(Text).attrs(props => ({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: props.variant === TITLE_VARIANTS.LARGE ? '24px' : '22px',
  mb: props.mb || '20px',
}))``;

Title.propTypes = {
  variant: PropTypes.oneOf([TITLE_VARIANTS.SMALL, TITLE_VARIANTS.LARGE]),
};

Title.defaultProps = {
  variant: TITLE_VARIANTS.LARGE,
};

export default Title;
