import React from 'react';
import { APP_ROUTES } from '../../../constants';
import { NavBar, NavBarItem } from '../../common/NavBar';

const TenantNavBar = () => (
  <NavBar>
    <NavBarItem to={APP_ROUTES.DASHBOARD.TENANT.BOOKINGS()}>
      Bookings
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.DASHBOARD.TENANT.TRANSACTIONS()}>
      Transactions
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.DASHBOARD.TENANT.PROFILE()}>
      Profile
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.DASHBOARD.TENANT.SETTINGS()}>
      Settings
    </NavBarItem>
  </NavBar>
);

export default TenantNavBar;
