import React from 'react';
import { Flex, Text, Image } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../theme/globalStyle';

const Wrapper = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.dustGray};
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
  height: 100%;
`;

const BorderedBox = ({
  title, imgSrc, children, width, ...props
}) => (
  <Wrapper
    {...props}
    width={width}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    py={35}
  >
    <Text mb={20} fontFamily={FONT_FAMILIES.AVENIR_BOLD} fontWeight={700} fontSize={16} letterSpacing={1.6} textAlign="center">{title}</Text>
    {imgSrc && <Image width={0.8} src={imgSrc} mt={20} />}
    {children}
  </Wrapper>
);

BorderedBox.defaultProps = {
  px: 20,
  py: 10,
  width: 1,
};

export default BorderedBox;
