import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Flex } from 'rebass';

import noViewings from '../../assets/img/no-viewings.png';
import noNegotiations from '../../assets/img/no-negotiations.png';
import noCosts from '../../assets/img/no-costs.png';
import TitleDescription from '../text/TitleDescription';
import WayPhotoCard from '../cards/WayPhotoCard';

class WhyDownroots extends Component {
  render() {
    const { t } = this.props;
    return (
      <Flex flexDirection="column" mx="auto" justifyContent="center" mt={100}>
        <TitleDescription
          title={t('why.title')}
          desc={t('why.desc')}
        />
        <Flex width={1} mt={50} justifyContent="space-between" alignItems="center" flexWrap="nowrap" flexDirection={['column', 'column', 'row']}>
          <WayPhotoCard
            title={t('way.noviewings.title')}
            desc={t('way.noviewings.desc')}
            imgSrc={noViewings}
          />
          <WayPhotoCard
            title={t('way.noneg.title')}
            desc={t('way.noneg.desc')}
            imgSrc={noNegotiations}
          />
          <WayPhotoCard
            title={t('way.nocost.title')}
            desc={t('way.nocost.desc')}
            imgSrc={noCosts}
          />
        </Flex>
      </Flex>
    );
  }
}

WhyDownroots.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withNamespaces('owners')(WhyDownroots);
