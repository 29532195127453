import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { Form } from 'react-final-form';
import Dropdown from '../../common/form/Dropdown';
import DatePicker from '../../common/form/DatePicker';

const TransactionsForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Flex width="400px">
          <Box width={0.6} pr={25}>
            <Dropdown
              placeholder="All listings"
              name="listingType"
              options={[{ value: 'all', label: 'All listings' }]}
            />
          </Box>
          <Box width={0.4}>
            <DatePicker name="date" placeholder="Dates" />
          </Box>
        </Flex>
      </form>
    )}
  />
);

TransactionsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TransactionsForm;
