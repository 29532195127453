import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { FilledRoundedButton } from '../../../common/wizard/Footer';
import BookingResultModal from '../BookingResultModal';
import { fontSize } from '../../../../theme/styleUtils';


const DecisionText = styled(Text)`
  ${fontSize(17)}
  letter-spacing: 1.2px;
  color: purplePrimary;
`;

const BookingModals = ({ t, onClose, openedModal }) => (
  <>
    {openedModal && openedModal === 'accept' && (
      <BookingResultModal
        onClose={onClose}
        content={t('bookingCompleteModal', { returnObjects: true })}
      >
        <DecisionText
          mt={20}
          onClick={onClose}
        >
          Close
        </DecisionText>
      </BookingResultModal>
    )}
    {openedModal && openedModal === 'decline' && (
      <BookingResultModal
        onClose={onClose}
        content={t('bookingDeclineModal', { returnObjects: true })}
      >
        <Flex alignItems="center" my={20}>
          <FilledRoundedButton mr={50} px={20} py={10} onClick={onClose}>
            View booking
          </FilledRoundedButton>
          <DecisionText>
            I want to decline booking
          </DecisionText>
        </Flex>
      </BookingResultModal>
    )}
  </>
);
BookingModals.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('tenancies')(BookingModals);
