import PropTypes from 'prop-types';
import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const modifiersStyles = {
  blocked: {
    color: 'white',
    backgroundColor: '#e21c21',
  },
};

const CalendarPreview = ({ blocked }) => {
  const modifiers = {
    blocked,
  };

  return (
    <DayPicker
      month={new Date()}
      modifiers={modifiers}
      modifiersStyles={modifiersStyles}
      numberOfMonths={2}
    />
  );
};

CalendarPreview.propTypes = {
  blocked: PropTypes.array.isRequired,
};

export default CalendarPreview;
