import gql from 'graphql-tag';

export const STUDENT_PARTS = gql`
  fragment StudentParts on User {
    university
    studentLoan
  }
`;

export const EMPLOYED_PARTS = gql`
  fragment EmployedParts on User {
    jobTitle
    businessName
  }
`;

export const SELF_EMPLOYED_PARTS = gql`
  fragment SelfEmployedParts on User {
    jobTitle
    businessName
  }
`;
