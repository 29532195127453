import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { Route } from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box } from 'rebass';
import { compose, renderNothing } from 'recompose';
import { ThemeProvider } from 'styled-components';
import MasterLogin from './components/auth/MasterLogin';
import Register from './components/auth/Register';
import RegisterSuccess from './components/auth/RegisterSuccess';
import ResetPassword from './components/auth/ResetPassword';
import SendResetPassword from './components/auth/SendResetPassword';
import SignInPage from './components/auth/SignInPage';
import Bookings from './components/bookings/Bookings';
import AppRoute from './components/common/AppRoute';
import PrivateAppRoute from './components/common/PrivateAppRoute';
import RedirectAfterLogin from './components/common/RedirectAfterLogin';
import Dashboard from './components/dashboard/Dashboard';
import ErrorBoundary from './components/ErrorBoundary';
import HomeWrapper from './components/home/HomeWrapper';
import ListingDetails from './components/listings/details/ListingDetails';
import ListingsWrapper from './components/listings/list/ListingsWrapper';
import Place from './components/listings/Place';
import NoDesignPage from './components/NoDesignPage';
import OwnersWrapper from './components/owners/OwnersWrapper';
import StaticPageWrapper from './components/StaticPageWrapper';
import UserProfileEdit from './components/user/profile/Profile';
import Welcome from './components/welcome/Welcome';
import { APP_ROUTES } from './constants';
import './i18n';
import { theme } from './theme/globalStyle';
import FullWideLayout from './components/common/FullWideLayout';
import Four04 from './components/Four04';
import { TransparentNavBar } from './components/common/NavBar';
import { customizedMainNavBar } from './components/navigation/MainNavBar';

class App extends Component {
  render() {
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <Fragment>
            <Helmet
              defaultTitle="Downroots"
              titleTemplate="%s | Downroots"
            >
              <meta name="description" content="Downroots app" />
            </Helmet>
            <ErrorBoundary>
              <Switch>
                <AppRoute exact path="/" layout={FullWideLayout} navBar={customizedMainNavBar(TransparentNavBar)} component={StaticPageWrapper(HomeWrapper)} />
                <AppRoute exact path="/owners/" layout={FullWideLayout} component={StaticPageWrapper(OwnersWrapper)} />
                <AppRoute exact path="/partners" component={NoDesignPage} />
                <AppRoute exact path="/help" component={NoDesignPage} />
                <AppRoute exact path="/welcome" navBar={renderNothing()} component={Welcome} />
                <AppRoute exact path="/sign-in" navBar={renderNothing()} component={SignInPage} />
                <AppRoute exact path="/master-login/:masterToken" navBar={renderNothing()} component={MasterLogin} />
                <AppRoute exact path="/signup" navBar={renderNothing()} component={Register} />
                <AppRoute exact path="/signup/success" component={StaticPageWrapper(RegisterSuccess)} />
                <AppRoute exact path="/listings/" layout={FullWideLayout} component={ListingsWrapper} />
                <AppRoute exact path={APP_ROUTES.LISTINGS.DETAILS()} layout={FullWideLayout} component={ListingDetails} />
                <AppRoute exact path="/signin/sendresetpassword" component={SendResetPassword} />
                <AppRoute exact path="/signin/resetpassword" component={ResetPassword} />
                <AppRoute exact path="/signup/" component={Register} />
                <AppRoute exact path="/signup/success" component={RegisterSuccess} />
                <PrivateAppRoute
                  component={Place}
                  navBar={renderNothing()}
                  path="/listings/place"
                  roles={['landlord']}
                  layout={Box}
                />
                <PrivateAppRoute
                  component={Bookings}
                  navBar={renderNothing()}
                  path={APP_ROUTES.BOOKINGS.ROOT()}
                  roles={['tenant']}
                  layout={Box}
                />
                <PrivateAppRoute
                  component={Dashboard}
                  navBar={renderNothing()}
                  path={APP_ROUTES.DASHBOARD.ROOT()}
                  roles={['landlord', 'tenant']}
                  layout={Box}
                />
                <PrivateAppRoute path="/profile/edit" component={UserProfileEdit} roles={['landlord', 'tenant']} />
                <Route path="*" component={Four04} roles={['landlord', 'tenant']} />
              </Switch>
            </ErrorBoundary>
            <ToastContainer />
            <RedirectAfterLogin />
          </Fragment>
        </ThemeProvider>
      </Router>
    );
  }
}

const enhance = compose(
  withNamespaces('auth'),
);

export default enhance(App);
