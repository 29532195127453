import React from 'react';
import PropTypes from 'prop-types';
import DropzoneRaw from 'react-dropzone';

const Dropzone = ({ children, onDrop }) => (
  <DropzoneRaw onDrop={onDrop}>
    {({ getRootProps, getInputProps }) => (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </div>
    )}
  </DropzoneRaw>
);

Dropzone.propTypes = {
  children: PropTypes.node,
  onDrop: PropTypes.func.isRequired,
};

export default Dropzone;
