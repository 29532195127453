import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const BookingsWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const BookingsFormWrapper = styled(Flex)`
  flex-direction: column;
  align-items: left;
  margin: 0px;
  margin-right: 40px;
  margin-bottom: 40px;
  width: 100%;
`;

const FullWideLayout = ({ navBar: NavBar, children }) => (
  <Fragment>
    <NavBar />
    {children}
  </Fragment>
);


FullWideLayout.propTypes = {
  children: PropTypes.element,
  navBar: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default FullWideLayout;
