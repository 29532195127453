import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Image, Box } from 'rebass';
import styled, { css } from 'styled-components';
import trashBin from '../../../assets/img/trash-bin.png';

const DeleteButton = styled.button.attrs({ type: 'button' })`
  background: url(${trashBin});
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  bottom: -22px;
  right: 10px;
  width: 16px;
  height: 20px;
  background-size: contain;
`;

const PhotoWrapper = styled(Flex)`
    position: relative;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(216, 223, 230, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    ${props => (props.smaller
    ? css`
      height: 160px;
      width: 166px;
    ` : css`
      height: 180px;
      width: 190px;
    `)}

    overflow: hidden;
    img {
      object-fit: cover;
    }
`;

const DisabledCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(200,200,200,0.6);
`;

const Photo = ({
  photo, onDelete, render, smaller, children, disabled,
}) => (
  <Box style={{ position: 'relative' }}>
    <PhotoWrapper smaller={smaller}>
      { photo ?
        <Image src={photo} width="100%" height="100%" /> :
        render()
      }
      { disabled && <DisabledCover /> }
      {children}
    </PhotoWrapper>
    { onDelete && <DeleteButton onClick={onDelete} /> }
  </Box>
);

Photo.defaultProps = {
  photo: undefined,
  render: () => {},
  onDelete: undefined,
};

Photo.propTypes = {
  photo: PropTypes.string,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  render: PropTypes.func,
  smaller: PropTypes.bool,
  children: PropTypes.node,
};

export default Photo;
