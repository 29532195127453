import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, Flex, Box } from 'rebass';
import { withNamespaces } from 'react-i18next';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import map from 'lodash/map';
import join from 'lodash/join';
import size from 'lodash/size';
import filter from 'lodash/filter';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import toNumber from 'lodash/toNumber';
import capitalize from 'lodash/capitalize';
import { compose } from 'recompose';

import { fontMixin, fontSize, BOLD } from '../../../theme/styleUtils';
import { GET_ALL_PLACE_DATA } from '../../../graphQL/queries/place';
import Loading from '../../common/Loading';
import { NAVBAR_HEIGHT } from '../../../theme/globalStyle';
import { Separator } from '../../common/form/Separator';
import ShowcasePart, { ShowcaseButton, FilledShowcaseButton, ShowcasePartItem } from './ShowcasePart';
import { APP_ROUTES } from '../../../constants';

const ListingEditAddress = styled(Text)`
  ${fontMixin(BOLD)}
  ${fontSize(22)}
  letter-spacing: 0.3em;
  color: ${props => props.theme.colors.darkBrown};
`;

const LongerButton = styled(FilledShowcaseButton)`
  padding: 4px 30px;
`;

const joinObjectNames = facilityType => join(map(facilityType, ({ name }) => name), ', ');

const ListingShowcase = ({
  listingTypeCode, placeId, t, history,
}) => {
  const isCoLiving = listingTypeCode === 'co_living';

  return (
    <Query query={GET_ALL_PLACE_DATA} variables={{ id: placeId }}>
      {({ loading, data, error }) => {
        if (loading) return <Loading />;
        if (error) return <Box mt={200}>error!</Box>;
        const {
          place, place: {
            availableNiceTouches, availableComfort, availableUtilities, virtualTourBookedAt,
          },
        } = data;
        return (
          <Flex justifyContent="center" mt={NAVBAR_HEIGHT} flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center">
              <ListingEditAddress mt={20} mb={35} width={720}>{place.location}</ListingEditAddress>
              <Flex width={290} justifyContent="flex-start">
                { isCoLiving
                  ? <LongerButton onClick={() => history.push(APP_ROUTES.LISTINGS.PLACE_ROOMS(placeId))}>{t('nav.rooms')}</LongerButton>
                  : (
                    <>
                      <FilledShowcaseButton
                        mr={15}
                        disabled={!virtualTourBookedAt}
                        onClick={() => history.push(APP_ROUTES.LISTINGS.PLACE_CALENDAR(placeId))}
                      >
                        {t('bedrooms.list.calendar')}
                      </FilledShowcaseButton>
                      {false && <ShowcaseButton onClick={() => history.push(APP_ROUTES.LISTINGS.DETAILS(placeId))}>{t('bedrooms.list.preview')}</ShowcaseButton>}
                    </>
                  )
                }
              </Flex>
            </Flex>
            <Separator />
            <ShowcasePart title={t('nav.basics')} buttonText={t('showcase.change')} route={APP_ROUTES.LISTINGS.PLACE_BASICS(placeId)}>
              <ShowcasePartItem title={t('showcase.basics.listing')} text={get(place, 'listingType.name')} />
              <ShowcasePartItem title={t('showcase.basics.type')} text={get(place, 'unitType.name')} />
              <ShowcasePartItem title={t('showcase.basics.building')} text={get(place, 'buildingType.name')} />
              <ShowcasePartItem title={t('showcase.basics.location')} text={place.location} />
              <ShowcasePartItem title={t('showcase.basics.numberOfTenants')} text={place.tenantAllowedCount} />
              <ShowcasePartItem title={t('showcase.basics.numberOfBedrooms')} text={place.bedroomsCount} />
              <ShowcasePartItem title={t('showcase.basics.owner')} text={t(`showcase.basics.ownerType.${get(place, 'legalOwner.type')}`)} />
              <ShowcasePartItem title={t('showcase.basics.ownerLivesIn')} text={t(`bool.${!!place.ownerLivesIn}`)} />
            </ShowcasePart>
            <ShowcasePart title={t('nav.amenities')} buttonText={t('showcase.change')} route={APP_ROUTES.LISTINGS.PLACE_AMENITIES(placeId)}>
              <ShowcasePartItem title={t('showcase.amenities.comfort')} text={joinObjectNames(availableComfort)} />
              <ShowcasePartItem title={t('showcase.amenities.utilities')} text={joinObjectNames(availableUtilities)} />
              <ShowcasePartItem title={t('showcase.amenities.niceTouches')} text={joinObjectNames(availableNiceTouches)} />
            </ShowcasePart>
            <ShowcasePart title={t('nav.media')} buttonText={t('showcase.change')} route={APP_ROUTES.LISTINGS.PLACE_MEDIA(placeId)}>
              <ShowcasePartItem title={t('showcase.media.photos')} text={size(place.photos)} />
              <ShowcasePartItem title={t('showcase.media.script')} text={place.script} />
              <ShowcasePartItem title={t('showcase.media.immersiveTour')} text={t(`bool.${!!place.virtualTourUrl}`)} />
            </ShowcasePart>
            <ShowcasePart title={t('nav.explicitBedsAndBaths')} buttonText={t('showcase.change')} route={APP_ROUTES.LISTINGS.PLACE_BEDS_AND_BATHS(placeId)}>
              <ShowcasePartItem title={t('showcase.bedsAndBaths.bedrooms')} text={size(place.rooms)} />
              <ShowcasePartItem title={t('showcase.bedsAndBaths.bathrooms')} text={place.bathroomsCount} />
              <ShowcasePartItem
                title={t('showcase.bedsAndBaths.withAmenities')}
                text={`${size(
                  filter(place.rooms, room => room.roomFacilities.length > 0 || room.roomNiceTouches.length > 0),
                )}`}
              />
              <ShowcasePartItem
                title={t('showcase.bedsAndBaths.withPhotos')}
                text={`${size(filter(place.rooms, ({ photos }) => photos.length > 0))}`}
              />
            </ShowcasePart>
            <ShowcasePart last={isCoLiving} title={t('nav.bookingRules')} buttonText={t('showcase.change')} route={APP_ROUTES.LISTINGS.PLACE_BOOKING_RULES(placeId)}>
              <ShowcasePartItem title={t('showcase.bookingRules.who')} text={joinObjectNames(place.tenantTypes)} />
              <ShowcasePartItem title={t('showcase.bookingRules.gender')} text={get(place, 'genderPreference.name')} />
              <ShowcasePartItem
                title={t('showcase.bookingRules.startDateWindow')}
                text={`${place.startDateWindow} ${t('showcase.bookingRules.startDateWindowText')}`}
              />
              <ShowcasePartItem
                title={t('showcase.bookingRules.preparationTime')}
                text={`${place.preparationTime} ${t('showcase.bookingRules.preparationTimeText')}`}
              />
              <ShowcasePartItem title={t('showcase.bookingRules.deposits')} text={joinObjectNames(place.depositOptions)} />
              <ShowcasePartItem title={t('showcase.bookingRules.moveOutGuarantee')} text={t(`bool.${!!place.moveOutGuarantee}`)} />
              <ShowcasePartItem title={t('showcase.bookingRules.houseRules')} text={joinObjectNames(place.houseRules)} />
              <ShowcasePartItem title={t('showcase.bookingRules.notes')} text={place.notes} />
              <ShowcasePartItem title={t('showcase.bookingRules.backgroundChecks')} text={joinObjectNames(place.backgroundChecks)} />
            </ShowcasePart>
            { !isCoLiving && (
              <ShowcasePart last title={t('showcase.pricing.title')} buttonText={t('showcase.change')} route={APP_ROUTES.LISTINGS.PLACE_PRICES(placeId)}>
                <ShowcasePartItem
                  title={t('showcase.pricing.monthlyRent')}
                  text={`£${get(maxBy(place.monthlyRentalPriceValues, 'value'), 'value')} - £${get(minBy(place.monthlyRentalPriceValues, 'value'), 'value')}`}
                />
                <ShowcasePartItem title={t('showcase.pricing.monthlyUtilities')} text={`£${toNumber(place.monthlyUtilityPrice)}`} />
                <ShowcasePartItem
                  title={t('showcase.pricing.seasonalPromotion')}
                  text={place.discount && `%${place.discount} ${join(map(place.promotionMonths, mth => capitalize(mth.slice(0, 3))), ' ')}`}
                />
              </ShowcasePart>
            )}
          </Flex>
        );
      }}
    </Query>
  );
};

ListingShowcase.propTypes = {
  listingTypeCode: PropTypes.oneOf(['co_living', 'entire_place']).isRequired,
  placeId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
);

export default enhance(ListingShowcase);
