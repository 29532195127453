import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, Flex, Text } from 'rebass';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import Hide from 'hidden-styled';
import TitleDescription from '../text/TitleDescription';
import breakfast from '../../assets/img/tours/breakfast.png';
import video from '../../assets/img/tours/mountains-video.png';
import greenSpot from '../../assets/img/green-spot.svg';
import dottedLine from '../../assets/img/dotted-line.svg';
import { fontSize } from '../../theme/styleUtils';
import { mediaQuery } from '../../theme/globalStyle';

const DottedLine = styled(Image)`
  position: absolute;
  transform: rotate(-37deg);
  height: 281px;
  z-index: -1;
  ${mediaQuery.tablet`
    right: -110px;
    bottom: -40px;
  `};
  right: -90px;
  bottom: -92px;
`;
const GreenSpot = styled(Image)`
  position: relative;
  width: 130px;
  z-index: -1;
  height: 130px;
  ${mediaQuery.tablet`
    left: -60px;
    top: 0px;
  `};
  left: -50px;
  top: -48px;
`;
const HeadingContainer = styled(Flex)`
  position: relative;
`;
const CoveredImage = styled(Image)`
  height: 320px;
  object-fit: cover;
`;


const TitleText = styled(Text)`
  ${fontSize(50)}
  color: darkBrown;
  text-align: center;
`;

class DownrootsTours extends Component {
  render() {
    const { t } = this.props;
    return (
      <Flex flexDirection="column" mt={172} mb={15}>
        <HeadingContainer width={1} mb={60}>
          <TitleText ml={[50, 50, 100]} width={[0.8, 0.7, 'auto']}>
            {t('way.title')}
          </TitleText>
          <Hide xs>
            <DottedLine src={dottedLine} />
          </Hide>
          <GreenSpot src={greenSpot} />
        </HeadingContainer>
        <TitleDescription
          title={t('tours.title')}
          desc={t('tours.desc')}
          lineHeight={8 / 5}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt={25}
          flexDirection={['column', 'column', 'row']}
        >
          <CoveredImage src={video} width={[1, 1, 0.5]} pr={[0, 0, 30]} mb={[30, 30, 0]} />
          <CoveredImage src={breakfast} width={[1, 1, 0.5]} />
        </Flex>
      </Flex>
    );
  }
}

DownrootsTours.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('owners')(DownrootsTours);
