import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Field } from 'react-final-form';
import { required } from '../../../utils/formValidation';
import { TextInputLabel } from './TextInput';
import withFinalForm from './withFinalForm';
import { CalendarIcon } from './DatePicker';

class DateRange extends React.Component {
  state = {
    from: undefined,
    to: undefined,
    // eslint-disable-next-line react/no-unused-state
    initialStartDateObtained: false,
    // eslint-disable-next-line react/no-unused-state
    initialEndDateObtained: false,
  };

  static getDerivedStateFromProps(props, state) {
    const shouldDeriveState =
      (props.initialStartDate || props.initialEndDate)
      &&
      !(state.initialStartDateObtained || state.initialEndDateObtained);

    if (shouldDeriveState) {
      let newObj = {};
      if (props.initialStartDate && !state.initialStartDateObtained) {
        newObj = {
          ...newObj,
          from: props.initialStartDate,
          initialDateObtained: true,
        };
      }
      if (props.initialEndDate && !state.initialEndDateObtained) {
        newObj = {
          ...newObj,
          from: props.initialStartDate,
          initialDateObtained: true,
        };
      }
      return newObj;
    }
    return null;
  }

  showFromMonth = () => {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  };

  handleFromChange = (from) => {
    const { fromName } = this.props;
    this.setState({ from }, () => {
      this.props.formOnChange(fromName, from);
    });
  };

  handleToChange = (to) => {
    const { toName } = this.props;
    this.setState({ to }, () => {
      this.showFromMonth();
      this.props.formOnChange(toName, to);
    });
  };

  render() {
    const { from, to } = this.state;
    const {
      fromName, toName, validate, label, dateSeparator,
    } = this.props;
    const modifiers = { start: from, end: to };
    return (
      <>
        <TextInputLabel>{label}</TextInputLabel>
        <div className="InputFromTo">
          <CalendarIcon />
          <Field name={fromName} validate={validate}>
            {({ input }) => (
              <DayPickerInput
                value={input.value}
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: [{ after: to }, ...this.props.blockedDays],
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 2,
                  onDayClick: () => this.to.getInput().focus(),
                }}
                onDayChange={this.handleFromChange}
              />
            )}
          </Field>
          {dateSeparator}
          <Field name={toName} validate={validate}>
            {({ input }) => (
              <span className="InputFromTo-to">
                <DayPickerInput
                  ref={(el) => { this.to = el; }}
                  value={input.value}
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: [{ before: from }, ...this.props.blockedDays],
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2,
                  }}
                  onDayChange={this.handleToChange}
                />
              </span>
            )}
          </Field>
        </div>
      </>
    );
  }
}

DateRange.propTypes = {
  blockedDays: PropTypes.array,
  formOnChange: PropTypes.func.isRequired,
  fromName: PropTypes.string,
  toName: PropTypes.string,
  validate: PropTypes.func,
  label: PropTypes.string,
  dateSeparator: PropTypes.node,
};

DateRange.defaultProps = {
  blockedDays: [],
  fromName: 'from',
  toName: 'to',
  validate: required,
  dateSeparator: ' to ',
};

export default withFinalForm({
  initialStartDate: 'fromName',
  initialEndDate: 'toName',
})(DateRange);
