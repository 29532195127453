import { ApolloClient } from 'apollo-client';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { RestLink } from 'apollo-link-rest';
import axios from 'axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

import config from '../configLoader';
import defaults from './state/defaults';
import resolvers from './state/resolvers';
import { clearAuthTokens } from '../utils/auth';

export const memoryCache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) { // eslint-disable-line
      case 'OfferedObject': return object.documentId;
      default: return defaultDataIdFromObject(object);
    }
  },
});

export const stateLink = withClientState({
  cache: memoryCache,
  defaults,
  resolvers,
});

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem('kumpfy.access-token');
  const clientToken = localStorage.getItem('kumpfy.client');
  const expiry = localStorage.getItem('kumpfy.expiry');
  const uid = localStorage.getItem('kumpfy.uid');
  const tokenType = localStorage.getItem('kumpfy.token-type');

  return {
    headers: {
      'access-token': accessToken || '',
      client: clientToken || '',
      expiry: expiry || '',
      uid: uid || '',
      'token-type': tokenType || '',
      ...headers,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('[GraphQL error]:', { graphQLErrors });
  console.log('[Network error]:', { networkError });
});

const restLink = new RestLink({
  uri: config.restApiUri,
});

export const axiosInstance = axios.create({
  baseURL: config.graphQlApiUri,
});

const httpLink = createUploadLink({
  uri: config.graphQlApiUri,
  fetch: buildAxiosFetch(axiosInstance, (cfg, input, init) => ({
    ...cfg,
    onUploadProgress: init.onUploadProgress,
  })),
});

const checkTokenLink = new ApolloLink((operation, forward) => forward(operation).map((data) => {
  if (operation.operationName === 'ChangePassword') {
    return data;
  }

  if (!(operation.getContext().response && operation.getContext().response.headers.get('access-token'))) {
    memoryCache.writeData({ data: { auth: { isAuthenticated: false, __typename: 'Auth' } } });
    clearAuthTokens();
  }

  return data;
}));

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    errorLink,
    stateLink,
    authLink,
    checkTokenLink,
    restLink,
    httpLink,
  ]),
  cache: memoryCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export default client;
