import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Navigation from '../navigation/MainNavBar';
import Layout from './Layout';

const AppLayout = ({ navBar: NavBar, children }) => (
  <Fragment>
    <NavBar />
    <Layout>
      {children}
    </Layout>
  </Fragment>
);

AppLayout.propTypes = {
  navBar: PropTypes.func,
  children: PropTypes.element,
};

AppLayout.defaultProps = {
  navBar: Navigation,
};

export default AppLayout;
