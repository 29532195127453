import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box } from 'rebass';
import { DYN_UPLOAD_MULTIPLE_PHOTOS, DESTROY_PHOTO, DYN_UPDATE_MULTIPLE_PHOTOS } from '../../../../graphQL/mutations/createOrUpdatePhoto';
import { GET_PLACE_PHOTOS } from '../../../../graphQL/queries/getPlace';
import WizardStep from '../../../common/wizard/WizardStep';
import { FormPart, FormSubPart } from '../../../common/form/FormWrapper';
import FieldError from '../../../common/form/FieldError';
import MultiplePhotoUpload from '../../../common/photos/MultiplePhotoUpload';

class Photos extends Component {
  renderPhotos = (data) => {
    const { t, destroyPhoto, match } = this.props;
    const { place: { photos } } = data;
    return (
      <FormPart title={t('photos.title')}>
        <FormSubPart subText={t('photos.subtitle')}>
          <FieldError direction="column" name="photoIds" />
          <MultiplePhotoUpload
            photos={photos}
            photoDataPath="photo"
            destroyPhoto={destroyPhoto}
            refetchQueries={[{ query: GET_PLACE_PHOTOS, variables: { id: match.params.id } }]}
            additionalUploadVariables={{
              placeId: match.params.id,
              cover: '0',
            }}
            createPhotosUploadMutation={DYN_UPLOAD_MULTIPLE_PHOTOS}
            reorderable
            createUpdateMutation={DYN_UPDATE_MULTIPLE_PHOTOS}
          />
        </FormSubPart>
      </FormPart>
    );
  }

  render() {
    return (
      <Box>
        <WizardStep query={GET_PLACE_PHOTOS} withoutLayout>
          {({ data }) => this.renderPhotos(data)}
        </WizardStep>
      </Box>
    );
  }
}

Photos.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  destroyPhoto: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(DESTROY_PHOTO, { name: 'destroyPhoto' }),
);

export default enhance(Photos);
