import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Flex, Text, Box, Image,
} from 'rebass';
import get from 'lodash/get';
import { compose } from 'recompose';
import styled from 'styled-components';
import { GET_BOOKING_TENANCY } from '../../../../graphQL/queries/bookings';
import CloseButton from '../../../common/buttons/CloseButton';
import Loading from '../../../common/Loading';
import { APP_ROUTES } from '../../../../constants';
import downrootsIcon from '../../../../assets/img/downroots-icon.webp.png';
import TenancyRequest from './TenancyRequest';
import { fontSize } from '../../../../theme/styleUtils';

const NoUnderlineLink = styled(Link)`
  text-decoration: none;
`;

const SectionRequestText = styled(Text)`
  ${fontSize(30)}
`;

const TenancyDetailsContainer = ({ t, match }) => (
  <Flex flexDirection="column">
    <Helmet>
      <title>{t('tenancies')}</title>
    </Helmet>
    <Query query={GET_BOOKING_TENANCY} variables={{ id: match.params.id }}>
      {({ error, loading, data }) => {
        const booking = get(data, 'booking', {});
        if (loading) return <Loading />;

        if (error) {
          return <Redirect to={APP_ROUTES.DASHBOARD.LANDLORD.TENANCIES()} />;
        }

        return (
          <Box mb={30}>
            <Flex alignItems="center" my={30}>
              <Link to="/">
                <Image src={downrootsIcon} width={60} />
              </Link>
              <SectionRequestText my={20} mr="auto">
                {t('sections.request')}
              </SectionRequestText>
              <NoUnderlineLink to={APP_ROUTES.DASHBOARD.LANDLORD.TENANCIES()}>
                <CloseButton />
              </NoUnderlineLink>
            </Flex>

            {/* render content depending on booking status */}
            <TenancyRequest booking={booking} />
          </Box>
        );
      }}
    </Query>
  </Flex>
);

TenancyDetailsContainer.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('tenancies'),
);

export default enhance(TenancyDetailsContainer);
