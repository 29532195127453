/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
import filter from 'lodash/filter';
import get from 'lodash/get';
import last from 'lodash/last';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import times from 'lodash/times';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Box, Flex } from 'rebass';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { ErrorBoxContainer } from '../form/FieldError';
import Loader from '../Loader';
import Photo from './Photo';
import { PhotoDropPlus } from './PhotoDropPlus';

const SortablePhoto = SortableElement(({ photo, photoDataPath, onDelete }) => (
  <Box mr={30} mb={30}>
    <Photo
      photo={get(photo, photoDataPath)}
      onDelete={onDelete}
    />
  </Box>
));

const SortablePhotos = SortableContainer(({
  displayedPhotos, photoDataPath, photosInProgress, uploadDisabled, onDrop, onDelete,
}) => (
  <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-start" mt={20}>
    {
      map(displayedPhotos, (photo, idx) => (
        <SortablePhoto key={photo.id} index={idx} photo={photo} photoDataPath={photoDataPath} onDelete={() => onDelete(photo.id)} />
      ))
    }
    {
      map(photosInProgress, (photo, idx) => (
        <Flex mr={30} mb={30} key={photo.id + idx} alignItems="center" justifyContent="center">
          <Photo
            onDelete={() => onDelete(photo.id)}
            render={Loader}
          />
        </Flex>
      ))
    }
    {!uploadDisabled && (
      <Box mr={30} mb={30}>
        <PhotoDropPlus onDrop={acceptedFiles => onDrop({ acceptedFiles })} />
      </Box>
    )}
  </Flex>
));

class MultiplePhotoUpload extends Component {
  state = {
    photosInProgress: [],
    uploadedPhotosCount: 0,
    error: '',
    photosObtained: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.photos && !state.photosObtained) {
      return {
        photos: props.photos.filter(props.filterDisplayedPhotos),
        photosObtained: true,
      };
    }
    return null;
  }

  addToPhotosInProggress = ({ photosLength, filesLength }, callback) => {
    this.setState((prevState) => {
      const { photosInProgress, uploadedPhotosCount } = prevState;
      return {
        photosInProgress: [
          ...photosInProgress,
          ...times(filesLength, () => ({ id: photosLength + uploadedPhotosCount })),
        ],
        uploadedPhotosCount: uploadedPhotosCount + filesLength,
        error: '',
      };
    }, () => callback(last(this.state.photosInProgress)));
  }

  removeFromPhotosInProgress = (localPhotoId) => {
    this.setState(prevState => ({
      photosInProgress: filter(prevState.photosInProgress, photo => photo.id !== localPhotoId),
    }));
  }

  onDrop = ({ acceptedFiles }) => {
    const {
      client, createPhotosUploadMutation, additionalUploadVariables, photos, refetchQueries,
    } = this.props;
    const uploadVariables = {
      ...reduce(acceptedFiles, (sum, current, idx) => ({ ...sum, [`photo${idx}`]: current }), {}),
      ...additionalUploadVariables,
    };

    const createOrUpdatePhoto = opts => client.mutate({
      ...opts,
      mutation: createPhotosUploadMutation(acceptedFiles),
    });

    const createUploadProgressContext = currentPhoto => ({
      fetchOptions: {
        onUploadProgress: (progress) => {
          const { loaded, total } = progress;
          this.setState((prevState) => {
            const { photosInProgress } = prevState;
            return ({
              photosInProgress: map(photosInProgress, (obj) => { // eslint-disable-line
                return obj.id === currentPhoto.id ? { ...obj, loaded, total } : obj;
              }),
            });
          });
        },
      },
    });

    this.addToPhotosInProggress(
      { photosLength: photos.length, filesLength: acceptedFiles.length },
      async (currentPhoto) => {
        try {
          const { errors, data } = await createOrUpdatePhoto({
            variables: uploadVariables,
            refetchQueries,
            context: createUploadProgressContext(currentPhoto),
            update: () => this.removeFromPhotosInProgress(currentPhoto.id),
          });
          if (errors) {
            // handle errors gotten as data
            this.setState({ error: get(errors, '[0].message', 'Network error.') });
            this.removeFromPhotosInProgress(currentPhoto.id);
          } else if (data) {
            this.setState(prevState => ({
              photos: [...prevState.photos, ...map(data)],
            }));
          }
        } catch (error) {
          // handle promise rejection errors
          if (!error.status) {
            // it is a network error
            this.setState({ error: 'Could not upload file!' });
            this.removeFromPhotosInProgress(currentPhoto.id);
          }
        }
      },
    );
  };

  onDelete = async (id) => {
    const {
      destroyPhoto, additionalDeleteVariables,
    } = this.props;
    const { photos: oldPhotos } = this.state;

    // optimistic state update
    this.setState(state => ({
      photos: state.photos.filter(photo => photo.id !== id),
    }));

    const { errors } = await destroyPhoto({
      variables: { id, additionalDeleteVariables },
    });

    this.revertIfErrors(errors, oldPhotos);
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return map(result, (photo, idx) => ({ ...photo, position: idx + 1 }));
  };

  revertIfErrors = (errors, oldPhotos) => {
    if (errors) {
      toast.error(JSON.stringify(errors, null, 4), {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
      this.setState({ photos: oldPhotos });
    }
  }

  onDragEnd = ({ oldIndex, newIndex }) => {
    if (!this.props.reorderable) return;
    if (oldIndex === newIndex) return;
    const { photos: oldPhotos } = this.state;
    this.setState(
      ({ photos }) => ({ photos: this.reorder(photos, oldIndex, newIndex) }),
      async () => {
        const {
          client, additionalUploadVariables, createUpdateMutation,
        } = this.props;
        const { photos } = this.state;

        const uploadVariables = {
          ...reduce(photos, (sum, current, idx) => ({
            ...sum,
            [`position${idx}`]: current.position,
            [`id${idx}`]: current.id,
          }), {}),
          ...omit(additionalUploadVariables, 'cover'),
        };

        const updatePhotos = opts => client.mutate({
          ...opts,
          mutation: createUpdateMutation(photos),
        });

        try {
          const { errors } = await updatePhotos({ variables: uploadVariables });
          this.revertIfErrors(errors, oldPhotos);
        } catch (error) {
          this.revertIfErrors(error, oldPhotos);
        }
      },
    );
  };

  render() {
    const { error, photosInProgress, photos } = this.state;
    const {
      photoDataPath, uploadDisabled,
    } = this.props;
    const displayedPhotos = photos;
    return (
      <>
        {error && <ErrorBoxContainer>{error}</ErrorBoxContainer>}
        <SortablePhotos
          axis="xy"
          onSortEnd={this.onDragEnd}
          photosInProgress={photosInProgress}
          photoDataPath={photoDataPath}
          uploadDisabled={uploadDisabled}
          displayedPhotos={displayedPhotos}
          onDrop={this.onDrop}
          onDelete={this.onDelete}
        />
      </>
    );
  }
}

MultiplePhotoUpload.propTypes = {
  photoDataPath: PropTypes.oneOf(['photo', 'file']),
  photos: PropTypes.array,
  destroyPhoto: PropTypes.func.isRequired,
  refetchQueries: PropTypes.array.isRequired,
  additionalDeleteVariables: PropTypes.object,
  createPhotosUploadMutation: PropTypes.func.isRequired,
  additionalUploadVariables: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  filterDisplayedPhotos: PropTypes.func,
  uploadDisabled: PropTypes.bool,
  reorderable: PropTypes.bool,
  createUpdateMutation: PropTypes.func,
};

MultiplePhotoUpload.defaultProps = {
  photoDataPath: 'photo',
  photos: [],
  filterDisplayedPhotos: () => true,
  uploadDisabled: false,
};

export default withApollo(MultiplePhotoUpload);
