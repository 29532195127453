import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import { required } from '../../../../../utils/formValidation';
import { FormPart, FormSubPart, FormWrapper } from '../../../../common/form/FormWrapper';
import { Separator } from '../../../../common/form/Separator';
import GooglePlacesAutocomplete from '../../../../google/GooglePlacesAutocomplete';

const LocationPart = ({ initialAddress, t, formChange }) => (
  <FormWrapper>
    <Separator mb={20} />
    <FormPart title={t('address.title')}>
      <FormSubPart subTitle={t('address.subtitle')}>
        <Box width="100%">
          <Field name="city" component="input" type="hidden" />
          <GooglePlacesAutocomplete initialAddress={initialAddress} name="location" placeholder="" validate={required} formChange={formChange} />
        </Box>
      </FormSubPart>
    </FormPart>
  </FormWrapper>
);

LocationPart.propTypes = {
  t: PropTypes.func.isRequired,
  initialAddress: PropTypes.string,
  formChange: PropTypes.func.isRequired,
};

export default withNamespaces('listingForm')(LocationPart);
