import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import noop from 'lodash/noop';

const MapWithReference = ({
  isMarkerShown = false,
  defaultZoom,
  defaultCenter,
  onRef = noop,
  children,
}) => (
  <GoogleMap
    defaultZoom={defaultZoom}
    defaultCenter={defaultCenter}
    ref={onRef}
  >
    {isMarkerShown && <Marker position={defaultCenter} />}
    {children}
  </GoogleMap>
);

MapWithReference.propTypes = {
  isMarkerShown: PropTypes.bool,
  defaultZoom: PropTypes.number.isRequired,
  defaultCenter: PropTypes.object.isRequired,
  onRef: PropTypes.func,
  children: PropTypes.node,
};

const enhance = compose(
  withProps({
    containerElement: <div style={{ height: '300px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withGoogleMap,
);

export default enhance(MapWithReference);
