import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  // Text,
  Flex,
  Heading,
} from 'rebass';
import { withNamespaces } from 'react-i18next';
import Text from '../common/Text';

class RegistrationSuccess extends Component {
  render = () => {
    const { t } = this.props;
    return (
      <Flex
        mt={150}
        flexDirection="column"
        alignItems="center"
      >
        <Heading textAlign="center" mb={10}>
          {t('signup.success.heading')}
        </Heading>
        <Text>Please check email to confirm your account.</Text>
      </Flex>
    );
  }
}

RegistrationSuccess.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('auth')(RegistrationSuccess);
