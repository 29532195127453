import styled from 'styled-components';
import { transitionBackgroundColor, fontSize } from '../../../theme/styleUtils';

export default styled.div`
  ${fontSize(20)}
  color: black;
  ${transitionBackgroundColor('0.3s')}
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.purplePrimary};
  }
  &::after {
    content: '>';
    ${fontSize(40)}
    font-weight: 200;
    position: relative;
    top: 6px;
    left: 12px;
  }
`;
