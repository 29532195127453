import { FORM_ERROR } from 'final-form';
import head from 'lodash/head';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import { compose } from 'recompose';
import { CHANGE_PASSWORD } from '../../../../graphQL/mutations/user';
import { showSuccess } from '../../../../utils/toast';
import { ErrorBoxContainer } from '../../../common/form/FieldError';
import TextInput from '../../../common/form/TextInput';
import Panel from '../../../common/Panel';
import { FilledRoundedButton } from '../../../common/wizard/Footer';

class ChangePasswordForm extends Component {
  onSubmit = async (values) => {
    try {
      await this.props.mutate({
        variables: values,
      });
      showSuccess('Password successfully updated');
    } catch (e) {
      return {
        [FORM_ERROR]: head(e.networkError.result.errors.full_messages),
      };
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Form onSubmit={this.onSubmit}>
        {({ handleSubmit, submitError, submitting }) => (
          <Panel title="Password" mb={20}>
            <TextInput name="currentPassword" label={t('settings.resetPassword.old')} placeholder={t('settings.resetPassword.old')} password />
            <TextInput name="password" label={t('settings.resetPassword.new')} placeholder={t('settings.resetPassword.new')} password />
            <TextInput name="passwordConfirmation" label={t('settings.resetPassword.confirm')} placeholder={t('settings.resetPassword.confirm')} password />
            {submitError && <ErrorBoxContainer my={20}>{submitError}</ErrorBoxContainer>}
            <Box mt={20}>
              <FilledRoundedButton py="5px" width={[1, 'initial', 'initial']} onClick={handleSubmit} disabled={submitting}>
                {t('settings.resetPassword.update')}
              </FilledRoundedButton>
            </Box>
          </Panel>
        )}
      </Form>
    );
  }
}

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};

const enhance = compose(
  withNamespaces('account'),
  graphql(CHANGE_PASSWORD),
);

export default enhance(ChangePasswordForm);
