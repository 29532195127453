import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { compose, Query } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import propertyList from '../../../assets/img/property-list.png';
import { APP_ROUTES } from '../../../constants';
import { GET_OFFERED_OBJECTS } from '../../../graphQL/queries/offeredObjects';
import { fontMixin, fontSize, LULO } from '../../../theme/styleUtils';
import coerceNumbers from '../../../utils/coerceNumbers';
import Error from '../../common/Error';
import Layout from '../../common/Layout';
import Loading from '../../common/Loading';
import SearchBar, { SEARCH_BAR_DEFAULT_VALUES } from '../../search/SearchBar';
import InfiniteScrollList from './InfiniteScrollList';
import ListingListItem from './ListingListItem';

const REQUEST_SIZE = 20;
const CONNECTION_PATH = 'offeredObjects';

const ListingsHeader = styled(Flex)`
  height: 200px;
  width: 100%;
  ${fontMixin(LULO)}
  ${fontSize(60)}
  color: white;
  position: relative;
  &:before {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    content: ' ';
    background: url(${props => props.src});
    opacity: 0.8;
    background-size: cover;
    background-position-y: 60%;
  }
`;

const getVariables = (location) => {
  const variables = {
    size: REQUEST_SIZE,
    page: 1,
    ...coerceNumbers(queryString.parse(location.search)),
  };
  let { bedroomsMinNumber, bedroomsMaxNumber, privateBathroom } = variables;
  const bedroomNumber = value => (value === 'studio' ? 1 : value);

  if (bedroomsMinNumber) {
    bedroomsMinNumber = `${bedroomNumber(bedroomsMinNumber)}`;
  }

  if (bedroomsMaxNumber) {
    bedroomsMaxNumber = `${bedroomNumber(bedroomsMaxNumber)}`;
  }

  privateBathroom = privateBathroom === 'true';

  return omit({
    ...variables,
    bedroomsMaxNumber,
    bedroomsMinNumber,
    privateBathroom,
  }, ['city', 'district', !privateBathroom && 'privateBathroom']);
};

const ListingsWrapper = ({ t, location, history }) => {
  useEffect(() => {
    const params = queryString.parse(location.search);
    if (isEmpty(params)) {
      history.push(`/listings?${queryString.stringify(SEARCH_BAR_DEFAULT_VALUES)}`);
    }
  });

  return (
    <Flex flexDirection="column">
      <Helmet>
        <title>{t('listings')}</title>
      </Helmet>
      <Query query={GET_OFFERED_OBJECTS} variables={getVariables(location)}>
        {({
          loading, data, error, fetchMore,
        }) => (
          <>
            <SearchBar
              aggregations={get(data, 'offeredObjects.aggregations', [])}
            />
            <ListingsHeader
              src={propertyList}
              alignItems="center"
              justifyContent="center"
            >
              LONDON
            </ListingsHeader>
            <Layout>
              {loading && <Loading />}
              {error && <Error />}

              {!(loading || error) && (
              <Box mx="-5px">
                <InfiniteScrollList
                  data={data}
                  connectionPath={CONNECTION_PATH}
                  loading={loading}
                  fetchMore={fetchMore}
                  requestSize={REQUEST_SIZE}
                >
                  {(listing) => {
                    const newSearch = queryString.stringify({
                      ...queryString.parse(location.search),
                      type: listing.objectType,
                      indexableId: listing.indexableId,
                      dateFrom: listing.availableFrom,
                    });
                    return (
                      <ListingListItem
                        key={`offered-object-${listing.documentId}`}
                        link={`${APP_ROUTES.LISTINGS.DETAILS()}?${newSearch}`}
                        listing={{
                          ...listing,
                          image: listing.mainImageUrl,
                          address: listing.objectAddress,
                          monthlyPrice: toNumber(listing.pricePerMonth),
                          listingType: listing.typeOfListing,
                        }}
                      />
                    );
                  }}
                </InfiniteScrollList>
              </Box>
              )}
            </Layout>
          </>
        )}
      </Query>
    </Flex>
  );
};

ListingsWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listings'),
);

export default enhance(ListingsWrapper);
