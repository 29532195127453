import { Text } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

const Label = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_LIGHT,
  fontSize: '17px',
  pb: '5px',
})``;

export default Label;
