import gql from 'graphql-tag';

export const GET_FACILITIES_FOR_PLACE = gql`
  query GetFacilitiesForPlace($facilityType: String) {
    facilitiesForPlace(facilityType: $facilityType) {
      id
      name
      type
    } 
  }
`;
