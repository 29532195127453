import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

const MenuWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
`;

export const MenuList = styled(Flex)`
  position: absolute;
  min-width: 150px;
  right: 0;
  background-color: #fff;
  flex-direction: column;
  padding: 5px 0;
  top: 48px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
`;

export const MenuListItem = styled(Flex).attrs({
  p: ['10px', '10px', '5px'],
})``;

const Menu = ({ children }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const ref = useRef(null);

  const handleClick = (e) => {
    if (!ref.current.contains(e.target)) {
      setIsMenuOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  return (
    <MenuWrapper ref={ref}>
      {children({
        isMenuOpened,
        toggleMenu: () => setIsMenuOpened(!isMenuOpened),
      })}
    </MenuWrapper>
  );
};

Menu.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Menu;
