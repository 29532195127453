import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';
import logo from '../assets/img/kumpfy-logo.svg';
import { fontMixin, fontSize, LULO } from '../theme/styleUtils';
import { Heading } from './common/Text';

const Wrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  flex-direction: column;
`;

const NavBarTitle = styled(Link)`
  ${fontMixin(LULO)}
  ${fontSize(10)}
  color: ${props => props.theme.colors.purplePrimary};
  text-decoration: none;
`;

const NoRolePage = () => (
  <Wrapper>
    <Flex px={20} mb={20} justifyContent="center" alignItems="center">
      <Image src={logo} height={46} mr={20} />
      <NavBarTitle to="/">Downroots</NavBarTitle>
    </Flex>
    <Heading mb={20}>{'You don\'t have required role to perform this action'}</Heading>
  </Wrapper>
);

export default NoRolePage;
