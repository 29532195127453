import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, Flex } from 'rebass';
import FieldError from '../../../common/form/FieldError';
import Radio from '../../../common/form/Radio';
import TextInput from '../../../common/form/TextInput';
import Panel from '../../../common/Panel';
import Body from '../../../common/text/Body';

const PayoutMethods = ({ t }) => (
  <Panel title={t('settings.payoutMethods.title')} mb={20}>
    <Body mb={20}>{t('settings.payoutMethods.subText')}</Body>
    <FieldError name="payoutMethodVariant">
      <Radio name="payoutMethodVariant" label={t('settings.payoutMethods.variants.0')} value="bankTransfer" />
    </FieldError>
    <Flex mx="-5px" flexWrap="wrap" justifyContent="space-between">
      <Box width={[1, 1 / 2, 1 / 2]} px="5px">
        <TextInput name="payoutMethod.bankName" label={t('settings.payoutMethods.bankName')} />
      </Box>
      <Box width={[1, 1 / 2, 1 / 2]} px="5px">
        <TextInput name="payoutMethod.payee" label={t('settings.payoutMethods.payee')} placeholder={t('settings.payoutMethods.payeePlaceholder')} />
      </Box>
      <Box width={[1, 1 / 2, 1 / 2]} px="5px">
        <TextInput name="payoutMethod.accountNumber" label={t('settings.payoutMethods.accountNumber')} />
      </Box>
      <Box width={[1, 1 / 2, 1 / 2]} px="5px">
        <TextInput name="payoutMethod.sortCode" label={t('settings.payoutMethods.sortCode')} />
      </Box>
    </Flex>
  </Panel>
);

PayoutMethods.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('account')(PayoutMethods);
