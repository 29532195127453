import gql from 'graphql-tag';
import map from 'lodash/map';

export const CREATE_OR_UPDATE_PHOTO = gql`
  mutation CreateOrUpdatePhoto(
    $photo: Upload!
    $cover: String
    $placeId: ID!
  ) {
    createOrUpdatePhoto(payload: {
      photo: $photo
      cover: $cover
      placeId: $placeId
    }) {
      id
      photo
    }
  }
`;

export const DYN_UPLOAD_MULTIPLE_PHOTOS = photos => gql`
  mutation CreateOrUpdatePhoto(
    ${map(photos, (photo, idx) => (`
      $photo${idx}: Upload
    `))}
    $cover: String
    $placeId: ID!
  ) {
    ${map(photos, (photo, idx) => (`
      upload${idx}: createOrUpdatePhoto(payload: {
        photo: $photo${idx}
        cover: $cover
        placeId: $placeId
      }) {
        id
        photo
      }
    `))}
  }
`;

export const DYN_UPDATE_MULTIPLE_PHOTOS = photos => gql`
  mutation UpdatePhotos(
    ${map(photos, (photo, idx) => (`
      $position${idx}: Int
      $id${idx}: ID
    `))}
    $placeId: ID!
  ) {
    ${map(photos, (photo, idx) => (`
      upload${idx}: createOrUpdatePhoto(payload: {
        position: $position${idx}
        id: $id${idx}
        placeId: $placeId
      }) {
        id
        photo
      }
    `))}
  }
`;

export const DESTROY_PHOTO = gql`
  mutation DestroyPhoto($id: ID!) {
    destroyPhoto(id: $id) {
      id
      photo
    }
  }
`;
