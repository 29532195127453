import React from 'react';
import { Switch } from 'react-router';
import { APP_ROUTES } from '../../constants';
import AppRoute from '../common/AppRoute';
import PrivateAppRoute from '../common/PrivateAppRoute';
import Landlord from './landlord/Landlord';
import LandlordNavBar from './landlord/LandlordNavBar';
import Tenant from './tenant/Tenant';
import TenantNavBar from './tenant/TenantNavBar';

const Dashboard = () => (
  <Switch>
    <PrivateAppRoute
      component={Tenant}
      navBar={TenantNavBar}
      path={APP_ROUTES.DASHBOARD.TENANT.ROOT()}
      roles={['tenant']}
    />
    <PrivateAppRoute
      component={Landlord}
      navBar={LandlordNavBar}
      path={APP_ROUTES.DASHBOARD.LANDLORD.ROOT()}
      roles={['landlord']}
    />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.ROOT()} component={Landlord} />
  </Switch>
);

export default Dashboard;
