import styled from 'styled-components';
import { Box } from 'rebass';
import { mediaQuery } from '../../../../theme/globalStyle';

export default styled(Box).attrs(props => ({
  tooltipOpacity: props.tooltipOpacity || 0.8,
}))`
  position: relative;
  background-image: url(${props => props.src});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  
  
  width: calc(25% - 18px);
  padding-top: calc(25% - 18px);
  height: 0;
  
  ${mediaQuery.tablet`
    width: calc(33% - 18px);
    padding-top: calc(33% - 18px)  
  `};

  margin-right: 18px;
  margin-bottom: 18px;
  transition: background-size .15s ease-in;
  &:hover {
    background-color: rgba(255,255,255,${props => props.tooltipOpacity});
    background-blend-mode: lighten;
    background-size: auto 80%;
    div {
      visibility: visible;
      opacity: 1;
    }
  }
  ${mediaQuery.tablet`
    &:hover {
      background-color: transparent;
      div {
        visibility: hidden;
      }
    }
  `}
`;
