import React from 'react';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../theme/globalStyle';
import { FlowPart, ListingIntroBackground } from '../listings/ListingsIntro';
import whiteCouchRoom from '../../assets/img/white-corner-couch.png';

const RentTermsShowase = styled(ListingIntroBackground)`
  color: white;
`;

const FlowParts = () => (
  <Flex width={[420, 600, 600, 1100]}>
    <RentTermsShowase bgImage={whiteCouchRoom}>
      <Text py={100} px={50} fontFamily={FONT_FAMILIES.AVENIR_NORMAL} fontSize={50}>RENTING WITH DOWNROOTS</Text>
      <Flex>
        <FlowPart text="MONEY SAFE" isContinueEnabled={false} index="01" isCompleted={false} bg="rgba(255, 255, 255, 0.5)" />
        <FlowPart text="FAIR LEASE TERMS" isContinueEnabled={false} index="02" isCompleted={false} bg="rgba(255, 255, 255, 0.5)" />
        <FlowPart text="PRIORITY EXTENSIONS" isContinueEnabled={false} index="03" isCompleted={false} bg="rgba(255, 255, 255, 0.5)" />
        <FlowPart text="CONCIERGE SERVICE" isContinueEnabled={false} index="04" isCompleted={false} bg="rgba(255, 255, 255, 0.5)" />
      </Flex>
    </RentTermsShowase>
  </Flex>
);

export default FlowParts;
