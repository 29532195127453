import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import Layout from '../common/Layout';
import Modal from '../common/Modal';
import FiltersForm from './FiltersForm';
import { fontMixin, fontSize, LIGHT } from '../../theme/styleUtils';

const ModalContainer = styled(Flex)`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  justify-content: center;
  overflow-y: auto;
`;

const CloseButton = styled.a`
  ${fontMixin(LIGHT)}
  ${fontSize(35)}
  color: #000;
  text-decoration: none;
  cursor: pointer;
`;

const FiltersModal = ({ onClose, onSubmit, initialValues }) => (
  <Modal>
    <ModalContainer>
      <Layout>
        <Flex justifyContent="flex-end">
          <CloseButton onClick={onClose}>x</CloseButton>
        </Flex>
        <Flex justifyContent="center">
          <FiltersForm
            initialValues={initialValues}
            onSubmit={(values) => {
              onSubmit(values);
              onClose();
            }}
          />
        </Flex>
      </Layout>
    </ModalContainer>
  </Modal>
);

FiltersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default FiltersModal;
