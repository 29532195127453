import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { APP_ROUTES } from '../../../constants';
import { NavBar, NavBarItem } from '../../common/NavBar';

const CalendarNavBar = ({ match, t }) => (
  <NavBar>
    <NavBarItem to={APP_ROUTES.LISTINGS.PLACE_ROOMS_CALENDAR(match.params.placeId, match.params.id)}>
      {t('nav.calendar')}
    </NavBarItem>
  </NavBar>
);

CalendarNavBar.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
);

export default enhance(CalendarNavBar);
