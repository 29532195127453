import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import Checkbox from '../../../common/form/Checkbox';
import { TextInputLabel } from '../../../common/form/TextInput';
import Panel from '../../../common/Panel';

const ContactOptions = ({ t, isTenant = false }) => (
  <Panel title={t('settings.contactOptions.title')} mb={20}>
    <Checkbox name="receiveMessages" label={t('settings.contactOptions.messages')} />
    <Checkbox name="receiveBooking" label={t('settings.contactOptions.reminders')} />
    { isTenant && (
      <>
        <Checkbox name="profileAvailable" label={t('settings.contactOptions.profileAvailable')} />
        <Box ml={30}>
          <TextInputLabel>{t('settings.contactOptions.profileAvailableSubLabel')}</TextInputLabel>
        </Box>
        <Checkbox name="messagesAvailable" label={t('settings.contactOptions.messagesAvailable')} />
      </>
    )}
  </Panel>
);

ContactOptions.propTypes = {
  t: PropTypes.func.isRequired,
  isTenant: PropTypes.bool,
};

export default withNamespaces('account')(ContactOptions);
