import gql from 'graphql-tag';

export const GET_BOOKING_BASICS = gql`
  query GetBookingBasics($id: ID!) {
    booking(id: $id) {
      id
      creditCard {
        id
        name
        addressZipCheck
        expMonth
        expYear
        last4
      }
      depositOptionId
      moveOutGuarantee
      studentGuarantor
      paymentMethod
      emptyContractLink
      houseRules {
        id
        name
      }
      bookedObject {
        id
        moveOutGuarantee
        studentGuarantor
        depositOptions {
          id
          name
        }
        moveOutGuarantee
      }
    }
  }
`;

export const GET_BOOKING_BOX_DATA = gql`
  query GetBookingBoxData($id: ID!) {
    booking(id: $id) {
      id
      status
      startDate
      stayMonths
      rentValue
      utilitiesValue
      signTotal
      depositValue
      firstMonthsRentValue
      studentGuarantor
      studentGuarantorValue
      moveOutGuarantee
      moveOutGuaranteeValue
      reservationFee
      bookedObject {
        id
        listingType
        location
        mainImageUrl
      }
    }
  }
`;

export const GET_BOOKING_PERSONAL = gql`
  query GetBookingPersonal($id: ID!) {
    booking(id: $id) {
      id
      tenantsNumber
      bookedObject {
        id
        depositOptions {
          id
          description
          name
          type
        }
        moveOutGuarantee
        resourceId
        resourceType
      }
      mainPerson: people(main: true) {
        id
        firstName
        lastName
        birthday
        phone
        sex
        email
        address
        kindOfJob
        proofOfIdentities {
          id kind name file
        }
        university
        studentLoan
        businessName
        jobTitle
        university
        studentLoan
      }
      flatMates: people(main: false) {
        id
        firstName
        lastName
        relationship
        age
      }
    }
  }
`;

export const GET_MAX_PLACE_TENANTS = gql`
  query GetMaxPlaceTenants($bookedObjectId: ID!) {
    place(id: $bookedObjectId) {
      id
      tenantAllowedCount
    }
  }
`;

export const GET_JOB_NAMES = gql`
  query GetJobNames {
    dictionary(type: Dictionaries__Job) {
      id name type
    }
  }
`;

export const GET_COUNTRIES_PREFIXES = gql`
  query GetCountries {
    dictionary(type: Dictionaries__CountryPhonePrefix) {
      id name type value
    }
  }
`;

export const GET_UNIVERSITY_NAMES = gql`
  query GetUniversityNames {
    dictionary(type: Dictionaries__University) {
      id name type
    }
  }
`;


export const GET_RELATIONSHIP_TYPES = gql`
  query GetRelationshipTypes {
    dictionary(type: Dictionaries__Relationship) {
      id name type value
    }
  }
`;

export const GET_PERSONS_DOCUMENTS = gql`
  query GetBookingPersonDocuments($id: ID!) {
    booking(id: $id) {
      id
      backgroundCheckConsent
      people(main: true) {
        firstName
        lastName
        country
        id
        documentKind
        proofOfIdentities {
          file
          id
          kind
          name
        }
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    booking(id: $id) {
      id
      status
      previewContractLink
      statuses {
        id
        name
        status
      }
      landlordVerifications {
        key
        value
      }
      landlord {
        id
        avatar
        fullName
        description
        address
      }
    }
  }
`;

export const GET_BOOKING_TENANCY = gql`
  query GetBookingTenancy($id: ID!) {
    booking(id: $id) {
      id
      previewContractLink
      tenantVerifications {
        key
        value
      }
      status
      startDate
      stayMonths
      rentValue
      utilitiesValue
      total
      depositValue
      firstMonthsRentValue
      studentGuarantorValue
      reservationFee
      bookedObject {
        id
        location
        mainImageUrl
      }
      mainPerson: people(main: true) {
        id
        firstName
        lastName
        documentKind
        email
      }
    }
  }
`;
