import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import LocationPart from './LocationPart';
import OwnerPart from './OwnerPart';
import PropertyPart from './PropertyPart';
import { scrollToFirstError, initialValuesUpdateRule } from '../../../../../utils/formHelpers';
import ExposeState from '../../../../common/form/ExposeState';
import { RoundedButton } from '../../../../common/wizard/Footer';

class BasicsForm extends React.Component {
  shouldComponentUpdate({ initialValues }) {
    return initialValuesUpdateRule(initialValues, this.props.initialValues);
  }

  render() {
    const {
      initialValues, onNext, onSubmit, listingTypes, exposeState,
    } = this.props;
    return (
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        decorators={[]}
        render={({
          handleSubmit,
          form: { getState, change },
          form,
          submitErrors,
          errors,
          submitting,
          values,
        }) => {
          onNext(async () => {
            await handleSubmit();
            scrollToFirstError(submitErrors, errors);
          });
          const { listingTypeId: listingTypeError, location: locError, latitude: latError } = errors;
          const listingTypeId = get(getState(), 'values.listingTypeId');
          const listingTypeCode = get(listingTypes.find(l => l.id === listingTypeId), 'code');
          const unitTypeId = get(getState(), 'values.unitTypeId');
          const unitTypeName = get(getState(), 'values.unitTypeName');
          const buildingTypeId = get(getState(), 'values.buildingTypeId');
          return (
            <form onSubmit={handleSubmit}>
              <ExposeState exposeState={exposeState} />
              <PropertyPart
                buildingTypeId={buildingTypeId}
                listingTypeCode={listingTypeCode}
                listingTypeId={listingTypeId}
                unitTypeId={unitTypeId}
                unitTypeName={unitTypeName}
                form={form}
              />
              {
                listingTypeId && unitTypeId && buildingTypeId && (
                  <Fragment>
                    <LocationPart initialAddress={get(getState(), 'values.location')} formChange={change} />
                    <OwnerPart listingTypeCode={listingTypeCode} legalOwner={get(getState(), 'values.legalOwner.type')} />
                    <RoundedButton
                      mb={20}
                      style={{ float: 'right' }}
                      disabled={submitting || listingTypeError || locError || latError}
                      onClick={async () => {
                        await onSubmit({
                          ...values,
                          saveAndExit: true,
                        });
                        scrollToFirstError(submitErrors, errors);
                      }}
                    >
                      Save and Exit
                    </RoundedButton>
                  </Fragment>
                )
              }
            </form>
          );
        }}
      />
    );
  }
}

BasicsForm.propTypes = {
  listingTypes: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  exposeState: PropTypes.func.isRequired,
};

export default withNamespaces('listingForm')(BasicsForm);
