import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Truncate from 'react-truncate';
import Body from '../../common/text/Body';
import SeeMore from './SeeMore';
import { DetailsSubtitle } from './DetailsFacilityPart';

const DetailsScriptPart = ({ t, listing, isPlace }) => {
  const [scriptCollapsed, setScriptCollapsed] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);
  const description = get(listing, isPlace ? 'script' : 'place.script');

  if (!description) {
    return null;
  }

  return (
    <>
      <DetailsSubtitle mt={70} mb={20}>{t('details.description')}</DetailsSubtitle>
      <Body mb={10}>
        <Truncate
          lines={scriptCollapsed && 3}
          onTruncate={(truncated) => {
            if (isTruncated !== truncated) {
              setIsTruncated(truncated);
            }
          }}
        >
          {description}
        </Truncate>
      </Body>
      {(isTruncated || !scriptCollapsed) && (
        <SeeMore
          collapsedState={scriptCollapsed}
          stateSetter={setScriptCollapsed}
          color="purplePrimary"
        />
      )}
    </>
  );
};

DetailsScriptPart.propTypes = {
  t: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  isPlace: PropTypes.bool.isRequired,
};

export default withNamespaces('listings')(DetailsScriptPart);
