import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import { Flex } from 'rebass';
import { compose } from 'recompose';
import { EDIT_AVATAR } from '../../../graphQL/mutations/editProfile';
import Avatar from '../../common/Avatar';
import Dropzone from '../../common/Dropzone';
import Loading from '../../common/Loading';
import { PhotoDropPlus } from '../../common/photos/PhotoDropPlus';

const AvatarPart = ({ avatar, change, createOrUpdatePhoto }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedPhoto, setuploadedPhoto] = useState(null);

  const onDrop = async ({ acceptedFiles }) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const { errors, data } = await createOrUpdatePhoto({
      variables: {
        avatar: acceptedFiles[0],
      },
    });
    if (data) {
      setuploadedPhoto(data.editProfile.avatar);
      setIsLoading(false);
    }
    if (errors) {
      change('avatarError', get(errors, '[0].message', 'Network error.'));
      setIsLoading(false);
    }
  };

  return (
    <Flex width={1} mb={20}>
      {(uploadedPhoto || avatar || isLoading) ? (
        <Flex flexDirection="column" alignItems="center">
          <Dropzone onDrop={acceptedFiles => onDrop({ acceptedFiles, change })}>
            {
              !isLoading ?
                <Avatar src={uploadedPhoto || avatar} size={160} /> :
                <Loading />
            }
          </Dropzone>
        </Flex>
      ) : (
        <PhotoDropPlus onDrop={acceptedFiles => onDrop({ acceptedFiles, change })} />
      )}
    </Flex>
  );
};

AvatarPart.propTypes = {
  change: PropTypes.func.isRequired,
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  createOrUpdatePhoto: PropTypes.func.isRequired,
};

const enhance = compose(
  graphql(EDIT_AVATAR, { name: 'createOrUpdatePhoto' }),
);

export default enhance(AvatarPart);
