import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router';
import Loading from '../Loading';
import Error from '../Error';

class WizardStep extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        {
          match.params.id && (
            <Query
              query={this.props.query}
              variables={{ id: match.params.id }}
              fetchPolicy="network-only"
            >
              {({ loading, data, error }) => {
                if (loading) {
                  return (
                    <Loading />
                  );
                }
                if (error) return <Error />;
                return this.props.children({ data });
              }}
            </Query>
          )
        }
        {!match.params.id && this.props.children({})}
      </>
    );
  }
}

WizardStep.propTypes = {
  match: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
};

export default withRouter(WizardStep);
