/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import noop from 'lodash/noop';
import { Box } from 'rebass';
import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';
import MaskedInput from 'react-maskedinput';

import { fontSize } from '../../../theme/styleUtils';
import { ErrorBox } from './FieldError';
import { FONT_FAMILIES } from '../../../theme/globalStyle';
import Label from '../text/Label';

const BaseInputStyles = css`
  font-size: 17px;
  font-family: ${props => props.theme.fonts[FONT_FAMILIES.AVENIR_LIGHT]};
  border: none;
  color: ${props => props.theme.colors.darkBrown};
  &:focus, &:active , &:hover {
    border-color: ${props => props.theme.colors.purplePrimary};
  }
  &::placeholder {
    padding: 0;
    font-size: 15px;
    color: ${props => props.theme.colors.pearlGray};
    /* text-align: left; */
    position: relative;
    left: -2px; 
  }
  &:active, &:focus {
    outline: none;
  }
  width: 100%;
`;

export const BorderedInput = styled.input`
  ${BaseInputStyles}
`;

const BorderedMaskedInput = styled(MaskedInput)`
  ${BaseInputStyles}
`;

const CustomTextArea = styled.textarea`
  ${BaseInputStyles}
  resize: none;
`;

export const InputWrapper = styled(Box)`
  padding: 8px 8px 5px 18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid ${props => props.theme.colors.pearlGray};
  border-radius: 5px;
  &:focus, &:hover {
    border-color: ${props => props.theme.colors.purplePrimary};
  }
  border-color: ${props => (props.active || (props.error && props.touched)) && props.theme.colors.purplePrimary};
`;

const InputPreloader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.navInactiveGray};
  text-align: center;
`;

const Prefix = styled.div`
  padding: 2px 15px;
  color: #888;
`;

const Suffix = styled.div`
  padding: 2px 15px;
  color: #888;
`;

const ErrorWrapper = styled(Box)`
  position: absolute;
  ${props => (props.topError ? 'top' : 'bottom')}: -25px;
  right: 0;
`;

const onlyNumeric = value => value && value.replace(/[^\d]/g, '');

export const TextInputLabel = styled.label`
  ${fontSize(15)}
  letter-spacing: 0.05em;
  color: ${props => props.theme.colors.darkBrown};
  position: relative;
  padding: 5px 0px;
`;

const TextInput = ({
  name,
  placeholder,
  validate,
  loading,
  password = false,
  textarea = false,
  autoFocus,
  mask,
  prefix,
  suffix,
  numbersOnly,
  label,
  placeholderChar,
  format,
  formatOnBlur,
  topError,
}) => {
  const ownInputProps = {
    type: (!textarea && password) ? 'password' : 'text',
    placeholder,
    placeholderChar: mask && placeholderChar,
    autoFocus,
    mask,
    numbersOnly,
    rows: 8,
  };
  return (
    <Field
      name={name}
      component="input"
      validate={validate}
      parse={numbersOnly && onlyNumeric}
      format={format}
      formatOnBlur={formatOnBlur}
    >
      {({ input, meta }) => (
        <>
          {label && <Label>{label}</Label>}
          <InputWrapper {...meta} mb="5px">
            {prefix && <Prefix>{prefix}</Prefix>}
            { textarea
              ? <CustomTextArea {...ownInputProps} {...input} />
              : mask
                ? <BorderedMaskedInput {...ownInputProps} {...input} />
                : <BorderedInput {...ownInputProps} {...input} />
            }
            { suffix && <Suffix>{suffix}</Suffix>}
            { loading && (
              <InputPreloader>
                <Loader
                  type="ThreeDots"
                  color="#818181"
                  height="5"
                />
              </InputPreloader>
            )}
            <ErrorWrapper topError={topError}>
              <ErrorBox meta={meta} />
            </ErrorWrapper>
          </InputWrapper>
        </>
      )}
    </Field>
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  loading: PropTypes.bool,
  py: PropTypes.number,
  px: PropTypes.number,
  variant: PropTypes.oneOf(['gray', 'dash', 'bordered']),
  autoFocus: PropTypes.bool,
  mask: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  numbersOnly: PropTypes.bool,
  label: PropTypes.string,
  placeholderChar: PropTypes.string,
  format: PropTypes.func,
  formatOnBlur: PropTypes.bool,
  topError: PropTypes.bool,
};

TextInput.defaultProps = {
  placeholder: undefined,
  validate: noop,
  loading: false,
  py: undefined,
  px: undefined,
  autoFocus: false,
  variant: 'bordered',
  numbersOnly: false,
  placeholderChar: '',
};

export default TextInput;
