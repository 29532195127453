import gql from 'graphql-tag';

export const GET_OFFERED_OBJECTS = gql`
  query GetOfferedObjects(
    $dateFrom: ISO8601DateTime
    $leaseLength: Int
    $privateBathroom: Boolean
    $type: String
    $maxPrice: Float
    $minPrice: Float
    $bedroomsMinNumber: String
    $bedroomsMaxNumber: String
    $size: Int
    $page: Int
  ) {
    offeredObjects(
      dateFrom: $dateFrom
      leaseLength: $leaseLength
      privateBathroom: $privateBathroom
      typeOfListing: $type
      maxPrice: $maxPrice
      minPrice: $minPrice
      bedroomsMaxNumber: $bedroomsMaxNumber
      bedroomsMinNumber: $bedroomsMinNumber
      size: $size
      page: $page
    ) {
      results {
        documentId
        indexableId
        mainImageUrl
        objectAddress
        objectType
        privateBathroom
        pricePerMonth
        bedroomsNumber
        bathroomsNumber
        availableFrom
        typeOfListing
      }
      aggregations {
        name
        value
      }
      total
    }
  }
`;
