import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { withNamespaces } from 'react-i18next';
import Body from '../../common/text/Body';
import ProfileEditPart from './ProfileEditPart';
import TextInput from '../../common/form/TextInput';
import Checkbox from '../../common/form/Checkbox';

const ProfileCompanyPart = ({ t }) => (
  <ProfileEditPart title={t('profile.company.title')}>
    <Box mb={35} />
    <TextInput label={t('profile.company.name')} name="company.companyName" />
    <Box mb={25} />
    <TextInput label={t('profile.company.address')} name="company.address" />
    <Box mb={25} />
    <TextInput label={t('profile.company.registeredCountry')} name="company.registeredCountry" />
    <Box mb={25} />
    <TextInput label={t('profile.company.registeredNumber')} name="company.registeredNumber" />
    <Box mb={25} />
    <Checkbox label={t('profile.company.authorisation')} name="company.isRegisteredLandlord" />
    <Box mb={25} />
    <Body>{t('profile.company.invoicingTitle')}</Body>
    <Checkbox label={t('profile.company.invoicing')} name="company.downrootsInvoices" />
  </ProfileEditPart>
);

ProfileCompanyPart.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('account')(ProfileCompanyPart);
