import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import { injectStripe } from 'react-stripe-elements';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import { UPDATE_BOOKING_PAYMENT } from '../../../graphQL/mutations/bookings';
import { GET_BOOKING_BASICS } from '../../../graphQL/queries/bookings';
import { mapApiErrors } from '../../../utils/errorHandling';
import Footer from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';
import PaymentForm from './PaymentForm';

class Payment extends Component {
  state = {
    submitting: false,
    isDefaultCreditCard: true,
  };

  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.BOOKINGS.VERIFICATION(match.params.id));
  };

  onNext = noop;

  setIsDefaultCreditCard = bool => this.setState({ isDefaultCreditCard: bool });

  onSubmit = async ({ values, creditCard }) => {
    try {
      const variables = {};
      const {
        match, stripe, history, mutate,
      } = this.props;

      if (!this.state.isDefaultCreditCard || !creditCard) {
        const { token, error } = await stripe.createToken({
          type: 'card',
        });

        if (error) {
          return {
            [FORM_ERROR]: error.message,
          };
        }

        variables.stripeToken = token.id;
      }

      const { errors } = await mutate({
        variables: {
          ...variables,
          id: match.params.id,
          paymentMethod: values.paymentMethod,
        },
      });

      if (errors) {
        return mapApiErrors(errors);
      }

      history.push(APP_ROUTES.BOOKINGS.VIEW_LEASE_AND_BOOK(match.params.id));
    } catch (e) {
      console.log(e);
    }
  };

  getInitialValues = data => pick(get(data, 'booking'), ['depositOptionId', 'moveOutGuarantee', 'paymentMethod']);

  render() {
    return (
      <Fragment>
        <WizardStep query={GET_BOOKING_BASICS} withoutLayout>
          {({ data }) => (
            <PaymentForm
              initialValues={this.getInitialValues(data)}
              onSubmit={values => this.onSubmit({ values, creditCard: data.booking.creditCard })}
              onNext={(onNext) => { this.onNext = onNext; }}
              exposeState={({ submitting }) => this.setState({ submitting })}
              creditCard={data.booking.creditCard}
              isDefaultCreditCard={this.state.isDefaultCreditCard}
              setIsDefaultCreditCard={this.setIsDefaultCreditCard}
            />
          )}
        </WizardStep>
        <Footer
          onPrev={this.onPrev}
          onNext={() => this.onNext()}
          disabledNext={this.state.submitting}
          disabledSaveAndExit
        />
      </Fragment>
    );
  }
}

Payment.propTypes = {
  stripe: PropTypes.shape({
    createToken: PropTypes.func.isRequired,
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  graphql(UPDATE_BOOKING_PAYMENT),
  injectStripe,
);

export default enhance(Payment);
