import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import noop from 'lodash/noop';
import { Button, Flex } from 'rebass';
import styled from 'styled-components';
import { fontSize, transitionBackgroundColor } from '../../../theme/styleUtils';
import { ErrorBox } from './FieldError';

const IncrementButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.47);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  ${transitionBackgroundColor('.15s')}
  user-select: none;
  &:hover {
    background-color: ${props => props.theme.colors.cremeWhite};
  }
  &:active {
    color: #fff;
    background-color: ${props => props.theme.colors.purplePrimary};
  }
  &:focus {
    outline: none;
  }
`;

const Number = styled.label`
  ${fontSize(16)}
  color: ${props => props.theme.colors.darkBrown};
  margin: 0 20px;
`;

const CounterWrapper = styled(Flex)`
  height: 47px;
`;

class Counter extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
  }

  static defaultProps = {
    validate: noop,
  }

  increment = (onChange, value, add = 1) => (event) => {
    event.preventDefault();
    if (value + add >= 0) {
      onChange(`${value + add}`);
    }
  };

  render = () => {
    const {
      name, validate,
    } = this.props;
    return (
      <Field name={name} component="input" validate={validate}>
        {({ input, meta }) => (
          <CounterWrapper flexDirection="column" alignItems="flex-end" justifyContent="flex-start">
            <Flex alignItems="center">
              <input type="hidden" {...input} />
              <IncrementButton onClick={this.increment(input.onChange, +input.value, -1)}>-</IncrementButton>
              <Number>{input.value}</Number>
              <IncrementButton onClick={this.increment(input.onChange, +input.value)}>+</IncrementButton>
            </Flex>
            <ErrorBox meta={meta} />
          </CounterWrapper>
        )}
      </Field>
    );
  }
}

export default Counter;
