import compact from 'lodash/compact';
import get from 'lodash/get';
import map from 'lodash/map';
import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import {
  fontMixin, fontSize, LIGHT,
} from '../../../theme/styleUtils';
import SeeMore from './SeeMore';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

const FacilityList = styled.ul`
  padding-left: 10px;
  margin-left: 7px;
  > li {
    ${fontMixin(LIGHT)}
    ${fontSize(17)}
    color: ${props => props.theme.colors.darkBrown};
    margin: 8px 0;
  }
`;

export const FacilityListTitle = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 20,
  color: 'darkBrown',
})``;

export const DetailsSubtitle = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 30,
  mb: 30,
})``;

const FacilitiesList = ({
  title,
  facilities,
  collapsed,
}) => (
  <Box width={[1 / 2, 1 / 2, 1 / 4]} mb={20}>
    <FacilityListTitle>{title}</FacilityListTitle>
    <FacilityList>
      {map(collapsed ? take(facilities, 4) : facilities, (item, idx) => (
        <li key={`facility-${item}-${idx}`}>{item}</li>
      ))}
    </FacilityList>
  </Box>
);

FacilitiesList.defaultProps = {
  facilities: ['Double bed', 'Wardrobe', 'Sheets and bedding', 'Desk and chair'],
  collapsed: true,
};

FacilitiesList.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
};

const DetailsFacilityPart = ({
  t, data, isPlace,
}) => {
  const [facilitiesCollapsed, setFacilitiesCollapsed] = useState(true);

  const room = get(data, isPlace ? 'place.rooms[0]' : 'room', {});
  const { bedKind, bathKind, roomFacilities: roomFacilitiesObjects } = room;
  const roomFacilities = compact([get(bedKind, 'name'), get(bathKind, 'name'), ...map(roomFacilitiesObjects, 'name')]);
  const placeFacilities = map(get(data, `${isPlace ? 'place' : 'room.place'}.availableComfort`, []), 'name');
  const utilitiesFacilities = map(get(data, `${isPlace ? 'place' : 'room.place'}.availableUtilities`, []), 'name');
  const niceTouchesFacilities = map(uniqBy(get(data, `${isPlace ? 'place' : 'room.place'}.availableNiceTouches`, []), 'name'), 'name');
  const isStudio = get(data, 'place.unitType.name') === 'Studio';

  const isSeeMoreAvailable = roomFacilities.length > 4 ||
    placeFacilities.length > 4 ||
    utilitiesFacilities.length > 4 ||
    niceTouchesFacilities.length > 4;

  return (
    <>
      <DetailsSubtitle>{t('details.price')}</DetailsSubtitle>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <FacilitiesList
          title={t('details.facilities.place')}
          facilities={placeFacilities}
          collapsed={facilitiesCollapsed}
        />
        <FacilitiesList
          title={t(`details.facilities.${!isPlace ? 'room' : isStudio ? 'sleepingArea' : 'mainRoom'}`)}
          facilities={roomFacilities}
          collapsed={facilitiesCollapsed}
        />
        <FacilitiesList
          title={t('details.facilities.utilities')}
          facilities={utilitiesFacilities}
          collapsed={facilitiesCollapsed}
        />
        <FacilitiesList
          title={t('details.facilities.niceTouches')}
          facilities={niceTouchesFacilities}
          collapsed={facilitiesCollapsed}
        />
      </Flex>
      {
        isSeeMoreAvailable && (
          <SeeMore
            collapsedState={facilitiesCollapsed}
            stateSetter={setFacilitiesCollapsed}
          />
        )
      }
    </>
  );
};

DetailsFacilityPart.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isPlace: PropTypes.bool.isRequired,
};

export default withNamespaces('listings')(DetailsFacilityPart);
