import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import get from 'lodash/get';
import intersectionWith from 'lodash/intersectionWith';
import { Scrollbars } from 'react-custom-scrollbars';

import { compose } from 'recompose';
import { Field } from 'react-final-form';

import { FormPart } from '../../common/form/FormWrapper';
import Body from '../../common/text/Body';
import Subtitle from '../../common/text/Subtitle';
import Title from '../../common/text/Title';
import { RoundedButton, FilledRoundedButton } from '../../common/wizard/Footer';
import Modal from '../../common/Modal';
import crossClose from '../../../assets/img/cross-close.svg';
import Checkbox from '../../common/form/Checkbox';
import FieldError from '../../common/form/FieldError';
import Photo from '../../common/photos/Photo';

const EditButton = styled(RoundedButton).attrs({ type: 'button' })`
  padding: 2px 45px;
`;

const CloseButton = styled.a`
  float: right;
  cursor: pointer;
`;

const LightboxContainer = styled(Box)`
  width: 858px;
  height: 696px;
`;

const PhotoCheckboxWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

class RoomPhotos extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    room: PropTypes.object.isRequired,
    getState: PropTypes.func.isRequired,
    formOnChange: PropTypes.func.isRequired,
  }

  state = {
    lightboxOpened: false,
    showPhotosFromPlace: false,
  };

  openLightbox = () => {
    const { formOnChange, getState } = this.props;
    const memoizedPhotoIds = get(getState(), 'values.memoizedPhotoIds');
    if (memoizedPhotoIds) {
      formOnChange('photoIds', memoizedPhotoIds);
    } else {
      // only for initialization of the field
      formOnChange('memoizedPhotoIds', get(getState(), 'values.photoIds'));
    }
    this.setState({ lightboxOpened: true });
  }

  onSavePhotos = () => {
    const { getState, formOnChange } = this.props;
    const value = get(getState(), 'values.photoIds');
    const initial = get(getState(), 'initialValues.photoIds');
    const shouldUpdatePhotoState = !isEqual(initial.sort(), value.sort());
    formOnChange('memoizedPhotoIds', value);
    this.setState({ lightboxOpened: false, showPhotosFromPlace: shouldUpdatePhotoState });
  }

  onLightboxClose = () => {
    const { formOnChange, getState } = this.props;
    formOnChange('photoIds', get(getState(), 'values.memoizedPhotoIds'));
    this.setState({ lightboxOpened: false });
  };

  onSelectPhoto = (id) => {
    const { getState, formOnChange } = this.props;
    const photoIds = get(getState(), 'values.photoIds', []);
    formOnChange(
      'photoIds',
      photoIds.find(photoId => photoId === id) ?
        photoIds.filter(photoId => photoId !== id) :
        [...photoIds, id],
    );
  };

  _renderPhotos = photos => (
    <FieldError direction="column" name="photoIds">
      <Flex flexDirection="row" flexWrap="wrap">
        {map(photos, photo => (
          <Box mr={30} my={15} key={`room-photo-${photo.id}`}>
            <Photo smaller photo={photo.photo} />
          </Box>
        ))}
      </Flex>
    </FieldError>
  );

  _renderPhotosLightbox = () => {
    const { room, t } = this.props;
    const placePhotos = get(room, 'place.photos');
    return (
      <LightboxContainer py={80} px={60} bg="white">
        <Box width={1}>
          <Title>{t('bedroom.photos.title')}</Title>
          <CloseButton
            onClick={this.onLightboxClose}
          >
            <img src={crossClose} alt="close" />
          </CloseButton>
        </Box>
        <Subtitle>{t('bedroom.photos.select')}</Subtitle>
        { placePhotos && (
          <Scrollbars style={{ height: '75%' }}>
            <Flex flexWrap="wrap">
              {map(placePhotos, (photo) => {
                const photoDisabled = photo.roomId && (photo.roomId !== room.id);
                return (
                  <Box
                    width={1 / 4}
                    pr={30}
                    my={15}
                    key={`modal-room-photo-${photo.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.onSelectPhoto(photo.id);
                    }}
                  >
                    <Box>
                      <Photo smaller photo={photo.photo} disabled={photoDisabled}>
                        <PhotoCheckboxWrapper>
                          <Checkbox
                            name="photoIds"
                            value={photo.id}
                            disabled={photoDisabled}
                            format={(val) => {
                              if (photoDisabled) {
                                return omit(val, photo.id);
                              }
                              return val;
                            }}
                          />
                        </PhotoCheckboxWrapper>
                      </Photo>
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Scrollbars>
        )}
        <Flex justifyContent="flex-end" mt={25}>
          <FilledRoundedButton onClick={this.onSavePhotos}>{t('save')}</FilledRoundedButton>
        </Flex>
      </LightboxContainer>
    );
  }

  render() {
    const {
      t, room, getState,
    } = this.props;
    const { lightboxOpened, showPhotosFromPlace } = this.state;
    const placePhotos = get(room, 'place.photos');
    return (
      <FormPart title={t('bedroom.photos.title')}>
        <Flex justifyContent="space-between" width={1} mb={15} mt={-10}>
          <Body>{t('bedroom.photos.subtitle')}</Body>
          <EditButton onClick={this.openLightbox}>{t('bedroom.photos.edit')}</EditButton>
        </Flex>
        <Flex flexWrap="wrap">
          { showPhotosFromPlace
            ? this._renderPhotos(intersectionWith(placePhotos, get(getState(), 'values.photoIds'), (a, b) => a.id === b))
            : this._renderPhotos(room.photos)
          }
        </Flex>
        { map(placePhotos, photo => (
          <Field key={`photo-hidden-${photo.id}`} component="input" type="hidden" value={photo.id} name="photoIds" />
        ))}
        { lightboxOpened && (
          <Modal onClose={this.onLightboxClose}>
            {this._renderPhotosLightbox()}
          </Modal>
        )}
      </FormPart>
    );
  }
}

const enhance = compose(
  withNamespaces('listingForm'),
);

export default enhance(RoomPhotos);
