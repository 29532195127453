import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet-async';
import get from 'lodash/get';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from 'rebass';

import AppHeader from './AppHeader';
import FreedomShowcase from './FreedomShowcase';
import Mosaic from './Mosaic';
import HomeBulletLists from './HomeBulletLists';
import Cards from './Cards';
import Modal from '../common/Modal';
import TenantProfileForm from '../welcome/forms/TenantProfileForm';
import Layout from '../common/Layout';
import FlowParts from './FlowParts';


const HomeLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


class HomeWrapper extends Component {
  state = { isModalOpened: false };

  componentDidMount() {
    const isModalOpened = get(this.props, 'location.isModalOpened', false);
    if (isModalOpened) {
      this.setState({ isModalOpened });
    }
  }

  toggleModal = () => this.setState(prevState => ({ isModalOpened: !prevState.isModalOpened }));

  render() {
    const { t } = this.props;
    const { isModalOpened } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>{t('home')}</title>
        </Helmet>
        <AppHeader />
        <HomeLayout>
          <Flex flexDirection="column" alignItems="stretch" width={[0.9, 645, 645, 1290]}>
            <FreedomShowcase />
            <Cards />
          </Flex>
          <Mosaic />
          <FlowParts />
          <HomeBulletLists />

          {isModalOpened && (
            <Modal bg="rgba(0, 0, 0, 0.5)" onClose={this.toggleModal}>
              <TenantProfileForm onClose={this.toggleModal} />
            </Modal>
          )}
        </HomeLayout>
      </Fragment>
    );
  }
}

HomeWrapper.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('home')(HomeWrapper);
