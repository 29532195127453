import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { withNamespaces } from 'react-i18next';

import bgWoman from '../../assets/img/woman-black-cup.jpg';
import {
  dimMixin, fontMixin, fontSize, THICK,
} from '../../theme/styleUtils';
import Headline from '../common/text/Headline';
import { mediaQuery } from '../../theme/globalStyle';

const Col = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  text-align: left;
  h1 {
    ${fontSize(90)}
    ${fontMixin(THICK)}
    color: #fff;
    margin: 0;
  }
  h4 {
    ${fontSize(20)}
    max-width: 450px;
    color: #fff;
  }
  > {
    margin: 20px 0;
  }
`;
const Line = styled(Box)`
  height: 4px;
  background-color: #fff;
`;

const AppHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 600px;
  width: 100%;
  background: url(${bgWoman});
  background-size: cover;
  background-position-x: 50%;
  ${mediaQuery.tablet``}
  ${mediaQuery.tablet`${dimMixin()}`}
`;
const Wrapper = styled(Flex)`
  //max-width: 1200px;
  width: 85%;
  align-items: center;
`;

class AppHeader extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <AppHeaderWrapper>
          <Wrapper>
            <Col>
              <Flex>
                <Headline color="white" fontSize={[40, 40, 60]}>{t('header.title')}</Headline>
              </Flex>
              <Line width="100px" />
              <Flex>
                <h4>{t('header.desc')}</h4>
              </Flex>
            </Col>
          </Wrapper>
        </AppHeaderWrapper>
      </>
    );
  }
}

AppHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('home')(AppHeader);
