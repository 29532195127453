import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { Flex, Box } from 'rebass';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import map from 'lodash/map';
import get from 'lodash/get';
import DayPicker from 'react-day-picker';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Form, Field } from 'react-final-form';

import { UPDATE_PLACE_BOOK_MY_VIRTUAL_TOUR } from '../../../../graphQL/mutations/createOrUpdatePlace';
import TextInput from '../../../common/form/TextInput';
import HollowButton from '../../../common/buttons/HollowButton';
import { FormSubPart } from '../../../common/form/FormWrapper';
import Dropdown from '../../../common/form/Dropdown';
import { required } from '../../../../utils/formValidation';
import { APP_ROUTES } from '../../../../constants';


const WhiteBox = styled(Box)`
  background-color: white;
`;

class BookVirtualTourForm extends Component {
  _submitVirtualTourBooked = async ({ variables }) => {
    const { updatePlaceBookMyVirtualTour, onClose, history } = this.props;
    await updatePlaceBookMyVirtualTour({ variables: { id: variables.placeId, virtualTourBookedAt: moment(variables.virtualTourBookedAt).hour(variables.virtualTourBookedAtHour).format(), virtualTourBookedNote: variables.virtualTourBookedNote } });
    onClose();
    history.push(APP_ROUTES.DASHBOARD.LANDLORD.LISTINGS(), {
      bookedPlaceId: variables.placeId,
    });
  };

  _timeSlotOptions = () => map([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], i => ({ label: `${i % 12 || 12}:00 ${(i < 12) ? 'am' : 'pm'}`, value: i }));


  render = () => {
    const { t, placeId } = this.props;
    const selectedDate = this.props.bookedAt ? moment(this.props.bookedAt) : moment();
    const selectedHour = selectedDate.hours();

    return (
      <Flex>
        <WhiteBox p="15px">
          <FormSubPart subText={t('tour.booking.subtitle')} subTitle={t('tour.booking.title')}>
            <Form
              initialValues={{
                virtualTourBookedAtHour: selectedHour,
                virtualTourBookedAt: selectedDate,
                virtualTourBookedNote: this.props.bookedNote,
              }}
              onSubmit={values => this._submitVirtualTourBooked({ variables: { ...values, placeId } })}
              id="book-virtual-tour-password-form"
              render={({ handleSubmit, form: { change, getState }, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Flex flexDirection="column">
                    <Field name="virtualTourBookedAt" component="input" type="hidden" />
                    <DayPicker
                      selectedDays={moment(get(getState(), 'values.virtualTourBookedAt')).toDate()}
                      disabledDays={[{ before: moment().add(2, 'days').toDate(), after: moment().add(3, 'months').toDate() }]}
                      placeholder="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      numberOfMonths={2}
                      onDayClick={(v) => {
                        change('virtualTourBookedAt', v);
                      }}
                      onDayChange={this.handleFromChange}
                    />
                    <Box mt={10}>
                      <Dropdown label={t('tour.booking.hour')} name="virtualTourBookedAtHour" options={this._timeSlotOptions()} validate={required} />
                    </Box>
                    <Box mt={10}>
                      <TextInput label={t('tour.booking.note')} value={this.props.bookedNote} id="virtualTourBookedNote" textarea name="virtualTourBookedNote" placeholder={t('tour.booking.notePlaceholder')} />
                    </Box>
                    <Box mt={10}>
                      <HollowButton mt={10} id="book-tour-button" type="submit" disabled={submitting}>
                        {t('tour.booking.button')}
                      </HollowButton>
                    </Box>
                  </Flex>
                </form>
              )}
            />
          </FormSubPart>
        </WhiteBox>
      </Flex>
    );
  }
}

BookVirtualTourForm.propTypes = {
  placeId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  updatePlaceBookMyVirtualTour: PropTypes.func.isRequired,
  bookedNote: PropTypes.string,
  bookedAt: PropTypes.string,
  history: PropTypes.object.isRequired,
};


const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(UPDATE_PLACE_BOOK_MY_VIRTUAL_TOUR, { name: 'updatePlaceBookMyVirtualTour' }),
);

export default enhance(BookVirtualTourForm);
