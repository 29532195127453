import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, Query } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { GET_CURRENT_USER } from '../../graphQL/queries';
import { NavBar, NavBarItem } from '../common/NavBar';

class MainNavBar extends Component {
  render() {
    const { t, navBar: ChosenNavBar } = this.props;
    return (
      <Query query={GET_CURRENT_USER}>
        {({ data }) => {
          const isLoggedIn = get(data, 'user');
          return (
            <ChosenNavBar>
              <NavBarItem to="/listings">
                {t('nav.listings')}
              </NavBarItem>
              <NavBarItem to="/owners">
                {t('nav.owners')}
              </NavBarItem>
              <NavBarItem to="/partners">
                {t('nav.partners')}
              </NavBarItem>
              { !isLoggedIn && (
                <NavBarItem to="/sign-in">
                  {t('nav.signIn')}
                </NavBarItem>
              )}
              <NavBarItem to="/help">
                Help
              </NavBarItem>
            </ChosenNavBar>
          );
        }}
      </Query>
    );
  }
}

MainNavBar.propTypes = {
  t: PropTypes.func.isRequired,
  navBar: PropTypes.func,
};

MainNavBar.defaultProps = {
  navBar: NavBar,
};

const enhance = compose(
  withNamespaces('home'),
);

const EnhancedMainNavBar = enhance(MainNavBar);

// eslint-disable-next-line react/prop-types
export const customizedMainNavBar = CustomNavBar => ({ children }) => (
  <EnhancedMainNavBar navBar={CustomNavBar}>
    {children}
  </EnhancedMainNavBar>
);

export default EnhancedMainNavBar;
