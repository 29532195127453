import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import { Mutation } from 'react-apollo';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import TextInput from '../../common/form/TextInput';
import { required, isValidBirthday } from '../../../utils/formValidation';
import Dropdown from '../../common/form/Dropdown';
import FormLabel from '../../common/form/FormLabel';
import FieldError from '../../common/form/FieldError';
import { EDIT_PROFILE } from '../../../graphQL/mutations/editProfile';
import { EMPLOYMENT_TYPES, GENDERS } from '../../../constants';
import { FilledRoundedButton } from '../../common/wizard/Footer';
import { fontSize } from '../../../theme/styleUtils';
import DatePicker from '../../common/form/DatePicker';
import PhoneNumberPrefix from '../../common/form/PhoneNumberPrefix';


const CreateProfileText = styled(Text)`
  ${fontSize(30)}
`;

const SecureInfoText = styled(Text)`
  ${fontSize(17)}
`;

const TenantProfileFrom = ({ t, onClose }) => (
  <Mutation mutation={EDIT_PROFILE}>
    {updateProfile => (
      <Form
        onSubmit={async (values) => {
          try {
            const { errors } = await updateProfile({
              variables: { ...values },
            });

            if (errors) {
              return {
                [FORM_ERROR]: 'Please try again',
              };
            }
            onClose();
          } catch (e) {
            console.log(e);
          }
        }}
        render={({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Flex
              bg="#fff"
              px={[20, 50]}
              py={[20, 50]}
              pb={20}
              flexDirection="column"
              width={[1, 1, 620, 620]}
              alignItems="center"
            >
              <CreateProfileText mb={30}>
                Create your profile
              </CreateProfileText>
              <SecureInfoText mb={30}>
                This information helps keep Downroots secure for everybody.
              </SecureInfoText>
              <Flex width={1} mb={25}>
                <Box width={0.5} mr={15}>
                  <FormLabel required>
                    {t('profile.contact.form.firstname')}
                  </FormLabel>
                  <TextInput py={2} name="firstName" validate={required} />
                </Box>
                <Box width={0.5} ml={15}>
                  <FormLabel required>
                    {t('profile.contact.form.lastname')}
                  </FormLabel>
                  <TextInput
                    py={2}
                    bordered
                    name="lastName"
                    validate={required}
                  />
                </Box>
              </Flex>
              <Box width={1} mb={25}>
                <FormLabel required>
                  {t('profile.contact.form.address')}
                </FormLabel>
                <TextInput py={2} bordered name="address" validate={required} />
              </Box>
              <Flex justifyContent="space-between" width={1}>
                <Box width={1} mb={25}>
                  <FormLabel required>
                    {t('profile.contact.form.birthday')}
                  </FormLabel>
                  <Flex>
                    <DatePicker name="birthday" />
                  </Flex>
                  <Flex width={1}>
                    <FieldError name="birthday" validate={isValidBirthday} />
                  </Flex>
                </Box>
                <Box width={1}>
                  <FormLabel required>
                    {t('profile.employment.title')}
                  </FormLabel>
                  <Dropdown
                    name="kindOfJob"
                    validate={required}
                    options={EMPLOYMENT_TYPES}
                  />
                </Box>
              </Flex>
              <Box width={1} mb={25}>
                <FormLabel>{t('profile.contact.form.gender')}</FormLabel>
                <Dropdown name="sex" options={GENDERS} />
              </Box>

              <Box width={1} mb={25}>
                <FormLabel>{t('profile.contact.form.phone')}</FormLabel>
                <PhoneNumberPrefix name="phone" />
              </Box>
              <div>{submitError}</div>
              <Flex width={1}>
                <FilledRoundedButton onClick={handleSubmit} disabled={submitting}>Done</FilledRoundedButton>
              </Flex>
            </Flex>
          </form>
        )}
      />
    )}
  </Mutation>
);

TenantProfileFrom.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withNamespaces('account')(TenantProfileFrom);
