import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Flex, Box, Text, Image,
} from 'rebass';
import { withNamespaces } from 'react-i18next';

import Hide from 'hidden-styled';
import BasicCard from '../cards/BasicCard';
import graySpot from '../../assets/img/gray-spot.svg';
import dottedPath from '../../assets/img/dotted-line.svg';
import consierge from '../../assets/img/card-call.png';
import gallery from '../../assets/img/card-gallery.png';
import movieBox from '../../assets/img/movie-box.png';
import booking from '../../assets/img/booking.png';
import circleWithLine from '../../assets/img/circle-with-line.svg';
// import { fontMixin, fontSize, THICK } from '../../theme/styleUtils';
import { FONT_FAMILIES, mediaQuery } from '../../theme/globalStyle';
import { ReactComponent as ClockIcon } from '../../assets/img/home-clock.svg';
import { ReactComponent as ExitIcon } from '../../assets/img/home-exit.svg';

const BoxWithGrayBacker = styled(Box)`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 230px;
    top: 128px;
    left: -18px;
    background-color: #c9d3d8;
    z-index: -1;
  }
`;

const CircleWithLine = styled(Image)`
  position: absolute;
  height: 145px;
  width: auto;
  right: -96px;
`;

const GraySpot = styled(Image)`
  position: absolute;
  width: auto;
  z-index: -1;
  height: 180px;
  right: -80px;
  top: -80px;
`;

const DottedPath = styled.img`
  position: relative;
  z-index: -1;
  transform: rotate(160deg);
  right: 55px;
  bottom: -150px;
  top: 91px;
`;

const MosaicTitle = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_NORMAL,
  fontSize: 60,
  width: [350, 350, 350, 450],
})`
  position: relative;
  line-height: 70px;
  text-align: center;
`;

const GuaranteeBox = styled(Flex).attrs({
  color: 'paleBlue',
  alignItems: 'center',
  flexDirection: ['column', 'row', 'row', 'column'],
  justifyContent: 'space-evenly',
  py: [40, 40, 40, 60],
  px: [40, 40, 40, 60],
})`
  > :not(:last-child){
    margin-bottom: 30px;
    margin-right: 0;
    ${mediaQuery.desktop`
      margin-right: 30px
      margin-bottom: 0px;
    `};
    ${mediaQuery.mobile`
      margin-right: 0px
      margin-bottom: 30px;
    `};
  }
  path {
    fill: ${props => props.theme.colors.paleBlue};
  } 
  svg {
    width: 90px;
    height: 90px;
    flex: 0 0 90px;
  }
}
`;

class Mosaic extends Component {
  render() {
    const { t } = this.props;
    return (
      <Box mb={140} width={1}>
        <Flex
          justifyContent="center"
          mt={100}
          style={{ position: 'relative' }}
        >
          <MosaicTitle width={450}>
            {t('mosaic.experienceHome')}
            <GraySpot src={graySpot} />
          </MosaicTitle>
        </Flex>
        <Flex flexDirection={['column', 'column', 'column', 'row']} flexWrap="nowrap">
          <Flex
            alignItems="center"
            flexDirection={['row', 'column']}
            justifyContent={['space-around', 'initial']}
            flexWrap="wrap"
            width={[1, 1, 1, 1 / 2]}
            mr={[60]}
            ml={[0, 0, 0, 60]}
          >
            <BoxWithGrayBacker mt={50} width={1}>
              <Hide xs sm md>
                <CircleWithLine src={circleWithLine} />
              </Hide>
              <BasicCard
                title={t('mosaic.consierge.title')}
                label={t('mosaic.consierge.desc')}
                cardWidth={[0.9, 334]}
                cardHeight={['auto', 395]}
                boxShadow="2px 2px 16px rgba(0, 0, 0, .25)"
                content={() => <Image src={consierge} width={1} />}
              />
            </BoxWithGrayBacker>
            <Box mt={50}>
              <BasicCard
                title={t('mosaic.viewings.title')}
                label={t('mosaic.viewings.desc')}
                cardHeight="auto"
                cardWidth={0.9}
                boxShadow="none"
                content={() => <Image src={movieBox} width={1} />}
              />
            </Box>
            <Box mt={50}>
              <BasicCard
                title={t('mosaic.guarantee.title')}
                label={t('mosaic.guarantee.desc')}
                cardHeight="auto"
                cardWidth={0.9}
                content={() => (
                  <GuaranteeBox>
                    <ClockIcon />
                    <Text fontFamily={FONT_FAMILIES.AVENIR_BOLD} fontSize={[40]} fontWeight={700} textAlign="center">
                      48
                      <br />
                      Hours
                    </Text>
                    <ExitIcon />
                  </GuaranteeBox>
                )}
              />
            </Box>
          </Flex>
          <Flex
            alignItems="center"
            flexDirection="column"
            width={[1, 1, 1, 1 / 2]}
            style={{ position: 'relative' }}
            flexWrap="wrap"
            ml={[0, 0, 0, 60]}
            mr={60}
          >
            <Box mt={50} width={1}>
              <BasicCard
                title={t('mosaic.community.title')}
                label={t('mosaic.community.desc')}
                cardHeight="auto"
                boxShadow="none"
                content={() => <Image src={gallery} width={1} />}
              />
            </Box>
            <Box mt={50} width={1}>
              <BasicCard
                title={t('mosaic.booking.title')}
                label={t('mosaic.booking.desc')}
                cardHeight="auto"
                content={() => <Image src={booking} width={1} />}
              />
            </Box>
            <DottedPath src={dottedPath} />
          </Flex>
        </Flex>
      </Box>
    );
  }
}

Mosaic.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('home')(Mosaic);
