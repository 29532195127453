import gql from 'graphql-tag';

export const SIGN_IN = gql`
  mutation SignIn(
      $email: String!
      $password: String!
    ) {
      signIn(payload: {
        email: $email
        password: $password
      }) {
        accessToken
        client
        expiry
        tokenType
        uid
        user {
          id
          role {
            id
            name
          }
        }
      }
    }
`;

export const FB_SIGN_IN = gql`
  mutation SignInByFacebook(
    $accessToken: String!
    $userID: String!
    $role: String
    $termsOfService: Boolean
  ) {
    signInByFacebook(payload: {
      access_token: $accessToken
      uid: $userID
      role: $role
      termsOfService: $termsOfService
    }) {
      accessToken
      client
      expiry
      tokenType
      uid
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const SIGN_IN_BY_MASTER_TOKEN = gql`
  mutation SignInByMasterToken($masterToken: String!) {
    signInByMasterToken(masterToken: $masterToken) {
      accessToken
    }
  }
`;
