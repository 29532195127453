import gql from 'graphql-tag';

export const REGISTER_USER = gql`
  mutation SignUp(
    $lastName: String
    $firstName: String
    $email: String!
    $password: String!
    $role: String
    $termsOfService: Boolean
  ) {
    signUp(payload: {
      lastName: $lastName
      firstName: $firstName
      email: $email
      password: $password
      role: $role
      termsOfService: $termsOfService
    }) {
      email
      firstName
    }
  }
`;
