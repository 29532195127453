/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import take from 'lodash/take';
import slice from 'lodash/slice';
import nth from 'lodash/nth';
import drop from 'lodash/drop';
import fill from 'lodash/fill';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Flex } from 'rebass';
import { FieldArray } from 'react-final-form-arrays';

import Dropdown from '../../common/form/Dropdown';
import { FormSubPart, FormPart } from '../../common/form/FormWrapper';
import { HalfTextInput } from './ProfilePart';
import { Separator } from '../../common/form/Separator';
import QueryDropdown from '../../common/form/QueryDropdown';
import { GET_RELATIONSHIP_TYPES } from '../../../graphQL/queries/bookings';

const TenantsPart = ({ formChange, getState, numberOfTenants }) => {
  const [tempId, setTempId] = useState(0);
  return (
    <FormPart title="Tenants">
      <FormSubPart subTitle="How many people will live here">
        <Dropdown
          name="tenantsNumber"
          options={[
            { value: 1, label: 'Just me' },
            ...times(numberOfTenants - 1, nr => ({ value: nr + 2, label: nr + 2 })),
          ]}
          onChange={(selected) => {
            const flatMates = get(getState(), 'values.flatMates');
            const flatMatesToDelete = get(getState(), 'values.flatMatesToDelete');
            const diffSelectedToLast = (selected.value - 1) - get(flatMates, 'length', 0);

            let newArray;
            let newFlatMatesToDelete;
            if (diffSelectedToLast < 0) {
              newArray = take(flatMates, selected.value - 1);
              const toDelete = map(slice(flatMates, selected.value - 1, flatMates.length), mate => ({ ...mate, destroy: '1' }));
              newFlatMatesToDelete = unionBy(flatMatesToDelete, toDelete, 'id');
            } else {
              const hollowArray = map(
                fill(Array(diffSelectedToLast || selected.value - 1), {}),
                () => {
                  // this is needed for flatmates persistence before backends assings an id to them
                  setTempId(tempId + 1);
                  return { id: `tmp${tempId}` };
                },
              );

              const arrayWithOldFlatmates = map(
                hollowArray,
                (mate, idx) => omit(nth(flatMatesToDelete, idx), 'destroy') || mate,
              );

              newArray = [...flatMates, ...arrayWithOldFlatmates];
              newFlatMatesToDelete = drop(flatMatesToDelete, arrayWithOldFlatmates.length);
            }

            formChange('flatMates', newArray);
            formChange('flatMatesToDelete', newFlatMatesToDelete);
          }}
        />
      </FormSubPart>
      <FieldArray name="flatMates">
        {({ fields }) => fields.map((name, idx) => (
          <Fragment key={`flatmate-${name}-${idx}`}>
            <Separator mb={30} />
            <Flex justifyContent="space-between">
              <HalfTextInput name={`${name}.firstName`} label="First name" />
              <HalfTextInput name={`${name}.lastName`} label="Last name" />
            </Flex>
            <FormSubPart subTitle="Age">
              <Dropdown
                name={`${name}.age`}
                options={[
                  ...times(100, age => ({ value: age, label: age })),
                  { value: 100, label: '100+' },
                ]}

              />
            </FormSubPart>
            <FormSubPart subTitle="Relationship">
              <QueryDropdown
                query={GET_RELATIONSHIP_TYPES}
                name={`${name}.relationship`}
                dataPath="dictionary"
                mapData={data => map(data, rel => ({ value: rel.name, label: rel.value }))}
              />
            </FormSubPart>
          </Fragment>
        ))}
      </FieldArray>
    </FormPart>
  );
};

TenantsPart.propTypes = {
  getState: PropTypes.func.isRequired,
  formChange: PropTypes.func.isRequired,
  numberOfTenants: PropTypes.string.isRequired,
};

export default TenantsPart;
