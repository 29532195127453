import React, { Component } from 'react';
import { Flex, Text, Box } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { fontMixin, fontSize, LULO } from '../../theme/styleUtils';

const SpacedCaps = styled(Text)`
  ${fontMixin(LULO)}
  ${fontSize(25)}
  margin-bottom: 40px;
  text-align: center;
`;

const Enumerator = styled.span`
  position: absolute;
  left: -20px;
  color: #fff;
  font-weight: 600;
  ::before {
    position: absolute;
    z-index: -1;
    content: ' ';
    border-radius: 50%;
    width: 25px;
    height: 25px;
    left: -8px;
    top: -4px;
    background-color: rgba(176, 21, 106, 0.61);
  }
  
`;

const JumboText = styled(Text)`
  ${fontSize(17)}
  position: relative;
`;
const Container = styled(Box)`
  border: 1px solid rgba(186, 184, 184, 1);
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
  height: 100%;
`;

export default class BulletpointJumbo extends Component {
  static propTypes = {
    listItems: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]).isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const { title, listItems } = this.props;
    return (
      <Container width={1}>
        <Flex mx={25} mt={40} mb={30} justifyContent="center">
          <Box w={1 / 5} />
          <Flex
            w={3 / 5}
            m={15}
            alignItems="left"
            flexDirection="column"
            justifyContent="center"
          >
            <SpacedCaps>{title}</SpacedCaps>
            {map(listItems, (item, index) => (
              <JumboText key={`${item}${index}`} pb={20}>
                <Enumerator>
                  {index + 1}
                </Enumerator>
                {item}
              </JumboText>
            ))}
          </Flex>
          <Box w={1 / 5} />
        </Flex>
      </Container>
    );
  }
}
