import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Body from '../text/Body';
import Subtitle from '../text/Subtitle';
import Title from '../text/Title';

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  align-items: left;
  margin-bottom: 20px;
  position: relative;
`;

export const FormPart = ({ title, children }) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="left"
    style={{ position: 'relative' }}
  >
    <Title>{title}</Title>
    {children}
  </Flex>
);

FormPart.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export const FormSubPart = ({ subTitle = undefined, subText = undefined, children }) => (
  <Box mb={20}>
    <Box mb={20}>
      {subTitle && <Subtitle>{subTitle}</Subtitle>}
      {subText && <Body mt="8px">{subText}</Body>}
    </Box>
    {children}
  </Box>
);

export const FormSubPartInline = ({ subTitle, subText = undefined, children }) => (
  <Flex mb={20}>
    <Flex flexDirection="column" mr="auto">
      <Subtitle mb="5px">{subTitle}</Subtitle>
      {subText && <Body>{subText}</Body>}
    </Flex>
    {children}
  </Flex>
);

FormSubPart.propTypes = {
  subTitle: PropTypes.string,
  subText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

FormSubPartInline.propTypes = {
  subTitle: PropTypes.string.isRequired,
  subText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
