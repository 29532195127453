import gql from 'graphql-tag';

export const GET_FACILITIES_RANSACK_SEARCH = gql`
  query GetFacilitiesRansackSearch($queries: [RansackInput]!) {
    facilitiesRansackSearch(queries: $queries) {
      id
      name
      code
      description
      obligatory
    }
  }
`;
