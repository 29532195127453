import find from 'lodash/find';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import { diff } from 'deep-diff';

export function scrollToFirstError(errors, submitErrors) {
  const inputs = document.querySelectorAll('input');
  const input = find(inputs, (({ name }) => has(errors, name) || has(submitErrors, name)));
  if (input) {
    input.parentElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollBy(0, -63);
  }
}

export function initialValuesUpdateRule(initialValues, propsInitialValues, photoPaths) {
  const initialValuesDiff = diff(initialValues, propsInitialValues);
  let returnValue = initialValuesDiff && initialValuesDiff.length > 0;
  if (isArray(photoPaths)) {
    returnValue = returnValue && !photoPaths.includes(initialValuesDiff[0].path[0]);
  }
  return !!(returnValue);
}
