import flatten from 'lodash/flatten';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { graphql, Query } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Flex } from 'rebass';
import { compose } from 'recompose';
import styled from 'styled-components';
import { APP_ROUTES } from '../../../constants';
import { CREATE_BOOKING } from '../../../graphQL/mutations/bookings';
import { GET_PLACE_DETAILS, GET_PLACE_DETAILS_PHOTOS } from '../../../graphQL/queries/place';
import { GET_ROOM_DETAILS, GET_ROOM_DETAILS_PHOTOS } from '../../../graphQL/queries/room';
import { GET_USER_ROLE } from '../../../graphQL/queries/user';
import i18n from '../../../i18n';
import Error from '../../common/Error';
import Layout from '../../common/Layout';
import Loading from '../../common/Loading';
import DetailsContactPart from './DetailsContactPart';
import DetailsFacilityPart from './DetailsFacilityPart';
import DetailsFlatmatesPart from './DetailsFlatmatesPart';
import DetailsLocationPart from './DetailsLocationPart';
import DetailsMoveOutPart from './DetailsMoveOutPart';
import DetailsOwnerPart from './DetailsOwnerPart';
import DetailsScriptPart from './DetailsScriptPart';
import ListingDetailsFooter from './Footer';
import ListingBreakdownModal from './ListingBreakdownModal';
import ListingDetailsHeader from './ListingDetailsHeader';
import ListingDetailsImages from './images/ListingDetailsImages';

const DetailsWrapper = styled(Box).attrs({
  mt: 100,
  width: [1, 1, 1, 1000],
})`
  z-index: 0;
`;

const onReserve = ({ mutate, history, location }) => async () => {
  try {
    const {
      type,
      indexableId,
      dateFrom,
      leaseLength,
    } = queryString.parse(location.search);
    const { data, errors } = await mutate({
      variables: {
        startDate: dateFrom,
        stayMonths: +leaseLength,
        bookedObject: {
          resourceId: indexableId,
          resourceType: type,
        },
      },
    });

    if (errors) {
      if (errors.find(({ error_type }) => error_type === 'Unauthorized')) { // eslint-disable-line
        history.push(`/sign-in?${queryString.stringify({ redirect: location.pathname + location.search })}`);
      } else {
        toast.error(get(head(errors), 'message', 'Error'), {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }

    history.push(APP_ROUTES.BOOKINGS.PERSONAL(data.createOrUpdateBooking.id));
  } catch (e) {
    console.log(e);
  }
};

const mapImages = ({ listing, isPlace }) => {
  if (isPlace) {
    // eslint-disable-next-line arrow-body-style
    return flatten(map(listing.rooms, ({ photos, roomFacilities, roomNiceTouches }) => {
      return map(photos, ({ photo, id }, idx) => ({
        id,
        src: photo,
        caption: idx === 0 ? [...map(roomFacilities, 'name'), ...map(roomNiceTouches, 'name')].join(', ') : undefined,
      }));
    }));
  }
  return map(get(listing, 'photos'), photo => ({ src: photo.photo, id: photo.id }));
};

const ListingDetails = ({ mutate, history, location }) => {
  const [breakdownOpened, setBreakdownOpened] = useState(false);

  const {
    type,
    indexableId,
  } = queryString.parse(location.search);
  const isPlace = type === 'Place';

  useEffect(() => {
    i18n.loadNamespaces('booking');
  }, []);

  return (
    <>
      <Query query={isPlace ? GET_PLACE_DETAILS : GET_ROOM_DETAILS} variables={{ id: indexableId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;
          const listing = get(data, isPlace ? 'place' : 'room', {});
          return (
            <Box mb={80} width={1}>
              <ListingDetailsHeader
                downrootsApproved={get(data, isPlace ? 'place.downrootsApproved' : 'room.place.downrootsApproved', false)}
                virtualTourUrl={get(data, isPlace ? 'place.virtualTourUrl' : 'room.place.virtualTourUrl')}
                listing={listing}
                isPlace={isPlace}
              />
              <Layout>
                <Flex justifyContent="center">
                  <DetailsWrapper>
                    <DetailsFacilityPart data={data} isPlace={isPlace} />
                    <Box mt={30}>
                      <Query query={isPlace ? GET_PLACE_DETAILS_PHOTOS : GET_ROOM_DETAILS_PHOTOS} variables={{ id: indexableId }}>
                        {({ loading: photosLoading, data: photosData }) => {
                          if (photosLoading) {
                            return null;
                          }

                          const photos = get(photosData, isPlace ? 'place' : 'room', {});
                          return (
                            <ListingDetailsImages
                              thumbImages={mapImages({ listing, isPlace })}
                              images={mapImages({ listing: photos, isPlace })}
                            />
                          );
                        }}
                      </Query>
                    </Box>
                    <DetailsScriptPart listing={listing} isPlace={isPlace} />
                    <DetailsContactPart />
                    {get(listing, isPlace ? 'moveOutGuarantee' : 'place.moveOutGuarantee') && (
                      <DetailsMoveOutPart />
                    )}
                    <DetailsLocationPart listing={listing} isPlace={isPlace} />
                    <DetailsOwnerPart landlord={get(data, isPlace ? 'place.landlord' : 'room.landlord', {})} />
                    { !isPlace && <DetailsFlatmatesPart flatmates={get(data, 'room.tenants')} />}
                  </DetailsWrapper>
                </Flex>
              </Layout>
            </Box>
          );
        }}
      </Query>
      <Query query={GET_USER_ROLE}>
        {({ data }) => {
          if (get(data, 'user.role.name') === 'landlord') {
            return null;
          }

          return (
            <ListingDetailsFooter
              onReserve={onReserve({ mutate, history, location })}
              openBreakdown={() => setBreakdownOpened(true)}
            />
          );
        }}
      </Query>
      {breakdownOpened && <ListingBreakdownModal onClose={() => setBreakdownOpened(false)} />}
    </>
  );
};

ListingDetails.propTypes = {
  t: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withNamespaces('listings'),
  graphql(CREATE_BOOKING),
);

export default enhance(ListingDetails);
