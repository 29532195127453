import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import noop from 'lodash/noop';
import map from 'lodash/map';
import pick from 'lodash/fp/pick';
import omit from 'lodash/fp/omit';
import first from 'lodash/first';
import drop from 'lodash/drop';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import { UPDATE_BOOKING_PERSONAL } from '../../../graphQL/mutations/bookings';
import { GET_BOOKING_PERSONAL } from '../../../graphQL/queries/bookings';
import { mapApiErrors } from '../../../utils/errorHandling';
import Footer from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';
import PersonalForm from './PersonalForm';

class Personal extends Component {
  onNext = noop;

  state = {
    submitting: false,
  };

  onSubmit = async (values) => {
    try {
      const { history, match, mutate } = this.props;
      const personFields = [
        'firstName', 'lastName', 'phone', 'birthday', 'sex', 'address', 'email',
        'kindOfJob', 'university', 'studentLoan', 'businessName',
        'jobTitle', 'id',
      ];
      const { data, errors } = await mutate({
        variables: {
          id: match.params.id,
          tenantsNumber: values.tenantsNumber,
          people: [
            {
              ...pick(personFields)(values.person),
            },
            ...get(values, 'flatMates', []),
            ...get(values, 'flatMatesToDelete', []),
          ],
        },
      });

      if (errors) {
        const defaultErrors = mapApiErrors(errors);
        const flatMatesErrors = drop(get(defaultErrors, 'people'));
        const customMappedErrors = {
          ...defaultErrors,
          person: get(defaultErrors, 'people[0]', null),
          flatMates: flatMatesErrors,
        };
        const toOmit = [
          'people',
          customMappedErrors.flatMates.length <= 0 && 'flatMates',
        ];
        return omit(toOmit)(customMappedErrors);
      }

      history.push(APP_ROUTES.BOOKINGS.BASICS(data.createOrUpdateBooking.id));
    } catch (e) {
      console.log(e);
    }
  };

  getInitialValues = (data) => {
    const person = first(get(data, 'booking.mainPerson'));
    return ({
      person: {
        ...omit(['__typename'])(person),
      },
      flatMates: [
        ...map(get(data, 'booking.flatMates'), pick(['firstName', 'lastName', 'age', 'relationship', 'id'])),
      ],
      tenantsNumber: get(data, 'booking.tenantsNumber'),
    });
  }

  render() {
    return (
      <Fragment>
        <WizardStep query={GET_BOOKING_PERSONAL} withoutLayout>
          {({ data }) => (
            <PersonalForm
              initialValues={this.getInitialValues(data)}
              onSubmit={this.onSubmit}
              onNext={(onNext) => { this.onNext = onNext; }}
              bookableType={get(data, 'booking.bookedObject.resourceType')}
              person={get(data, 'booking.mainPerson[0]', {})}
              bookedObjectId={get(data, 'booking.bookedObject.resourceId')}
              exposeState={({ submitting }) => this.setState({ submitting })}
            />
          )}
        </WizardStep>
        <Footer
          omitPrev
          onNext={() => this.onNext()}
          disabledNext={this.state.submitting}
          disabledSaveAndExit
        />
      </Fragment>
    );
  }
}

Personal.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  graphql(UPDATE_BOOKING_PERSONAL),
);

export default enhance(Personal);
