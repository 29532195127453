import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Box } from 'rebass';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import bgBedroom from '../../assets/img/bedroom-bg.jpg';
import {
  fontMixin, fontSize, LIGHT, THICK,
} from '../../theme/styleUtils';
import { FilledRoundedButton } from '../common/wizard/Footer';
import { APP_ROUTES } from '../../constants';
import { mediaQuery } from '../../theme/globalStyle';

const OwnersHeaderWrapper = styled(Flex)`
  padding: 100px 0;
  width: 100%;
  background: url(${bgBedroom});
  background-size: cover;
  background-position-y: 50%;
  ${mediaQuery.desktop`background-position-y: 30%;`}
  ${mediaQuery.tablet`
    background-position-x: 50%;
    background-position-y: 42%;
    background-size: 300%;
  `};
  ${mediaQuery.mobile`
    background-position-y: 22%;
  `};
`;

const Title = styled(Text)`
  ${fontMixin(THICK)}
  ${fontSize(70)}
`;

const Desc = styled(Text).attrs({

})`
  ${fontMixin(LIGHT)}
  ${fontSize(20)}
`;

const OwnersFilledRoundedButton = styled(FilledRoundedButton)`
  ${fontSize(17)}
`;

class OwnersHeader extends Component {
  render() {
    const { t } = this.props;
    return (
      <OwnersHeaderWrapper>
        <Box width={1 / 16} />
        <Flex width={[0.9, 0.9, 7 / 16]} flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <Title my={15} width={[1, 540]} color="darkBrown">
            {t('header.title')}
          </Title>
          <Desc my={15} width={[1 / 2, 1 / 2, 567]}>
            {t('header.desc')}
          </Desc>
          <Link to={APP_ROUTES.LISTINGS.PLACE_INTRO()}>
            <OwnersFilledRoundedButton my={15} py={10}>
              {t('header.button')}
            </OwnersFilledRoundedButton>
          </Link>
        </Flex>
      </OwnersHeaderWrapper>
    );
  }
}
OwnersHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('owners')(OwnersHeader);
