import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import Label from '../text/Label';
import FieldError from './FieldError';
import withFinalForm from './withFinalForm';

export const CalendarIcon = styled.i.attrs({
  className: 'fa fa-calendar-alt',
})`
  color: ${props => props.theme.colors.dustGray};
  padding: 0 10px;
`;

class DatePicker extends Component {
  static propTypes = {
    blockedDays: PropTypes.array,
    formOnChange: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
    label: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabledModifiers: PropTypes.object,
  };

  static defaultProps = {
    blockedDays: [],
    onChange: () => {},
  };


  static getDerivedStateFromProps(props, state) {
    if (props.initialValue && !state.initialDateObtained) {
      return {
        date: props.initialValue,
        initialDateObtained: true,
      };
    }
    return null;
  }

  state = {
    date: undefined,
    // eslint-disable-next-line react/no-unused-state
    initialDateObtained: false,
  };

  showFromMonth = () => {
    const { date } = this.state;
    if (!date) {
      return;
    }
    this.date.getDayPicker().showMonth(date);
  };

  handleFromChange = (date) => {
    const { name } = this.props;
    this.setState({ date }, () => {
      this.showFromMonth();
      this.props.formOnChange(name, moment(date).format());
      this.props.onChange();
    });
  };

  render() {
    const { date } = this.state;
    const {
      name, validate, label, placeholder, disabledModifiers,
    } = this.props;
    return (
      <>
        {label && <Label>{label}</Label>}
        <div className="InputFromTo InputSingle" style={{ marginBottom: 5 }}>
          <CalendarIcon />
          <Field name={name} component="input" type="hidden" validate={validate} />
          <DayPickerInput
            ref={(r) => { this.date = r; }}
            value={date ? moment(date).toDate() : ''}
            format="DD/MM/YYYY"
            placeholder={placeholder || 'DD/MM/YYYY'}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: moment(date).toDate(),
              disabledDays: [disabledModifiers, ...this.props.blockedDays],
              modifiers: moment(date).toDate(),
              numberOfMonths: 1,
              onDayClick: () => this.date.getInput().focus(),
            }}
            onDayChange={this.handleFromChange}
          />
        </div>
        <FieldError name={name} direction="column" />
      </>
    );
  }
}

export default withFinalForm()(DatePicker);
