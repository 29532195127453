import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../constants';
import { MenuListItem } from '../common/Menu';
import { NavItem } from './DesktopMenu';

const TenantMenu = () => (
  <Fragment>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.TENANT.BOOKINGS()}>
        <NavItem>Bookings</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.TENANT.TRANSACTIONS()}>
        <NavItem>Transactions</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.TENANT.PROFILE()}>
        <NavItem>Profile</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.TENANT.SETTINGS()}>
        <NavItem>Settings</NavItem>
      </Link>
    </MenuListItem>
  </Fragment>
);

export default TenantMenu;
