import { FORM_ERROR } from 'final-form';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { injectStripe } from 'react-stripe-elements';
import styled from 'styled-components';
import { Flex, Image } from 'rebass';
import { Form } from 'react-final-form';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { UPDATE_CARD } from '../../../graphQL/mutations/user';
import { mapApiErrors } from '../../../utils/errorHandling';
import { ErrorBoxContainer } from '../../common/form/FieldError';
import Modal from '../../common/Modal';
import { FilledRoundedButton } from '../../common/wizard/Footer';
import CreditCardFormFields from '../../bookings/payment/common/CreditCardFormFields';
import { THICK5 } from '../../../theme/styleUtils';
import downrootsDLogo from '../../../assets/img/Downroots-D-logo.png';
import CloseButton from '../../common/buttons/CloseButton';

const CreditCardWrapper = styled(Flex)`
  margin: 58px;
  width: 614px;
  border: 1px solid ${props => props.theme.colors.blackBrown};
  flex-direction: column;
  align-items: flex-start;
`;

const CreditCardForm = ({ onSubmit, onClose }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{
      billingCountry: 'United Kingdom',
    }}
    subscription={{}}
  >
    {({ handleSubmit, submitError, submitting }) => (
      <CreditCardWrapper p={20}>
        <Flex width={1} justifyContent="space-between">
          <Image src={downrootsDLogo} height={60} />
          <CloseButton onClick={onClose} />
        </Flex>
        {submitError && <ErrorBoxContainer my={20}>{submitError}</ErrorBoxContainer>}
        <CreditCardFormFields />
        <FilledRoundedButton
          mt={35}
          fontMixin={THICK5}
          onClick={handleSubmit}
          disabled={submitting}
        >
          Add / Change card
        </FilledRoundedButton>
      </CreditCardWrapper>
    )}
  </Form>
);

CreditCardForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const CreditCardModal = ({ onClose, stripe, mutate }) => (
  <Modal onClose={onClose}>
    <Flex justifyContent="center" alignItems="center" bg="white">
      <CreditCardForm
        onClose={onClose}
        onSubmit={async (values) => {
          const { token, error } = await stripe.createToken({
            type: 'card',
            name: values.cardholdersName,
          });

          if (error) {
            return {
              [FORM_ERROR]: error.message,
            };
          }
          const { errors } = mutate({
            variables: {
              stripeToken: token.id,
            },
          });

          if (errors) {
            return mapApiErrors(errors);
          }

          onClose();
        }}
      />
    </Flex>
  </Modal>
);

CreditCardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
};

const enhance = compose(
  withNamespaces('account'),
  injectStripe,
  graphql(UPDATE_CARD),
);

export default enhance(CreditCardModal);
