import noop from 'lodash/noop';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { compose, withProps } from 'recompose';
import get from 'lodash/get';
import { APP_ROUTES } from '../../../constants';
import { UPDATE_PLACE_PRICES } from '../../../graphQL/mutations/createOrUpdatePlace';
import { GET_PLACE_PRICES } from '../../../graphQL/queries/place';
import { mapApiErrors } from '../../../utils/errorHandling';
import { getMonthlyRentalPriceSubmitValues } from '../../../utils/prices';
import { NarrowLayout } from '../../common/Layout';
import Footer, { FilledRoundedButton } from '../../common/wizard/Footer';
import PricesForm from './forms/PricesForm';
import BookVirtualTourForm from '../place/forms/BookVirtualTourForm';
import { isPlaceDone } from '../place/utils';
import Modal from '../../common/Modal';
import Loading from '../../common/Loading';
import Error from '../../common/Error';

class Prices extends Component {
  state = {
    submitting: false,
    showBookMyVirtualTour: false,
  };

  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.LISTINGS.PLACE_BOOKING_RULES(match.params.id));
  };

  onNext = noop;

  onSubmit = async ({ values, place }) => {
    try {
      const { history, match, updatePlacePrices } = this.props;
      const { data, errors } = await updatePlacePrices({
        variables: {
          ...values,
          id: match.params.id,
          discount: toNumber(values.discount) || null,
          monthlyUtilityPrice: toNumber(values.monthlyUtilityPrice) || 0,
          monthlyRentalPriceValues: getMonthlyRentalPriceSubmitValues({
            placeOrRoom: place,
            values,
            match,
          }),
          yieldCalculator: toNumber(values.yieldCalculator),
        },
      });

      if (errors) {
        return mapApiErrors(errors);
      }


      if (isPlaceDone(data) && get(data, 'createOrUpdatePlace.virtualTourBookedAt')) {
        return history.push(APP_ROUTES.LISTINGS.PLACE_INTRO(data.createOrUpdatePlace.id));
      }

      this._showBookMyVirtualTourClicked();
    } catch (e) {
      console.error(e);
    }
  };

  _closeBookMyVirtualTourClicked = () => this.setState({ showBookMyVirtualTour: false });

  _showBookMyVirtualTourClicked = () => this.setState({ showBookMyVirtualTour: true });

  render() {
    const {
      t, match, data, data: { loading, error },
    } = this.props;
    if (loading) return <Loading />;
    if (error) return <Error />;
    return (
      <NarrowLayout>
        <PricesForm
          onNext={(onNext) => {
            this.onNext = onNext;
          }}
          onSubmit={values => this.onSubmit({ values, place: data.place })}
          place={data.place}
          exposeState={({ submitting }) => this.setState({ submitting })}
        />
        { this.state.showBookMyVirtualTour &&
          (
            <Modal bg="rgba(0, 0, 0, 0.5)" onClose={this._closeBookMyVirtualTourClicked}>
              <BookVirtualTourForm placeId={match.params.id} onClose={this._closeBookMyVirtualTourClicked} bookedNote={get(data, 'place.virtualTourBookedNote')} bookedAt={get(data, 'place.virtualTourBookedAt')} />
            </Modal>
          )
        }
        <Footer
          onPrev={this.onPrev}
          onNext={this.onNext}
          disabledSaveAndExit
          virtualTourNeededForDone
          renderNext={(
            <FilledRoundedButton
              onClick={this.onNext}
              disabled={this.state.submitting}
            >
              {t('prices.bookTour')}
            </FilledRoundedButton>
          )}
          isPlace
        />
      </NarrowLayout>
    );
  }
}

Prices.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withProps(({ match }) => ({ id: match.params.id })),
  graphql(GET_PLACE_PRICES),
  withNamespaces('listingForm'),
  graphql(UPDATE_PLACE_PRICES, { name: 'updatePlacePrices' }),
);

export default enhance(Prices);
