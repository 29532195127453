import React, { Fragment } from 'react';
import { Box } from 'rebass';
import ContactSection from './home/ContactSection';

const StaticPageWrapper = WrappedComponent => props => (
  <Fragment>
    <Box pb={80}>
      <WrappedComponent {...props} />
    </Box>
    <ContactSection />
  </Fragment>
);

export default StaticPageWrapper;
