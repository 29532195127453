import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from 'rebass';
import styled from 'styled-components';
import { fontMixin, fontSize, BOLD } from '../../theme/styleUtils';

const Title = styled(Text)`
  ${fontMixin(BOLD)}
  ${fontSize(22)}
`;

const Label = styled(Text)`
  ${fontSize(17)}
  line-height: 23px;
`;


export default class DescriptionLabel extends Component {
  static propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    title: '',
    label: '',
    width: 'auto',
  }

  render() {
    const {
      width,
      title,
      label,
    } = this.props;
    return (
      <Box width={width} mb={40}>
        <Title
          pt={20}
        >
          {title}
        </Title>
        <Label pt={10}>{label}</Label>
      </Box>
    );
  }
}
