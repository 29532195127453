import React from 'react';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import { FormSpy } from 'react-final-form';

const withFinalForm = namesWithInitialValues => WrappedComponent => props => (
  <FormSpy subscription={{ initialValues: true }}>
    {({ form: { change, getState } }) => {
      let nameProps;

      if (props.initialValueNames) {
        nameProps = reduce(props.initialValueNames, (res, val, key) => ({
          ...res,
          [key]: get(getState(), `initialValues.${val}`),
          [namesWithInitialValues[key]]: val,
        }), {});
      } else {
        nameProps = { initialValue: get(getState(), `initialValues.${props.name}`) };
      }
      return (
        <WrappedComponent
          {...props}
          formOnChange={change}
          {...nameProps}
        />
      );
    }}
  </FormSpy>
);

export default withFinalForm;
