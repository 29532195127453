import PropTypes from 'prop-types';
import React, { Component } from 'react';
import noop from 'lodash/noop';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { graphql, compose } from 'react-apollo';
import { APP_ROUTES } from '../../../constants';
import Footer from '../../common/wizard/Footer';
import VerificationForm from './VerificationForm';
import { mapApiErrors } from '../../../utils/errorHandling';
import { UPDATE_BOOKING_VERIFICATION } from '../../../graphQL/mutations/bookings';
import { GET_PERSONS_DOCUMENTS } from '../../../graphQL/queries/bookings';
import WizardStep from '../../common/wizard/WizardStep';

const BOOKING_STEP = 'verification';

class Verification extends Component {
  state = {
    submitting: false,
  };

  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.BOOKINGS.BASICS(match.params.id));
  };

  onNext = noop;

  onSubmit = async (values) => {
    const { history, match, mutate } = this.props;
    const { errors } = await mutate({
      variables: {
        id: match.params.id,
        currentSection: BOOKING_STEP,
        backgroundCheckConsent: values.backgroundCheckConsent,
        people: [
          {
            ...pick(values, [
              'id',
              'documentKind',
              'firstName',
              'lastName',
              'country',
            ]),
          },
        ],
      },
    });
    if (errors) {
      return mapApiErrors(errors);
    }
    history.push(APP_ROUTES.BOOKINGS.PAYMENT(match.params.id));
  };

  render() {
    const { id } = this.props.match.params;
    return (
      <WizardStep query={GET_PERSONS_DOCUMENTS} withoutLayout>
        {({ data }) => (
          <>
            <VerificationForm
              initialValues={{ ...get(data, 'booking.people[0]', {}), backgroundCheckConsent: get(data, 'booking.backgroundCheckConsent') }}
              onSubmit={this.onSubmit}
              onNext={(onNext) => {
                this.onNext = onNext;
              }}
              exposeState={({ submitting }) => this.setState({ submitting })}
              matchParamsId={id}
            />
            <Footer
              onPrev={this.onPrev}
              onNext={() => this.onNext()}
              disabledNext={this.state.submitting}
              disabledSaveAndExit
            />
          </>
        )}
      </WizardStep>
    );
  }
}

Verification.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(graphql(UPDATE_BOOKING_VERIFICATION))(Verification);
