import React, { Component } from 'react';
import { Card } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DescriptionLabel from './DescriptionLabel';

const FixedCard = styled(Card)`
  height: ${props => props.height}px;
  background-color: white;
  overflow: hidden;
`;

export default class BookingCard extends Component {
  static propTypes = {
    cardHeight: PropTypes.number,
    cardWidth: PropTypes.number,
    title: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.func,
    boxShadow: PropTypes.string,
  }

  static defaultProps = {
    cardHeight: 460,
    cardWidth: 1,
    title: 'No Paperwork',
    label: 'We reserve and sign digitally. We take care of leases and likeminded sharers. ',
    content: () => '',
    boxShadow: '0 0 16px rgba(0, 0, 0, .25)',
  }

  render() {
    const {
      boxShadow,
      cardWidth,
      cardHeight,
      title,
      label,
      content,
    } = this.props;
    return (
      <>
        <DescriptionLabel
          title={title}
          label={label}
        />
        <FixedCard boxShadow={boxShadow} width={cardWidth} height={cardHeight}>
          {content()}
        </FixedCard>
      </>
    );
  }
}
