import styled from 'styled-components';
import { Button } from 'rebass';
import { transitionBackgroundColor, fontSize } from '../../../theme/styleUtils';

export default styled(Button)`
  width: 190px;
  height: 50px;
  border-radius: 0;
  background-color: transparent;
  color: black;
  border: 2px solid black;
  ${fontSize(17)}
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${transitionBackgroundColor('.3s')}
  &:hover {
    background-color: ${props => props.theme.colors.darkBrown};
    border-color: ${props => props.theme.colors.darkBrown};
    color: white;
  }
  &:active {
    outline: none;
  }
`;
