import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import config from '../../configLoader';

import { SEND_RESET_PASSWORD } from '../../graphQL/mutations';
import { emailFormat, required } from '../../utils/formValidation';
import HollowButton from '../common/buttons/HollowButton';
import TextInput from '../common/form/TextInput';
import Title from '../common/text/Title';

const SendResetPassword = ({ t }) => (
  <Box mt={100}>
    <Flex mb={30} alignItems="center" justifyContent="space-around">
      <Mutation
        mutation={SEND_RESET_PASSWORD}
        errorPolicy="all"
      >
        {(sendResetPassword, { data, called }) => (
          <Flex flexDirection="column">
            <Title separator>{t('resetpassword.button')}</Title>
            <Form
              onSubmit={values => sendResetPassword({ variables: { ...values, redirect_url: config.resetPasswordRedirectUrl } })}
              id="reset-password-form"
              render={({ handleSubmit, submitting }) => {
                let message = get(data, 'sendResetPassword.message');
                if (called && data && data.sendResetPassword === null) {
                  message = 'There is no such user';
                }
                return (
                  <form onSubmit={handleSubmit}>

                    <Flex flexDirection="column">
                      <Text>
                        {message}
                      </Text>
                      <Box mt={35}>
                        <TextInput id="email" name="email" placeholder={t('signin.email')} validate={value => (required(value) || emailFormat(value))} />
                      </Box>
                      <Box mt={35}>
                        <HollowButton mt={10} id="signin-button" type="submit" disabled={submitting}>
                          {t('resetpassword.button')}
                        </HollowButton>
                      </Box>
                    </Flex>
                  </form>
                );
              }}
            />
          </Flex>
        )}
      </Mutation>
    </Flex>
  </Box>
);

SendResetPassword.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('auth')(SendResetPassword);
