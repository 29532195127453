import gql from 'graphql-tag';

export const CREATE_ROOM = gql`
  mutation CreateRoom(
    $placeId: ID!
  ) {
    createOrUpdateRoom(payload: {
      placeId: $placeId
      name: "Bedroom"
    }) {
      id
      place {
        id
        listingType {
          id
          code
        }
      }
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation UpdateRoom(
    $id: ID,
    $bedKindId: ID,
    $bathKindId: ID,
    $roomFacilityIds: [ID],
    $roomNiceTouchIds: [ID],
    $monthlyUtilityPrice: Float,
    $discount: Int,
    $promotionMonths: [Promotion],
    $monthlyRentalPriceValues: [MonthlyRentalPriceValueInput]
    $photoIds: [ID]
  ) {
    createOrUpdateRoom(payload: {
      id: $id
      bedKindId: $bedKindId
      bathKindId: $bathKindId
      roomFacilityIds: $roomFacilityIds
      roomNiceTouchIds: $roomNiceTouchIds
      monthlyUtilityPrice: $monthlyUtilityPrice
      discount: $discount,
      promotionMonths: $promotionMonths,
      monthlyRentalPriceValues: $monthlyRentalPriceValues
      photoIds: $photoIds
    }) {
      id
      place {
        id
        listingType {
          id
          code
        }
        unitType {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation DeleteRoom(
    $roomId: ID!
  ) {
    deleteRoom(roomId: $roomId) {
      id
    }
  }
`;

export const GET_ROOM_LIVE = gql`
  query GetRoomLive($id: ID!) {
    room(id: $id) {
      id
      live
      place {
        id
        virtualTourUrl
      }
    }
  }
`;

export const GET_ROOM_BLOCK_OUTS = gql`
  query GetRoomBlockOuts($id: ID!) {
    room(id: $id) {
      id
      blockOuts {
        id
        startDate
        endDate
      }
    }
  }
`;

export const UPDATE_ROOM_LIVE = gql`
  mutation UpdateRoomLive(
  $id: ID,
  $live: Boolean
  ) {
    createOrUpdateRoom(payload: {
      id: $id,
      live: $live
    }) {
      id
      place {
        id
        listingType {
          id
          code
        }
      }
    }
  }
`;
