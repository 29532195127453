import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Image } from 'rebass';
import greenTick from '../../../../assets/img/green-tick-icon.webp.png';
import envelope from '../../../../assets/img/envelope-icon.svg';
import Body from '../../../common/text/Body';

export const PersonCheck = ({ children }) => (
  <Flex mr={10} alignItems="center">
    <Image width="auto" height={20} src={greenTick} mr="5px" />
    <Body>{children}</Body>
  </Flex>
);

PersonCheck.propTypes = {
  children: PropTypes.node.isRequired,
};

const PersonHeading = ({
  firstName, lastName, email, tenantVerifications,
}) => (
  <Flex flexDirection="column">
    <Flex alignItems="center">
      <Text fontSize={30} mb={10} mr={20}>{`${firstName} ${lastName}`}</Text>
      <a href={`mailto:${email}`}>
        <Image src={envelope} width={30} />
      </a>
    </Flex>
    <Flex mb={30}>
      {tenantVerifications.map(tenantVerification => (
        <PersonCheck key={tenantVerification.key}>{tenantVerification.value}</PersonCheck>
      ))}
    </Flex>
  </Flex>
);

PersonHeading.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  tenantVerifications: PropTypes.array,
};

export default PersonHeading;
