import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Text } from 'rebass';
import styled from 'styled-components';

const CircleRadio = styled.label`
  transition: border .3s ease-in-out, background-color .3s ease-in-out;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  color: ${props => props.theme.colors.darkBrown};
  &::before {
    border-radius: 50%;
    box-sizing: border-box;
    content: ' ';
    position: absolute;
    left: -30px;
    top: -2px;
    width: 18px;
    height: 18px;
    border: 1px solid ${props => props.theme.colors.darkBrown};
    input:checked ~ & {
      border-color: ${props => props.theme.colors.purplePrimary};
    }
  }
  &:hover {
    &::before {
      border: 1px solid ${props => props.theme.colors.purplePrimary}
    }
  }
  &::after {
    position:absolute;
    content: '';
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.colors.purplePrimary};
    left: -26px;
    top: 2px;
    transition: opacity .3s ease-in-out;
    visibility: hidden;
    input:checked ~ & {
      visibility: visible;
    }
  }
`;

const SubLabel = styled(Text)`
  line-height: 1.2em;
  color: ${props => props.theme.colors.darkBrown};
  letter-spacing: 0.05em;
`;

const Radio = ({
  label, name, value, validate, type, subLabel, mb,
}) => (
  <Box pl={30} mb={mb || 10}>
    <Field name={name} id={name + value} component="input" type={type} value={value} style={{ display: 'none' }} validate={validate} />
    <CircleRadio htmlFor={name + value}>
      {label}
    </CircleRadio>
    {subLabel && (
      <SubLabel mt="3px" ml="1px" fontSize={15}>
        {subLabel}
      </SubLabel>
    )}
  </Box>
);

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  validate: PropTypes.func,
  type: PropTypes.string,
  subLabel: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Radio.defaultProps = {
  type: 'radio',
};

export default Radio;
