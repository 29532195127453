import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';

const BorderedBox = styled(Flex)`
  border: 2px solid #d8dfe6;
  min-width: 120px;
  :not(:last-of-type) {
    border-right: none;
  }
`;

const TableColumn = ({ title, content }) => (
  <BorderedBox flexDirection="column">
    <Flex p="7px" justifyContent="center" width={1} bg="#D8DFE6">
      {title}
    </Flex>
    <Flex p="7px" justifyContent="center" width={1}>
      {content}
    </Flex>
  </BorderedBox>
);

TableColumn.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default TableColumn;
