import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import queryString from 'query-string';
import head from 'lodash/head';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../constants';
import { SIGN_IN } from '../../graphQL/mutations';
import { setAuthTokens } from '../../utils/auth';
import { required } from '../../utils/formValidation';
import { ErrorBoxContainer } from '../common/form/FieldError';
import TextInput from '../common/form/TextInput';
import { FormButton } from './AuthLayout';

const SignInForm = ({ t, history, location }) => (
  <Mutation refetchQueries={() => ['GetCurrentUser']} mutation={SIGN_IN}>
    {signIn => (
      <Flex flexDirection="column" width={1}>
        <Form
          onSubmit={async (values) => {
            try {
              const { data, errors } = await signIn({ variables: { ...values } });
              if (errors) {
                return {
                  [FORM_ERROR]: get(head(errors), 'message'),
                };
              }
              setAuthTokens(data.signIn);
              const params = queryString.parse(location.search);

              let url = null;
              const role = get(data, 'signIn.user.role.name');
              const redirectAfterLogin = localStorage.getItem('downrootsRedirectAfterLogin');

              if (role === 'landlord') {
                url = APP_ROUTES.DASHBOARD.LANDLORD.LISTINGS();
              }

              history.push(params.redirect || url || redirectAfterLogin);
            } catch (e) {
              console.log(e);
            }
          }}
          id="sign-in-form"
          render={({ handleSubmit, submitError, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Flex flexDirection="column" justifyContent="space-evenly">
                {submitError && (
                  <Flex justifyContent="center" mb={15}>
                    <ErrorBoxContainer>{submitError}</ErrorBoxContainer>
                  </Flex>
                )}
                <Box mb={15}>
                  <TextInput id="email" name="email" placeholder={t('signin.email')} validate={required} />
                </Box>
                <Box mb={30}>
                  <TextInput password type="password" id="password" name="password" placeholder={t('signin.password')} validate={required} />
                </Box>
                <FormButton type="submit" id="signin-button" disabled={submitting}>
                  {t('signin.signin')}
                </FormButton>
              </Flex>
            </form>
          )}
        />
      </Flex>
    )}
  </Mutation>
);

SignInForm.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('auth'),
);

export default enhance(SignInForm);
