import { toast } from 'react-toastify';

export const showUnhandledAction = () => {
  toast.error('Unhandled action', {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
  });
};

export const showDesignMissing = () => {
  toast.error('Design missing', {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
  });
};

export const showSuccess = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
  });
};

export const showError = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
  });
};
