export const PLACE_PROPERTIES = {
  LISTING_TYPE: 'listing_type',
  BUILDING_TYPE: 'building_type',
  GENDER_PREFERENCE: 'gender_preference',
};

export const JOB_KINDS = ['employed', 'self_employed', 'student'];

export const APP_ROUTES = {
  LISTINGS: {
    PLACE_INTRO: id => (id ? `/listings/place/${id}/intro` : '/listings/place/intro'),
    PLACE_BASICS: id => (id ? `/listings/place/${id}/basics` : '/listings/place/basics'),
    PLACE_AMENITIES: id => `/listings/place/${id}/amenities`,
    PLACE_MEDIA: id => `/listings/place/${id}/media`,
    PLACE_BEDS_AND_BATHS: id => `/listings/place/${id}/bedrooms`,
    PLACE_ROOMS: id => `/listings/place/${id}/rooms`,
    PLACE_ROOMS_CALENDAR: (placeId, roomId) => `/listings/place/${placeId}/rooms/${roomId}/calendar`,
    PLACE_PRICES: id => `/listings/place/${id}/prices`,
    PLACE_BOOKING_RULES: id => `/listings/place/${id}/booking-rules`,
    PLACE_CALENDAR: id => `/listings/place/${id}/calendar`,
    BEDS_AND_BATHS_BED: id => `/listings/place/room/${id}`,
    NEW: '/listings/new/intro',
    DETAILS: () => '/listings/details',
  },
  BOOKINGS: {
    ROOT: () => '/bookings',
    BASICS: (id = ':id') => `/bookings/${id}/basics`,
    PERSONAL: (id = ':id') => `/bookings/${id}/personal`,
    VERIFICATION: (id = ':id') => `/bookings/${id}/verification`,
    PAYMENT: (id = ':id') => `/bookings/${id}/payment`,
    VIEW_LEASE_AND_BOOK: (id = ':id') => `/bookings/${id}/view-lease-and-book`,
  },
  DASHBOARD: {
    ROOT: () => '/dashboard',
    LANDLORD: {
      ROOT: () => '/dashboard/landlord',
      LISTINGS: () => '/dashboard/landlord/listings',
      TENANCIES: (id = '') => `/dashboard/landlord/tenancies/${id}`,
      TRANSACTIONS: () => '/dashboard/landlord/transactions',
      INBOX: () => '/dashboard/landlord/inbox',
      PROFILE: () => '/dashboard/landlord/profile',
      SETTINGS: () => '/dashboard/landlord/settings',
    },
    TENANT: {
      ROOT: () => '/dashboard/tenant',
      BOOKINGS: (id = '') => `/dashboard/tenant/bookings/${id}`,
      TRANSACTIONS: () => '/dashboard/tenant/transactions',
      FAVOURITES: () => '/dashboard/tenant/favourites',
      INBOX: () => '/dashboard/tenant/inbox',
      PROFILE: () => '/dashboard/tenant/profile',
      SETTINGS: () => '/dashboard/tenant/settings',
    },
  },
};

export const LAYOUT_PX_RWD = ['20px', '5%', '5%'];
export const LAYOUT_W_RWD = ['96%', '90%', '70%'];


export const GOOGLE_PLACE_TYPES = {
  TRANSPORT: 'transit_station',
  GROCERIES: 'convenience_store',
  PARKS: 'park',
  CAFE: 'cafe',
};

export const PROMOTION_MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const GENDERS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];

export const EMPLOYMENT_TYPES = [
  { label: 'Employed', value: 'employed' },
  { label: 'Self employed', value: 'self_employed' },
  { label: 'A student', value: 'student' },
];

export const DEFAULT_BOOKING_WINDOW = 12;

export const AVAILABLE_PHOTO_VERSIONS = {
  THUMB: 'thumb',
  LISTING_SQUARE: 'listing_square',
  DETAILS: 'details',
};
