import get from 'lodash/get';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import { UPDATE_BOOKING_BASICS } from '../../../graphQL/mutations/bookings';
import { GET_BOOKING_BASICS } from '../../../graphQL/queries/bookings';
import { mapApiErrors } from '../../../utils/errorHandling';
import Footer from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';
import BasicsForm from './BasicsForm';

class Basics extends Component {
  onNext = noop;

  state = {
    submitting: false,
  };

  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.BOOKINGS.PERSONAL(match.params.id));
  };

  onSubmit = async (values) => {
    try {
      const { history, match, mutate } = this.props;

      const { data, errors } = await mutate({
        variables: {
          id: match.params.id,
          ...values,
        },
        refetchQueries: () => ['GetBookingBoxData'],
      });

      if (errors) {
        return mapApiErrors(errors);
      }

      history.push(APP_ROUTES.BOOKINGS.VERIFICATION(data.createOrUpdateBooking.id));
    } catch (e) {
      console.log(e);
    }
  };

  getInitialValues = (data) => {
    const depositOptions = get(data, 'booking.bookedObject.depositOptions', []);
    const depositOptionId = get(data, 'booking.depositOptionId');
    const initialValues = pick(get(data, 'booking'), ['depositOptionId', 'moveOutGuarantee', 'studentGuarantor']);

    if (!depositOptionId) {
      return {
        ...initialValues,
        depositOptionId: get(depositOptions.find(depositOption => depositOption.name === '6 weeks deposit'), 'id'),
      };
    }

    return initialValues;
  };

  render() {
    return (
      <Fragment>
        <WizardStep query={GET_BOOKING_BASICS} withoutLayout>
          {({ data }) => (
            <BasicsForm
              initialValues={this.getInitialValues(data)}
              depositOptions={get(data, 'booking.bookedObject.depositOptions', [])}
              moveOutGuarantee={get(data, 'booking.bookedObject.moveOutGuarantee', false)}
              studentGuarantor={get(data, 'booking.bookedObject.studentGuarantor', false)}
              houseRules={get(data, 'booking.houseRules', [])}
              onSubmit={this.onSubmit}
              onNext={(onNext) => { this.onNext = onNext; }}
              exposeState={({ submitting }) => this.setState({ submitting })}
            />
          )}
        </WizardStep>
        <Footer
          onPrev={this.onPrev}
          onNext={async () => {
            await this.onNext();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabledNext={this.state.submitting}
          disabledSaveAndExit
        />
      </Fragment>
    );
  }
}

Basics.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  graphql(UPDATE_BOOKING_BASICS),
);

export default enhance(Basics);
