import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex, Image, Text } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../theme/globalStyle';

const FlowWrapper = styled(Flex)`
  flex: 1 1 300px
`;

const Title = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 30,
})``;

const Desc = styled(Text).attrs({ fontSize: 18 })``;

export default class FlowElement extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    imgWidth: PropTypes.number,
  };

  static defaultProps = {
    width: 'auto',
    imgWidth: 60,
  };

  render() {
    const {
      width,
      imgSrc,
      imgWidth,
      title,
      desc,
    } = this.props;
    return (
      <FlowWrapper
        flexDirection="column"
        width={width}
        alignItems="center"
        justifyContent="space-evenly"
        // mb={10}
      >
        <Image py={15} src={imgSrc} width={imgWidth} />
        <Title color="darkGrayBlue" py={15} width={7 / 10} textAlign="center">{title}</Title>
        <Desc color="darkGrayBlue" py={15} width={7 / 10} textAlign="center">{desc}</Desc>
      </FlowWrapper>
    );
  }
}
