import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Form } from 'react-final-form';
import { Box } from 'rebass';
import { scrollToFirstError } from '../../../utils/formHelpers';
import { required } from '../../../utils/formValidation';
import Checkbox from '../../common/form/Checkbox';
import FieldError from '../../common/form/FieldError';
import { FormPart, FormSubPart } from '../../common/form/FormWrapper';
import Radio from '../../common/form/Radio';
import { Separator } from '../../common/form/Separator';
import Text from '../../common/Text';
import ExposeState from '../../common/form/ExposeState';

const BasicsForm = ({
  depositOptions,
  houseRules,
  initialValues,
  moveOutGuarantee,
  studentGuarantor,
  onSubmit,
  onNext,
  exposeState,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit, submitErrors, errors }) => {
      onNext(async () => {
        await handleSubmit();
        scrollToFirstError(submitErrors, errors);
      });
      return (
        <form onSubmit={handleSubmit}>
          <ExposeState exposeState={exposeState} />
          <FormPart title="Deposit">
            <FormSubPart subText="The deposit acts as a refundable guarantee to the host to cover any damages. Select your deposit option">
              <FieldError direction="column" name="depositOptionId">
                {depositOptions.map(depositOption => (
                  <Box key={depositOption.id}>
                    <Radio
                      name="depositOptionId"
                      label={depositOption.name}
                      value={depositOption.id}
                      validate={required}
                    />
                  </Box>
                ))}
              </FieldError>
            </FormSubPart>
          </FormPart>
          <Separator />
          <Box style={{ opacity: !moveOutGuarantee ? 0.5 : 1 }}>
            <FormPart title="Move out guarantee">
              <FormSubPart subText="You can cancel the lease up to 2 days after move in, in unlikely event you are not happy.">
                <Checkbox disabled={!moveOutGuarantee} label="Move out guarantee (non refundable)" name="moveOutGuarantee" />
              </FormSubPart>
            </FormPart>
          </Box>
          <Separator />
          {
            studentGuarantor && (
              <Fragment>
                <FormPart title="Student Guarantor">
                  <FormSubPart subText="The host requires students to have a UK based guarantor. We will automatically add you to our policy.">
                    <Checkbox label="UK guarantor" name="studentGuarantor" />
                  </FormSubPart>
                </FormPart>
                <Separator />
              </Fragment>
            )
          }
          <FormPart title="House rules">
            <FormSubPart subTitle="By booking you agree to house rules">
              {houseRules.map(houseRule => (
                <Text my={10} key={houseRule.id}>{houseRule.name}</Text>
              ))}
            </FormSubPart>
          </FormPart>
        </form>
      );
    }}
  />
);

BasicsForm.propTypes = {
  depositOptions: PropTypes.array.isRequired,
  houseRules: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  moveOutGuarantee: PropTypes.bool.isRequired,
  studentGuarantor: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  exposeState: PropTypes.func.isRequired,
};

export default BasicsForm;
