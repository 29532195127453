import React, { Component } from 'react';
import { Switch } from 'react-router';
import { APP_ROUTES } from '../../constants';
import AppRoute from '../common/AppRoute';
import ScrollToTop from '../common/ScrollToTop';
import StripeWrapper from '../common/stripe/StripeWrapper';
import Basics from './basics/Basics';
import BookingsLayout from './BookingsLayout';
import Payment from './payment/Payment';
import Personal from './personal/Personal';
import Verification from './verification/Verification';
import ViewLeaseAndBook from './viewLeaseAndBook/ViewLeaseAndBook';
import BookingsNavBar from './BookingsNavBar';

export const withStripeWrapper = WrappedComponent => class extends Component {
  render() {
    return (
      <StripeWrapper>
        <WrappedComponent {...this.props} />
      </StripeWrapper>
    );
  }
};

const Bookings = () => (
  <ScrollToTop>
    <Switch>
      <AppRoute exact path={APP_ROUTES.BOOKINGS.BASICS()} navBar={BookingsNavBar} layout={BookingsLayout} component={Basics} />
      <AppRoute exact path={APP_ROUTES.BOOKINGS.PERSONAL()} navBar={BookingsNavBar} layout={BookingsLayout} component={Personal} />
      <AppRoute exact path={APP_ROUTES.BOOKINGS.VERIFICATION()} navBar={BookingsNavBar} component={Verification} />
      <AppRoute exact path={APP_ROUTES.BOOKINGS.PAYMENT()} navBar={BookingsNavBar} layout={BookingsLayout} component={withStripeWrapper(Payment)} />
      <AppRoute exact path={APP_ROUTES.BOOKINGS.VIEW_LEASE_AND_BOOK()} navBar={BookingsNavBar} layout={BookingsLayout} component={ViewLeaseAndBook} />
    </Switch>
  </ScrollToTop>
);

export default Bookings;
