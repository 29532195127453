import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router';

class RedirectAfterLogin extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.props.location.pathname === '/sign-in'
    ) {
      localStorage.setItem('downrootsRedirectAfterLogin', prevProps.location.pathname);
    }
  }

  render() {
    return null;
  }
}

RedirectAfterLogin.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(RedirectAfterLogin);
