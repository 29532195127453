import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import { Box, Flex } from 'rebass';
import { Query } from 'react-apollo';
import { Field, FormSpy } from 'react-final-form';
import { GET_COUNTRIES_PREFIXES } from '../../../graphQL/queries/bookings';
import Loading from '../Loading';
import Error from '../Error';
import Dropdown from './Dropdown';
import TextInput from './TextInput';
import Label from '../text/Label';

const PhoneNumberField = ({
  form: { change, getState, batch },
  initialValues,
  name,
  label,
  validate,
  prefixName,
  numberName,
  placeholder,
}) => {
  // has to be done like this because Field's initialValue prop doesn't work
  useEffect(() => {
    if (initialValues) {
      batch(() => {
        const [prefix, number] = (get(initialValues, name) || '').split(' ');
        change(prefixName, prefix);
        change(numberName, number);
      });
    }
  }, [initialValues]);
  return (
    <Field
      name={name}
      validate={validate}
      component="input"
      type="hidden"
      formatOnBlur
      format={() => {
        const gottenValues = get(getState(), 'values'); // values prop has obsolete values
        return `${get(gottenValues, prefixName)} ${get(gottenValues, numberName)}`;
      }}
    >
      {({ input }) => (
        <Box width={1}>
          <Label>{label}</Label>
          <Flex width={1} alignItems="flex-end">
            <Box mr={10} style={{ alignSelf: 'flex-start', flex: '0 0 90px' }}>
              <Query query={GET_COUNTRIES_PREFIXES}>
                {({ loading, error, data }) => {
                  if (loading) return <Loading />;
                  if (error) return <Error />;
                  const countryPrefixes = map(data.dictionary, country => ({
                    label: `${country.name} (+${country.value})`,
                    value: `+${country.value}`,
                  }));
                  return (
                    <Dropdown
                      name={prefixName}
                      options={countryPrefixes}
                      variant="phonePrefix"
                      validate={validate}
                      onChange={() => input.onBlur()}
                      defaultValue="+44"
                      fixedWidth="320px"
                    />
                  );
                }}
              </Query>
            </Box>
            <TextInput
              name={numberName}
              numbersOnly
              validate={validate}
              placeholder={placeholder}
              formatOnBlur
              format={(value) => {
                // used as an onBlur handler
                input.onBlur();
                return value;
              }}
            />
          </Flex>
        </Box>
      )}
    </Field>
  );
};

PhoneNumberField.propTypes = {
  form: PropTypes.any,
  initialValues: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  prefixName: PropTypes.string,
  numberName: PropTypes.string,
  placeholder: PropTypes.string,
};

const PhoneNumberPrefix = ({
  name,
  label,
  validate,
  placeholder,
}) => {
  const prefixName = `${name}--prefix`;
  const numberName = `${name}--number`;
  return (
    <FormSpy subscription={{ values: true, initialValues: true }}>
      {formSpyProps => (
        <PhoneNumberField
          {...formSpyProps}
          prefixName={prefixName}
          numberName={numberName}
          name={name}
          label={label}
          validate={validate}
          placeholder={placeholder}
        />
      )}
    </FormSpy>
  );
};

PhoneNumberPrefix.propTypes = {
  label: PropTypes.string,
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
};

export default PhoneNumberPrefix;
