import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import {
  Flex, Text, Box, Image,
} from 'rebass';
import get from 'lodash/get';
import styled from 'styled-components';
import { compose } from 'recompose';
import { showError, showSuccess } from '../../../../utils/toast';
import { Separator } from '../../../common/form/Separator';
import { LinkText } from '../../../common/Text';
import { FilledRoundedButton } from '../../../common/wizard/Footer';
import { ACCEPT_BOOKING, DECLINE_BOOKING, SIGN_LEASE } from '../../../../graphQL/mutations/bookings';
import PersonHeading from './PersonHeading';
import personPlaceholder from '../../../../assets/img/person-placeholder.webp.png';
import BookingBoxContainer from '../../../bookings/BookingBoxContainer';
import BookingDetailsTable from './BookingDetailsTable';
import BookingModals from './BookingModals';
import { fontSize } from '../../../../theme/styleUtils';

const ACCEPT_STATUS = 'reservation_fee_paid';
const SIGN_THE_LEASE_STATUS = 'first_month_paid';

const LeaseInfoText = styled(Text)`
  ${fontSize(18)}
`;

const TenancyRequest = ({ t, booking, match }) => {
  const [openedModal, setOpenedModal] = useState(null);
  return (
    <Flex flexDirection="column">
      <Flex width={1}>
        <Image width="auto" height={180} src={personPlaceholder} mr={50} />
        <Flex flexDirection="column">
          <PersonHeading {...get(booking, 'mainPerson[0]')} tenantVerifications={booking.tenantVerifications} />
          <BookingDetailsTable booking={booking} />
        </Flex>
      </Flex>

      <Separator my={60} />
      <Flex width={1}>
        <Flex
          width={2 / 3}
          flexDirection="column"
          alignItems="flex-start"
          pr={25}
        >
          <LeaseInfoText mb="5px">
            {t('lease.title')}
          </LeaseInfoText>
          <Text>{t('lease.description')}</Text>
          <Separator my={30} />
          <LeaseInfoText mb="5px">
            {t('whatHappensNext.title')}
          </LeaseInfoText>
          <Text>{t('whatHappensNext.description')}</Text>
          <Mutation
            mutation={ACCEPT_BOOKING}
            onCompleted={() => setOpenedModal('accept')}
          >
            {(accept, { loading }) => (
              <>
                {booking.status === ACCEPT_STATUS && (
                  <Flex mt={50} alignItems="center">
                    <FilledRoundedButton
                      mr={50}
                      type="button"
                      onClick={() => !loading &&
                        accept({
                          variables: { id: booking.id },
                          refetchQueries: () => ['GetBookingTenancy'],
                        })
                      }
                    >
                      Accept booking
                    </FilledRoundedButton>
                    <Mutation
                      mutation={DECLINE_BOOKING}
                      onCompleted={() => setOpenedModal('decline')}
                    >
                      {declineBooking => (
                        <Text
                          color="purplePrimary"
                          onClick={async () => {
                            try {
                              const { errors } = await declineBooking(booking.id);
                              if (errors) {
                                showError(JSON.stringify(errors, null, 4));
                              } else {
                                setOpenedModal('decline');
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        >
                          Decline
                        </Text>
                      )}
                    </Mutation>
                  </Flex>
                )}
              </>
            )}
          </Mutation>
          {booking.status === SIGN_THE_LEASE_STATUS && (
            <>
              <a href={booking.previewContractLink} target="_blank" rel="noopener noreferrer">
                <LinkText mt={30}>Contract preview</LinkText>
              </a>
              <Flex>
                <Mutation mutation={SIGN_LEASE}>
                  {signLease => (
                    <FilledRoundedButton
                      type="button"
                      mt={30}
                      mr={30}
                      onClick={async () => {
                        try {
                          const { errors } = await signLease({
                            variables: { id: booking.id },
                            refetchQueries: () => ['GetBookingTenancy'],
                          });
                          if (errors) {
                            showError(JSON.stringify(errors, null, 4));
                          } else {
                            showSuccess('Lease signed in');
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      Sign in your lease
                    </FilledRoundedButton>
                  )}
                </Mutation>
                <Mutation mutation={DECLINE_BOOKING}>
                  {declineBooking => (
                    <FilledRoundedButton
                      type="button"
                      mt={30}
                      onClick={async () => {
                        try {
                          const { errors } = await declineBooking({
                            variables: { id: booking.id },
                            refetchQueries: () => ['GetBookingTenancy'],
                          });
                          if (errors) {
                            showError(JSON.stringify(errors, null, 4));
                          } else {
                            showSuccess('Booking cancelled');
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      Decline
                    </FilledRoundedButton>
                  )}
                </Mutation>
              </Flex>
            </>
          )}
        </Flex>
        <Box width={1 / 3}>
          <BookingBoxContainer match={match} />
        </Box>
        {openedModal && (
          <BookingModals
            openedModal={openedModal}
            onClose={() => setOpenedModal(null)}
          />
        )}
      </Flex>
    </Flex>
  );
};

TenancyRequest.propTypes = {
  t: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('tenancies'),
);

export default enhance(TenancyRequest);
