import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../constants';
import { MenuListItem } from '../common/Menu';
import { NavItem } from './DesktopMenu';

const LandlordMenu = () => (
  <Fragment>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.LANDLORD.LISTINGS()}>
        <NavItem>Listings</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.LANDLORD.TENANCIES()}>
        <NavItem>Tenancies</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.LANDLORD.TRANSACTIONS()}>
        <NavItem>Transactions</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.LANDLORD.PROFILE()}>
        <NavItem>Profile</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to={APP_ROUTES.DASHBOARD.LANDLORD.SETTINGS()}>
        <NavItem>Settings</NavItem>
      </Link>
    </MenuListItem>
  </Fragment>
);

export default LandlordMenu;
