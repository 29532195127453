import PropTypes from 'prop-types';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { DECLINE_BOOKING, SIGN_LEASE } from '../../../graphQL/mutations/bookings';
import { GET_BOOKING } from '../../../graphQL/queries/bookings';
import { showError, showSuccess } from '../../../utils/toast';
import BookingBoxContainer from '../../bookings/BookingBoxContainer';
import Avatar from '../../common/Avatar';
import Loading from '../../common/Loading';
import { Heading, LinkText } from '../../common/Text';
import Body from '../../common/text/Body';
import Subtitle from '../../common/text/Subtitle';
import { FilledRoundedButton, RoundedButton } from '../../common/wizard/Footer';
import { fontSize } from '../../../theme/styleUtils';
import { PersonCheck } from '../landlord/tenancyDetails/PersonHeading';
import avatar from '../../../assets/img/avatar.svg';

const ContentContainer = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
`;

const StatusBox = styled(Flex).attrs({
  width: ['60px', '100px', '100px'],
})`
  border: 2px solid ${props => props.theme.colors[props.color]};
  border-radius: 50%;
  padding: 20px;
  height: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 20px;
  
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    height: 60px;
    font-size: 14px;
    margin-right: 0;
  }
`;

const InfoBox = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.dustGray};
  padding: 20px;
  flex-direction: column;
`;

const STATUS_COLORS = {
  to_do: 'dustGray',
  in_progress: 'yellow',
  done: 'green',
};

const StatusNameText = styled(Body)`
  ${fontSize(12)}
`;

const LEASE_AUTHORISED_BY_DOWNROOTS = 'lease_authorised_by_downroots';

const BookingStatus = ({ match }) => (
  <Flex flexDirection="column" my={30}>
    <Query query={GET_BOOKING} variables={{ id: match.params.id }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return null;
        }

        return (
          <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
            <ContentContainer order={[1, 1, 0]}>
              <Heading mb={40}>Booking status</Heading>
              <Flex mb={40} justifyContent={['space-between', 'flex-start', 'flex-start']}>
                {data.booking.statuses.map(status => (
                  <StatusBox key={status.id} color={STATUS_COLORS[status.status]}>
                    <StatusNameText>
                      {status.name}
                    </StatusNameText>
                  </StatusBox>
                ))}
              </Flex>
              <Flex mb={40} flexDirection="column">
                <InfoBox>
                  <Flex mb={10}>
                    <Box mr={20}>
                      <Avatar size={64} src={data.booking.landlord.avatar || avatar} />
                    </Box>
                    <Flex flexDirection="column">
                      <Subtitle mb={10}>
                        {data.booking.landlord.fullName}
                      </Subtitle>
                      <Subtitle mb={10}>
                        {data.booking.landlord.address}
                      </Subtitle>
                      <Flex>
                        {data.booking.landlordVerifications.map(landlordVerification => (
                          <PersonCheck key={landlordVerification.key}>{landlordVerification.value}</PersonCheck>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Body>{data.booking.landlord.description}</Body>
                </InfoBox>
              </Flex>
              <Flex mb={40} flexDirection="column">
                <InfoBox>
                  <Subtitle mb={10}>The Lease</Subtitle>
                  <Body>The contract is an assured shorthand tenancy (AST). If the owner lives in the property the contract is an excluded tenancy license. Contracts are subject to the laws of the United Kingdom.</Body>
                </InfoBox>
              </Flex>
              <Flex mb={40} flexDirection="column">
                <InfoBox>
                  <Subtitle mb={10}>What happens next</Subtitle>
                  <Body>The Downroots booking team are managing the booking and will notify you when there is a status change. A tenancy is only created once both parties have signed the lease.</Body>
                </InfoBox>
              </Flex>
              {
                data.booking.status === LEASE_AUTHORISED_BY_DOWNROOTS && (
                  <>
                    <a href={data.booking.previewContractLink} target="_blank" rel="noopener noreferrer">
                      <LinkText mb={30}>Contract preview</LinkText>
                    </a>
                    <Flex>
                      <Mutation mutation={SIGN_LEASE}>
                        {signLease => (
                          <FilledRoundedButton
                            type="button"
                            mr={20}
                            px={30}
                            py={10}
                            onClick={async () => {
                              try {
                                const { errors } = await signLease({
                                  variables: { id: match.params.id },
                                  refetchQueries: () => ['GetBooking'],
                                });
                                if (errors) {
                                  showError(JSON.stringify(errors, null, 4));
                                } else {
                                  showSuccess('Lease successfully signed in');
                                }
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          >
                            Sign in your lease
                          </FilledRoundedButton>
                        )}
                      </Mutation>
                      <Mutation mutation={DECLINE_BOOKING}>
                        {signLease => (
                          <RoundedButton
                            type="button"
                            mr={20}
                            px={30}
                            py={10}
                            onClick={async () => {
                              try {
                                const { errors } = await signLease({
                                  variables: { id: match.params.id },
                                  refetchQueries: () => ['GetBooking'],
                                });
                                if (errors) {
                                  showError(JSON.stringify(errors, null, 4));
                                } else {
                                  showSuccess('Booking cancelled');
                                }
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          >
                            Decline
                          </RoundedButton>
                        )}
                      </Mutation>
                    </Flex>
                  </>
                )
              }
            </ContentContainer>
            <Box order={[0, 0, 1]}>
              <BookingBoxContainer match={match} />
            </Box>
          </Flex>
        );
      }}
    </Query>
  </Flex>
);

BookingStatus.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BookingStatus;
