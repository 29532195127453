import PropTypes from 'prop-types';
import React from 'react';
import { APP_ROUTES } from '../../constants';
import { NavBar, NavBarItem } from '../common/NavBar';

const BookingsNavBar = ({ match }) => (
  <NavBar>
    <NavBarItem to={APP_ROUTES.BOOKINGS.PERSONAL(match.params.id)}>
      Personal
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.BOOKINGS.BASICS(match.params.id)} inactive="true">
      Terms
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.BOOKINGS.VERIFICATION(match.params.id)} inactive="true">
      Verification
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.BOOKINGS.PAYMENT(match.params.id)} inactive="true">
      Payment
    </NavBarItem>
    <NavBarItem to={APP_ROUTES.BOOKINGS.VIEW_LEASE_AND_BOOK(match.params.id)} inactive="true">
      View lease and book
    </NavBarItem>
  </NavBar>
);

BookingsNavBar.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BookingsNavBar;
