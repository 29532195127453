import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { graphql } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import { GET_PLACE_LISTING_TYPE_CODE } from '../../../graphQL/queries/getPlace';
import { NavBar, NavBarItem } from '../../common/NavBar';
import { isInactive } from './FirstStepNavBar';
import ThirdStepNavBar from './ThirdStepNavBar';

const SecondStepNavBar = ({
  match, t, loading, data, location,
}) => {
  const listingTypeCode = get(data, 'place.listingType.code');
  const filledStep = get(data, 'place.filledStep');

  if (filledStep === 3) {
    return <ThirdStepNavBar />;
  }

  return (
    <NavBar>
      {
        loading ? (
          null
        ) : (
          <Fragment>
            <NavBarItem
              to={APP_ROUTES.LISTINGS.PLACE_BASICS(match.params.id)}
              inactive={isInactive({
                match, location, index: 0, listingTypeCode, filledStep,
              })}
            >
              {t('nav.basics')}
            </NavBarItem>
            <NavBarItem
              to={APP_ROUTES.LISTINGS.PLACE_AMENITIES(match.params.id)}
              inactive={isInactive({
                match, location, index: 1, listingTypeCode, filledStep,
              })}
            >
              {t('nav.amenities')}
            </NavBarItem>
            <NavBarItem
              to={APP_ROUTES.LISTINGS.PLACE_MEDIA(match.params.id)}
              inactive={isInactive({
                match, location, index: 2, filledStep, listingTypeCode,
              })}
            >
              {t('nav.media')}
            </NavBarItem>
            {get(data, 'place.listingType.code') === 'entire_place' && (
              <NavBarItem
                to={APP_ROUTES.LISTINGS.PLACE_BEDS_AND_BATHS(match.params.id)}
                inactive={isInactive({
                  match, location, index: 3, filledStep, listingTypeCode,
                })}
              >
                {t('nav.bedsAndBaths')}
              </NavBarItem>
            )}
            <NavBarItem
              to={APP_ROUTES.LISTINGS.PLACE_BOOKING_RULES(match.params.id)}
              inactive="false"
            >
              {t('nav.bookingRules')}
            </NavBarItem>
          </Fragment>
        )
      }
    </NavBar>
  );
};

SecondStepNavBar.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(GET_PLACE_LISTING_TYPE_CODE, {
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
    }),
  }),
);
export default enhance(SecondStepNavBar);
