import gql from 'graphql-tag';

export const GET_PHOTO = gql`
  query GetPhoto(
    $id: ID!
    $version: String
  ) {
    photo(id: $id) {
      id
      photo(version: $version)
    }
  }
`;
