import React from 'react';
import { Image, Box } from 'rebass';
import styled from 'styled-components';
import { fontMixin, fontSize } from '../../theme/styleUtils';

const StyledImage = styled(Image)`
  flex: 0 0 ${props => (props.size ? props.size : 32)};
  width: ${props => (props.size ? props.size : 32)}px;
  height: ${props => (props.size ? props.size : 32)}px;
  margin: 0;
  border-radius: 50%;
`;

const DefaultAvatar = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.avatarDeepBlue};
  color: white;
  text-transform: uppercase;
  ${fontMixin()}
  ${fontSize(15)}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Avatar = ({ src, initials, ...props }) => (
  initials && !src
    ? <DefaultAvatar {...props}>{initials}</DefaultAvatar>
    : <StyledImage src={src} {...props} />
);

export default Avatar;
