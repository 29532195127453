import baseConfig from './baseConfig';

const config = {
  appEnv: 'staging',
  baseUrl: '/',
  domain: 'https://downroots.goodylabs.com',
  graphQlApiUri: 'https://kumpfy-api-staging.goodylabs.com/graphql/',
  restApiUri: 'https://kumpfy-api-staging.goodylabs.com/',
  resetPasswordRedirectUrl: 'https://downroots.goodylabs.com/signin/resetpassword',
  confirmationRedirectUrl: 'https://downroots.goodylabs.com/welcome',
  facebookAppId: '2273278112929016',
  googleApiKey: 'AIzaSyBHQQxs-xDWOmq_mJnMxnM2i2rhb2ulSS8',
  stripe: 'pk_test_zeCs5wAWmnTzMb4BDNVVbz7t ',
};

export default Object.freeze(Object.assign({}, baseConfig, config));
