import gql from 'graphql-tag';

export const EDIT_PROFILE = gql`
  mutation EditProfile(
    $lastName: String
    $firstName: String
    $birthday: ISO8601DateTime
    $address: String
    $phone: String
    $description: String
    $sex: Sex
    $rentPaymentAssistance: Boolean
    $kindOfJob: KindOfJob
    $avatar: Upload
    $company: CompanyInput
    $country: String
    $documentKind: IdentityEnum
    $businessName: String
    $university: String
    $studentLoan: Boolean
    $jobTitle: String
  ) {
    editProfile(payload: {
      lastName: $lastName
      firstName: $firstName
      birthday: $birthday
      address: $address
      phone: $phone
      description: $description
      sex: $sex
      rentPaymentAssistance: $rentPaymentAssistance
      kindOfJob: $kindOfJob
      avatar: $avatar
      company: $company
      country: $country
      documentKind: $documentKind
      businessName: $businessName
      university: $university
      studentLoan: $studentLoan
      jobTitle: $jobTitle
    }) {
      id
      firstName
      lastName
      birthday
      address
      phone
      country
      documentKind
      company {
        id
        isRegisteredLandlord
        companyName
      }
    }
  }
`;

export const EDIT_AVATAR = gql`
  mutation EditAvatar(
    $avatar: Upload
  ) {
    editProfile(payload: {
      avatar: $avatar
    }) {
      id
      avatar
    }
  }
`;
