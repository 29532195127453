import gql from 'graphql-tag';

export const CREATE_BLOCK_OUT = gql`
  mutation CreateBlockOut(
    $startDate: ISO8601DateTime!,
    $endDate: ISO8601DateTime!,
    $resourceId: ID,
    $resourceType: String
  ) {
    createBlockOut(payload: {
      startDate: $startDate,
      endDate: $endDate,
      resourceId: $resourceId,
      resourceType: $resourceType
    }) {
      id
    }
  }
`;

export const DELETE_BLOCK_OUT = gql`
  mutation DeleteBlockOut(
  $blockOutId: ID!
  ) {
    deleteBlockOut(blockOutId: $blockOutId) {
      id
    }
  }
`;
