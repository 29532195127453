import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';

const Exposer = ({ getState, exposeState }) => {
  const { submitting } = getState();
  useEffect(() => {
    exposeState({ submitting });
  }, [submitting]);
  return null;
};

const ExposeState = ({ exposeState }) => (
  <FormSpy subscription={{ submitting: true }}>
    {({ form: { getState } }) => <Exposer getState={getState} exposeState={exposeState} />}
  </FormSpy>
);

ExposeState.propTypes = {
  exposeState: PropTypes.func.isRequired,
};

export default ExposeState;
