import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import { Field } from 'react-final-form';

export const ErrorBoxContainer = styled(Box)`
  color: ${props => props.theme.colors.pinkRed};
`;

export const ErrorBox = ({
  meta: {
    pristine, touched, error: fieldError, submitError, dirtySinceLastSubmit,
  },
  ...rest
}) => ((((!pristine || touched) && fieldError) || (submitError && !dirtySinceLastSubmit))
  ? <ErrorBoxContainer {...rest}>{fieldError || submitError}</ErrorBoxContainer>
  : null
);

const FieldError = ({
  name, children, direction, ...props
}) => (
  <Box>
    <Flex flexDirection={direction} justifyContent="space-between" alignItems="flex-start">
      {name && (
        <Field
          {...props}
          name={name}
          render={({ meta }) => <ErrorBox meta={meta} />}
        />
      )}
      {children}
    </Flex>
  </Box>
);

FieldError.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['row', 'column']),
  name: PropTypes.string.isRequired,
};

FieldError.defaultProps = {
  direction: 'row',
};

export default FieldError;
