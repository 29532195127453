import Hide from 'hidden-styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, NavLink as NavBarLinkRaw } from 'react-router-dom';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';
import logo from '../../assets/img/downroots-icon.webp.png';
import { fontMixin, fontSize, LIGHT } from '../../theme/styleUtils';
import DesktopMenu from '../navigation/DesktopMenu';
import MobileMenu from '../navigation/MobileMenu';
import StickyContainer from './StickyContainer';
import MenuText from './text/MenuText';
import FixedContainer from './FixedContainer';

const NavBarLink = styled(({ withUnderline, ...rest }) => <NavBarLinkRaw {...rest} />)`
  ${fontMixin(LIGHT)}
  ${fontSize(17)}
  color: ${props => props.theme.colors.darkBrown};
  text-decoration: none;
  pointer-events: ${props => (props.inactive === 'true' ? 'none' : undefined)};
  opacity: ${props => (props.inactive === 'true' ? 0.5 : 1)};
  border: none;
  padding: 0 20px;
  height: 100%;
  &.active {
    color: ${props => props.theme.colors.navActiveGrey};
    border-bottom: 2px solid ${props => props.theme.colors.purplePrimary};
    ${props => !props.withUnderline && 'border-bottom: 0 none;'}
  }
  > div {
    height: 100%;
  }
`;

export const NavBarItem = ({
  to,
  children,
  isActive,
  inactive,
  withUnderline = true,
  exact,
  className,
}) => (
  <NavBarLink
    className={className}
    isActive={isActive}
    inactive={inactive}
    to={to}
    withUnderline={withUnderline}
    exact={exact}
  >
    <Flex alignItems="center">
      <MenuText>
        {children}
      </MenuText>
    </Flex>
  </NavBarLink>
);

NavBarItem.propTypes = {
  isActive: PropTypes.func,
  inactive: PropTypes.string,
  className: PropTypes.string,
  withUnderline: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  exact: PropTypes.bool,
};
NavBarItem.defaultProps = {
  exact: true,
};

const NavBarWrapper = styled(Flex)`
  height: 4em;
  border-bottom: ${props => props.border && '1px solid rgba(121,121,121,0.32)'};
`;

const NavBarLayout = styled(Flex)`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

const NavBarContainer = ({ children, border = true }) => (
  <NavBarWrapper justifyContent="flex-start" width={1} alignItems="center" border={border}>
    <NavBarLayout>
      <Flex alignItems="center" width={1}>
        <Link to="/">
          <Image pl={10} src={logo} height={48} />
        </Link>
        {children}
      </Flex>
    </NavBarLayout>
  </NavBarWrapper>
);

NavBarContainer.propTypes = {
  children: PropTypes.node.isRequired,
  border: PropTypes.bool,
};

export const NavBar = ({ children }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <StickyContainer>
      <Hide xs sm>
        <NavBarContainer>
          {children}
          <DesktopMenu />
        </NavBarContainer>
      </Hide>
      <Hide md lg>
        <NavBarContainer>
          <MobileMenu isModalOpened={isModalOpened} setIsModalOpened={setIsModalOpened} />
        </NavBarContainer>
      </Hide>
    </StickyContainer>
  );
};

NavBar.propTypes = {
  children: PropTypes.node,
};

export const TransparentNavBar = ({ children }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  return (
    <>
      <Hide xs sm>
        <StickyContainer>
          <NavBarContainer>
            {children}
            <DesktopMenu />
          </NavBarContainer>
        </StickyContainer>
      </Hide>
      <Hide md lg>
        <FixedContainer isModalOpened={isModalOpened}>
          <NavBarContainer border={false}>
            <MobileMenu iconFill={isModalOpened ? '#000' : '#fff'} isModalOpened={isModalOpened} setIsModalOpened={setIsModalOpened} />
          </NavBarContainer>
        </FixedContainer>
      </Hide>
    </>
  );
};

TransparentNavBar.propTypes = {
  children: PropTypes.node,
};
