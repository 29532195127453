import get from 'lodash/get';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { Box, Flex } from 'rebass';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as Bars } from '../../assets/img/bars.svg';
import { ReactComponent as Times } from '../../assets/img/times.svg';
import { GET_CURRENT_USER } from '../../graphQL/queries';
import LogOut from '../auth/LogOut';
import Loader from '../common/Loader';
import Modal from '../common/Modal';
import CommonMenu from './CommonMenu';
import LandlordMenu from './LandlordMenu';
import TenantMenu from './TenantMenu';

const TimesIcon = styled(Times).attrs({
  height: 24,
  width: 24,
})`
  cursor: pointer;
`;

const BarsIcon = styled(Bars).attrs({
  height: 24,
  width: 24,
})`
  cursor: pointer;
`;

const modalStyles = css`
  align-items: flex-start;
  background-color: #fff;
  margin-top: 63px;
`;

const MobileMenu = ({ iconFill, isModalOpened, setIsModalOpened }) => (
  <Fragment>
    <Flex ml="auto" px={20} alignItems="center" justifyContent="center">
      {!isModalOpened && <BarsIcon fill={iconFill} onClick={() => setIsModalOpened(true)} />}
      {isModalOpened && <TimesIcon fill={iconFill} onClick={() => setIsModalOpened(false)} />}
      {isModalOpened && (
        <Modal bg="white" styles={modalStyles}>
          <Query query={GET_CURRENT_USER}>
            {({ loading, data }) => {
              if (loading) {
                return <Loader />;
              }

              const role = get(data, 'user.role.name');

              return (
                <Fragment>
                  {role === 'landlord' && <LandlordMenu />}
                  {role === 'tenant' && <TenantMenu />}
                  {role && <Box py={15} />}
                  <LogOut>
                    {({ onLogOut }) => (
                      <CommonMenu
                        isLoggedIn={!!role}
                        onLogOut={onLogOut}
                      />
                    )}
                  </LogOut>
                </Fragment>
              );
            }}
          </Query>
        </Modal>
      )}
    </Flex>
  </Fragment>
);

MobileMenu.propTypes = {
  iconFill: PropTypes.string,
  isModalOpened: PropTypes.bool.isRequired,
  setIsModalOpened: PropTypes.func.isRequired,
};

export default MobileMenu;
