import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { desaturate, lighten } from 'polished';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';

import { setAuthTokens } from '../../utils/auth';
import { FB_SIGN_IN, SET_FB_DATA_FOR_REGISTARTION } from '../../graphQL/mutations';
import PrimaryButton from '../common/buttons/PrimaryButton';
import config from '../../configLoader';
import { fontSize } from '../../theme/styleUtils';

const FacebookButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.colors.facebookBlue};
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  color: #fff;
  justify-content: center;
  align-items: center;
  ${fontSize(15)}
  font-weight: bold;
  &:hover {
    background-color: ${props => lighten(0.1, desaturate(0.05, props.theme.colors.facebookBlue))};
    cursor: pointer;
  }
`;

export const facebookResponse = (apolloClient, history, callback) => (data) => {
  if (data) {
    const { accessToken, userID } = data;

    apolloClient.mutate({
      mutation: FB_SIGN_IN,
      variables: { accessToken, userID },
      update: (cache, { data: { signInByFacebook } }) => { setAuthTokens(signInByFacebook); },
      refetchQueries: () => ['GetCurrentUser'],
    })
      .then(async ({ errors }) => {
        if (!isEmpty(errors)) {
          const oauthUser = get((find(errors, e => e.error_type === 'oauth_user') || {}), 'oauth_user', null);
          if (oauthUser) {
            apolloClient.mutate({
              mutation: SET_FB_DATA_FOR_REGISTARTION,
              variables: {
                email: data.email,
                firstName: data.first_name,
                lastName: data.last_name,
                pictureUrl: get(data, 'picture.data.url'),
                uid: data.userID,
                accessToken: data.accessToken,
              },
            });
          }
          callback();
        } else {
          history.push('/listings');
        }
      })
      .catch((error) => { console.log({ error }); });
  }
};
class FacebookSignInButton extends Component {
  state = {
    redirectToFB: false,
  }

  render = () => {
    const {
      t,
      label,
      history,
      client: apolloClient,
    } = this.props;
    const { redirectToFB } = this.state;
    return (
      <Fragment>
        { redirectToFB && <Redirect to="/signup" /> }
        <FacebookLogin
          appId={config.facebookAppId}
          // autoLoad
          callback={facebookResponse(apolloClient, history, () => this.setState({ redirectToFB: true }))}
          type="common"
          size="medium"
          fields="name,email,picture,first_name,last_name"
          render={renderProps => (
            <FacebookButton onClick={renderProps.onClick}>
              {label || t('signin.fbLogin')}
            </FacebookButton>
          )}
        />
      </Fragment>
    );
  };
}

FacebookSignInButton.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  history: PropTypes.object,
  client: PropTypes.object,
};

FacebookSignInButton.defaultProps = {
  label: null,
};
const enhance = compose(
  withRouter,
  withApollo,
  withNamespaces('auth'),
);

export default enhance(FacebookSignInButton);
