const auth = {
  __typename: 'Auth',
  isAuthenticated: false,
};

const signUpFBData = {
  __typename: 'FBData',
  firstName: '',
  lastName: '',
  email: '',
  uid: '',
  pictureUrl: '',
  accessToken: '',
};

export default {
  auth,
  signUpFBData,
};
