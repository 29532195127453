import capitalize from 'lodash/capitalize';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { Box, Flex } from 'rebass';
import { PROMOTION_MONTHS } from '../../../constants';
import { GET_MONTHLY_RENTAL_PRICES } from '../../../graphQL/queries/monthlyRentalPrices';
import Checkbox from '../../common/form/Checkbox';
import { FormPart, FormSubPart } from '../../common/form/FormWrapper';
import { Separator } from '../../common/form/Separator';
import TextInput from '../../common/form/TextInput';
import Loading from '../../common/Loading';
import FieldError from '../../common/form/FieldError';


class PricesAndPromotions extends Component {
  render() {
    return (
      <Fragment>
        <FormPart title="Prices">
          <FormSubPart subTitle="Rent price" subText="Select the lease lengths you wish to make available and add your prices">
            <FieldError name="monthlyRentalPriceValueIds" direction="column" />
            <FieldError name="monthlyRentalPriceValues.value" direction="column" />
            <Query query={GET_MONTHLY_RENTAL_PRICES}>
              {({ loading, data }) => {
                if (loading) {
                  return <Loading />;
                }

                return (
                  <Flex flexWrap="wrap" justifyContent="space-between">
                    {data.monthlyRentalPrices.map(monthlyRentalPrice => (
                      <Box key={monthlyRentalPrice.id} width="45%" py="5px">
                        <TextInput label={monthlyRentalPrice.name} prefix="£" name={`monthlyRentalPriceValues[${monthlyRentalPrice.id}]`} suffix="Monthly" />
                      </Box>
                    ))}
                  </Flex>
                );
              }}
            </Query>
          </FormSubPart>
          <FormSubPart subTitle="Utility price" subText="Utilities should be working, with no account switching required. Tenants expect to pay 5-15% of rent">
            <Box>
              <TextInput label="Utility price for all leases selected" prefix="£" name="monthlyUtilityPrice" suffix="Monthly" />
            </Box>
          </FormSubPart>
        </FormPart>
        <Separator />
        <FormPart title="Promotions">
          <FormSubPart subText="Add a discount to your price to reduce vacancies during quiet months. The discounted price will be applied to new leases that start in any of the the months selected. The discounted price will be applied to every month of the lease.">
            <Box>
              <TextInput label="Discount to apply" name="discount" suffix="%" />
              <FieldError direction="column" name="promotionMonths">
                <Flex flexWrap="wrap" py="5px">
                  {PROMOTION_MONTHS.map(promotionMonth => (
                    <Box key={promotionMonth} mr={10}>
                      <Checkbox label={capitalize(promotionMonth)} name="promotionMonths" value={promotionMonth} />
                    </Box>
                  ))}
                </Flex>
              </FieldError>
            </Box>
          </FormSubPart>
        </FormPart>
        <Separator mb={40} />
      </Fragment>
    );
  }
}

export default PricesAndPromotions;
