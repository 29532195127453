import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  Flex, Box, Text,
} from 'rebass';
import { withNamespaces } from 'react-i18next';
import Hide from 'hidden-styled';
import { AVAILABLE_PHOTO_VERSIONS } from '../../../constants';
import Modal from '../../common/Modal';
import PhotoLoader from '../../common/PhotoLoader';
import { FilledRoundedButton, RoundedButton } from '../../common/wizard/Footer';
import { FONT_FAMILIES, mediaQuery } from '../../../theme/globalStyle';


const RentInfo = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 12,
  color: 'lightGray',
  pr: 10,
})`
  text-align: left;
  text-transform: uppercase;
`;

const ApprovedInfo = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 14,
  color: 'purplePrimary',
})`
  text-align: left;
`;

const PlaceInfo = styled(Text).attrs({
  fontSize: 36,
  fontFamily: FONT_FAMILIES.AVENIR_NORMAL,
  fontWeight: 600,
  color: 'darkBrown',
})`
  line-height: 1.2em;
  text-align: left;
`;

const ListingImage = styled(Box)`
  background-image: url(${props => props.src});
  background-size: cover;
  height: 429px;
  ${mediaQuery.tablet`height: 530px`};
`;

const TourButton = styled(RoundedButton)`
  padding: 13px 83px;
`;

const FilledTourButton = styled(FilledRoundedButton)`
  padding: 13px 53px;

  position: absolute;
  top: -75px;
  right: 30px;
  z-index: 0;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.pearlGray};
  color: ${props => props.theme.colors.darkBrown};
  :hover {
    background-color: white;
    color: ${props => props.theme.colors.purplePrimary};
    opacity: 1;
  }
`;

const PlaceInfoContainer = styled(Flex).attrs({
  flexDirection: 'column',
  bg: ['transparent', 'transparent', 'lightTransparentGray'],
  mr: 25,
  pl: ['5%', '5%', 60],
  pt: [45, 45, 0],
  width: [1, 1, 2 / 5],
  justifyContent: 'space-evenly',
  alignItems: 'baseline',
})`
  position: relative;
  ${mediaQuery.tablet``};
`;

const ListingDetailsHeader = ({
  listing, t, isPlace, downrootsApproved, virtualTourUrl,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <Flex justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'row']}>
      <PlaceInfoContainer>
        <Box mb={10}>
          {downrootsApproved && <ApprovedInfo mb={10}>Downroots approved</ApprovedInfo>}
          <Flex flexDirection={['row', 'row', 'column']}>
            <RentInfo>{t(`typeNames.${isPlace ? 'entire_place' : 'co_living'}`)}</RentInfo>
            <RentInfo>
              {`${isPlace ? listing.bedroomsCount : get(listing, 'place.bedroomsCount')}${t('bed')} : ${isPlace ? listing.bathroomsCount : get(listing, 'place.bathroomsCount')}${t('bath')}`}
            </RentInfo>
          </Flex>
        </Box>
        <Box width={0.9} mt={20}>
          <PlaceInfo>{(isPlace ? (listing.title || listing.location) : (get(listing, 'place.title') || get(listing, 'place.location'))).substr(0, 100)}</PlaceInfo>
        </Box>
        {(true || virtualTourUrl) && (
          <>
            <Hide xs sm>
              <TourButton onClick={() => setIsModalOpened(true)}>{t('3dtour')}</TourButton>
            </Hide>
            <Hide md lg>
              <FilledTourButton onClick={() => setIsModalOpened(true)}>{t('3dtour')}</FilledTourButton>
            </Hide>
            {isModalOpened && (
              <Modal onClose={() => setIsModalOpened(false)}>
                <Box width="80vw" mt={15}>
                  <iframe
                    title="Sample Tour"
                    width="100%"
                    height="600"
                    src="https://livetour.istaging.com/c951a5af-603f-4003-9d1c-707657febe95"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </Box>
              </Modal>
            )}
          </>
        )}
      </PlaceInfoContainer>
      <PhotoLoader id={get(listing, 'photos[0].id')} version={AVAILABLE_PHOTO_VERSIONS.DETAILS}>
        {({ data }) => (
          <ListingImage width={[1, 1, 3 / 5]} src={data.photo.photo} />
        )}
      </PhotoLoader>
    </Flex>
  );
};

ListingDetailsHeader.propTypes = {
  listing: PropTypes.object,
  t: PropTypes.func.isRequired,
  isPlace: PropTypes.bool.isRequired,
  downrootsApproved: PropTypes.bool.isRequired,
  virtualTourUrl: PropTypes.string,
};

export default withNamespaces('listings')(ListingDetailsHeader);
