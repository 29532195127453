import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import React from 'react';
import { Form } from 'react-final-form';
import { scrollToFirstError, initialValuesUpdateRule } from '../../../utils/formHelpers';
import { FormPart } from '../../common/form/FormWrapper';
import GovernmentIdentity from './GovernmentIdentity';
import BackgroundCheck from './BackgroundCheck';
import Panel from '../../common/Panel';
import { GET_PERSONS_DOCUMENTS } from '../../../graphQL/queries/bookings';
import ExposeState from '../../common/form/ExposeState';

class VerificationForm extends React.Component {
  shouldComponentUpdate({ initialValues }) {
    return initialValuesUpdateRule(initialValues, this.props.initialValues, ['proofOfIdentities']);
  }

  render() {
    const {
      onSubmit,
      onNext,
      initialValues,
      matchParamsId,
      exposeState,
    } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit, values, errors, submitErrors,
        }) => {
          onNext(async () => {
            await handleSubmit();
            scrollToFirstError(submitErrors, errors);
          });
          return (
            <form onSubmit={handleSubmit}>
              <ExposeState exposeState={exposeState} />
              <FormPart title="Verifying you">
                <Panel title="Government Identity">
                  <Text mb={20}>
                    Downroots is built on safety and honesty. Please upload a clear
                    photo or scan of your government issued identity document. This
                    will never be shared. Existing documents should not be out of
                    date
                  </Text>
                  <GovernmentIdentity
                    documentKind={values.documentKind}
                    documentsDataPath="booking.people[0].proofOfIdentities"
                    query={GET_PERSONS_DOCUMENTS}
                    variables={{ id: matchParamsId }}
                  />
                </Panel>
                <Flex pt={20} />
                <BackgroundCheck />
              </FormPart>
            </form>
          );
        }}
      />
    );
  }
}

VerificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  matchParamsId: PropTypes.string,
  exposeState: PropTypes.func.isRequired,
};

export default VerificationForm;
