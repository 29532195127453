import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Text, Box } from 'rebass';
import get from 'lodash/get';
import some from 'lodash/some';

import ListingItem from '../common/ListingItem';
import { APP_ROUTES } from '../../../../constants';
import RenderIfNotEmpty from '../../common/RenderIfNotEmpty';
import Modal from '../../../common/Modal';
import TourBookedModal from './TourBookedModal';

const transformLabels = (category) => {
  switch (category) {
    case 'incomplete':
      return {
        title: 'Finish your listing',
        btnLabel: 'Finish listing',
        status: false,
        url: id => APP_ROUTES.LISTINGS.PLACE_INTRO(id),
      };
    case 'unlisted':
      return {
        title: 'Unlisted',
        btnLabel: 'Relist',
        status: false,
        url: id => APP_ROUTES.LISTINGS.PLACE_INTRO(id),
      };
    case 'listed':
      return {
        title: 'Listed',
        btnLabel: 'View',
        status: true,
        url: id => APP_ROUTES.LISTINGS.PLACE_INTRO(id),
      };
    default:
      return {};
  }
};

const ListingsList = ({ items, history: { push, location: { state } } }) => {
  const labels = transformLabels(get(items, '[0].category', ''));
  const [modalListingId, setModalListingId] = useState(null);
  useEffect(() => {
    if (state && some(items, item => item.id === state.bookedPlaceId)) {
      setModalListingId(state.bookedPlaceId);
    }
  }, [get(state, 'bookedPlaceId')]);
  return (
    <Box>
      <Text fontSize={30} fontWeight={700} mt={50} mb={15}>
        {labels.title}
      </Text>
      <RenderIfNotEmpty array={items} name={labels.title}>
        {items.map(item => (
          <ListingItem
            title={item.location}
            subTitle={get(item, 'listingType.name')}
            status={labels.status}
            redirect={item.virtualTourBookedAt ? () => setModalListingId(item.id) : () => push(labels.url(item.id))}
            progress={item.progress}
            btnLabel={labels.btnLabel}
            mainImageUrl={item.mainImageUrl}
            has3DTour={item.has3DTour}
            virtualTourBookedAt={item.virtualTourBookedAt}
            show3DTour
            percentage={item.percentage}
            key={`listing-item-${item.id}`}
          />
        ))}
        { modalListingId && (
          <Modal onClose={() => setModalListingId(null)}>
            <TourBookedModal redirect={() => push(labels.url(modalListingId))} onClose={() => setModalListingId(null)} />
          </Modal>
        )}
      </RenderIfNotEmpty>
    </Box>
  );
};

ListingsList.propTypes = {
  history: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default withRouter(ListingsList);
