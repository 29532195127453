import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { fontSize } from '../../../theme/styleUtils';

const ExpandText = styled.a`
  ${fontSize(15)}
  color: ${props => props.theme.colors[props.color] || props.color || props.theme.colors.purplePrimary};
  cursor: pointer;
`;

const toggleStateSliceHook = setter => () => setter(state => !state);

const SeeMore = ({
  collapsedState,
  stateSetter,
  color,
  t,
}) => (
  <ExpandText
    onClick={toggleStateSliceHook(stateSetter)}
    color={color}
  >
    {t('expand.see')}
    &nbsp;
    {collapsedState ? t('expand.more') : t('expand.less')}
  </ExpandText>
);

SeeMore.defaultProps = {
  color: undefined,
};

SeeMore.propTypes = {
  collapsedState: PropTypes.bool.isRequired,
  stateSetter: PropTypes.func.isRequired,
  color: PropTypes.any,
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listings')(SeeMore);
