import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import {
  CardCVCElement, CardExpiryElement, CardNumberElement,
} from 'react-stripe-elements';
import { Box, Flex } from 'rebass';
import { composeValidators, mustBeValidStripeElement, required } from '../../../../utils/formValidation';
import StripeInput from '../../../common/form/StripeInput';

const CreditCardFormFieldsWrapper = styled(Flex)`
  .stripe-input {
    border-radius: 5px;
  }
`;

const CreditCardFormFields = () => (
  <CreditCardFormFieldsWrapper flexDirection="column" width={1}>
    <Flex mb={10}>
      <Box width={1} style={{ minWidth: '230px' }}>
        <Field
          name="cardNumber"
          validate={composeValidators(required, mustBeValidStripeElement)}
        >
          {({ input, meta }) => (
            <StripeInput
              label="Card number"
              component={CardNumberElement}
              input={input}
              meta={meta}
              onChange={input.onChange}
            />
          )}
        </Field>
      </Box>
    </Flex>
    <Flex alignItems="flex-start">
      <Box width={1 / 2} mr={10}>
        <Field
          name="cardCvc"
          validate={composeValidators(required, mustBeValidStripeElement)}
        >
          {({ input, meta }) => (
            <StripeInput
              label="Security code"
              component={CardCVCElement}
              input={input}
              meta={meta}
              onChange={input.onChange}
            />
          )}
        </Field>
      </Box>
      <Box width={1 / 2}>
        <Field
          name="cardExpiry"
          validate={composeValidators(required, mustBeValidStripeElement)}
        >
          {({ input, meta }) => (
            <StripeInput
              label="Expires on"
              placeholder="MM / YY"
              component={CardExpiryElement}
              input={input}
              meta={meta}
              onChange={input.onChange}
            />
          )}
        </Field>
      </Box>
    </Flex>
  </CreditCardFormFieldsWrapper>
);

export default CreditCardFormFields;
