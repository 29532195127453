import gql from 'graphql-tag';

export const SEND_RESET_PASSWORD = gql`
  mutation sendResetPassword($email: String, $redirect_url: String){
    sendResetPassword(input: {email: $email, redirect_url: $redirect_url})
      @rest( type: "DeviseResult", path: "/user/password", method: "POST" ){
        message
        errors
        success
      }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String, $password_confirmation: String, $reset_password_token: String){
    changePassword(input: {reset_password_token: $reset_password_token, password: $password, password_confirmation: $password_confirmation})
    @rest( type: "DeviseResult", path: "/user/password", method: "PUT" ){
      message
      errors
      success
    }
  }
`;
