import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import get from 'lodash/get';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../../common/Loading';

export const mergePaginatedData = (connectionPath, prevData, newData) => ({
  [connectionPath]: {
    ...prevData[connectionPath],
    results: [
      ...prevData[connectionPath].results,
      ...newData[connectionPath].results,
    ],
  },
});

const InfiniteScrollList = ({
  data,
  connectionPath,
  loading,
  fetchMore,
  children,
  requestSize,
}) => {
  const results = get(data, `${connectionPath}.results`, []);
  const total = get(data, `${connectionPath}.total`);

  return (
    <InfiniteScroll
      initialLoad={false}
      hasMore={results.length < total}
      loader={<Loading key="scroller-loader" />}
      loadMore={() => {
        if (loading) {
          return;
        }
        fetchMore({
          variables: {
            page: (results.length / requestSize) + 1,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return mergePaginatedData(connectionPath, prev, fetchMoreResult);
          },
        });
      }}
    >
      <Flex flexWrap="wrap">
        {results.map(children)}
      </Flex>
    </InfiniteScroll>
  );
};

InfiniteScrollList.propTypes = {
  data: PropTypes.object.isRequired,
  connectionPath: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  requestSize: PropTypes.number.isRequired,
};

export default InfiniteScrollList;
