import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { HelmetProvider } from 'react-helmet-async';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import 'react-tippy/dist/tippy.css';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GlobalStyle } from './theme/globalStyle';
import client from './graphQL/apolloClient';

ReactDOM.render(
  <Fragment>
    <GlobalStyle />
    <HelmetProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </HelmetProvider>
  </Fragment>,
  document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
