import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import config from '../../../configLoader';

class StripeWrapper extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  }

  state = {
    stripe: null,
  };

  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(config.stripe) });
    } else {
      document.querySelector('#stripe').addEventListener('load', () => {
        this.setState({ stripe: window.Stripe(config.stripe) });
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          {this.props.children}
        </Elements>
      </StripeProvider>
    );
  }
}

StripeWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StripeWrapper;
