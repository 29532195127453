import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MenuListItem } from '../common/Menu';
import { NavItem } from './DesktopMenu';

const CommonMenu = ({
  isLoggedIn,
  onLogOut,
}) => (
  <Fragment>
    <MenuListItem>
      <Link to="/listings">
        <NavItem>Discover</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to="/owners">
        <NavItem>List your space</NavItem>
      </Link>
    </MenuListItem>
    <MenuListItem>
      <Link to="/partners">
        <NavItem>Partners</NavItem>
      </Link>
    </MenuListItem>
    {!isLoggedIn && (
      <MenuListItem>
        <Link to="/sign-in">
          <NavItem>Sign in</NavItem>
        </Link>
      </MenuListItem>
    )}
    <MenuListItem>
      <Link to="/help">
        <NavItem>Help</NavItem>
      </Link>
    </MenuListItem>
    {isLoggedIn && (
      <MenuListItem>
        <NavItem onClick={onLogOut}>Sign out</NavItem>
      </MenuListItem>
    )}
  </Fragment>
);

CommonMenu.propTypes = {
  isLoggedIn: PropTypes.bool,
  onLogOut: PropTypes.func.isRequired,
};

export default CommonMenu;
