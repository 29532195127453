import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';

const Header = styled(Box)`
  border: 1px solid rgba(186, 184, 184, 0.35);
  background-color: rgba(204, 204, 204, 0.17);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  font-size: 16px;
`;
Header.defaultProps = {
  px: 20,
  py: 10,
  width: 1,
};

const Container = styled(Box)`
  border: 1px solid rgba(186, 184, 184, 0.35);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
Container.defaultProps = {
  width: 1,
  p: 20,
};

const Panel = ({ title, children, mb }) => (
  <Flex width={1} flexDirection="column" mb={mb}>
    <Header>{title}</Header>
    <Container>{children}</Container>
  </Flex>
);

Panel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
};

export default Panel;
