import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import { Query } from 'react-apollo';
import map from 'lodash/map';
import { required } from '../../../utils/formValidation';
import { GET_COUNTRIES_PREFIXES } from '../../../graphQL/queries/bookings';
import Dropdown from '../../common/form/Dropdown';
import FieldError from '../../common/form/FieldError';
import DocumentPhotos from './DocumentPhotos';
import Radio from '../../common/form/Radio';
import Error from '../../common/Error';
import Loading from '../../common/Loading';

const GovernmentIdentity = ({
  documentKind,
  query,
  variables,
  documentsDataPath,
}) => (
  <Fragment>
    <Query query={GET_COUNTRIES_PREFIXES}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;
        const countries = map(data.dictionary, ({ name }) => ({
          label: name,
          value: name,
        }));
        return (
          <Dropdown
            options={countries}
            label="Issuing country"
            name="country"
            validate={required}
          />
        );
      }}
    </Query>
    <Text mt={20} mb="5px">
      Select a government issued I.D to upload.
    </Text>
    <FieldError direction="column" name="documentKind">
      <Flex justifyContent="space-between" flexWrap="wrap" mb={20} width={1}>
        <Box width={[1, 1 / 3, 1 / 3]}>
          <Radio
            name="documentKind"
            label="Passport"
            value="passport"
            validate={required}
          />
        </Box>
        <Box width={[1, 1 / 3, 1 / 3]}>
          <Radio
            name="documentKind"
            label="Identity card"
            value="identity_card"
            validate={required}
          />
        </Box>
        <Box width={[1, 1 / 3, 1 / 3]}>
          <Radio
            name="documentKind"
            label="Drivers Licence"
            value="license"
            validate={required}
          />
        </Box>
      </Flex>
    </FieldError>
    {documentKind && (
      <DocumentPhotos
        documentKind={documentKind}
        query={query}
        variables={variables}
        documentsDataPath={documentsDataPath}
      />
    )}
  </Fragment>
);

GovernmentIdentity.propTypes = {
  documentKind: PropTypes.string,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  documentsDataPath: PropTypes.string.isRequired,
};

export default GovernmentIdentity;
