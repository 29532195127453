import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router';
import Navigation from '../navigation/MainNavBar';
import AppLayout from './AppLayout';

const AppRoute = ({
  component: Component, layout: Layout, navBar: NavBar, ...routeProps
}) => (
  <Route
    {...routeProps}
    render={props => (
      <Layout
        {...props}
        navBar={() => <NavBar {...props} />}
      >
        <Component {...routeProps} {...props} />
      </Layout>
    )}
  />
);

AppRoute.propTypes = {
  component: PropTypes.func,
  layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  navBar: PropTypes.func,
};

AppRoute.defaultProps = {
  navBar: Navigation,
  layout: AppLayout,
};

export default AppRoute;
