import get from 'lodash/get';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isNaN from 'lodash/isNaN';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { graphql, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import { CREATE_OR_UPDATE_PLACE_BASICS } from '../../../graphQL/mutations/createOrUpdatePlace';
import { GET_USER_INFO } from '../../../graphQL/queries/getCurrentUser';
import { GET_PLACE_BASICS } from '../../../graphQL/queries/getPlace';
import { GET_LISTING_TYPES } from '../../../graphQL/queries/place';
import Loading from '../../common/Loading';
import Footer from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';
import BasicsForm from './forms/basics/BasicsForm';
import { mapApiErrors } from '../../../utils/errorHandling';
import { NarrowLayout } from '../../common/Layout';
import { isPlaceDone } from './utils';


class Basics extends Component {
  onNext = noop;

  state = {
    submitting: false,
  };

  getInitialValues = ({ place, user }) => {
    const params = queryString.parse(this.props.location.search);
    return ({
      listingTypeId: params.listingTypeId || get(place, 'listingType.id'),
      unitTypeId: get(place, 'unitTypeId'),
      buildingTypeId: get(place, 'buildingTypeId'),
      location: get(place, 'location'),
      latitude: get(place, 'latitude'),
      longitude: get(place, 'longitude'),
      geolocationData: get(place, 'geolocationData'),
      ownerLivesIn: `${+get(place, 'ownerLivesIn', false)}`,
      bedroomsCount: `${+get(place, 'bedroomsCount', 0)}`,
      bathroomsCount: `${+get(place, 'bathroomsCount', 0)}`,
      tenantAllowedCount: `${+get(place, 'tenantAllowedCount', 0)}`,
      legalOwner: {
        type: get(place, 'legalOwner.type', 'me'),
        authorisation: get(place, 'legalOwner.authorisation') === 'true',
        me: {
          firstName: '',
          lastName: '',
          ...pick(user, ['firstName', 'lastName']),
          ...pickBy(pick(get(place, 'legalOwner', {}), ['firstName', 'lastName'])),
        },
        my_client: {
          firstName: '',
          lastName: '',
          ...pick(get(place, 'legalOwner', {}), ['firstName', 'lastName']),
        },
        my_company: {
          companyName: '',
          registeredCountry: '',
          registeredNumber: '',
          ...pick(get(user, 'company'), ['companyName', 'registeredCountry', 'registeredNumber']),
          ...pickBy(pick(get(place, 'legalOwner', {}), ['companyName', 'registeredCountry', 'registeredNumber'])),
        },
        my_client_company: {
          companyName: '',
          registeredCountry: '',
          registeredNumber: '',
          ...pick(get(place, 'legalOwner', {}), ['companyName', 'registeredCountry', 'registeredNumber']),
        },
      },
      user: get(place, 'legalOwner.type') === 'my_client' ?
        { ...omit(get(place, 'legalOwner', {}), '__typename') } :
        { ...omit(user, ['__typename', 'company', 'id']) },
    });
  };

  onSubmit = async (values) => {
    try {
      const { mutate, match, history } = this.props;
      const currentSection = values.saveAndExit ? 'initial' : 'basics';

      const legalOwner = values.legalOwner && {
        type: values.legalOwner.type,
        authorisation: values.legalOwner.authorisation,
        ...values.legalOwner[values.legalOwner.type],
      };
      const { data, errors } = await mutate({
        variables: omit({
          ...values,
          id: match.params.id,
          legalOwner,
          currentSection,
          ownerLivesIn: isNaN(+values.ownerLivesIn) ? null : !!+values.ownerLivesIn,
        }, ['user', 'saveAndExit']),
      });
      if (errors) {
        return mapApiErrors(errors);
      }

      if (values.saveAndExit || isPlaceDone(data)) {
        history.push(APP_ROUTES.LISTINGS.PLACE_INTRO(data.createOrUpdatePlace.id));
      } else {
        history.push(APP_ROUTES.LISTINGS.PLACE_AMENITIES(data.createOrUpdatePlace.id));
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <NarrowLayout>
        <WizardStep query={GET_PLACE_BASICS}>
          {({ data }) => (
            <Query query={GET_LISTING_TYPES}>
              {({ loading, data: { listingTypes } }) => {
                if (loading) {
                  return <Loading />;
                }
                return (
                  <Query query={GET_USER_INFO}>
                    {({ loading: userInfoLoading, data: { user } }) => {
                      if (userInfoLoading) {
                        return <Loading />;
                      }
                      return (
                        <BasicsForm
                          listingTypes={listingTypes}
                          initialValues={this.getInitialValues({ place: get(data, 'place'), user })}
                          onNext={(onNext) => {
                            this.onNext = onNext;
                          }}
                          exposeState={({ submitting }) => this.setState({ submitting })}
                          onSubmit={this.onSubmit}
                        />
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          )}
        </WizardStep>
        <Footer
          onNext={() => this.onNext()}
          disabledNext={this.state.submitting}
          disabledSaveAndExit
          omitPrev
          isPlace
        />
      </NarrowLayout>
    );
  }
}

Basics.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  graphql(CREATE_OR_UPDATE_PLACE_BASICS),
);

export default enhance(Basics);
