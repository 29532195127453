import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Flex, Box, Text } from 'rebass';
import { Mutation } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';
import styled from 'styled-components';
import queryString from 'query-string';
import { CHANGE_PASSWORD } from '../../graphQL/mutations/deviseMutations';
import HollowButton from '../common/buttons/HollowButton';
import TextInput from '../common/form/TextInput';
import { required } from '../../utils/formValidation';
import FieldErrorMessage from '../text/FieldErrorMessage';

export const Label = styled.label`
  margin-top: 15px;
  font-size: 20px;
  font-weight: 300;
  color: ${props => props.theme.colors.darkBrown};
`;

class ResetPassword extends Component {
  render = () => {
    const { t, location: { search } } = this.props;
    const headers = queryString.parse(search);
    const resetPasswordToken = headers.token;
    return (
      <Box mt={100}>
        <Flex mb={30} alignItems="center" justifyContent="space-around">
          <Mutation
            mutation={CHANGE_PASSWORD}
            onError={() => {}}
            context={{ headers }}
          >
            {(changePassword, { error: apiErrors, data }) => (
              <Flex flexDirection="column">
                <Form
                  onSubmit={values => changePassword({ variables: { ...values, reset_password_token: resetPasswordToken } })}
                  id="reset-password-form"
                  render={({ handleSubmit, submitError: errors }) => {
                    const message = get(data, 'changePassword.message');
                    const success = get(data, 'changePassword.success', false);
                    const deviseErrors = get(data, 'changePassword.errors', {});
                    const allErrors = { ...errors, ...deviseErrors };
                    if (success) {
                      return (
                        <Flex flexDirection="column">
                          <Text>
                            {message}
                          </Text>
                        </Flex>
                      );
                    }
                    return (
                      <form onSubmit={handleSubmit}>
                        <Flex flexDirection="column">
                          <FieldErrorMessage apiErrors={apiErrors} errors={allErrors} flexProps={{ ml: -10 }} />
                          {message}
                          <Label htmlFor="password">
                            {`${t('resetpassword.password')}:`}
                          </Label>
                          <TextInput password name="password" placeholder={t('resetpassword.password_placeholder')} validateOnBlur validate={required} bgcolor="pearlGray" />
                          <FieldErrorMessage apiErrors={apiErrors} errors={allErrors} flexProps={{ ml: -10 }} field="password" />

                          <Label htmlFor="password_confirmation">
                            {`${t('resetpassword.password_confirmation')}:`}
                          </Label>
                          <TextInput password name="password_confirmation" placeholder={t('resetpassword.password_confirmation')} validateOnBlur validate={required} bgcolor="pearlGray" />
                          <FieldErrorMessage apiErrors={apiErrors} errors={allErrors} flexProps={{ ml: -10 }} field="password_confirmation" />

                          <HollowButton mt={10} id="signin-button" type="submit">
                            {t('resetpassword.button')}
                          </HollowButton>
                        </Flex>
                      </form>
                    );
                  }}
                />
              </Flex>
            )}
          </Mutation>
        </Flex>
      </Box>
    );
  };
}

ResetPassword.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withNamespaces('auth')(ResetPassword);
