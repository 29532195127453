import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Text, Box,
} from 'rebass';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import contactImage from '../../assets/img/contactPeople.jpg';
import { fontSize } from '../../theme/styleUtils';

const ContactsImage = styled.div`
  width: 100%;
  height: 281px;
  background: url(${contactImage});
  background-attachment: fixed;
  background-size: cover;
  background-position-y: 30%;
`;

const CopyrightText = styled(Text)`
  ${fontSize(14)}
  color: #BAB8B8;
  text-align: center;
`;

const SocialsFooter = styled(Flex)`
  ${fontSize(30)}
  height: 80px;
  > * {
    margin: 0 10px;
    color: black;
  }
`;

const ContactSection = () => (
  <>
    <ContactsImage />
    <Flex justifyContent="center" alignItems="center">
      <CopyrightText width={1 / 3}>© 2017 Downroots.com</CopyrightText>
      <SocialsFooter alignItems="center" justifyContent="center" width={1 / 3}>
        <a href="https://www.facebook.com/downroots" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f" />
        </a>
        <a href="https://twitter.com/downrootsliving" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter" />
        </a>
        <a href="https://www.instagram.com/downrootsliving" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram" />
        </a>
      </SocialsFooter>
      <Box width={1 / 3} />
    </Flex>
  </>
);

ContactSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('home')(ContactSection);
