import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Box, Flex } from 'rebass';
import Hide from 'hidden-styled';
import Body from '../../common/text/Body';

import HollowButton from '../../common/buttons/HollowButton';
import bigBedroom from '../../../assets/img/bigBedroom.png';
import { DetailsSubtitle } from './DetailsFacilityPart';
import { mediaQuery } from '../../../theme/globalStyle';

const ContactImageBox = styled(Box)`
  background-color: white;
  position: absolute;
  width: 279px;
  height: 262px;
  padding: 24px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 20px 0;
  > div {
    overflow: hidden;
    max-height: 100%;
  }
  top: 30px;
  left: 60px;
  ${mediaQuery.tablet`left: initial`};
`;

const GreenBox = styled(Box).attrs({
  mb: [100, 100, 65],
})`
  z-index: 0;
  content: '';
  position: relative;
  background-color: ${props => props.theme.colors.paleGreen};
  min-width: 366px;
  height: 229px;
  //top: 30px;
  //left: -300px;
  top: 0;
  left:0;
  width: 90%;
  ${mediaQuery.tablet``};
`;

const ContactBackground = styled(Flex).attrs({
  bg: ['whiteGray', 'whiteGray', 'lightTransparentGray'],
  flexDirection: ['column', 'column', 'row'],
  alignItems: 'center',
  width: ['100vw', '100vw', 1],
  ml: ['calc((100vw - 100%)/-2)', 'calc((100vw - 100%)/-2)', 0],
  py: 20,
})`
  z-index: -2;
  position: relative;
`;

const ContactContentWrapper = styled(Flex).attrs({
  width: [1, 1, 1 / 2],
  px: ['5%', '5%', 70],
  pt: 40,
  pb: 10,
  justifyContent: 'space-evenly',
  flexDirection: 'column',
})`
  z-index: 1;
`;

const ContactDecorationWrapper = styled(Flex).attrs({
  width: [1, 1, 1 / 2],
  alignItems: 'center',
  flexDirection: 'column',
})`
  position: relative;
`;


const DetailsContactPart = ({ t }) => (
  <>
    <DetailsSubtitle mt={70} mb={30}>{t('details.consiergeTeam.title')}</DetailsSubtitle>
    <ContactBackground>
      <ContactContentWrapper>
        <Body pb={30}>{t('details.consiergeTeam.text')}</Body>
        <Hide xs sm>
          <HollowButton mb={30}>{t('details.consiergeTeam.contact')}</HollowButton>
        </Hide>
      </ContactContentWrapper>
      <ContactDecorationWrapper>
        <GreenBox />
        <ContactImageBox>
          <div>
            <img src={bigBedroom} alt="" />
          </div>
        </ContactImageBox>
      </ContactDecorationWrapper>
      <Hide md lg>
        <HollowButton mb={30}>{t('details.consiergeTeam.contact')}</HollowButton>
      </Hide>
    </ContactBackground>
  </>
);

DetailsContactPart.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listings')(DetailsContactPart);
