import gql from 'graphql-tag';

export const GET_MONTHLY_RENTAL_PRICES = gql`
  query GetMonthlyRentalPrices {
    monthlyRentalPrices {
      id
      name
    }
  }
`;
