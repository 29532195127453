import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { Box, Flex } from 'rebass';
import map from 'lodash/map';

import { GET_FACILITIES_FOR_PLACE } from '../../../../graphQL/queries/getFacilitiesForPlace';
import Checkbox from '../../../common/form/Checkbox';
import Loading from '../../../common/Loading';
import { Separator } from '../../../common/form/Separator';
import { FormWrapper, FormPart, FormSubPart } from '../../../common/form/FormWrapper';
import FieldError from '../../../common/form/FieldError';
import ExposeState from '../../../common/form/ExposeState';

const PlaceFacilityCheckboxes = ({ facilityType }) => (
  <Flex flexDirection="row" justifyContent="space-between" flexWrap="wrap">
    <Query
      query={GET_FACILITIES_FOR_PLACE}
      variables={{ facilityType }}
    >
      {({ loading, data }) => {
        if (loading) {
          return <Loading />;
        }

        return map(data.facilitiesForPlace, (facility, idx) => (
          <Box mt={10} width={1 / 2} key={`${facility.id}-${idx}`}>
            <FieldError name="facilityIds" />
            <Checkbox label={facility.name} name="facilityIds" value={facility.id} />
          </Box>
        ));
      }}
    </Query>
  </Flex>
);

PlaceFacilityCheckboxes.propTypes = {
  facilityType: PropTypes.string.isRequired,
};

const AmenitiesForm = ({
  initialValues, onNext, onSubmit, t, exposeState,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => {
      onNext(handleSubmit);
      return (
        <form onSubmit={handleSubmit}>
          <ExposeState exposeState={exposeState} />
          <FormWrapper>
            <FormPart title={t('amenities.title')}>
              <FormSubPart subTitle={t('comfort.title')} subText={t('comfort.subtext')}>
                <PlaceFacilityCheckboxes facilityType="PlaceFacility" />
              </FormSubPart>
              <Separator mb={20} />
              <FormSubPart subTitle={t('niceTouches.title')} subText={t('niceTouches.subtext')}>
                <PlaceFacilityCheckboxes facilityType="NiceTouch" />
              </FormSubPart>
              <Separator mb={20} />
            </FormPart>
            <FormPart title={t('utilities.title')}>
              <FormSubPart subTitle={t('utilities.subtitle')} subText={t('utilities.subtext')}>
                <PlaceFacilityCheckboxes facilityType="Utility" />
              </FormSubPart>
            </FormPart>
          </FormWrapper>
        </form>
      );
    }}
  />
);

AmenitiesForm.propTypes = {
  initialValues: PropTypes.object,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  exposeState: PropTypes.func.isRequired,
};

export default withNamespaces('listingForm')(AmenitiesForm);
