import { Text as TextRaw, Heading as HeadingRaw } from 'rebass';
import styled from 'styled-components';
import { fontMixin, fontSize, LIGHT } from '../../theme/styleUtils';

const Text = styled(TextRaw)`
  ${props => (props.fontFamily ? fontMixin(props.fontFamily) : fontMixin())}
  ${props => (props.fontSize ? fontSize(props.fontSize) : fontSize(16))}
  letter-spacing: 0.05em;
  color: ${props => props.theme.colors[props.color] || props.theme.colors.darkBrown};
`;

export const LinkText = styled(Text)`
  color: ${props => props.theme.colors.purplePrimary};
  text-decoration: underline;
  cursor: pointer;
`;

export const Heading = styled(HeadingRaw)`
  ${fontMixin(LIGHT)}
  ${fontSize(22)}
  font-weight: 400;
  color: ${props => props.theme.colors.darkBrown};
`;

export default Text;
