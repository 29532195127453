import noop from 'lodash/noop';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import { CREATE_OR_UPDATE_PLACE_SCRIPT } from '../../../graphQL/mutations/createOrUpdatePlace';
import { GET_PLACE_MEDIA } from '../../../graphQL/queries/getPlace';
import Footer from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';
import MediaForm from './forms/MediaForm';
import VirtualTour from './media/VirtualTour';
import { FormWrapper } from '../../common/form/FormWrapper';
import { Separator } from '../../common/form/Separator';
import { mapApiErrors } from '../../../utils/errorHandling';
import { addAndEditNewRoom } from './forms/BedsDashboard';
import { CREATE_ROOM } from '../../../graphQL/mutations/room';
import { isUnitNameStudio } from './forms/basics/PropertyPart';
import { NarrowLayout } from '../../common/Layout';
import { isPlaceDone } from './utils';

class Media extends Component {
  state = {
    submitting: false,
  };

  onNext = noop;

  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.LISTINGS.PLACE_AMENITIES(match.params.id));
  };

  onSubmit = async (values) => {
    try {
      const {
        mutate, match, history, createRoom,
      } = this.props;
      const { data, errors } = await mutate({
        variables: {
          ...values,
          id: match.params.id,
        },
      });
      if (errors) {
        return mapApiErrors(errors);
      }

      if (isPlaceDone(data)) {
        return history.push(APP_ROUTES.LISTINGS.PLACE_INTRO(data.createOrUpdatePlace.id));
      }

      const listingTypeCode = get(data, 'createOrUpdatePlace.listingType.code');
      const unitTypeName = get(data, 'createOrUpdatePlace.unitType.name');
      if (isUnitNameStudio(unitTypeName)) {
        const roomsCount = get(data, 'createOrUpdatePlace.rooms.length');
        if (roomsCount === 0) {
          addAndEditNewRoom({ createRoom, placeId: match.params.id, history });
        } else {
          const studioRoomId = get(data, 'createOrUpdatePlace.rooms.0.id');
          history.push(APP_ROUTES.LISTINGS.BEDS_AND_BATHS_BED(studioRoomId));
        }
      } else {
        const link = listingTypeCode === 'entire_place' ?
          APP_ROUTES.LISTINGS.PLACE_BEDS_AND_BATHS(data.createOrUpdatePlace.id) :
          APP_ROUTES.LISTINGS.PLACE_INTRO(data.createOrUpdatePlace.id);

        history.push(link);
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <NarrowLayout>
        <WizardStep query={GET_PLACE_MEDIA}>
          {({ data }) => (
            <FormWrapper>
              <MediaForm
                title={data.place.title}
                script={data.place.script}
                onNext={(onNext) => {
                  this.onNext = onNext;
                }}
                onSubmit={this.onSubmit}
                exposeState={({ submitting }) => this.setState({ submitting })}
              />
              <Separator mt={30} />
              <VirtualTour virtualTourUrl={data.place.virtualTourUrl || 'https://livetour.istaging.com/c951a5af-603f-4003-9d1c-707657febe95'} />
            </FormWrapper>
          )}
        </WizardStep>
        <Footer
          onPrev={this.onPrev}
          onNext={() => this.onNext()}
          disabledNext={this.state.submitting}
          isPlace
        />
      </NarrowLayout>
    );
  }
}

Media.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  graphql(CREATE_OR_UPDATE_PLACE_SCRIPT),
  graphql(CREATE_ROOM, { name: 'createRoom' }),
);

export default enhance(Media);
