import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';
import { FONT_WEIGHTS } from '../../../theme/globalStyle';

const RenderIfNotEmpty = ({
  name, t, array, children, message,
}) => {
  if (isEmpty(array)) {
    return (message || name) ? (
      <Text fontSize={25} fontWeight={FONT_WEIGHTS.NORMAL} mt={50}>
        {
          message || (
            <>
              {t('common.youHaveNoItems')}
              <span style={{ textTransform: 'lowercase' }}>{` ${name}`}</span>
              .
            </>
          )
        }
      </Text>
    ) : null;
  }
  return children;
};


RenderIfNotEmpty.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
  t: PropTypes.func.isRequired,
  array: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default withNamespaces('account')(RenderIfNotEmpty);
