import gql from 'graphql-tag';

export const GET_PLACE_BASICS = gql`
 query GetPlaceBasics($id: ID!) {
    place(id: $id) {
      id
      location
      latitude
      longitude
      geolocationData
      listingType {
        id
        code
      }
      buildingTypeId
      unitTypeId
      genderPreferenceId
      ownerLivesIn
      tenantAllowedCount
      bedroomsCount
      bathroomsCount
      authorisationConfirmed
      legalOwner {
        id
        type
        companyName
        registeredCountry
        registeredNumber
        firstName
        lastName
        authorisation
      }
    }
    listingTypes {
      id
      code
    }
  }
`;

export const GET_PLACE_PHOTOS = gql`
  query GetPlacePhotos($id: ID!) {
    place(id: $id) {
      id
      photos {
        id
        cover
        photo
        position
      }
    }
  }
`;

export const GET_PLACE_LISTING_TYPE_CODE = gql`
  query GetPlaceListingTypeCode($id: ID!) {
    place(id: $id) {
      id
      filledStep
      virtualTourBookedAt
      listingType {
        id
        code
      }
    }
  }
`;

export const GET_PLACE_FOR_FOOTER = gql`
  query GetPlaceForFooter($id: ID!) {
    place(id: $id) {
      id
      filledStep
      listingType {
        id
        code
      }
      virtualTourBookedAt
    }
  }
`;

export const GET_PLACE_AMENITIES = gql`
  query GetPlaceAmenities($id: ID!) {
    place(id: $id) {
      id
      filledStep
      listingType {
        id
        code
      }
      chosenFacilities {
        id
        facilityId
      }
    }
  }
`;

export const GET_PLACE_MEDIA = gql`
  query GetPlaceMedia($id: ID!) {
    place(id: $id) {
      id
      script
      title
      virtualTourUrl
    }
  }
`;

export const GET_PLACE_ROOMS = gql`
  query GetPlaceRooms($id: ID!){
    place(id: $id) {
      id
      unitType { id name }
      virtualTourBookedAt
      virtualTourBookedNote
      listingType {
        id
        code
      }
      rooms {
        id
        bedKind {
          id
          name
          type
        }
        bathKind {
          id
          name
          type
        }
        name
        roomNiceTouches {
          id
          name
          type
        }
        roomFacilities {
          id
          name
          type
        }
        photos {
          id
          cover
          photo
          position
        }
        monthlyRentalPriceValues {
          id
          value
        }
      }
      photos {
          id
          cover
          photo
          position
        }
    }
  }
`;

export const GET_PLACE_FILLED_STEP = gql`
  query GetPlaceStep($id: ID!) {
    place(id: $id) {
      id
      filledStep
      listingType {
        id
        code
      }
    }
  }
`;
