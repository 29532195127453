import { Box } from 'rebass';
import styled from 'styled-components';

const FixedContainer = styled(Box).attrs(props => ({
  bg: props.isModalOpened ? '#fff' : 'transparent',
}))`
  position: sticky;
  top: ${props => props.top || '0'};
  margin-bottom: -80px;
  width: 100%;
  z-index: 1;
`;

export default FixedContainer;
