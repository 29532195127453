import difference from 'lodash/difference';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import { Form, FormSpy } from 'react-final-form';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { GET_ALL_UNIT_TYPES, GET_LISTING_TYPES, GET_UNIT_TYPES } from '../../graphQL/queries/place';
import { fontSize } from '../../theme/styleUtils';
import Error from '../common/Error';
import Checkbox from '../common/form/Checkbox';
import Dropdown from '../common/form/Dropdown';
import Loading from '../common/Loading';
import { FilledRoundedButton } from '../common/wizard/Footer';

const BEDROOM_NUMBERS = [
  { value: 'studio', label: 'Studio' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6+', label: '6+' },
];

const FilterFormPartText = styled(Text)`
  ${fontSize(20)}
`;

const FiltersFormPart = ({ title, children }) => (
  <Flex flexDirection="column" alignItems="flex-start" mb={20}>
    <FilterFormPartText mb={20}>
      {title}
    </FilterFormPartText>
    {children}
  </Flex>
);

FiltersFormPart.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

const FiltersCheckbox = props => (
  <Box mb={10}>
    <Checkbox {...props} />
  </Box>
);

export const LISTING_TYPE_LABELS = {
  co_living: 'Private room in a shared place',
  entire_place: 'Entire place',
};

const FiltersForm = ({ initialValues, onSubmit }) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({
      handleSubmit, form: { getState }, values, submitting,
    }) => {
      const getUnitTypesByListing = get(getState(), 'values.type.length') === 1;
      return (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Flex flexDirection="column" width={1} alignItems="flex-start">
            <Query query={GET_LISTING_TYPES}>
              {({ loading, error, data }) => {
                if (loading) return <Loading />;
                if (error) return <Error />;
                const listingTypes = get(data, 'listingTypes');
                return (
                  <>
                    <FiltersFormPart title="The Place">
                      { map(listingTypes, listingType => (
                        <FiltersCheckbox
                          key={`listingType-${listingType.id}`}
                          variant="bigTick"
                          name="type"
                          value={listingType.code}
                          label={LISTING_TYPE_LABELS[listingType.code]}
                        />
                      ))}
                    </FiltersFormPart>
                    <Query
                      query={getUnitTypesByListing ? GET_UNIT_TYPES : GET_ALL_UNIT_TYPES}
                      variables={getUnitTypesByListing
                        ? {
                          listingTypeId: get(find(listingTypes, { code: get(getState(), 'values.type.0') }), 'id'),
                        }
                        : undefined
                      }
                    >
                      {({ loading: ll, error: err, data: unitData }) => {
                        if (ll) return <Loading />;
                        if (err) return <Error error={err} />;
                        return (
                          <FormSpy subscription={{ values: true }}>
                            {({ form: { change } }) => {
                              const buildingNames = get(unitData, 'unitTypes').map(unit => unit.name);
                              const formBuildingValues = get(values, 'building', []);
                              const diff = difference(formBuildingValues, buildingNames);

                              if (diff.length > 0) {
                                const newBuildingValues = buildingNames.filter(building => formBuildingValues.includes(building));
                                change('building', newBuildingValues);
                              }
                              return (
                                <FiltersFormPart title="Building type">
                                  { map(get(unitData, 'unitTypes'), unit => (
                                    <FiltersCheckbox key={`unitType=${unit.id}`} variant="bigTick" name="building" value={unit.name} label={unit.name} />
                                  ))}
                                </FiltersFormPart>
                              );
                            }}
                          </FormSpy>
                        );
                      }}
                    </Query>
                  </>
                );
              }}
            </Query>

            <FiltersFormPart title="Total number of bedrooms">
              <Flex flexWrap="wrap">
                <Box width={240} mr={100} mb={20}>
                  <Dropdown name="bedroomsMinNumber" options={BEDROOM_NUMBERS} label="Min" />
                </Box>
                <Box width={240}>
                  <Dropdown name="bedroomsMaxNumber" options={BEDROOM_NUMBERS} label="Max" />
                </Box>
              </Flex>
            </FiltersFormPart>

            <FiltersFormPart title="Privacy">
              <FiltersCheckbox variant="bigTick" name="privateBathroom" label="Private bathroom wanted" />
            </FiltersFormPart>

            <Flex justifyContent="center" mb={20}>
              <FilledRoundedButton px={20} py={10} onClick={handleSubmit} disabled={submitting}>Update</FilledRoundedButton>
            </Flex>
          </Flex>
        </form>
      );
    }}
  />
);

FiltersForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default FiltersForm;
