import moment from 'moment';
import { DEFAULT_BOOKING_WINDOW } from '../constants';

const MAX_AGE = 90;
const MAX_YEAR = moment().year() - 17;

export const days = () => [...Array(31).keys()].map(day => ({
  label: day + 1,
  value: day + 1,
}));

export const months = () => moment.months().map((month, idx) => ({
  label: month,
  value: idx + 1,
}));

export const years = () => [...Array(MAX_AGE).keys()].map(year => ({
  label: MAX_YEAR - MAX_AGE + year,
  value: MAX_YEAR - MAX_AGE + year,
}));

export const isBookingDateAvailable = (dateFrom, availableFrom, startDateWindow) => moment(dateFrom).isBefore(moment().subtract(1, 'day').add(DEFAULT_BOOKING_WINDOW, 'weeks')) &&
  moment(dateFrom).isBefore(moment(availableFrom).subtract(1, 'day').add(startDateWindow, 'weeks'));
