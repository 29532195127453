import gql from 'graphql-tag';

export const GET_OFFERED_OBJECT_PRICES = gql`
  query GetOfferedObjectPrices(
    $dateFrom: ISO8601DateTime
    $indexableId: ID!
    $leaseLength: Int!
    $objectType: String!
  ) {
    offeredObjectPrices(
      dateFrom: $dateFrom
      indexableId: $indexableId
      leaseLength: $leaseLength
      objectType: $objectType
    ) {
      monthlyRentValue
      monthlyUtilitiesValue
      monthlyTotal
      offeredObject {
        documentId
        objectAddress
        objectType
        availablePrices
        availableFrom
        blockOuts {
          id
          startDate
          endDate
        }
        leaseBlockOuts {
          id
          startDate
          endDate
        }
        startDateWindow
      }
    }
  }
`;
