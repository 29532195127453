import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import get from 'lodash/get';
import find from 'lodash/find';
import concat from 'lodash/concat';
import Layout from '../common/Layout';
import SearchForm from './SearchForm';
import coerceNumbers from '../../utils/coerceNumbers';

export const SEARCH_BAR_DEFAULT_VALUES = {
  leaseLength: 12,
  dateFrom: moment().add(2, 'days').format(),
  bedroomsMinNumber: '1',
  bedroomsMaxNumber: '6+',
  city: 'London',
  district: 'any',
};

class SearchBar extends Component {
  onSubmit = (values) => {
    const { history, location: { search } } = this.props;
    const [minPrice, maxPrice] = get(values, 'priceRange', []);
    const selectedPropertyTypes = get(values, 'type', []);

    let valuesToOmit = ['priceRange', 'type'];
    const queryParams = queryString.parse(search);
    if (+queryParams.leaseLength !== +values.leaseLength) {
      valuesToOmit = concat(valuesToOmit, 'minPrice', 'maxPrice');
    }

    const mappedValues = omit({
      ...values,
      minPrice,
      maxPrice,
    }, valuesToOmit);

    if (selectedPropertyTypes.length === 1) {
      const [type] = selectedPropertyTypes;
      mappedValues.type = type;
    }

    history.push(`/listings?${queryString.stringify(mappedValues)}`);
  };

  getInitialValues = (priceRangeDomain) => {
    const { search } = this.props.location;
    const defaultValues = SEARCH_BAR_DEFAULT_VALUES;

    const [defaultMinPrice, deafultMaxPrice] = priceRangeDomain;

    const searchValues = coerceNumbers(queryString.parse(search));
    const {
      bedroomsMinNumber, bedroomsMaxNumber, minPrice, maxPrice, type, building,
    } = searchValues;

    const initialValues = {
      ...defaultValues,
      ...searchValues,
      priceRange: [minPrice || defaultMinPrice, maxPrice || deafultMaxPrice],
      type: type && [type],
      bedroomsMinNumber: bedroomsMinNumber ? `${bedroomsMinNumber}` : defaultValues.bedroomsMinNumber,
      bedroomsMaxNumber: bedroomsMaxNumber ? `${bedroomsMaxNumber}` : defaultValues.bedroomsMaxNumber,
    };

    if (building) {
      initialValues.building = isArray(building) ? building : [building];
    }

    return initialValues;
  };

  render() {
    const { aggregations } = this.props;
    const priceRangeDomain = [
      +get(find(aggregations, { name: 'min_price' }), 'value'),
      +get(find(aggregations, { name: 'max_price' }), 'value'),
    ];
    return (
      <Layout>
        <SearchForm
          initialValues={this.getInitialValues(priceRangeDomain)}
          onSubmit={this.onSubmit}
          priceRangeDomain={priceRangeDomain}
        />
      </Layout>
    );
  }
}

SearchBar.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  aggregations: PropTypes.array.isRequired,
};

export default withRouter(SearchBar);
