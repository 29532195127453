import get from 'lodash/get';
import join from 'lodash/join';
import map from 'lodash/map';
import max from 'lodash/max';
import min from 'lodash/min';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import { compose } from 'recompose';
import styled from 'styled-components';

import bedroomPlaceholder from '../../../assets/img/bedroom-placeholder.png';
import { APP_ROUTES } from '../../../constants';
import { DELETE_ROOM } from '../../../graphQL/mutations/room';
import { GET_PLACE_ROOMS } from '../../../graphQL/queries/getPlace';
import { BOLD, fontMixin, fontSize } from '../../../theme/styleUtils';
import Caption from '../../common/text/Caption';
import Title from '../../common/text/Title';
import { FilledRoundedButton, RoundedButton } from '../../common/wizard/Footer';

export const BedInfoTitle = styled(Text)`
  ${fontMixin(BOLD)}
  ${fontSize(18)}
`;

const BedInfoText = styled(Text)`
  ${fontSize(14)}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
`;

const BedInfoPart = ({
  title, text, children, width,
}) => (
  <Flex flexDirection="column" justifyContent="space-between" width={width || 1 / 4} py={13}>
    <BedInfoTitle>{title}</BedInfoTitle>
    <BedInfoText>{children || text}</BedInfoText>
  </Flex>
);

BedInfoPart.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.number,
};

export const BedWrapper = styled(Flex)`
  border-bottom: 1px solid #000;
  position: relative;
`;

const DeleteButton = styled.a`
  ${fontMixin(BOLD)};
  ${fontSize(17)};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  user-select: none;
`;

export const BedImage = styled(Box).attrs({
  width: [100, 150, 150],
})`
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center center;
    height: 150px;
`;

const ChangeButton = styled(RoundedButton).attrs({
  py: ['5px', '10px', '10px'],
  px: [15, 30, 30],
})``;

const BedBathPricing = styled(FilledRoundedButton)`
  padding: 6px 30px;
`;

const RoomSublink = styled(Link).attrs(props => ({
  to: props.disabled ? '#' : props.to,
}))`
  ${fontSize(16)}
  color: ${props => (props.disabled ? props.theme.colors.lightGray : props.theme.colors.purplePrimary)};
  cursor: ${props => props.disabled && 'not-allowed'};
  text-decoration: none;
  margin-right: 30px;
`;

const ListLink = styled(Link)`
  ${fontSize(14)}
  color: ${props => props.theme.colors.orangeRed};
  text-decoration: underline;
`;

const AddInfoText = styled(Text)`
  ${fontSize(14)}
  color: ${props => props.theme.colors.darkBrown};
  position: absolute;
  bottom: 30px;
`;

const currentBedroomStatus = (room) => {
  const hasBedBath = room.bathKind && room.bedKind;
  const isComplete = room.photos.length > 0 && (room.roomFacilities.length > 0 || room.roomNiceTouches.length > 0);
  return !hasBedBath ? 'incomplete' : isComplete ? 'complete' : 'nearlyComplete';
};

class BedList extends Component {
  _renderCoLivingBedInfo = ({ room }) => {
    const { t, editRoom } = this.props;
    const priceValues = map(room.monthlyRentalPriceValues, price => price.value);
    const minPrice = min(priceValues);
    const maxPrice = max(priceValues);
    return (
      <>
        <Flex justifyContent="space-between" width={7 / 10} mr={0}>
          <BedInfoPart title={t('bedrooms.list.status')} width={1 / 7}>
            <ListLink to="#">{room.bedKind && t(`bedrooms.list.listed.${room.live ? 'yes' : 'no'}`)}</ListLink>
          </BedInfoPart>
          <BedInfoPart title={t('bedrooms.list.bed')} text={get(room, 'bedKind.name')} />
          <BedInfoPart title={t('bedrooms.list.bath')} text={get(room, 'bathKind.name')} />
          <BedInfoPart title={t('bedrooms.list.pricing')}>
            {priceValues.length > 0 && `£${minPrice} to £${maxPrice}`}
          </BedInfoPart>
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          <BedBathPricing onClick={() => editRoom(room.id)}>{t('bedrooms.list.bedBathPricing')}</BedBathPricing>
        </Flex>
      </>
    );
  }

  _renderEntirePlaceBedInfo = ({ room }) => {
    const { t, editRoom } = this.props;
    return (
      <>
        <Flex justifyContent="space-between" flexWrap="wrap" width={1}>
          <BedInfoPart title={t('bedrooms.list.bed')} width={[1 / 2, 1 / 5, 1 / 5]} text={get(room, 'bedKind.name')} />
          <BedInfoPart title={t('bedrooms.list.bath')} width={[1 / 2, 1 / 5, 1 / 5]} text={get(room, 'bathKind.name')} />
          <BedInfoPart
            width={[1 / 2, 1 / 5, 1 / 5]}
            title={t('bedrooms.list.amenities')}
            text={join(get(room, 'roomFacilities', []).map(f => f.name), ', ')}
          />
          <BedInfoPart
            width={[1 / 2, 1 / 5, 1 / 5]}
            title={t('bedrooms.list.niceTouches')}
            text={join(get(room, 'roomNiceTouches', []).map(f => f.name), ', ')}
          />
          <Flex flexDirection="column" justifyContent="center">
            <ChangeButton onClick={() => editRoom(room.id)}>{t('bedrooms.list.change')}</ChangeButton>
          </Flex>
        </Flex>
      </>
    );
  }

  render = () => {
    const {
      t, rooms, listingType, placeId, deleteRoom, match, virtualTourBookedAt,
    } = this.props;
    return (
      <>
        { map(rooms, (room, idx) => (
          <BedWrapper
            key={`bed-list-item-${room.id}`}
            py={25}
            mb={35}
          >
            <DeleteButton
              onClick={() => deleteRoom({
                variables: { roomId: room.id },
                refetchQueries: () => [{ query: GET_PLACE_ROOMS, variables: { id: match.params.id } }],
              })}
            >
              x
            </DeleteButton>
            <BedImage src={get(room, 'photos[0].photo') || bedroomPlaceholder} />
            <Flex flexDirection="column" justifyContent="space-between" pl={40} width={1}>
              <Box style={{ flex: 1 }}>
                <Title mb={0}>
                  {t(`${listingType === 'co_living' ? 'rooms' : 'bedrooms'}.list.bedroom`)}
                  &nbsp;
                  {idx + 1}
                </Title>
                { listingType === 'co_living' && (
                  <>
                    <RoomSublink disabled={!virtualTourBookedAt} to={APP_ROUTES.LISTINGS.PLACE_ROOMS_CALENDAR(placeId, room.id)}>
                      {t('bedrooms.list.calendar')}
                    </RoomSublink>
                    {false && (
                      <RoomSublink to={APP_ROUTES.LISTINGS.PLACE_BEDS_AND_BATHS(placeId)}>
                        {t('bedrooms.list.preview')}
                      </RoomSublink>
                    )}
                  </>
                )}
                {/* need isComplete flag from the api */}
                { listingType === 'entire_place' && (
                  <Caption>
                    {t(`bedrooms.list.${currentBedroomStatus(room)}`)}
                  </Caption>
                )}
              </Box>
              <Flex justifyContent="space-between" width={1} style={{ flex: 1 }}>
                { listingType === 'entire_place'
                  ? this._renderEntirePlaceBedInfo({ room })
                  : this._renderCoLivingBedInfo({ room })
                }
                {
                  !room.bedKind && <AddInfoText>{t('bedrooms.list.fillInfo')}</AddInfoText>
                }
              </Flex>
            </Flex>
          </BedWrapper>
        ))}
      </>
    );
  }
}

BedList.propTypes = {
  t: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  editRoom: PropTypes.func.isRequired,
  listingType: PropTypes.oneOf(['co_living', 'entire_place']).isRequired,
  placeId: PropTypes.string.isRequired,
  deleteRoom: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  virtualTourBookedAt: PropTypes.string,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(DELETE_ROOM, { name: 'deleteRoom' }),
);

export default enhance(BedList);
