import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import BookingBoxContainer from './BookingBoxContainer';
import Layout from '../common/Layout';

export const BookingsWrapper = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
  width: 1,
})``;

export const BookingsFormWrapper = styled(Flex)`
  flex-direction: column;
  align-items: left;
  margin: 0px;
  margin-right: 40px;
  margin-bottom: 40px;
  width: 100%;
`;

const BookingsLayout = ({ navBar: NavBar, children, ...props }) => (
  <Fragment>
    <NavBar />
    <Layout>
      <BookingsWrapper>
        <BookingsFormWrapper>
          {children}
        </BookingsFormWrapper>
        <Box mb={20}>
          <BookingBoxContainer match={props.match} />
        </Box>
      </BookingsWrapper>
    </Layout>
  </Fragment>
);


BookingsLayout.propTypes = {
  children: PropTypes.element,
  match: PropTypes.object.isRequired,
  navBar: PropTypes.object.isRequired,

};

export default BookingsLayout;
