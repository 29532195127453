import { Box } from 'rebass';
import styled from 'styled-components';

import { LAYOUT_PX_RWD } from '../../constants';

const Layout = styled(Box).attrs(() => ({
  px: LAYOUT_PX_RWD,
}))`
  width: 100%;
  margin: 20px auto;
  max-width: ${props => props.maxWidth};
`;

Layout.defaultProps = {
  // maxWidth: '1200px',
};

export const NarrowLayout = styled(Box)`
  width: 100%;
  max-width: ${props => props.maxWidth};
`;

NarrowLayout.defaultProps = {
  maxWidth: '900px',
};

export default Layout;
