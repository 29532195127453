import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet-async';
import moment from 'moment';
import { Flex, Text, Box } from 'rebass';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import TransactionsForm from './TransactionsForm';
import { formatPrice } from '../../../utils/prices';
import { fontSize } from '../../../theme/styleUtils';
import { NarrowLayout } from '../../common/Layout';

const Table = styled(Box)`
  border: 1px solid rgba(216, 223, 230, 1);
`;
const TableHead = styled(Flex)`
  background-color: rgba(249, 249, 249, 1);
  padding: 15px 20px;
`;
const TableData = styled(Flex)`
  padding: 20px;
  border-top: 1px solid rgba(186, 184, 184, 0.35);
`;

const TransactionsTitle = styled(Text)`
  ${fontSize(30)}
`;

const TRANSACTION_MOCK = [
  {
    date: moment()
      .subtract(11, 'months')
      .format(),
    transaction: 'Rent June 17 to July 16 2019 Wykeham Road Room 2',
    amount: 2000,
  },
  {
    date: moment()
      .subtract(11, 'years')
      .format(),
    transaction:
      'Rent June 17 to July 16 2019 Wykeham Road Room 2 nt June 17 to July 16 2019 Wykeham Road Room 2',
    amount: 300,
  },
];

const Transactions = ({ t, transactions, balance }) => (
  <NarrowLayout>
    <Flex flexDirection="column">
      <Helmet>
        <title>{t('transactions')}</title>
      </Helmet>
      <Flex mt={90} mb={50} alignItems="center">
        <TransactionsTitle mr={30}>
          {t('transactions')}
        </TransactionsTitle>
        <TransactionsForm onSubmit={() => {}} />
        <Text mr={80} ml="auto">
          {t('balance')}
        </Text>
        <Text>{formatPrice(balance)}</Text>
      </Flex>
      <Table>
        <Flex>
          <TableHead width={1 / 4}>{t('tableHead.date')}</TableHead>
          <TableHead width={1 / 2}>{t('tableHead.transaction')}</TableHead>
          <TableHead width={1 / 4}>{t('tableHead.amount')}</TableHead>
        </Flex>
        {transactions.map(({ transaction, date, amount }, i) => (
          <Flex key={`transaction-data-${i}`}>
            <TableData width={1 / 4}>
              {moment(date).format('MMMM DD YYYY')}
            </TableData>
            <TableData width={1 / 2}>{transaction}</TableData>
            <TableData width={1 / 4}>{formatPrice(amount)}</TableData>
          </Flex>
        ))}
      </Table>
    </Flex>
  </NarrowLayout>
);

Transactions.propTypes = {
  t: PropTypes.func.isRequired,
  balance: PropTypes.number,
  transactions: PropTypes.array,
};
Transactions.defaultProps = {
  transactions: TRANSACTION_MOCK,
  balance: 1000,
};

export default withNamespaces('transactions')(Transactions);
