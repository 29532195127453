import head from 'lodash/head';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field, FormSpy } from 'react-final-form';
import noop from 'lodash/noop';
import { Box } from 'rebass';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { fontSize } from '../../../theme/styleUtils';
import { theme, FONT_FAMILIES } from '../../../theme/globalStyle';
import dropdownIndicatorSign from '../../../assets/img/dropdown-indicator.svg';
import { ErrorBox } from './FieldError';
import Label from '../text/Label';

const styles = ({ fixedWidth }) => ({
  container: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? theme.colors.purplePrimary : theme.colors.pearlGray}`,
    ':hover': {
      borderColor: theme.colors.purplePrimary,
    },
    backgroundColor: '#fff',
    borderRadius: '5px',
    height: '37px',
    '>div:first-of-type': {
      height: '35px',
      minHeight: '35px',
    },
  }),
  singleValue: provided => ({
    ...provided,
    fontFamily: theme.fonts[FONT_FAMILIES.AVENIR_LIGHT],
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: theme.fonts[FONT_FAMILIES.AVENIR_LIGHT],
    fontSize: 15,
    fontWeight: state.isSelected ? 600 : 400,
  }),
  control: provided => ({ ...provided, boxShadow: 'none', border: 'none' }),
  menu: provided => ({ ...provided, width: fixedWidth || '100%' }),
  dropdownIndicator: provided => ({ ...provided, paddingLeft: 0 }),
});

const IndicatorWrapper = styled.img`
  transition: transform .25s ease-in-out;
  transform: rotate(${props => (props.menuIsOpen ? '0' : '180deg')}) scale(0.5);
  /* padding: 4px 8px;  */
`;

const DropdownWrapper = styled.div`
  ${fontSize(16)}
`;

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <IndicatorWrapper menuIsOpen={props.selectProps.menuIsOpen} src={dropdownIndicatorSign} />
    </components.DropdownIndicator>
  )
);

const prefixDropdownLabelFormatter = (option, labelMeta) => (labelMeta.context === 'menu' ? option.label : option.value);

const DropdownField = ({
  options, onChange, placeholder, label, variant, disabled, input, meta, defaultValue, fixedWidth,
}) => {
  useEffect(() => {
    if (defaultValue !== undefined) {
      input.onChange(defaultValue);
    } else if (!input.value) {
      input.onChange(head(options).value);
    }
  }, defaultValue !== undefined ? [defaultValue] : []);
  return (
    <Box width={1}>
      <input type="hidden" {...input} />
      { label && <Label>{label}</Label> }
      <DropdownWrapper>
        <FormSpy subscription={{}}>
          {({ form: { batch } }) => (
            <Select
              isDisabled={disabled}
              formatOptionLabel={variant === 'phonePrefix' ? prefixDropdownLabelFormatter : undefined}
              components={{ DropdownIndicator, IndicatorSeparator: () => null }}
              styles={styles({ fixedWidth })}
              options={options}
              placeholder={placeholder}
              value={options.find(o => o.value === input.value)}
              onChange={(selected) => {
                batch(() => {
                  input.onChange(selected.value);
                  onChange(selected);
                });
              }}
              theme={providedTheme => ({
                ...providedTheme,
                colors: {
                  ...providedTheme.colors,
                  primary: theme.colors.purplePrimary,
                },
              })}
            />
          )}
        </FormSpy>
      </DropdownWrapper>
      <ErrorBox meta={meta} />
    </Box>
  );
};

const Dropdown = props => (
  <Field name={props.name} component="input" validate={props.validate}>
    {({ input, meta }) => <DropdownField {...props} input={input} meta={meta} />}
  </Field>
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['default', 'phonePrefix']),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any,
  fixedWidth: PropTypes.string,
};

Dropdown.defaultProps = {
  validate: noop,
  onChange: noop,
  placeholder: '',
  variant: 'default',
  disabled: false,
};

export default Dropdown;
