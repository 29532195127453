import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Body from '../../common/text/Body';
import { DetailsSubtitle } from './DetailsFacilityPart';

const DetailsMoveOutPart = ({ t }) => (
  <>
    <DetailsSubtitle mb={20} mt={80}>{t('details.moveOut.title')}</DetailsSubtitle>
    <Body mt={20} width={0.9}>{t('details.moveOut.text')}</Body>
  </>
);

DetailsMoveOutPart.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listings')(DetailsMoveOutPart);
