import React from 'react';
import PropTypes from 'prop-types';
import { Text, Flex } from 'rebass';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import styled from 'styled-components';
import { fontMixin, fontSize, THICK } from '../../theme/styleUtils';

const ErrorText = styled(Text)`
  ${fontMixin(THICK)}
  ${fontSize(14)}
  border-radius: 3px;
`;

const FieldErrorMessage = ({
  errors,
  apiErrors,
  flexProps,
  field,
  path,
}) => {
  const gQlErrors = get(apiErrors, 'graphQLErrors', []);
  const networkErrors = get(apiErrors, 'networkError.errors', []);

  const t3 = map(gQlErrors, (e) => {
    if (isEmpty(path) || path === get(e.path, '[0]', null)) {
      if (isEmpty(field) || e.attribute === field) {
        return [e.attribute, e.message];
      }
    }
    return null;
  });
  const allErrors = [...t3];
  if (errors[field]) {
    allErrors.push([field, errors[field]]);
  }
  if (networkErrors[field]) {
    allErrors.push([field, networkErrors[field]]);
  }

  if (isEmpty(allErrors)) return null;

  return (
    <Flex {...flexProps}>
      {map(allErrors, (errorArr) => {
        if (errorArr) {
          return (
            <ErrorText
              key={errorArr[1]}
            >
              {field && errorArr[1]}
              {!field && `${errorArr[0]} : ${errorArr[1]}`}
            </ErrorText>
          );
        }
        return null;
      })}
    </Flex>
  );
};

FieldErrorMessage.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  apiErrors: PropTypes.object,
  id: PropTypes.string,
  negative: PropTypes.bool,
  flexProps: PropTypes.object,
  field: PropTypes.string,
  path: PropTypes.string,
};

FieldErrorMessage.defaultProps = {
  errors: {},
  apiErrors: {},
  id: undefined,
  negative: false,
  flexProps: null,
  field: null,
  path: null,
};

export default FieldErrorMessage;
