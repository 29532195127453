import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { Flex, Box, Text } from 'rebass';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import Body from '../../../common/text/Body';
import Subtitle from '../../../common/text/Subtitle';

import BedList from '../BedList';
import { Separator } from '../../../common/form/Separator';
import { FormPart } from '../../../common/form/FormWrapper';
import { RoundedButton } from '../../../common/wizard/Footer';
import { CREATE_ROOM } from '../../../../graphQL/mutations/room';
import { GET_PLACE_ROOMS } from '../../../../graphQL/queries/getPlace';
import { APP_ROUTES } from '../../../../constants';
import { isUnitNameStudio } from './basics/PropertyPart';
import { UPDATE_PLACE_BOOK_MY_VIRTUAL_TOUR } from '../../../../graphQL/mutations/createOrUpdatePlace';

const AddBedroom = styled(RoundedButton)`
  padding: 7px 74px;
  align-self: flex-end;
`;

export const addAndEditNewRoom = async ({ createRoom, placeId, history }) => {
  try {
    const { data } = await createRoom({
      refetchQueries: [{ query: GET_PLACE_ROOMS, variables: { id: placeId } }],
      variables: { placeId },
    });
    history.push(APP_ROUTES.LISTINGS.BEDS_AND_BATHS_BED(data.createOrUpdateRoom.id));
  } catch (e) {
    console.log(e);
  }
};

class BedsDashboard extends Component {
  static defaultProps = {
    data: {},
  };

  static propTypes = {
    createRoom: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    data: PropTypes.object,
  };

  state = {
  };

  constructor(props) {
    super(props);
    const { data, history } = props;

    let stateObj = {};
    if (isUnitNameStudio(get(data, 'place.unitType.name'))) {
      history.replace(APP_ROUTES.LISTINGS.BEDS_AND_BATHS_BED(get(data, 'place.rooms.0.id')));
    } else {
      stateObj = {
        checkedForStudio: true,
      };
    }
    this.state = stateObj;
  }

  _isVirtualTourBooked = () => get(this.props.data, 'place.virtualTourBookedAt') && get(this.props.data, 'place.virtualTourBookedNote');

  componentDidMount = () => {
    const {
      data: { place }, createRoom, match, history,
    } = this.props;
    if (place && place.rooms.length === 0) {
      addAndEditNewRoom({ createRoom, placeId: match.params.id, history });
    }
  }

  render = () => {
    const {
      t,
      data,
      history,
      createRoom,
      match,
    } = this.props;
    const { checkedForStudio } = this.state;
    const listingType = get(data, 'place.listingType.code');
    if (checkedForStudio) {
      return (
        <Flex justifyContent="center" mb={40}>
          <Flex flexDirection="column" alignItems="left" width={1}>
            <FormPart title={t(`${listingType === 'co_living' ? 'rooms' : 'bedrooms'}.title`)}>
              <Subtitle>{t('bedrooms.number')}</Subtitle>
              <Body>
                {t('bedrooms.subNumber')}
              </Body>
            </FormPart>
            <Separator mt={30} mb={10} color="heavilyTransparentGray" opaque />
            <BedList
              rooms={get(data, 'place.rooms')}
              listingType={listingType}
              editRoom={id => history.push(APP_ROUTES.LISTINGS.BEDS_AND_BATHS_BED(id))}
              placeId={get(data, 'place.id')}
              virtualTourBookedAt={get(data, 'place.virtualTourBookedAt')}

            />
            <AddBedroom onClick={() => addAndEditNewRoom({ createRoom, placeId: match.params.id, history })}>{t('bedrooms.add')}</AddBedroom>
            { this._isVirtualTourBooked() && (
              <Flex justifyContent="center">
                <Box>
                  <Text>
                    { `booked: ${moment(get(data, 'place.virtualTourBookedAt')).format('lll')}` }
                  </Text>
                  <Text>
                    { `note: ${get(data, 'place.virtualTourBookedNote')}` }
                  </Text>
                </Box>
              </Flex>

            )}
          </Flex>
        </Flex>
      );
    }
    return null;
  };
}

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(CREATE_ROOM, { name: 'createRoom' }),
  graphql(UPDATE_PLACE_BOOK_MY_VIRTUAL_TOUR, { name: 'updatePlaceBookMyVirtualTour' }),
);

export default enhance(BedsDashboard);
