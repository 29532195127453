import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { graphql } from 'react-apollo';
import { OnChange } from 'react-final-form-listeners';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../../../constants';
import { DESTROY_PLACE } from '../../../../../graphQL/mutations/createOrUpdatePlace';
import FieldError from '../../../../common/form/FieldError';
import Radio from '../../../../common/form/Radio';
import Loading from '../../../../common/Loading';
import Modal from '../../../../common/Modal';
import Body from '../../../../common/text/Body';
import Title from '../../../../common/text/Title';
import { FilledRoundedButton } from '../../../../common/wizard/Footer';

const ListingTypePart = (({
  loading, data, form: { change, batch, getState }, setDefaultUnitTypeInitialized, t, listingTypeId, match, history, mutate,
}) => {
  const [typeInitialized, setTypeInitialized] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [previousListingTypeId, setPreviousListingTypeId] = useState(null);

  useEffect(() => {
    if (!listingTypeId && !typeInitialized) {
      const defaultListingTypeId = get(find(data.listingTypes, ['code', 'entire_place']), 'id');
      if (defaultListingTypeId) {
        change('listingTypeId', defaultListingTypeId);
        setTypeInitialized(true);
        setDefaultUnitTypeInitialized(false);
      }
    }
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <Box width="100%">
      <FieldError direction="column" name="listingTypeId">
        {map(data.listingTypes, l => (
          <Radio
            name="listingTypeId"
            key={`listingTypeId-${l.id}`}
            label={l.name}
            value={l.id}
            subLabel={t(`listingType.subLabels.${l.code}`)}
            mb={20}
          />
        ))}
      </FieldError>
      <OnChange name="listingTypeId">
        {(value, previous) => {
          batch(() => {
            change('unitTypeId', null);
          });
          if (match.params.id) {
            setIsModalOpened(true);
          }
          setPreviousListingTypeId(previous);
          setDefaultUnitTypeInitialized(false);
        }}
      </OnChange>
      {isModalOpened && (
        <Modal>
          <Flex p={30} justifyContent="center" alignItems="center" flexDirection="column" bg="white">
            <Title mb={15}>Warning</Title>
            <Body mb={15}>This action will erase all saved listing settings</Body>
            <Flex alignItems="center">
              <FilledRoundedButton
                onClick={async () => {
                  await change('listingTypeId', previousListingTypeId);
                  setIsModalOpened(false);
                }}
                mr={30}
              >
                Cancel
              </FilledRoundedButton>
              <FilledRoundedButton
                onClick={async () => {
                  const selectedListingTypeId = get(getState(), 'values.listingTypeId');
                  const { errors } = await mutate({ variables: { placeId: match.params.id } });

                  if (errors) {
                    setIsModalOpened(false);
                    return;
                  }

                  await history.push(`${APP_ROUTES.LISTINGS.PLACE_BASICS()}?listingTypeId=${selectedListingTypeId}`);
                  setIsModalOpened(false);
                }}
              >
                Confirm
              </FilledRoundedButton>
            </Flex>
          </Flex>
        </Modal>
      )}
    </Box>
  );
});

ListingTypePart.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  form: PropTypes.object,
  setDefaultUnitTypeInitialized: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  listingTypeId: PropTypes.string,
  mutate: PropTypes.func.isRequired,
};

const enhance = compose(
  graphql(DESTROY_PLACE),
  withRouter,
);

export default enhance(ListingTypePart);
