import gql from 'graphql-tag';

export const GET_LISTING_TYPES = gql`
  query GetListingTypes {
    listingTypes {
      id
      name
      description
      code
    }
  }
`;

export const GET_UNIT_TYPES = gql`
  query GetUnitTypes($listingTypeId: ID!) {
    unitTypes(listingTypeId: $listingTypeId) {
      id
      name
      default
    }
  }
`;

export const GET_ALL_UNIT_TYPES = gql`
  query GetAllUnitTypes {
    unitTypes {
      id
      name
    }
  }
`;

export const GET_BUILDING_TYPES = gql`
  query GetBuildingTypes($unitTypeId: ID!) {
    buildingTypes(unitTypeId: $unitTypeId) {
      id
      name
      default
    }
  }
`;

export const GET_GENDER_PREFERENCES = gql`
  query GetGenderPreferences {
    genderPreferences {
      id
      name
    }
  }
`;

export const GET_PLACE_PRICES = gql`
  query GetPlacePrices($id: ID!) {
    place(id: $id) {
      id
      monthlyRentalPriceValues {
        id
        monthlyRentalPriceId
        placeId
        value
      }
      discount
      promotionMonths
      yieldCalculator
      monthlyUtilityPrice
    }
  }
`;

export const GET_PLACE_BOOKING_RULES = gql`
  query GetPlaceBookingRules($id: ID!) {
    place(id: $id) {
      id
      genderPreferenceId
      preparationTime
      startDateWindow
      notes
      moveOutGuarantee
      listingType {
        id
        code
      }
      tenantTypes {
        id
      }
      houseRules {
        id
      }
      requiredContactAvailableIds
      contactAvailables {
        id
      }
      contactAvailableFirstName
      contactAvailableLastName
      contactAvailableEmail
      contactAvailablePhone
      requiredDepositOptionIds
      depositOptions {
        id
      }
      backgroundChecks {
        id
        name
      }
    }
  }
`;

export const GET_BACKGROUND_CHECKS = gql`
  query GetBackgroundChecks($ukGuarantator: String) {
    backgroundChecks(ukGuarantator: $ukGuarantator) {
      id
      name
      description
      code
    }
  }
`;

export const GET_PLACE_BLOCK_OUTS = gql`
  query GetPlaceBlockOuts($id: ID!) {
    place(id: $id) {
      id
      blockOuts {
        id
        startDate
        endDate
      }
    }
  }
`;

export const GET_PLACE_LIVE = gql`
  query GetPlaceLive($id: ID!) {
    place(id: $id) {
      id
      live
      virtualTourUrl
    }
  }
`;

export const GET_ALL_PLACE_DATA = gql`
  fragment PlaceBasicsPart on Place {
    id
    listingType {
      id
      code
    }
    unitType {
      id
      name
    }
    buildingType {
      id
      name
    }
    tenantAllowedCount
    legalOwner {
      type
      id
    }
    listingType {
      id
      name
      code
    }
    location
    bedroomsCount
    ownerLivesIn
  }

  fragment amenitiesPart on Place {
    id
    chosenFacilities {
      id
      facilityId
    }
  }

  fragment PlaceMediaPart on Place {
    id
    photos {
      id
    }
    script
    virtualTourUrl
  }

  fragment PlaceBedsAndBathsPart on Place {
    id
    bathroomsCount
    rooms {
      id
      photos {
        id
      }
      roomFacilities {
        id
      }
      roomNiceTouches {
        id
      }
    }
  }

  fragment bookingRulesPart on Place {
    id
    tenantTypes {
      id
      name
    }
    startDateWindow
    depositOptions {
      id
      name
    }
    houseRules {
      id
      name
    }
    backgroundChecks {
      id
      name
    }
    genderPreference {
      id
      name
    }
    preparationTime
    moveOutGuarantee
    notes
  }

  fragment pricingPart on Place {
    id
    discount
    promotionMonths
    monthlyUtilityPrice
    monthlyRentalPriceValues {
      id
      value
    }
  }

  query GetPlaceData($id: ID!) {
    place(id: $id) {
      id
      ...PlaceBasicsPart
      ...amenitiesPart
      ...PlaceMediaPart
      ...PlaceBedsAndBathsPart
      ...bookingRulesPart
      ...pricingPart
      virtualTourBookedAt
      availableComfort: placeFacilities {
        id
        name
        description
      }
      availableNiceTouches: niceTouches {
        id
        name
        description
      }
      availableUtilities: utilities {
        id
        name
        description
      }
    }
  }
`;

export const GET_PLACE_DETAILS_PHOTOS = gql`
  query GetPlaceDetailsPhotos($id: ID!) {
    place(id: $id) {
      id
      rooms {
        id
        photos {
          id
          photo(version: details)
        }
        roomFacilities {
          id
          name
        }
        roomNiceTouches {
          id
          name
        }
      }
    }
  }
`;

export const GET_PLACE_DETAILS = gql`
  fragment basicsPart on Place {
    id
    virtualTourUrl
    tenants {
      id
      profileAvailable
      fullName
      firstName
      lastName
      jobTitle
      description
    }
    downrootsApproved
    title
    latitude
    longitude
    moveOutGuarantee
    listingType {
      id
      code
    }
    unitType {
      id
      name
    }
    buildingType {
      id
      name
    }
    tenantAllowedCount
    legalOwner {
      type
      id
    }
    listingType {
      id
      name
      code
    }
    location
    bedroomsCount
    ownerLivesIn
  }

  fragment amenitiesPart on Place {
    id
    chosenFacilities {
      id
      facilityId
    }
  }

  fragment mediaPart on Place {
    id
    photos {
      id
      photo
    }
    script
    virtualTourUrl
  }

  fragment bedsAndBathsPart on Place {
    id
    bathroomsCount
    rooms {
      id
      bathKind {
        id
        name
      }
      bedKind {
        id
        name
      }
      photos {
        id
        photo(version: listing_square)
      }
      roomFacilities {
        id
        name
      }
      roomNiceTouches {
        id
        name
      }
    }
  }

  fragment bookingRulesPart on Place {
    id
    tenantTypes {
      id
      name
    }
    startDateWindow
    depositOptions {
      id
      name
    }
    houseRules {
      id
      name
    }
    backgroundChecks {
      id
      name
    }
    genderPreference {
      id
      name
    }
    preparationTime
    moveOutGuarantee
    notes
  }

  fragment pricingPart on Place {
    id
    discount
    promotionMonths
    monthlyUtilityPrice
    monthlyRentalPriceValues {
      id
      value
    }
  }

  query GetPlaceData($id: ID!) {
    place(id: $id) {
      id
      ...basicsPart
      ...amenitiesPart
      ...mediaPart
      ...bedsAndBathsPart
      ...bookingRulesPart
      ...pricingPart
      landlord {
        id
        avatar
        fullName
        firstName
        lastName
        address
        description
        jobTitle
        email
        messagesAvailable
      }
      availableComfort: placeFacilities {
        id
        name
      }
      availableNiceTouches: niceTouches {
        id
        name
      }
      availableUtilities: utilities {
        id
        name
      }
    }
  }
`;
