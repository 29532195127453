import { createGlobalStyle, css } from 'styled-components';
import { fontSize } from './styleUtils';

export const FONT_FAMILIES = {
  NUNITO: 'nunito',
  GFS: 'gfs',
  AVENIR_LIGHT: 'AvenirLight',
  AVENIR_NORMAL: 'AvenirNormal',
  AVENIR_BOLD: 'AvenirBold',
  LULO: 'lulo',
};

export const FONT_WEIGHTS = {
  LIGHT: 'light',
  NORMAL: 'normal',
  BOLD: 'bold',
};

export const theme = {
  colors: {
    purplePrimary: '#b0156a',
    darkPurple: '#b8308a',
    lightPurple: '#d12477',
    blackBrown: '#2f2e2e',
    darkBrown: '#323232',
    midDarkBrown: '#545454',
    paleGreen: '#a6b56b',
    paleRed: '#c1282b',
    pearlGray: '#d8dfe6',
    whiteGray: '#f2f2f2',
    lightGray: '#7A7979',
    transparentGray: 'rgba(122, 121, 121, 0.72)',
    heavilyTransparentGray: 'rgba(186, 184, 184, 0.38)',
    lightTransparentGray: 'rgba(216, 223, 230, 0.35)',
    dustGray: '#bab8b8',
    pinkRed: '#cd3971',
    darkBlue: '#0a5587',
    avatarDeepBlue: '#243C6B',
    oliveGreen: '#465e52',
    facebookBlue: '#4267b2',
    paleBlue: '#577083',
    darkGrayBlue: '#324158',
    navInactiveGrey: '#858585',
    navActiveGrey: '#212121',
    cremeWhite: '#f2eddf',
    black: '#000',
    orangeRed: '#e21c21',
    green: '#1db578',
    lightGreen: '#4BD1A0',
    yellow: '#FFDE5F',
    text: '#333333',
    placeholder: '#B6C1CD',
  },
  fonts: {
    [FONT_FAMILIES.AVENIR_LIGHT]: 'AvenirLTPro-Light',
    [FONT_FAMILIES.AVENIR_NORMAL]: 'AvenirLTPro-Medium',
    [FONT_FAMILIES.AVENIR_BOLD]: 'AvenirLTPro-Heavy',
  },
  fontWeights: {
    [FONT_WEIGHTS.LIGHT]: 350,
    [FONT_WEIGHTS.NORMAL]: 650,
    [FONT_WEIGHTS.BOLD]: 850,
  },
  breakpoints: ['40em', '52em', '72em'],
};

const sizes = {
  mobile: theme.breakpoints[0],
  tablet: theme.breakpoints[1],
  desktop: theme.breakpoints[2],
};

export const mediaQuery = {
  mobile: (...args) => css`
    @media (max-width: ${sizes.mobile}) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: ${sizes.tablet}) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (max-width: ${sizes.desktop}) {
      ${css(...args)}
    }
  `,
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: AvenirLTPro-Medium, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    font-size: 16px;
    color: ${theme.colors.text};
  }
  .InputFromTo {
    padding-top: 1px;
    padding-bottom: 1px;
    /* (1) fix inputs overlapping border on large screens */
    &.InputSingle {
      padding-right: 6px;
      margin: 0;
      border-radius: 6px;
      display: flex;
      align-items: center;
      .DayPickerInput {
        width: 100%;
        text-align: center;
        input {
          padding: 8px 10px;
          width: calc(100% - 20px);
        }
      }
      .DayPickerInput-Overlay {
        width: 280px;
      }
    }
    border: 1px solid ${theme.colors.pearlGray};
    border-radius: 5px;
    margin: 15px 0;
    ${fontSize(16)};
    color: ${theme.colors.darkBrown};
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .DayPicker-Day {
      border-radius: 0 !important;
    }
    .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .DayPickerInput-Overlay {
      position: absolute;
      left: 0;
      z-index: 1;
      background: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.35);
      // builtin

      width: 580px;
      display: flex;
      justify-content: space-between;
    }
    .DayPickerInput-OverlayWrapper {
      position: relative;
      top: 5px;
    }
    .DayPickerInput {
      > input {
        padding: 11px 5px;
        border: none;
        position: relative;
        ${fontSize(16)}
        color: ${theme.colors.darkBrown};
        :focus {
          padding-top: 6px;
          outline: none;
          border-bottom: 2px solid ${theme.colors.purplePrimary};
          top: 2px;
          /* fix padding issue created by fix (1) on normal screens */
        }
      }
    }
  }
  .InputFromTo-to {
    margin-left: 32px;
    border-radius: 5px;
    .DayPickerInput-Overlay {
      margin-left: -233px;
    }
    .DayPickerInput {
      border-radius: 5px;
      display: inline-block;
    }
  }
  .stripe-input {
    display: block;
    padding: 7px 5px;
    border-radius: 0px;
    background-color: #ffffff;
    border: 1px solid ${theme.colors.transparentGray};
    outline: none;
    height: 24px;
    ${fontSize(16)}
    color: ${theme.colors.darkBrown};
  }

  .StripeElement--focus {
    border-color: ${theme.colors.purplePrimary};
  }
`;


export const NAVBAR_HEIGHT = 80;
export const FOOTER_HEIGHT = 60;
