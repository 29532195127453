import gql from 'graphql-tag';

export const GET_FB_DATA_FOR_REGISTARTION = gql`
  query {
    signUpFBData @client {
      firstName
      lastName
      email
      pictureUrl
      uid
      accessToken
    }
  }
`;
