import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { NavBar, NavBarItem } from '../../common/NavBar';
import { APP_ROUTES } from '../../../constants';

const InitialNavBar = ({ t }) => (
  <NavBar>
    <NavBarItem
      to={APP_ROUTES.LISTINGS.PLACE_BASICS()}
      inactive="false"
    >
      {t('nav.basics')}
    </NavBarItem>
    <NavBarItem
      to="#"
      inactive="true"
    >
      {t('nav.amenities')}
    </NavBarItem>
    <NavBarItem
      to="#"
      inactive="true"
    >
      {t('nav.media')}
    </NavBarItem>
    <NavBarItem
      to="#"
      inactive="true"
    >
      {t('nav.bedsAndBaths')}
    </NavBarItem>
  </NavBar>
);

InitialNavBar.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listingForm')(InitialNavBar);
