import noop from 'lodash/noop';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, Query } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box } from 'rebass';
import { compose } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import { UPDATE_PLACE_LIVE } from '../../../graphQL/mutations/createOrUpdatePlace';
import { GET_PLACE_BLOCK_OUTS, GET_PLACE_LIVE } from '../../../graphQL/queries/place';
import { FormPart, FormSubPart, FormWrapper } from '../../common/form/FormWrapper';
import { Separator } from '../../common/form/Separator';
import Toggle from '../../common/form/Toggle';
import Loading from '../../common/Loading';
import Footer from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';
import BlockOutsForm from '../place/forms/BlockOutsForm';
import { mapApiErrors } from '../../../utils/errorHandling';
import { GET_ROOM_LIVE, GET_ROOM_BLOCK_OUTS, UPDATE_ROOM_LIVE } from '../../../graphQL/mutations/room';
import { NarrowLayout } from '../../common/Layout';
import ExposeState from '../../common/form/ExposeState';

class Calendar extends Component {
  state = {
    submitting: false,
  };

  onNext = noop;

  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.LISTINGS.PLACE_PRICES(match.params.id));
  };

  onSubmitCoLiving = async (values) => {
    try {
      const { history, match, updateRoomLive } = this.props;
      const { data, errors } = await updateRoomLive({
        variables: {
          ...values,
          id: match.params.id,
        },
      });
      if (errors) {
        return mapApiErrors(errors);
      }
      history.push(APP_ROUTES.LISTINGS.PLACE_ROOMS(data.createOrUpdateRoom.place.id));
    } catch (e) {
      console.error(e);
    }
  };

  onSubmitEntirePlace = async (values) => {
    try {
      const { history, match, updatePlaceLive } = this.props;
      const { data, errors } = await updatePlaceLive({
        variables: {
          ...values,
          id: match.params.id,
        },
      });
      if (errors) {
        return mapApiErrors(errors);
      }
      history.push(APP_ROUTES.LISTINGS.PLACE_INTRO(data.createOrUpdatePlace.id));
    } catch (e) {
      console.error(e);
    }
  };

  renderForm = ({ place }) => (
    <Form
      initialValues={{ ...place }}
      onSubmit={this.isRoomCalendar() ? this.onSubmitCoLiving : this.onSubmitEntirePlace}
      render={({ handleSubmit }) => {
        this.onNext = handleSubmit;
        return (
          <form onSubmit={handleSubmit}>
            <ExposeState exposeState={({ submitting }) => this.setState({ submitting })} />
            <FormWrapper>
              <FormPart title="Listing status">
                <FormSubPart subTitle="Stay live" subText="Downroots platform keeps your listings on a continual flow so opportunities are never missed. Keeping your diary up to date is critical to its success.">
                  <Box mt={10}>
                    <Toggle name="live" label="Live" disabled={!place.virtualTourUrl} />
                  </Box>
                </FormSubPart>
              </FormPart>
              <Separator />
            </FormWrapper>
          </form>
        );
      }}
    />
  );

  isRoomCalendar = () => this.props.match.path === '/listings/place/:placeId/rooms/:id/calendar';

  render() {
    const { match } = this.props;
    const isRoomCalendar = this.isRoomCalendar();
    return (
      <NarrowLayout>
        <WizardStep query={isRoomCalendar ? GET_ROOM_LIVE : GET_PLACE_LIVE}>
          {({ data }) => this.renderForm(
            isRoomCalendar
              ? { place: { ...data.room, virtualTourUrl: get(data, 'room.place.virtualTourUrl') } }
              : data // eslint-disable-line comma-dangle
          )}
        </WizardStep>
        <Query
          query={isRoomCalendar ? GET_ROOM_BLOCK_OUTS : GET_PLACE_BLOCK_OUTS}
          variables={{ id: match.params.id }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loading />;
            }

            return (
              <BlockOutsForm blockOuts={get(data, `${isRoomCalendar ? 'room' : 'place'}.blockOuts`)} />
            );
          }}
        </Query>
        <Footer
          omitPrev={!!match.params.placeId}
          onPrev={this.onPrev}
          onNext={() => this.onNext()}
          disabledNext={this.state.submitting}
          onSaveAndExit={
            isRoomCalendar ?
              () => this.props.history.push(APP_ROUTES.LISTINGS.PLACE_ROOMS(match.params.placeId)) :
              null
          }
          isPlace
        />
      </NarrowLayout>
    );
  }
}

Calendar.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  updatePlaceLive: PropTypes.func.isRequired,
  updateRoomLive: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(UPDATE_PLACE_LIVE, { name: 'updatePlaceLive' }),
  graphql(UPDATE_ROOM_LIVE, { name: 'updateRoomLive' }),
);

export default enhance(Calendar);
