import { Text } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

const Body = styled(Text).attrs(props => ({
  fontFamily: FONT_FAMILIES.AVENIR_LIGHT,
  fontSize: props.fontSize || '17px',
  lineHeight: '1.6em',
}))``;

export default Body;
