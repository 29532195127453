import gql from 'graphql-tag';
import { EMPLOYED_PARTS, SELF_EMPLOYED_PARTS, STUDENT_PARTS } from '../fragments/user';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user {
      id
      avatar
      email
      firstName
      lastName
      role {
        id
        name
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query GetUserInfo {
    user {
      id
      firstName
      lastName
      address
      phone
      email
      sex
      birthday
      description
      documentKind
      documents {
        id kind name file
      }
      country
      role { id name }
      avatar
      kindOfJob
      ...StudentParts
      ...EmployedParts
      ...SelfEmployedParts
      company {
        id
        address
        isRegisteredLandlord
        downrootsInvoices
        companyName
        ownerFullName
        registeredNumber
        registeredCountry
        phone
      }
    }
  }
  
  ${STUDENT_PARTS}
  ${EMPLOYED_PARTS}
  ${SELF_EMPLOYED_PARTS}
`;

export const GET_TENANT_SETTINGS = gql`
  query GetTenantSettings {
    user {
      id
      receiveBooking
      receiveMessages
      messagesAvailable
      profileAvailable
      paymentMethod
      payoutMethod {
        id accountNumber bankName payee sortCode
      }
    }
  }
`;

export const GET_LANDLORD_SETTINGS = gql`
  query GetLandlordSettings {
    user {
      id
      receiveBooking
      receiveMessages
      payoutMethod {
        id accountNumber bankName payee sortCode
      }
    }
  }
`;
