import React from 'react';
import { Switch } from 'react-router';
import { Box } from 'rebass';
import { renderNothing } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import AppRoute from '../../common/AppRoute';
import Profile from '../../user/profile/Profile';
import Transactions from '../common/Transactions';
import Listings from './listings/ListingsContainer';
import Settings from './settings/Settings';
import Tenancies from './tenancies/TenancyContainer';
import TenanciesDetails from './tenancyDetails/TenancyDetailsContainer';

const Landlord = () => (
  <Switch>
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.LISTINGS()} layout={Box} component={Listings} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.TENANCIES()} layout={Box} component={Tenancies} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.TENANCIES(':id')} layout={Box} component={TenanciesDetails} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.TRANSACTIONS()} layout={Box} component={Transactions} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.INBOX()} layout={Box} component={renderNothing()} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.PROFILE()} layout={Box} component={Profile} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.LANDLORD.SETTINGS()} layout={Box} component={Settings} navBar={renderNothing()} />
  </Switch>
);

export default Landlord;
