import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { Switch, withRouter } from 'react-router';
import { Flex } from 'rebass';
import { formatLocation } from '../../utils/string';
import AppRoute from '../common/AppRoute';
import FullWideLayout from '../common/FullWideLayout';
import { NavBar } from '../common/NavBar';
import ScrollToTop from '../common/ScrollToTop';
import NavBarByRole from '../navigation/NavBarByRole';
import BookingRules from './BookingRules';
import ListingsIntro from './ListingsIntro';
import CalendarNavBar from './navBars/CalendarNavBar';
import FirstStepNavBar from './navBars/FirstStepNavBar';
import InitialNavBar from './navBars/InitialNavBar';
import SecondStepNavBar from './navBars/SecondStepNavBar';
import ThirdStepNavBar from './navBars/ThirdStepNavBar';
import Amenities from './place/Amenities';
import Basics from './place/Basics';
import BedsAndBaths from './place/BedsAndBaths';
import Media from './place/Media';
import Calendar from './pricesAndCalendar/Calendar';
import Prices from './pricesAndCalendar/Prices';
import Room from './Room';

const Place = ({ location, t }) => (
  <Flex
    flexDirection="column"
    alignItems="left"
  >
    <Helmet>
      <title>{formatLocation({ location, t })}</title>
    </Helmet>
    <ScrollToTop>
      <Switch>
        <AppRoute exact path="/listings/place/intro" navBar={NavBarByRole} layout={FullWideLayout} component={ListingsIntro} />
        <AppRoute exact path="/listings/place/:id/intro" navBar={NavBarByRole} layout={FullWideLayout} component={ListingsIntro} />
        <AppRoute exact path="/listings/place/basics" navBar={InitialNavBar} component={Basics} />
        <AppRoute exact path="/listings/place/:id/basics" navBar={FirstStepNavBar} component={Basics} />
        <AppRoute exact path="/listings/place/:id/amenities" navBar={FirstStepNavBar} component={Amenities} />
        <AppRoute exact path="/listings/place/:id/media" navBar={FirstStepNavBar} component={Media} />
        <AppRoute exact path="/listings/place/:id/bedrooms" navBar={FirstStepNavBar} component={BedsAndBaths} />
        <AppRoute exact path="/listings/place/:id/booking-rules" navBar={SecondStepNavBar} component={BookingRules} />
        <AppRoute exact path="/listings/place/:id/rooms" navBar={ThirdStepNavBar} component={BedsAndBaths} />
        <AppRoute exact path="/listings/place/:id/prices" navBar={ThirdStepNavBar} component={Prices} />
        <AppRoute exact path="/listings/place/:id/calendar" navBar={ThirdStepNavBar} component={Calendar} />
        <AppRoute exact path="/listings/place/room/:id" navBar={NavBar} component={Room} />
        <AppRoute exact path="/listings/place/:placeId/rooms/:id/calendar" navBar={CalendarNavBar} component={Calendar} />
      </Switch>
    </ScrollToTop>
  </Flex>
);

Place.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const enhance = compose(
  withNamespaces('listingForm'),
  withRouter,
);

export default enhance(Place);
