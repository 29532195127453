import get from 'lodash/get';

export const setAuthTokens = (signIn) => {
  localStorage.setItem('kumpfy.access-token', signIn.accessToken);
  localStorage.setItem('kumpfy.client', signIn.client);
  localStorage.setItem('kumpfy.expiry', signIn.expiry);
  localStorage.setItem('kumpfy.uid', signIn.uid);
  localStorage.setItem('kumpfy.token-type', signIn.tokenType);
};

export const clearAuthTokens = () => {
  localStorage.removeItem('kumpfy.access-token');
  localStorage.removeItem('kumpfy.client');
  localStorage.removeItem('kumpfy.expiry');
  localStorage.removeItem('kumpfy.uid');
  localStorage.removeItem('kumpfy.token-type');
};

export const isLandlord = user => get(user, 'role.name') === 'landlord';
