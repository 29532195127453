import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import { Redirect, Route } from 'react-router';
import { GET_CURRENT_USER } from '../../graphQL/queries';
import Navigation from '../navigation/MainNavBar';
import NoRolePage from '../NoRolePage';
import AppLayout from './AppLayout';

class PrivateAppRoute extends React.Component {
  isAuthorized = (data) => {
    const { roles } = this.props;
    const userRole = get(data, 'user.role.name');

    return !isEmpty(roles) && userRole && roles.includes(userRole);
  };

  isLoggedIn = (data) => {
    const userRole = get(data, 'user.role.name');

    return !!userRole;
  };

  render() {
    const {
      component: Component,
      layout: Layout,
      navBar: NavBar,
      ...routeProps
    } = this.props;
    return (
      <Route
        {...routeProps}
        render={props => (
          <Query query={GET_CURRENT_USER}>
            {({ loading, data }) => {
              if (loading) {
                return null;
              }

              if (this.isAuthorized(data)) {
                return (
                  <Layout
                    {...props}
                    navBar={() => <NavBar {...props} />}
                  >
                    <Component {...routeProps} {...props} />
                  </Layout>
                );
              }

              if (this.isLoggedIn(data)) {
                return <NoRolePage />;
              }

              return (
                <Redirect to="/sign-in" />
              );
            }}
          </Query>
        )}
      />
    );
  }
}

PrivateAppRoute.propTypes = {
  component: PropTypes.func,
  layout: PropTypes.any,
  navBar: PropTypes.func,
};

PrivateAppRoute.defaultProps = {
  layout: AppLayout,
  navBar: Navigation,
};

export default PrivateAppRoute;
