import moment from 'moment';
import plularize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link as LinkRaw } from 'react-router-dom';
import Truncate from 'react-truncate';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import bedroom from '../../../assets/img/bedroom.png';
import { fontMixin, fontSize, THICK } from '../../../theme/styleUtils';
import Body from '../../common/text/Body';
import Title from '../../common/text/Title';

const ListingContainer = styled(Flex)`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px 0px;
  width: 100%;
  height: 100%;
`;

const Link = styled(LinkRaw)`
  text-decoration: none;
`;

const ListingType = styled(Title)`
  text-transform: uppercase;
  font-size: 12px;
  opacity: .5;
  margin-bottom: 10px;
  height: 30px;
  overflow: hidden;
`;

const AddressText = styled(Text)`
  ${fontMixin(THICK)}
  ${fontSize(16)}
  margin-bottom: 10px;
  height: 36px;
  overflow: hidden;
`;

const RentInfo = styled(Text)`
  ${fontMixin(THICK)}
  ${fontSize(14)}
  color: ${props => props.theme.colors.darkPurple};
  margin-bottom: 10px;
`;

const CardImage = styled(Box)`
  background-size: cover;
  background: url(${props => props.src}) center center;
  height: 250px;
  
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    height: 200px;
  }
  
`;

const ListingListItem = ({ listing, link, t }) => {
  const {
    address,
    monthlyPrice,
    listingType,
    image,
    bedroomsNumber,
    bathroomsNumber,
    availableFrom,
  } = listing;
  return (
    <Box width={[1 / 2, 1 / 3, 1 / 5]} p={['5px', 10, 10]}>
      <Link to={link}>
        <ListingContainer flexDirection="column">
          <CardImage src={image || bedroom} />
          <Flex flexDirection="column" p={[10, 15, 15]} justifyContent="space-between">
            <Flex flexDirection="column">
              <ListingType>
                {t(`types.${listingType}`)}
                {`: ${bedroomsNumber} ${plularize('bed', bedroomsNumber)} / ${bathroomsNumber} ${plularize('baths', bathroomsNumber)}`}
              </ListingType>
              <AddressText>
                <Truncate lines={2}>
                  {address}
                </Truncate>
              </AddressText>
            </Flex>
            <Box>
              <RentInfo>
                {`£${monthlyPrice} ${t('monthly')}`}
              </RentInfo>
              <Body fontSize={12}>
                {`${t('availability.self')}: `}
                {moment.utc(availableFrom).format('MMM Do YYYY')}
              </Body>
            </Box>
          </Flex>
        </ListingContainer>
      </Link>
    </Box>
  );
};

ListingListItem.propTypes = {
  link: PropTypes.string.isRequired,
  listing: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listings')(ListingListItem);
