import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';
import Body from '../text/Body';

const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  
  &:checked + label:after {
    left: calc(100% - 5px);
    background: ${props => props.theme.colors.purplePrimary};
    transform: translateX(-100%);
  }
`;

const Switch = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: block;
  position: relative;
  border-radius: 2px;

  &:active:after {
    width: 25px;
    background: ${props => props.theme.colors.purplePrimary};
  }
  
  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: ${props => props.theme.colors.lightGray};
    border-radius: 2px;
    transition: 0.3s;
  }
  
  ${props => props.disabled && css`
    pointer-events: none;
  `}
`;

const Toggle = ({
  name, validate, label, disabled,
}) => (
  <Field
    name={name}
    component="input"
    validate={validate}
    disabled={disabled}
  >
    {({ input, meta }) => (
      <Flex alignItems="center">
        <Input type="checkbox" id="switch" {...input} {...meta} checked={input.value} />
        <Switch for="switch" disabled={disabled} />
        {label && <Body ml={20}>{label}</Body>}
      </Flex>
    )}
  </Field>
);

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Toggle;
