import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import signInBackground from '../../assets/img/sign-in-background.webp';
import Text from '../common/Text';
import FacebookSignInButton from './FacebookSignInButton';
import PrimaryButton from '../common/buttons/PrimaryButton';
import { fontSize, fontMixin, LIGHT } from '../../theme/styleUtils';

const BoxWithBackground = styled(Box)`
  background: url(${signInBackground}) no-repeat center;
  background-size: cover;
  height: 100vh;
`;

export const LinkText = styled(Link)`
  :hover {
    text-decoration: underline;
  }
  color: #323232;
  text-decoration: none;
`;

const Separator = styled(Box)`
  background-color: ${props => props.theme.colors.darkBrown};
  height: 1px;
  position: relative;
  width: 100%;
`;

const SeparatorText = styled(Text)`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 0 30px;
`;

const Title = styled(Text)`
  padding-bottom: 10px;
  border-bottom: 2px solid #000;
`;

const Wrapper = styled(Box)`
  max-width: 350px;
`;

export const FormButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.colors.purplePrimary};
  border-radius: 5px;
  text-transform: uppercase;
  ${fontSize(16)}
`;

const AuthTitle = styled(Title)`
  ${fontMixin(LIGHT)}
  ${fontSize(30)}
  letter-spacing: 1.5px;
`;


const AuthLayout = ({ title, children }) => (
  <Flex width={1}>
    <BoxWithBackground width={[0, 0, 1]} />
    <Flex width={1} p={[15, 60]} justifyContent="center">
      <Wrapper width={1}>
        <Flex justifyContent="center" mb={30}>
          <AuthTitle>
            {title}
          </AuthTitle>
        </Flex>
        <Flex justifyContent="center">
          <FacebookSignInButton label="f" />
        </Flex>
        <Separator my={45}>
          <SeparatorText>OR</SeparatorText>
        </Separator>
        {children}
      </Wrapper>
    </Flex>
  </Flex>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AuthLayout;
