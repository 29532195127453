import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import {
  BOLD, fontMixin, fontSize, THICK5,
} from '../../../theme/styleUtils';
import { Separator } from '../../common/form/Separator';
import Title from '../../common/text/Title';
import { FilledRoundedButton, RoundedButton } from '../../common/wizard/Footer';

export const ShowcaseButton = styled(RoundedButton)`
  padding: 4px 22px;
`;

export const FilledShowcaseButton = styled(FilledRoundedButton)`
  padding: 4px 22px;
`;

const ShowcaseText = styled(Text)`
  ${fontSize(15)}
  color: ${props => props.theme.colors.darkBrown};
  white-space: nowrap;
  letter-spacing: 0.05em;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 45%;
`;

const ShowcaseTextLabel = styled.span`
  ${fontMixin(BOLD)}
  ${fontSize(15)}
`;
const ShowcaseTextValue = styled.span`
  ${fontMixin(THICK5)}
  ${fontSize(15)}
`;

export const ShowcasePartItem = ({ title, text, ...props }) => (
  <ShowcaseText my="6px" {...props}>
    <ShowcaseTextLabel>{`${title}:`}</ShowcaseTextLabel>
    <ShowcaseTextValue>{` ${text || ''}`}</ShowcaseTextValue>
  </ShowcaseText>
);

ShowcasePartItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShowcasePartItem.defaultProps = {
  text: '',
};

const ShowcasePart = ({
  title, children, buttonText, history, route, last,
}) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="left"
    mt={20}
    mb={30}
  >
    <Flex justifyContent="space-between" alignItems="center" width={1}>
      <Title mb={10} thicker>{title}</Title>
      <ShowcaseButton onClick={() => history.push(route)}>{buttonText}</ShowcaseButton>
    </Flex>
    <Flex justifyContent="space-between" flexWrap="wrap" mb={30} pr={110}>
      {children}
    </Flex>
    { !last && <Separator /> }
  </Flex>
);

ShowcasePart.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  buttonText: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  last: PropTypes.bool,
};

export default withRouter(ShowcasePart);
