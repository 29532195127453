import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';
import styled from 'styled-components';
import { Text, Flex } from 'rebass';
import { GOOGLE_PLACE_TYPES } from '../../../constants';
import { DetailsSubtitle, FacilityListTitle } from './DetailsFacilityPart';
import GoogleMapWithPlaces from '../../google/GoogleMapWithPlaces';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

const LocationText = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_LIGHT,
  fontSize: 17,
  color: 'black',
})``;

const DetailsLocationPart = ({ t, listing, isPlace }) => {
  const {
    TRANSPORT, GROCERIES, PARKS, CAFE,
  } = GOOGLE_PLACE_TYPES;
  return (
    <>
      <DetailsSubtitle mb={15} mt={80}>{t('details.location.title')}</DetailsSubtitle>
      <LocationText mb={30}>{get(listing, isPlace ? 'location' : 'place.location')}</LocationText>
      {false && (
        <>
          <FacilityListTitle>{t('details.location.byTransport')}</FacilityListTitle>
          <Flex mb={20}>
            <LocationText mr={40}>Heathrow: 24 mins</LocationText>
            <LocationText mr={40}>Hyde Park: 40 mins</LocationText>
            <LocationText mr={40}>Tower of London: 12 mins</LocationText>
          </Flex>
          </>
      )}
      <GoogleMapWithPlaces
        isMarkerShown
        placeTypes={[TRANSPORT, GROCERIES, PARKS, CAFE]}
        locationCoords={{
          lat: get(listing, isPlace ? 'latitude' : 'place.latitude', 0.0),
          lng: get(listing, isPlace ? 'longitude' : 'place.longitude', 52.0),
        }}
      />
    </>
  );
};

DetailsLocationPart.propTypes = {
  t: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  isPlace: PropTypes.bool.isRequired,
};

export default withNamespaces('listings')(DetailsLocationPart);
