import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Field } from 'react-final-form';
import Script from 'react-load-script';
import head from 'lodash/head';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Box, Text } from 'rebass';
import config from '../../configLoader';
import { BorderedInput, InputWrapper } from '../common/form/TextInput';
import { chooseAutocomplete } from '../../utils/formValidation';
import Loading from '../common/Loading';

const WarningText = styled(Text)`
  color: #ffbb33;
  margin-bottom: 10px;
`;

const DescriptionWrapper = styled(Box)`
  background-color: ${props => (props.active ? props.theme.colors.whiteGray : 'initial')};
  color: ${props => (props.active ? props.theme.colors.purplePrimary : 'initial')};
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
`;

const DescriptionsWrapper = styled(Box)`
  padding: 8px;
  border: 1px solid ${props => props.theme.colors.pearlGray};
  position: relative;
  top: 10px;
  background-color: white;
  border-radius: 5px;
`;

const Wrapper = styled(InputWrapper)`
  border-color: ${props => (props.active || (props.error && props.touched)) && props.theme.colors.purplePrimary};
`;

class GooglePlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.initialAddress,
      scriptLoaded: false,
      isLondon: true,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.scriptLoaded !== this.state.scriptLoaded) {
      try {
        const geocode = await geocodeByAddress(this.props.initialAddress);
        const city = this.getCity(geocode);
        const isLondon = city === 'London';
        this.setState({ // eslint-disable-line
          isLondon,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  getCity = (geocode) => {
    const filteredAdresses = geocode[0].address_components.filter(
      g => g.types.includes('postal_town') ||
        g.types.includes('administrative_area_level_2'),
    );
    return get(head(filteredAdresses), 'long_name', '');
  };

  onFormInputChange = () => {};

  onFormInputBlur = () => {};

  geocodeAddress = async (address) => {
    try {
      const { formChange } = this.props;

      const geocode = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(geocode[0]);
      const geolocationData = JSON.stringify(geocode);

      const city = this.getCity(geocode);
      const isLondon = city === 'London';

      this.setState({ address, isLondon }, () => {
        this.onFormInputChange(address);
        formChange('latitude', lat);
        formChange('longitude', lng);
        formChange('city', city);
        formChange('geolocationData', geolocationData);
      });
    } catch (e) {
      console.error('[GOOGLE API ERROR]', e);
      this.onFormInputBlur();
    }
  };

  handleChange = (address) => {
    const { formChange } = this.props;

    formChange('latitude', null);
    formChange('longitude', null);
    formChange('geolocationData', null);
    this.setState({ address }, () => {
      this.onFormInputChange(address);
      this.onFormInputBlur();
    });
  };

  handleSelect = async (address) => {
    this.geocodeAddress(address);
  };

  _renderPlaces() {
    const { name, placeholder, validate } = this.props;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={650}
        highlightFirstSuggestion
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <>
            {!this.state.isLondon && <WarningText>We are not in your area</WarningText>}
            <Field name="latitude" component="input" type="hidden" validate={chooseAutocomplete} />
            <Field name="longitude" component="input" type="hidden" validate={chooseAutocomplete} />
            <Field name="geolocationData" component="input" type="hidden" />
            <Field name={name} component="input" validate={validate}>
              {({ input, meta }) => {
                this.onFormInputChange = input.onChange;
                this.onFormInputBlur = input.onBlur;
                return (
                  <Box>
                    <Wrapper {...meta}>
                      <BorderedInput {...input} {...getInputProps({ placeholder })} onBlur={() => this.geocodeAddress(this.state.address)} />
                    </Wrapper>
                  </Box>
                );
              }}
            </Field>
            { !isEmpty(suggestions) && (
              <DescriptionsWrapper>
                {loading && <Loading />}
                {suggestions.map(suggestion => (
                  <DescriptionWrapper
                    {...getSuggestionItemProps(suggestion, {
                      active: suggestion.active,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </DescriptionWrapper>
                ))}
              </DescriptionsWrapper>
            )}
          </>
        )}
      </PlacesAutocomplete>
    );
  }

  render = () => (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&libraries=places,geometry`}
        onLoad={() => this.setState({ scriptLoaded: true })}
      />
      {this.state.scriptLoaded && this._renderPlaces()}
    </>
  );
}

GooglePlacesAutocomplete.defaultProps = {
  validate: undefined,
  placeholder: undefined,
  initialAddress: '',
};

GooglePlacesAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  initialAddress: PropTypes.any,
  formChange: PropTypes.func.isRequired,
};

export default GooglePlacesAutocomplete;
