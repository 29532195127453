import get from 'lodash/get';
import trim from 'lodash/trim';
import React, { Component, Fragment } from 'react';
import { compose, Query } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { GET_CURRENT_USER } from '../../graphQL/queries';
import { transitionBackgroundColor } from '../../theme/styleUtils';
import LogOut from '../auth/LogOut';
import Avatar from '../common/Avatar';
import { Separator } from '../common/form/Separator';
import Menu, { MenuList } from '../common/Menu';
import Caption from '../common/text/Caption';
import MenuText from '../common/text/MenuText';
import CommonMenu from './CommonMenu';
import LandlordMenu from './LandlordMenu';
import TenantMenu from './TenantMenu';

export const NavItem = styled(MenuText).attrs({
  fontSize: [20, 20, 14],
})`
  display: inline-block;
  padding: 0 15px;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    color: ${props => props.theme.colors.purplePrimary};
  }
  
  ${transitionBackgroundColor('.3s')}
`;

const getInitials = ([first], [last]) => `${first}${last}`;

const MasterModeText = styled(Caption).attrs(({ color: 'purplePrimary' }))`
  text-align: center;
  width: 100px;
`;

class DesktopMenu extends Component {
  render() {
    return (
      <Flex ml="auto" px={20} alignItems="center">
        <Query query={GET_CURRENT_USER}>
          {({ data }) => {
            if (!get(data, 'user')) {
              return null;
            }
            const { firstName, lastName } = get(data, 'user', {});
            const role = get(data, 'user.role.name');

            return (
              <Flex justifyContent="flex-end" alignItems="center" width={(1)}>
                <Flex alignItems="center">
                  <Menu>
                    {({ toggleMenu, isMenuOpened }) => (
                      <Fragment>
                        {sessionStorage.getItem('downrootsMasterToken') && (
                        <MasterModeText>
                          You are in master mode
                        </MasterModeText>
                        )}
                        {false && <Text px="10px">{get(data, 'user.role.name')}</Text>}
                        <Avatar style={{ cursor: 'pointer' }} src={get(data, 'user.avatar')} onClick={toggleMenu} initials={getInitials(trim(firstName), trim(lastName))} />
                        {isMenuOpened && (
                        <MenuList>
                          {get(data, 'user.role.name') === 'landlord' && <LandlordMenu />}
                          {get(data, 'user.role.name') === 'tenant' && <TenantMenu />}
                          <Separator my="5px" />
                          <LogOut>
                            {({ onLogOut }) => (
                              <CommonMenu
                                isLoggedIn={!!role}
                                onLogOut={() => {
                                  toggleMenu();
                                  onLogOut();
                                }}
                              />
                            )}
                          </LogOut>
                        </MenuList>
                        )}
                      </Fragment>
                    )}
                  </Menu>
                </Flex>
              </Flex>
            );
          }}
        </Query>
      </Flex>
    );
  }
}

const enhance = compose(
  withNamespaces('home'),
  withRouter,
);

export default enhance(DesktopMenu);
