import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Flex, Image } from 'rebass';
import styled from 'styled-components';
import logo from '../../../assets/img/downroots-icon.webp.png';
import { GET_OFFERED_OBJECT_PRICES } from '../../../graphQL/queries/offeredObjectPrices';
import { FONT_WEIGHTS } from '../../../theme/globalStyle';
import { isBookingDateAvailable } from '../../../utils/dates';
import { formatPrice } from '../../../utils/prices';
import { showUnhandledAction } from '../../../utils/toast';
import { ErrorBoxContainer } from '../../common/form/FieldError';
import Loader from '../../common/Loader';
import Body from '../../common/text/Body';
import { BaseFooter, FilledRoundedButton } from '../../common/wizard/Footer';


const Text = styled(Body).attrs(({
  fontWeight: FONT_WEIGHTS.BOLD,
  color: 'purplePrimary',
}))`
  cursor: pointer;
`;

const ListingDetailsFooter = ({ location, onReserve, openBreakdown }) => {
  const {
    type,
    indexableId,
    dateFrom,
    leaseLength,
  } = queryString.parse(location.search);

  return (
    <BaseFooter px={20}>
      <Query
        query={GET_OFFERED_OBJECT_PRICES}
        variables={{
          indexableId,
          dateFrom,
          leaseLength: +leaseLength,
          objectType: type,
        }}
      >
        {({ loading, error, data }) => {
          const offeredObjectPrices = get(data, 'offeredObjectPrices', {});

          if (loading) {
            return <Loader />;
          }

          if (isEmpty(offeredObjectPrices)) {
            return (
              <ErrorBoxContainer>
                {get(error, 'graphQLErrors[0].message', 'Error')}
              </ErrorBoxContainer>
            );
          }

          const isAvailable = isBookingDateAvailable(
            dateFrom,
            get(data, 'offeredObjectPrices.offeredObject.availableFrom'),
            get(data, 'offeredObjectPrices.offeredObject.startDateWindow'),
          );

          return (
            <Flex alignItems="center" justifyContent="space-between" width={1}>
              <Flex alignItems="center">
                <Link to="/">
                  <Image src={logo} mr={10} height={48} />
                </Link>
                {!isAvailable && (
                  <Text onClick={openBreakdown}>
                    Bookable from
                    {' '}
                    {moment(get(data, 'offeredObjectPrices.offeredObject.availableFrom')).format('MMMM D YYYY')}
                  </Text>
                )}
                {isAvailable && (
                  <Text onClick={openBreakdown}>
                    {`${moment(dateFrom).format('MMMM D YYYY')} for ${leaseLength} months`}
                  </Text>
                )}
              </Flex>
              <Box>
                {!isAvailable && (
                  <Flex alignItems="center">
                    <FilledRoundedButton
                      px={30}
                      py={10}
                      onClick={showUnhandledAction}
                    >
                        Notify me
                    </FilledRoundedButton>
                  </Flex>
                )}
                {isAvailable && (
                  <Flex alignItems="center">
                    <Body fontWeight={FONT_WEIGHTS.BOLD} mr={30}>
                      {`${formatPrice(get(data, 'offeredObjectPrices.monthlyTotal'))} / month`}
                    </Body>
                    <FilledRoundedButton
                      px={30}
                      py={10}
                      onClick={onReserve}
                    >
                      Reserve
                    </FilledRoundedButton>
                  </Flex>
                )}
              </Box>
            </Flex>
          );
        }}
      </Query>
    </BaseFooter>
  );
};

ListingDetailsFooter.propTypes = {
  location: PropTypes.object.isRequired,
  onReserve: PropTypes.func.isRequired,
  openBreakdown: PropTypes.func.isRequired,
};

export default withRouter(ListingDetailsFooter);
