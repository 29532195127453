import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import { compose } from 'recompose';

import check from '../../assets/img/check.svg';
import newRoom from '../../assets/img/newRoom.jpg';
import { APP_ROUTES } from '../../constants';
import { GET_PLACE_FILLED_STEP } from '../../graphQL/queries/getPlace';
import {
  DIDOT, dimMixin, fontMixin, fontSize,
} from '../../theme/styleUtils';
import Layout from '../common/Layout';
import Loading from '../common/Loading';
import { RoundedButton } from '../common/wizard/Footer';
import ListingShowcase from './showcase/ListingShowcase';
import Modal from '../common/Modal';
import OwnerProfileForm from '../welcome/forms/OwnerProfileForm';
import {
  FONT_FAMILIES, FONT_WEIGHTS, mediaQuery, NAVBAR_HEIGHT,
} from '../../theme/globalStyle';

export const ListingIntroBackground = styled(Box)`
  height: 100%;
  width: 100%;
  position: relative;
  background: url(${props => props.bgImage || newRoom});
  background-size: cover;
  background-position-y: 20%;
  ${dimMixin()}
`;

const IntroContentWrapper = styled(Flex)`
  color: white;
  z-index: 1;
`;

const Title = styled(Text)`
  position: relative;
`;

const ItalicNumber = styled(Text)`
  ${fontMixin(DIDOT)}
  ${fontSize(100)}
  position: relative;
  &:after {
    background-color: white;
    content: ' ';
    height: ${props => (props.thick ? '5px' : '2px')};
    width: 60px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    ${mediaQuery.tablet`display: none`}
    
  }
`;

const ItemText = styled(Text)`
  text-align: center;
  line-height: 1.6em;
`;

const GreenButton = styled(RoundedButton)`
  color: ${props => props.theme.colors.whiteGray};
  background-color: ${props => props.theme.colors.green};
  border-color: ${props => props.theme.colors.green};
  width: 160px;
  height: 35px;
  padding-top: 9px;
  &:hover {
    background-color: ${props => props.theme.colors.green};
  }
`;

const CheckImage = styled(Image)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const FormPartContainer = styled(Flex)`
  position: relative;
`;

const IntroWrapper = styled(Flex)`
  min-height: calc(100vh - ${NAVBAR_HEIGHT});
`;

const BackButton = styled(Text).attrs({
  fontSize: 17,
  fontFamily: FONT_FAMILIES.AVENIR_LIGHT,
  fontWeight: FONT_WEIGHTS.BOLD,
})`
  position: relative;
  align-self: flex-start;
  cursor: pointer;
  :before {
    content: '<';
    font-size: 20px;
    position: relative;
    left: -10px;
    transition: left .3s ease-in-out;
  }
  &:hover {
    :before {
      left: -20px;
    }
  }
`;

export const FlowPart = ({
  index, text, to, isCompleted, isContinueEnabled, bg,
}) => (
  <FormPartContainer
    flexDirection="column"
    width={[0.85, 0.85, 320]}
    alignItems="center"
    bg={bg || 'rgba(120,120,120,0.75)'}
    mx={10}
    py={50}
    px={30}
    my={20}
  >
    {isCompleted && <CheckImage src={check} height={32} />}
    <ItalicNumber mb={[-20, -20, 20]} fontSize={100} fontFamily={FONT_FAMILIES.AVENIR_LIGHT}>
      0
      {index}
    </ItalicNumber>
    <ItemText
      thick={isCompleted}
      hiddenBar={[true, true, false]}
      mb={20}
      fontSize={22}
      fontFamily={FONT_FAMILIES.AVENIR_LIGHT}
    >
      {text}
    </ItemText>
    <Box mt="auto">
      {isContinueEnabled && to && (
      <Link to={to}>
        <GreenButton fontFamily={FONT_FAMILIES.AVENIR_LIGHT} fontSize={17}>
          {isCompleted ? 'Edit' : 'Continue'}
        </GreenButton>
      </Link>
      )}
    </Box>
  </FormPartContainer>
);

FlowPart.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  isCompleted: PropTypes.bool.isRequired,
  isContinueEnabled: PropTypes.bool.isRequired,
  bg: PropTypes.string,
};

const getStepLinks = (listingTypeCode, match) => [
  APP_ROUTES.LISTINGS.PLACE_BASICS(match.params.id),
  APP_ROUTES.LISTINGS.PLACE_BOOKING_RULES(match.params.id),
  listingTypeCode === 'entire_place'
    ? APP_ROUTES.LISTINGS.PLACE_PRICES(match.params.id)
    : APP_ROUTES.LISTINGS.PLACE_ROOMS(match.params.id),
];

const ListingsIntro = ({
  t, match, history, data, loading, ...props
}) => {
  const listingTypeCode = get(data, 'place.listingType.code', 'entire_place');
  const filledStep = get(data, 'place.filledStep');
  const stepLinks = getStepLinks(listingTypeCode, match);
  const [isModalOpened, setIsModalOpened] = useState(
    get(props, 'location.isModalOpened', false),
  );
  if (loading) {
    return <Loading />;
  }

  if (filledStep === 3) {
    return (
      <Layout>
        <ListingShowcase listingTypeCode={listingTypeCode} placeId={match.params.id} />
      </Layout>
    );
  }

  return (
    <IntroWrapper
      flexDirection="column"
      alignItems="center"
    >
      <Helmet>
        <title>{t('intro.new')}</title>
      </Helmet>
      <ListingIntroBackground>
        <IntroContentWrapper flexDirection="column" justifyContent="flex-start" alignItems="center" pb={100}>
          <BackButton ml="5vw" mt={20} onClick={() => history.push(APP_ROUTES.DASHBOARD.LANDLORD.LISTINGS())}>Listings</BackButton>
          <Title fontSize={40} fontFamily={FONT_FAMILIES.AVENIR_BOLD} mt={30} mb={10}>{t('intro.title')}</Title>
          <Flex mt={30} flexDirection={['column', 'column', 'row']} width={1} justifyContent="center" alignItems={['center', 'center', 'stretch']}>
            <FlowPart
              text={t(`intro.parts.${listingTypeCode}.1`)}
              index={1}
              isCompleted={!loading && filledStep > 0}
              isContinueEnabled={(!loading && filledStep >= 0) || !match.params.id}
              to={stepLinks[0]}
            />
            <FlowPart
              text={t(`intro.parts.${listingTypeCode}.2`)}
              index={2}
              isCompleted={!loading && filledStep > 1}
              isContinueEnabled={!loading && filledStep >= 1}
              to={stepLinks[1]}
            />
            <FlowPart
              text={t(`intro.parts.${listingTypeCode}.3`)}
              index={3}
              isCompleted={!loading && filledStep > 2}
              isContinueEnabled={!loading && filledStep >= 2}
              to={stepLinks[2]}
            />
          </Flex>
        </IntroContentWrapper>
      </ListingIntroBackground>

      {isModalOpened && (
        <Modal onClose={() => setIsModalOpened(false)}>
          <OwnerProfileForm onClose={() => setIsModalOpened(false)} />
        </Modal>
      )}
    </IntroWrapper>
  );
};


ListingsIntro.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(GET_PLACE_FILLED_STEP, {
    options: props => ({
      variables: {
        id: get(props, 'match.params.id'),
      },
    }),
  }),
);

export default enhance(ListingsIntro);
