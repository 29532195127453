import { css } from 'styled-components';

const BASE_FONT_SIZE = 16;

export const transitionBackgroundColor = length => css`
    transition: color ${length} ease-in-out,
                background-color ${length} ease-in-out;
  `;

export const fontSize = size => css`
  font-size: ${size / BASE_FONT_SIZE}em;
`;

export const dimMixin = (dim = 0.4) => css`
  position: relative;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: ' ';
    background-color: rgba(10, 10, 10, ${dim});
    background-size: cover;
  }
  * {
    z-index: 1;
    position: relative;
  }
`;

export const NORMAL = 'normal';
export const THICK = 'thick';
export const THICK5 = 'thick5';
export const BOLD = 'bold';
export const LIGHT = 'light';
export const LULO = 'lulo-large';
export const DIDOT = 'didot';

export const fontMixin = (name) => {
  switch (name) {
    case NORMAL:
    case LULO:
      return css`
        font-family: AvenirLTPro-Medium;
      `;
    case THICK:
    case THICK5:
    case BOLD:
      return css`
          font-family: AvenirLTPro-Heavy;
      `;
    case LIGHT:
    case DIDOT:
      return css`
        font-family: AvenirLTPro-Light;
      `;
    default:
      return css`
        font-family: AvenirLTPro-Medium;
      `;
  }
};
