import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { scrollToFirstError } from '../../../../utils/formHelpers';
import { FormPart, FormSubPart } from '../../../common/form/FormWrapper';
import TextInput from '../../../common/form/TextInput';
import Photos from '../media/Photos';
import { Separator } from '../../../common/form/Separator';
import { shorterThan } from '../../../../utils/formValidation';
import ExposeState from '../../../common/form/ExposeState';

const MediaForm = ({
  script, title, onNext, onSubmit, t, exposeState,
}) => (
  <Form
    initialValues={{ script, title }}
    onSubmit={onSubmit}
    render={({ handleSubmit, submitErrors, errors }) => {
      onNext(async () => {
        await handleSubmit();
        scrollToFirstError(submitErrors, errors);
      });
      return (
        <form onSubmit={handleSubmit}>
          <ExposeState exposeState={exposeState} />
          <FormPart title={t('media.title')}>
            <FormSubPart subText={t('media.subtitle')}>
              <TextInput name="title" placeholder={t('media.placeholder')} validate={shorterThan(150)} />
            </FormSubPart>
          </FormPart>
          <Separator />
          <FormPart title={t('script.title')}>
            <FormSubPart subText={t('script.subtitle')}>
              <TextInput name="script" textarea placeholder={t('script.placeholder')} />
            </FormSubPart>
          </FormPart>
          <Separator />
          <Photos />
        </form>
      );
    }}
  />
);

MediaForm.propTypes = {
  title: PropTypes.string,
  script: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  exposeState: PropTypes.func.isRequired,
};

export default withNamespaces('listingForm')(MediaForm);
