import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TooltipRaw } from 'react-tippy';

const Tooltip = ({ children, content, trigger }) => (
  <TooltipRaw title={content} position="bottom" trigger={trigger}>
    {children}
  </TooltipRaw>
);

Tooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string.isRequired,
  trigger: PropTypes.string,
};

Tooltip.defaultProps = {
  trigger: 'mouseenter',
};

export default Tooltip;
