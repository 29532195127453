import get from 'lodash/get';
import React from 'react';
import { Query } from 'react-apollo';
import { GET_CURRENT_USER } from '../../graphQL/queries';
import { NavBar } from '../common/NavBar';
import LandlordNavBar from '../dashboard/landlord/LandlordNavBar';
import TenantNavBar from '../dashboard/tenant/TenantNavBar';
import MainNavBar from './MainNavBar';

const NavBarByRole = () => (
  <Query query={GET_CURRENT_USER}>
    {({ loading, data }) => {
      if (loading) return <NavBar />;

      const role = get(data, 'user.role.name');

      if (role === 'landlord') {
        return <LandlordNavBar />;
      }

      if (role === 'tenant') {
        return <TenantNavBar />;
      }

      return <MainNavBar />;
    }}
  </Query>
);

export default NavBarByRole;
