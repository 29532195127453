import { Box } from 'rebass';
import styled from 'styled-components';

const StickyContainer = styled(Box)`
  position: sticky;
  top: ${props => props.top || '0'};
  background-color: #fff;
  width: 100%;
  z-index: 1;
`;

export default StickyContainer;
