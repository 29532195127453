import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import { Box } from 'rebass';
import { withNamespaces } from 'react-i18next';
import { Separator } from '../common/form/Separator';

import { fontMixin, fontSize, THICK } from '../../theme/styleUtils';
import { FilledRoundedButton } from '../common/wizard/Footer';
import { SEARCH_BAR_DEFAULT_VALUES } from '../search/SearchBar';

const BigText = styled.h1`
  ${fontMixin(THICK)}
  ${fontSize(30)}
  letter-spacing: 1.5px;
  line-height: 36px;
  margin-top: 30px;
`;

const TextContent = styled.p`
  ${fontSize(17)}
  letter-spacing: 0.85px;
  line-height: 20.4px;
`;

class FreedomShowcase extends Component {
  render() {
    const { t } = this.props;
    return (
      <Box width={[1, 1, 1 / 2, 1 / 2]} mb={50} alignSelf={['center', 'center', 'flex-start', 'flex-start']}>
        <Link to={`/listings?${queryString.stringify(SEARCH_BAR_DEFAULT_VALUES)}`}>
          <FilledRoundedButton mb={[15, 30, 30]} px={[30, 60, 60]} py={[10, 20, 20]}>
            {t('header.button')}
          </FilledRoundedButton>
        </Link>
        <Separator />
        <BigText>{t('freeway.title')}</BigText>
        <TextContent>{t('freeway.desc')}</TextContent>
      </Box>
    );
  }
}

FreedomShowcase.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('home')(FreedomShowcase);
