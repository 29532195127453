import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';
import logo from '../assets/img/kumpfy-logo.svg';
import { fontMixin, fontSize, LULO } from '../theme/styleUtils';
import { Heading } from './common/Text';
import { RoundedButton } from './common/wizard/Footer';

const Wrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`;

const NavBarTitle = styled(Link)`
  ${fontMixin(LULO)}
  ${fontSize(10)}
  color: ${props => props.theme.colors.purplePrimary};
  text-decoration: none;
`;

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <Flex px={20} mb={20} justifyContent="center" alignItems="center">
            <Image src={logo} height={46} mr={20} />
            <NavBarTitle to="/">Downroots</NavBarTitle>
          </Flex>
          <Heading mb={20}>Something went wrong</Heading>
          <Link to="/">
            <RoundedButton>Please try again</RoundedButton>
          </Link>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
