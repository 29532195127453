import React, { Component } from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import cozySalon from '../../assets/img/cozy-salon.webp.png';
import indoorStairs from '../../assets/img/indoors-stairs.webp.png';
import addMilk from '../../assets/img/add-milk.webp.png';
import sleepEasy from '../../assets/img/sleep-easy.webp.png';
import PhotoCard from '../cards/PhotoCard';

class Cards extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;
    return (
      <Flex justifyContent="center" flexWrap="wrap">
        <PhotoCard
          imgSrc={cozySalon}
          label={t('cards.salon.label')}
          title={t('cards.salon.title')}
          desc={t('cards.salon.desc')}
          buttonText={t('cards.salon.buttonText')}
        />
        <PhotoCard
          imgSrc={indoorStairs}
          label={t('cards.stairs.label')}
          title={t('cards.stairs.title')}
          desc={t('cards.stairs.desc')}
          buttonText={t('cards.stairs.buttonText')}
        />
        <PhotoCard
          imgSrc={addMilk}
          label={t('cards.milk.label')}
          title={t('cards.milk.title')}
          desc={t('cards.milk.desc')}
          buttonText={t('cards.milk.buttonText')}
        />
        <PhotoCard
          imgSrc={sleepEasy}
          label={t('cards.sleep.label')}
          title={t('cards.sleep.title')}
          desc={t('cards.sleep.desc')}
          buttonText={t('cards.sleep.buttonText')}
        />
      </Flex>
    );
  }
}

export default withNamespaces('home')(Cards);
