import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

const Button = styled(Flex)`
  border-radius: 50%;
  border: 1px solid #000;
  text-transform: none;
  text-decoration: none;
  color: #000;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
Button.defaultProps = {
  fontSize: 17,
  justifyContent: 'center',
  alignItems: 'center',
};

const CloseButton = ({ children, ...props }) => (
  <Button {...props}>{children || 'X'}</Button>
);

export default CloseButton;
