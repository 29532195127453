import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import { AVAILABLE_PHOTO_VERSIONS } from '../../constants';
import { GET_PHOTO } from '../../graphQL/queries/photo';

const PhotoLoader = ({ id, version, children }) => (
  <Query query={GET_PHOTO} variables={{ id, version }}>
    {({ loading, error, data }) => {
      if (loading) {
        return null;
      }

      if (error) {
        return error;
      }

      return children({ data });
    }}
  </Query>
);

PhotoLoader.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  version: PropTypes.oneOf(Object.values(AVAILABLE_PHOTO_VERSIONS)),
  children: PropTypes.func.isRequired,
};

export default PhotoLoader;
