import queryString from 'query-string';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import DirectionButton from '../common/buttons/DirectionButton';
import { fontMixin, fontSize, BOLD } from '../../theme/styleUtils';
import { SEARCH_BAR_DEFAULT_VALUES } from '../search/SearchBar';

const FlexAligned = styled(Flex)`
  text-align: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 315px;
  //max-width: 310px;
  width: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const LabeledImg = styled.div`
  position: relative;
`;

const CardTitle = styled.div`
  ${fontMixin(BOLD)}
  ${fontSize(24)}
  letter-spacing: 0.05em;
  margin-top: 20px;
`;

const CardDescription = styled.p`
  ${fontSize(17)}
  margin-top: 10px;
`;

const SmallerDirectionButton = styled(DirectionButton)`
  ${fontSize(17)}
  color: ${props => props.theme.colors.purplePrimary};
  position: relative;
  &::after {
    ${fontSize(20)}
    right: 10px;
    font-weight: 100;
    top: 1px;
  }
`;

export default class PhotoCard extends Component {
  static propTypes = {
    imgSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  static defaultProps = {
    width: [1, 1 / 2, 1 / 2, 1 / 4],
  };

  render() {
    const {
      width, imgSrc, title, desc, buttonText,
    } = this.props;
    return (
      <FlexAligned
        flexDirection="column"
        width={width}
        alignItems="center"
        pb={35}
        px="1px"
      >
        <Box width={310}>
          <LabeledImg>
            <ImageWrapper>
              <Image src={imgSrc} alt="" height={315} />
            </ImageWrapper>
          </LabeledImg>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{desc}</CardDescription>
          <Link to={`/listings?${queryString.stringify(SEARCH_BAR_DEFAULT_VALUES)}`} style={{ textDecoration: 'none' }}>
            <SmallerDirectionButton>{buttonText}</SmallerDirectionButton>
          </Link>
        </Box>
      </FlexAligned>
    );
  }
}
