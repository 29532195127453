import union from 'lodash/union';
import noop from 'lodash/noop';
import times from 'lodash/times';
import get from 'lodash/get';
import find from 'lodash/find';
import some from 'lodash/some';
import every from 'lodash/every';
import map from 'lodash/map';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, Query, withApollo } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import { compose } from 'recompose';
import { OnChange } from 'react-final-form-listeners';
import { APP_ROUTES } from '../../constants';
import { UPDATE_PLACE_BOOKING_RULES } from '../../graphQL/mutations/createOrUpdatePlace';
import { GET_FACILITIES_RANSACK_SEARCH } from '../../graphQL/queries/facilities';
import { GET_BACKGROUND_CHECKS, GET_GENDER_PREFERENCES, GET_PLACE_BOOKING_RULES } from '../../graphQL/queries/place';
import { scrollToFirstError } from '../../utils/formHelpers';
import { emailFormat, required, validateChosenAmount } from '../../utils/formValidation';
import Checkbox from '../common/form/Checkbox';
import Dropdown from '../common/form/Dropdown';
import FieldError from '../common/form/FieldError';
import { FormPart, FormSubPart, FormWrapper } from '../common/form/FormWrapper';
import Radio from '../common/form/Radio';
import { Separator } from '../common/form/Separator';
import TextInput from '../common/form/TextInput';
import Loading from '../common/Loading';
import Footer from '../common/wizard/Footer';
import WizardStep from '../common/wizard/WizardStep';
import { mapApiErrors } from '../../utils/errorHandling';
import { NarrowLayout } from '../common/Layout';
import ExposeState from '../common/form/ExposeState';


class BookingRules extends Component {
  state = {
    submitting: false,
    myHostFacility: {},
  };

  listingTypeCode = 'entire_place';

  componentDidMount = () => {
    const { client } = this.props;
    client.query({
      query: GET_FACILITIES_RANSACK_SEARCH,
      variables: {
        queries: [
          { condition: 'type_eq', value: 'ContactAvailable' },
        ],
      },
    }).then(({ data }) => {
      if (data) {
        const myHostFacility = find(get(data, 'facilitiesRansackSearch', []), ['code', 'my_host']);
        // const myHostFacility = find(get(data, 'facilitiesRansackSearch', []), ['name', 'My host']);
        this.setState({ myHostFacility });
      }
    });
  };

  onPrev = () => {
    const { history, match } = this.props;

    if (this.listingTypeCode === 'entire_place') {
      history.push(APP_ROUTES.LISTINGS.PLACE_BEDS_AND_BATHS(match.params.id));
    } else {
      history.push(APP_ROUTES.LISTINGS.PLACE_MEDIA(match.params.id));
    }
  };

  onNext = noop;

  onSubmit = async (values) => {
    try {
      const { history, match, mutate } = this.props;
      const { errors } = await mutate({
        variables: {
          ...values,
          id: match.params.id,
        },
      });
      if (errors) {
        return mapApiErrors(errors);
      }

      history.push(APP_ROUTES.LISTINGS.PLACE_INTRO(match.params.id));
    } catch (e) {
      console.error(e);
    }
  };

  getStartDateWindowOptions = () => times(6, i => (i + 1) * 2).map(i => ({
    label: `${i} weeks into the future`,
    value: i,
  }));

  getPreparationTimeOptions = () => times(7, i => (i + 1)).map(i => ({
    label: `${i} days blocked out before and after each tenancy`,
    value: i,
  }));

  renderFormSection = ({
    title, subtitle, facilityType, name, validate, form,
  }) => (
    <FormSubPart subTitle={title} subText={subtitle}>
      <Query
        query={GET_FACILITIES_RANSACK_SEARCH}
        variables={{
          queries: [
            { condition: 'type_eq', value: facilityType }],
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return <Loading />;
          }
          const { t } = this.props;
          return (
            <Flex flexDirection="column" flexWrap="wrap">
              {data.facilitiesRansackSearch.map((facility) => {
                const isRequired = includes(t('bookingRules.requiredFacilityNames', { returnObjects: true }), facility.name);
                return (
                  <Checkbox
                    disabled={isRequired}
                    key={facility.id}
                    label={facility.name}
                    name={name}
                    value={facility.id}
                    validate={validate}
                    format={(val) => {
                      if (isRequired) {
                        return union(val, [facility.id]);
                      }
                      return val;
                    }}
                    subLabel={facility.description}
                  />
                );
              })}
              { name === 'tenantTypeIds' && (
                <OnChange name="tenantTypeIds">
                  {(value, previous) => {
                    const anyoneId = get(find(data.facilitiesRansackSearch, ['name', 'Anyone']), 'id');
                    const anyoneChanged = (from, to) => every(from, v => v !== anyoneId) && some(to, v => v === anyoneId);
                    if (anyoneChanged(previous, value)) {
                      // check all boxes
                      form.change('tenantTypeIds', map(data.facilitiesRansackSearch, 'id'));
                    } else if (anyoneChanged(value, previous)) {
                      // uncheck all boxes
                      form.change('tenantTypeIds', []);
                    }
                  }}
                </OnChange>
              )}
            </Flex>
          );
        }}
      </Query>
      <FieldError direction="column" name={name} />
    </FormSubPart>
  );

  renderForm = ({ data: { place } }) => (
    <Query query={GET_GENDER_PREFERENCES}>
      {({ loading: genderLoading, data: genderData }) => {
        if (genderLoading) {
          return (<Loading />);
        }
        const defaultGenderId = genderData.genderPreferences.map(({ id }) => id)[0];
        return (
          <Form
            initialValues={{
              ...place,
              tenantTypeIds: get(place, 'tenantTypes', []).map(({ id }) => id),
              contactAvailableIds: union(
                get(place, 'contactAvailables', []).map(({ id }) => id),
                get(place, 'requiredContactAvailableIds', []),
              ),
              depositOptionIds: union(
                get(place, 'depositOptions', []).map(({ id }) => id),
                get(place, 'requiredDepositOptionIds', []),
              ),
              houseRuleIds: get(place, 'houseRules', []).map(({ id }) => id),
              backgroundCheckIds: get(place, 'backgroundChecks', []).map(({ id }) => id),
              genderPreferenceId: get(place, 'genderPreferenceId') || defaultGenderId,
            }}
            onSubmit={this.onSubmit}
            render={({
              handleSubmit, submitErrors, errors, form, form: { getState },
            }) => {
              this.onNext = async () => {
                await handleSubmit();
                scrollToFirstError(submitErrors, errors);
              };

              return (
                <form onSubmit={handleSubmit}>
                  <ExposeState exposeState={({ submitting }) => this.setState({ submitting })} />
                  <FormWrapper>
                    <FormPart title="People">
                      {this.renderFormSection({
                        facilityType: 'TenantType',
                        name: 'tenantTypeIds',
                        title: 'Who will you accept',
                        validate: validateChosenAmount(1),
                        form,
                      })}
                      <FormSubPart subTitle="Gender preference">
                        <FieldError direction="column" name="genderPreferenceId">
                          <Flex flexDirection="column" flexWrap="wrap">
                            {genderData.genderPreferences.map(genderPreference => (
                              <Box key={genderPreference.id}>
                                <Radio label={genderPreference.name} name="genderPreferenceId" value={genderPreference.id} validate={required} />
                              </Box>
                            ))}
                          </Flex>
                        </FieldError>
                      </FormSubPart>
                    </FormPart>
                    <Separator />
                    <FormPart title="Availability">
                      <FormSubPart subTitle="Booking window" subText="When available, how far into the future can an applicant request a start date">
                        <Dropdown name="startDateWindow" validate={required} options={this.getStartDateWindowOptions()} />
                      </FormSubPart>
                      <FormSubPart subTitle="Preparation time" subText="The number of days blocked out before and after each tenancy">
                        <Dropdown name="preparationTime" validate={required} options={this.getPreparationTimeOptions()} />
                      </FormSubPart>
                      {this.renderFormSection({
                        facilityType: 'ContactAvailable',
                        name: 'contactAvailableIds',
                        title: 'Who is available for tenants',
                        subtitle: 'Who can the tenants contact during their tenancy',
                      })}


                      {this.state.myHostFacility && get(getState(), 'values.contactAvailableIds', []).includes(this.state.myHostFacility.id) && (
                        <FormSubPart>
                          <Flex flexDirection="row">
                            <Flex flexDirection="column" px="10px" width="45%">
                              <TextInput name="contactAvailableFirstName" placeholder="First name" validate={required} topError />
                              <TextInput name="contactAvailableEmail" placeholder="email" validate={value => (required(value) || emailFormat(value))} />
                            </Flex>
                            <Flex flexDirection="column" px="10px" width="45%">
                              <TextInput name="contactAvailableLastName" placeholder="Last name" validate={required} topError />
                              <TextInput name="contactAvailablePhone" placeholder="phone" />
                            </Flex>
                          </Flex>
                        </FormSubPart>
                      )}
                    </FormPart>
                    <Separator />
                    <FormPart title="Deposit options">
                      {this.renderFormSection({
                        facilityType: 'DepositOption',
                        name: 'depositOptionIds',
                        title: 'Applicants will be able to choose from the options you select',
                      })}
                    </FormPart>
                    <Separator />
                    <FormPart title="Background checks">
                      <FormSubPart
                        subTitle="Applicants will be able to choose from the options you select."
                        subText="Offer worry free bookings to appeal to more applicants. Nice to have but rarely used. Applicants can move out for the cost of 1 weeks rent."
                      >
                        <Query query={GET_BACKGROUND_CHECKS}>
                          {({ loading, data }) => {
                            if (loading) {
                              return <Loading />;
                            }

                            return (
                              <Flex flexDirection="column" flexWrap="wrap">
                                {data.backgroundChecks.map((backgroundCheck) => {
                                  const isRequired = backgroundCheck.code === 'downroots_verifications';
                                  return (
                                    <Checkbox
                                      key={backgroundCheck.id}
                                      label={backgroundCheck.name}
                                      name="backgroundCheckIds"
                                      value={backgroundCheck.id}
                                      disabled={isRequired}
                                      format={(val) => {
                                        if (isRequired) {
                                          return union(val, [backgroundCheck.id]);
                                        }
                                        return val;
                                      }}
                                    />
                                  );
                                })}
                              </Flex>
                            );
                          }}
                        </Query>
                      </FormSubPart>
                    </FormPart>
                    <Separator />
                    <FormPart title="Move out guarantee">
                      <FormSubPart
                        subTitle="Tenants can end the lease up to 2 days after they move in."
                        subText="Offer worry free bookings to appeal to more applicants. Nice to have but rarely used. Applicants can move out for the cost of 1 weeks rent"
                      >
                        <FieldError direction="column" name="moveOutGuarantee">
                          <Checkbox label="Yes I will offer this promotion" name="moveOutGuarantee" />
                        </FieldError>
                      </FormSubPart>
                    </FormPart>
                    <Separator />
                    <FormPart title="House rules">
                      {this.renderFormSection({
                        facilityType: 'HouseRule',
                        name: 'houseRuleIds',
                      })}
                      <FormSubPart subTitle="Notes" subText="Tell tenants anything that's important to you">
                        <TextInput name="notes" placeholder="Type here" textarea />
                      </FormSubPart>
                    </FormPart>
                  </FormWrapper>
                </form>
              );
            }}
          />
        );
      }}
    </Query>
  );

  render() {
    const { t } = this.props;
    return (
      <NarrowLayout>
        <WizardStep query={GET_PLACE_BOOKING_RULES}>
          {({ data }) => {
            this.listingTypeCode = get(data, 'place.listingType.code');
            return this.renderForm({ t, data });
          }}
        </WizardStep>
        <Footer
          onPrev={this.onPrev}
          onNext={() => this.onNext()}
          disabledNext={this.state.submitting}
          isPlace
        />
      </NarrowLayout>
    );
  }
}

BookingRules.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  withApollo,
  graphql(UPDATE_PLACE_BOOKING_RULES),
);

export default enhance(BookingRules);
