import PropTypes from 'prop-types';
import React from 'react';
import { compose, Query } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Flex, Text } from 'rebass';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import { GET_DASHBOARD_LISTINGS } from '../../../../graphQL/queries/landlordDashboard';
import Loading from '../../../common/Loading';
import Error from '../../../common/Error';
import ListingsList from './ListingsList';
import RenderIfNotEmpty from '../../common/RenderIfNotEmpty';
import { NarrowLayout } from '../../../common/Layout';
import ListingItem from '../common/ListingItem';
import { APP_ROUTES } from '../../../../constants';

/* eslint-disable react/no-array-index-key */
const ListingsContainer = ({ t, history: { push } }) => (
  <NarrowLayout>
    <Flex flexDirection="column">
      <Helmet>
        <title>{t('listings')}</title>
      </Helmet>
      <Query query={GET_DASHBOARD_LISTINGS}>
        {({ loading, data, error }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;

          const sortedListings = sortBy(data.dashboardListings, (listing) => {
            const rank = {
              incomplete: 1,
              unlisted: 2,
              listed: 3,
            };
            return rank[listing.category];
          });
          const results = values(groupBy(sortedListings, 'category'));
          return (
            <>
              <Text fontSize={30} fontWeight={700} mt={50} mb={15}>
                New listing
              </Text>
              <ListingItem createNew redirect={() => push(APP_ROUTES.LISTINGS.PLACE_BASICS())} />
              <RenderIfNotEmpty array={results} name={t('listings')}>
                {
                  results.map((listings, idx) => (
                    <ListingsList key={`listings-list-${idx}`} items={listings} />
                  ))}
              </RenderIfNotEmpty>
            </>
          );
        }}
      </Query>
    </Flex>
  </NarrowLayout>
);

ListingsContainer.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listings'),
);

export default enhance(ListingsContainer);
