import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image, Flex, Text } from 'rebass';
import { FONT_FAMILIES } from '../../theme/globalStyle';

const BorderFlex = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.pearlGray};
  background-color: white;
  height: 166px;
`;

const WayCardTitle = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 20,
})``;

const WayCardText = styled(Text).attrs({ fontSize: 15 })``;


export default class WayPhotoCard extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
  };

  render() {
    const {
      imgSrc,
      title,
      desc,
    } = this.props;
    return (
      <Flex flexDirection="column" alignItems="center" width={[0.8, 0.7, 0.3]} mb={50}>
        <Image src={imgSrc} width={1} />
        <BorderFlex
          flexDirection="column"
          width="90%"
          mt={-15}
          alignItems="center"
          justifyContent="space-evenly"
          p={10}
        >
          <WayCardTitle
            textAlign="center"
            color="darkGrayBlue"
          >
            {title}
          </WayCardTitle>
          <WayCardText
            textAlign="center"
            color="darkGrayBlue"
          >
            {desc}
          </WayCardText>
        </BorderFlex>
      </Flex>
    );
  }
}
