import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';

import OwnersHeader from './OwnersHeader';
import WhyDownroots from './WhyDownroots';
import DownrootsFlow from './DownrootsFlow';
import DownrootsService from './DownrootsService';
import ControlGallery from './ControlGallery';
import DownrootsTours from './DownrootsTours';
import LandlordBenefits from './LandlordBenefits';
import Layout from '../common/Layout';

class OwnersWrapper extends Component {
  render() {
    const { t } = this.props;
    return (
      <Box>
        <Helmet>
          <title>{t('owners')}</title>
        </Helmet>
        <OwnersHeader />
        <Layout>
          <Flex width={1} alignItems="center" flexDirection="column" px="5%">
            <WhyDownroots />
            <DownrootsFlow />
            <DownrootsTours />
            <ControlGallery />
            <DownrootsService />
            <LandlordBenefits />
          </Flex>
        </Layout>
      </Box>
    );
  }
}

OwnersWrapper.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('owners')(OwnersWrapper);
