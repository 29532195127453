import React from 'react';
import PropTypes from 'prop-types';
import {
  Text, Flex, Box, Image,
} from 'rebass';
import styled from 'styled-components';
import {
  BedImage as MainImage,
  BedWrapper as Wrapper,
} from '../../../listings/place/BedList';
import { RoundedButton } from '../../../common/wizard/Footer';
import ProgressBar from '../../../common/ProgressBar';
import bedroomPlaceholder from '../../../../assets/img/bedroom-bg.jpg';
import camera from '../../../../assets/img/camera-icon.svg';
import cameraInactive from '../../../../assets/img/camera-inactive-icon.svg';
import { fontSize, fontMixin, BOLD } from '../../../../theme/styleUtils';
import { FONT_FAMILIES } from '../../../../theme/globalStyle';

const TitleText = styled(Text)`
  ${fontSize(22)}
  ${fontMixin(BOLD)}
`;

const SubTitleText = styled(Text)`
  ${fontSize(14)}
`;

const StatusText = styled(Text)`
  ${fontSize(18)}
  ${fontMixin(BOLD)}
  letter-spacing: 1.8px;
`;

const ProgressText = styled(Text)`
  ${fontSize(16)}
`;

const ListingItem = ({
  title,
  subTitle,
  status,
  redirect,
  progress,
  btnLabel,
  mainImageUrl,
  has3DTour,
  virtualTourBookedAt,
  show3DTour,
  percentage,
  createNew,
}) => (
  <Wrapper py={25} mb={35}>
    <MainImage src={mainImageUrl || bedroomPlaceholder} />
    <Flex
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
      width={1}
      ml={[10, 35, 35]}
    >
      <TitleText>
        {title}
      </TitleText>
      <SubTitleText mb={15}>
        {subTitle}
      </SubTitleText>
      <Flex width={1} justifyContent="space-between" alignItems="flex-end" mt={createNew && -35}>
        { createNew
          ? (
            <Text color="purplePrimary" fontSize={20} fontFamily={FONT_FAMILIES.AVENIR_NORMAL} fontWeight={650}>
              Create new listing
            </Text>
          ) : (
            <Box width={1}>
              <StatusText>
                {status ? 'Status' : 'Progress'}
              </StatusText>
              <ProgressText mb="8px">
                {progress}
              </ProgressText>
              <ProgressBar
                barColor={status && '#008bff'}
                progress={percentage}
              />
            </Box>
          )}
        <Flex alignItems="flex-end" flexDirection={['column', 'column', 'row']}>
          {show3DTour && (
            <Box mb={[15, 15, 0]}>
              <Image src={has3DTour ? camera : cameraInactive} width="auto" height={[32, 32, 64]} />
            </Box>
          )}
          <RoundedButton px={10} py={10} ml={[10, 30, 30]} width={[80, 120, 120]} onClick={() => redirect()}>
            {createNew ? 'Create new listing' : (virtualTourBookedAt ? '3d Tour booked' : btnLabel)}
          </RoundedButton>
        </Flex>
      </Flex>
    </Flex>
  </Wrapper>
);

ListingItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  progress: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  status: PropTypes.bool,
  percentage: PropTypes.number.isRequired,
  mainImageUrl: PropTypes.string,
  has3DTour: PropTypes.bool.isRequired,
  virtualTourBookedAt: PropTypes.string,
  redirect: PropTypes.func.isRequired,
  show3DTour: PropTypes.bool,
  createNew: PropTypes.bool,
};

ListingItem.defaultProps = {
  status: false,
  show3DTour: false,
  createNew: false,
};

export default ListingItem;
