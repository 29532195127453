import gql from 'graphql-tag';
import { TENANCY_PARTS } from '../fragments/tenancy';

export const GET_DASHBOARD_LISTINGS = gql`
  query GetDashBoardListings {
    dashboardListings {
      has3DTour
      listingType {
        name
        id
      }
      buildingType {
        name
        id
      }
      category
      progress
      percentage
      mainImageUrl
      location
      virtualTourBookedAt
      id
    }
  }
`;

export const GET_DASHBOARD_TENANCIES = gql`
  query __composed {
    leased: tenancies(section: leased) {
      ...TenancyParts
    }
    tenancyRequest: tenancies(section: tenancy_request) {
      ...TenancyParts
    }
    pastBookings: tenancies(section: past_booking) {
      ...TenancyParts
    }
  }
  ${TENANCY_PARTS}
`;
