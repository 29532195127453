import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $currentPassword: String,
    $password: String, 
    $passwordConfirmation: String
  ) {
    changePassword(input: {
      current_password: $currentPassword,
      password: $password, 
      password_confirmation: $passwordConfirmation
  })
    @rest(type: "DeviseResult", path: "user", method: "PUT") {
      message
      errors
      success
    }
  }
`;

export const SAVE_USER_SETTINGS = gql`
  mutation SaveUserSettings(
    $receiveMessages: Boolean
    $receiveBooking: Boolean
    $messagesAvailable: Boolean
    $profileAvailable: Boolean
    $payoutMethod: PayoutMethodInput
    $paymentMethod: PaymentMethod
  ) {
    editProfile(payload: {
      receiveBooking: $receiveBooking
      receiveMessages: $receiveMessages
      messagesAvailable: $messagesAvailable
      profileAvailable: $profileAvailable
      payoutMethod: $payoutMethod
      paymentMethod: $paymentMethod
    }) {
      id
    }
  }
`;

export const UPDATE_CARD = gql`
  mutation UpdateCard(
    $stripeToken: String
  ) {
    editProfile(payload: {
      stripeToken: $stripeToken
    }) {
      id
    }
  }
`;
