import gql from 'graphql-tag';

export const GET_USER_CARD = gql`
  query GetUserCard {
    user {
      id
      defaultCreditCard {
        last4
      }
    }
  }
`;

export const GET_USER_ROLE = gql`
  query GetUserRole {
    user {
      id
      role {
        id
        name
      }
    }
  }
`;
