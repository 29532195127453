import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import { FormSubPart, FormPart } from '../../../common/form/FormWrapper';

const VirtualTour = ({ virtualTourUrl, t }) => (
  <FormPart title={t('tour.title')}>
    <FormSubPart subText={t('tour.subtitle')}>
      <Box width={1} mt={15}>
        <iframe
          title="Sample Tour"
          width="100%"
          height="600"
          src={virtualTourUrl}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Box>
    </FormSubPart>
  </FormPart>
);

VirtualTour.propTypes = {
  virtualTourUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listingForm')(VirtualTour);
