import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Slider from 'react-slick';
import { Image, Flex, Box } from 'rebass';
import map from 'lodash/map';

import styled from 'styled-components';
import TitleDescription from '../text/TitleDescription';
import first from '../../assets/img/carousel/1.png';
import second from '../../assets/img/carousel/2.png';
import third from '../../assets/img/carousel/3.png';
import fourth from '../../assets/img/carousel/4.png';
import fifth from '../../assets/img/carousel/5.png';

const CustomSlider = styled(Slider)`
  height: 440px;
  overflow: hidden
`;

const carouselImages = [first, second, third, fourth, fifth];

const settings = {
  slidesToShow: 3,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1200,
  variableWidth: true,
  easing: 'ease-in-out',
  rows: 1,
  infinite: true,
  responsive: [
    {
      breakpoint: '72em',
      slidesToShow: 1,
    },
  ],
  lazyLoad: false,
  // useTransform: true,
  // cssEase: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
};

class ControlGallery extends Component {
  render() {
    const { t } = this.props;
    return (
      <Flex flexDirection="column" mt={50} mb={35} width={1}>
        <TitleDescription
          title={t('control.title')}
          desc={t('control.desc')}
          lineHeight="32px"
        />
        <Box width={1} mt={10}>
          <CustomSlider {...settings}>
            {map(carouselImages, (image, idx) => (
              <div key={`${image}-${idx}`}>
                <Image src={image} height={430} width={325} m={10} />
              </div>
            ))}
          </CustomSlider>
        </Box>
      </Flex>
    );
  }
}

export default withNamespaces('owners')(ControlGallery);
