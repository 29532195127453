import { Heading } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

const Headline = styled(Heading).attrs(props => ({
  fontFamily: FONT_FAMILIES.AVENIR_NORMAL,
  fontSize: props.fontSize ? props.fontSize : '50px',
}))``;

export default Headline;
