import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { Flex } from 'rebass';
import { GET_DASHBOARD_TENANCIES } from '../../../graphQL/queries/landlordDashboard';
import Error from '../../common/Error';
import Loading from '../../common/Loading';
import TenancyList from '../landlord/tenancies/TenancyList';

const TenancyContainer = ({ t }) => (
  <Flex flexDirection="column">
    <Helmet>
      <title>{t('tenancies')}</title>
    </Helmet>
    <Query query={GET_DASHBOARD_TENANCIES}>
      {({ data, error, loading }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <>
            <TenancyList
              title="Bookings"
              items={data.tenancyRequest}
            />
            <TenancyList
              title={t('sections.leased')}
              items={data.leased}
              emptyMessage={t('sections.noLeases')}
            />
            <TenancyList
              title={t('sections.pastBookings')}
              items={data.pastBookings}
            />
          </>
        );
      }}
    </Query>
  </Flex>
);

TenancyContainer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('tenancies')(TenancyContainer);
