import React, { Component } from 'react';
import { Text, Flex } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../theme/globalStyle';

const Title = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 40,
})``;

const Desc = styled(Text).attrs({
  fontSize: 20,
})``;


export default class TitleDescription extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    width: [1, 1, 4 / 5, 3 / 5],
    lineHeight: '1.6em',
  };

  render() {
    const {
      width,
      title,
      lineHeight,
      desc,
    } = this.props;
    return (
      <Flex width={width} flexDirection="column" justifyContent="center" alignItems="flex-start">
        <Title
          color="darkBrown"
          my={10}
        >
          {title}
        </Title>
        <Desc
          my={10}
          lineHeight={lineHeight}
        >
          {desc}
        </Desc>
      </Flex>
    );
  }
}
