import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import countryList from 'react-select-country-list';
import { required } from '../../utils/formValidation';
import Dropdown from '../common/form/Dropdown';
import TextInput from '../common/form/TextInput';

const Company = ({ legalOwnerType }) => (
  <Box>
    <Box>
      <Box mb={15}>
        <TextInput variant="bordered" p={15} name={`legalOwner.${legalOwnerType}.companyName`} placeholder="Company name" validate={required} />
      </Box>
    </Box>
    <Flex mb={15}>
      <Box width={1 / 2} pr="10px">
        <Dropdown name={`legalOwner.${legalOwnerType}.registeredCountry`} placeholder="Country of registration" options={countryList().getData()} />
      </Box>
      <Box width={1 / 2} pl="10px">
        <TextInput variant="bordered" name={`legalOwner.${legalOwnerType}.registeredNumber`} placeholder="Registered number" validate={required} />
      </Box>
    </Flex>
  </Box>
);

Company.propTypes = {
  legalOwnerType: PropTypes.oneOf(['me', 'my_company', 'my_client', 'my_client_company']),
};

export default Company;
