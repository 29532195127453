import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { graphql } from 'react-apollo';
import { SIGN_IN_BY_MASTER_TOKEN } from '../../graphQL/mutations/signIn';
import { showError } from '../../utils/toast';

class MasterLogin extends Component {
  async componentDidMount() {
    try {
      const { mutate, history } = this.props;
      const { masterToken } = this.props.match.params;

      const { errors } = await mutate({
        variables: { masterToken },
      });

      if (errors) {
        showError(get(errors, '[0].message', 'Error'));
        history.push('/');
        return;
      }

      sessionStorage.setItem('downrootsMasterToken', true);
      history.push('/');
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return null;
  }
}

MasterLogin.propTypes = {
  match: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
};

const enhance = graphql(SIGN_IN_BY_MASTER_TOKEN);

export default enhance(MasterLogin);
