import gql from 'graphql-tag';

export const ASSIGN_CHOSEN_FACILITIES = gql`
  mutation AssignChosenFacilities(
    $id: ID!
    $facilityIds: [ID]
  ) {
    assignChosenFacilities(payload: {
      id: $id
      facilityIds: $facilityIds
    }) {
      id
    }
  }
`;
