import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { Flex } from 'rebass';
import { GET_DASHBOARD_TENANCIES } from '../../../../graphQL/queries/landlordDashboard';
import { NarrowLayout } from '../../../common/Layout';
import Loading from '../../../common/Loading';
import Error from '../../../common/Error';
import TenancyList from './TenancyList';

const TenancyContainer = ({ t }) => (
  <NarrowLayout>
    <Flex flexDirection="column">
      <Helmet>
        <title>{t('tenancies')}</title>
      </Helmet>
      <Query query={GET_DASHBOARD_TENANCIES}>
        {({ data, error, loading }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;

          return (
            <>
              <TenancyList
                title="Tenancy requests"
                items={data.tenancyRequest}
                emptyMessage="You have no tenancies requests"
              />
              <TenancyList
                title={t('sections.leased')}
                items={data.leased}
                emptyMessage={t('sections.noLeases')}
              />
              <TenancyList
                title={t('sections.pastBookings')}
                items={data.pastBookings}
              />
            </>
          );
        }}
      </Query>
    </Flex>
  </NarrowLayout>
);

TenancyContainer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('tenancies')(TenancyContainer);
