import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';
import dotPlus from '../../../assets/img/dotPlus.png';
import Dropzone from '../Dropzone';

export const PhotoWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(216, 223, 230, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  height: 180px;
  width: 190px; 
  cursor: pointer;
`;

export const PhotoDropPlus = ({ onDrop }) => (
  <Dropzone onDrop={onDrop}>
    <PhotoWrapper>
      <Image height={60} src={dotPlus} />
    </PhotoWrapper>
  </Dropzone>
);

PhotoDropPlus.propTypes = {
  onDrop: PropTypes.func.isRequired,
};
