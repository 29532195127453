import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Flex, Box, Image } from 'rebass';
import styled from 'styled-components';
import Hide from 'hidden-styled';
import TitleDescription from '../text/TitleDescription';
import callCenter from '../../assets/img/call-center.png';
import twitter from '../../assets/img/black-twitter-icon.webp.png';
import phone from '../../assets/img/phone-icon.svg';
import email from '../../assets/img/black-email-icon.svg';

const ServiceImg = styled(Image)`
  object-fit: cover;
`;

class DownrootsFlow extends Component {
  render() {
    const { t } = this.props;
    return (
      <Flex alignItems="flex-start" style={{ position: 'relative' }} flexDirection={['column', 'column', 'row']} my={40}>
        <Box py={30} width={[1, 1, 0.6]}>
          <TitleDescription
            title={t('service.title')}
            desc={t('service.desc')}
            width={1}
          />
          <Hide xs sm>
            <Flex mt={40} justifyContent="space-between" width={0.6}>
              <Image width={60} height={60} src={phone} />
              <Image width={60} height={60} src={email} />
              <Image width={60} height={60} src={twitter} />
            </Flex>
          </Hide>
        </Box>
        <ServiceImg src={callCenter} height={360} width={[1, 1, 360]} ml={[0, 0, 50]} />
      </Flex>
    );
  }
}

DownrootsFlow.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('owners')(DownrootsFlow);
