import React, { Component } from 'react';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import { Query, graphql } from 'react-apollo';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import { compose } from 'recompose';
import styled from 'styled-components';
import Dropzone from '../../common/Dropzone';
import { ProfileEditText } from './ProfileEditPart';
import { EDIT_PROFILE } from '../../../graphQL/mutations/editProfile';
import { mapApiErrors } from '../../../utils/errorHandling';
import { GET_USER_INFO } from '../../../graphQL/queries/getCurrentUser';
import { LinkText } from '../../common/Text';
import Loading from '../../common/Loading';
import Error from '../../common/Error';
import { fontSize } from '../../../theme/styleUtils';
import { NarrowLayout } from '../../common/Layout';
import ProfileForm from './ProfileForm';
import { showSuccess } from '../../../utils/toast';

export const LinkSubtext = styled(ProfileEditText)`
  color: ${props => props.theme.colors.purplePrimary};
  text-decoration: underline;
  cursor: pointer;
`;

const UploadFileLinkText = styled(LinkText)`
  ${fontSize(15)}
`;

const FileUploadText = ({ onDrop, children }) => (
  <Dropzone onDrop={onDrop}>
    <UploadFileLinkText mt={10}>{children}</UploadFileLinkText>
  </Dropzone>
);

FileUploadText.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

class Profile extends Component {
  onDrop = ({ acceptedFiles, change, name = 'avatar' }) => change(name, acceptedFiles[0]);

  onSubmit = async (values) => {
    const { editProfile } = this.props;
    const personFields = [
      'firstName', 'lastName', 'phone', 'birthday', 'sex', 'address', 'email',
      'kindOfJob', 'university', 'studentLoan', 'businessName',
      'jobTitle', 'id',
    ];
    const variables = omit({
      ...values,
      ...pick(values.person, personFields),
      company: omit(pickBy(values.company, val => val), ['__typename', 'id']),
    }, [
      'id', 'role', '__typename', 'person',
    ]);
    const { errors } = await editProfile({ variables });
    if (errors) {
      return mapApiErrors(errors);
    }
    showSuccess('Settings saved!');
  };

  getInitialValues = (data) => {
    const user = get(data, 'user', {});
    const personOccupation = pick(user, ['jobTitle', 'businessName', 'university', 'studentLoan', 'kindOfJob']);
    return ({
      ...user,
      person: {
        ...personOccupation,
        ...pick(user, ['firstName', 'lastName', 'birthday', 'email', 'address', 'sex', 'phone']),
      },
    });
  }


  render() {
    return (
      <Query query={GET_USER_INFO}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;
          const isTenant = get(data, 'user.role.name') === 'tenant';
          return (
            <NarrowLayout>
              <Flex justifyContent="center">
                <Box>
                  <ProfileForm
                    onSubmit={this.onSubmit}
                    initialValues={this.getInitialValues(data)}
                    isTenant={isTenant}
                  />
                </Box>
              </Flex>
            </NarrowLayout>
          );
        }}
      </Query>
    );
  }
}

Profile.propTypes = {
  editProfile: PropTypes.func.isRequired,
};

const enhance = compose(
  graphql(EDIT_PROFILE, { name: 'editProfile', options: { refetchQueries: [{ query: GET_USER_INFO }] } }),
);

export default enhance(Profile);
