import gql from 'graphql-tag';

export const CREATE_OR_UPDATE_PLACE_BASICS = gql`
  mutation CreateOrUpdatePlaceBasics(
    $location: String,
    $listingTypeId: ID,
    $buildingTypeId: ID,
    $ownerLivesIn: Boolean,
    $id: ID,
    $bedroomsCount: String,
    $bathroomsCount: String,
    $tenantAllowedCount: String,
    $legalOwner: LegalOwnerInput,
    $unitTypeId: ID,
    $latitude: Float,
    $longitude: Float
    $city: String
    $geolocationData: String
    $currentSection: CurrentSection
  ) {
    createOrUpdatePlace(payload: {
      city: $city
      location: $location
      listingTypeId: $listingTypeId
      buildingTypeId: $buildingTypeId
      unitTypeId: $unitTypeId
      ownerLivesIn: $ownerLivesIn
      id: $id
      bedroomsCount: $bedroomsCount
      bathroomsCount: $bathroomsCount
      tenantAllowedCount: $tenantAllowedCount
      legalOwner: $legalOwner
      latitude: $latitude
      longitude: $longitude
      geolocationData: $geolocationData
      currentSection: $currentSection
    }) {
      id
      filledStep
    }
  }
`;

export const CREATE_OR_UPDATE_PLACE_SCRIPT = gql`
  mutation CreateOrUpdatePlaceScript($script: String, $id: ID, $title: String) {
    createOrUpdatePlace(payload: {
      script: $script
      id: $id
      title: $title
      currentSection: media
    }) {
      id
      title
      listingType { id code }
      unitType { id name }
      rooms { id }
      filledStep
    }
  }
`;

export const UPDATE_PLACE_PRICES = gql`
  mutation UpdatePlacePrices(
    $id: ID,
    $discount: Int,
    $promotionMonths: [Promotion],
    $monthlyRentalPriceValues: [MonthlyRentalPriceValueInput],
    $monthlyUtilityPrice: Float,
    $yieldCalculator: Float
  ) {
    createOrUpdatePlace(payload: {
      id: $id,
      discount: $discount,
      promotionMonths: $promotionMonths,
      monthlyRentalPriceValues: $monthlyRentalPriceValues,
      monthlyUtilityPrice: $monthlyUtilityPrice,
      yieldCalculator: $yieldCalculator
      currentSection: prices
    }) {
      id
      filledStep
      virtualTourBookedAt
    }
  }
`;

export const UPDATE_PLACE_BOOKING_RULES = gql`
  mutation UpdatePlaceBookingRules(
    $id: ID,
    $tenantTypeIds: [ID],
    $genderPreferenceId: ID,
    $startDateWindow: Int,
    $preparationTime: Int,
    $contactAvailableIds: [ID],
    $contactAvailableFirstName: String,
    $contactAvailableLastName: String,
    $contactAvailableEmail: String,
    $contactAvailablePhone: String,
    $depositOptionIds: [ID],
    $houseRuleIds: [ID],
    $notes: String
    $moveOutGuarantee: Boolean
    $backgroundCheckIds: [ID]
  ) {
    createOrUpdatePlace(payload: {
      id: $id,
      tenantTypeIds: $tenantTypeIds,
      genderPreferenceId: $genderPreferenceId,
      startDateWindow: $startDateWindow,
      preparationTime: $preparationTime,
      contactAvailableIds: $contactAvailableIds,
      contactAvailableFirstName: $contactAvailableFirstName,
      contactAvailableLastName: $contactAvailableLastName,
      contactAvailableEmail: $contactAvailableEmail,
      contactAvailablePhone: $contactAvailablePhone,
      depositOptionIds: $depositOptionIds,
      notes: $notes,
      moveOutGuarantee: $moveOutGuarantee
      houseRuleIds: $houseRuleIds
      currentSection: booking_rules
      backgroundCheckIds: $backgroundCheckIds
    }) {
      id
      listingType {
        id
        code
      }
    }
  }
`;

export const UPDATE_PLACE_LIVE = gql`
  mutation UpdatePlaceLive(
    $id: ID,
    $live: Boolean
  ) {
    createOrUpdatePlace(payload: {
      id: $id,
      live: $live
    }) {
      id
      listingType {
        id
        code
      }
    }
  }
`;

export const UPDATE_PLACE_BOOK_MY_VIRTUAL_TOUR = gql`
  mutation UpdatePlaceBookMyVirtualTour(
    $id: ID,
    $virtualTourBookedAt: ISO8601DateTime!
    $virtualTourBookedNote: String
  ) {
    createOrUpdatePlace(payload: {
      id: $id,
      virtualTourBookedAt: $virtualTourBookedAt,
      virtualTourBookedNote: $virtualTourBookedNote
    }) {
      id,
      virtualTourBookedAt,
      virtualTourBookedNote
    }
  }
`;

export const DESTROY_PLACE = gql`
  mutation DestroyPlace($placeId: ID!) {
    destroyPlace(placeId: $placeId) {
      id
    }
  }
`;
