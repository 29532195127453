import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import TitleDescription from '../text/TitleDescription';
import { RoundedButton } from '../common/wizard/Footer';
import { APP_ROUTES } from '../../constants';

const BenefitsFilledRoundedButton = styled(RoundedButton).attrs({ fontSize: 15 })``;

const BenefitSectionWrapper = styled(Box)`
  path {
    fill: ${props => props.theme.colors.paleBlue};
  }
  svg {
    height: 67px;
  }
`;

const BenefitSection = ({
  items, title, button, desc, children, ...props
}) => (
  <BenefitSectionWrapper {...props}>
    <TitleDescription title={title} desc={desc} />
    <Flex flexWrap="wrap" mx={-25} mt={20} px={[10, 0]}>
      {children.map((child, i) => (
        <Box px={['5px', 25]} key={`benefits-promote-${i}`} width={[1 / 2, 1 / 2, 1 / 2, 1 / 3]} mb={30}>
          {child}
        </Box>
      ))}
    </Flex>
    <Link to={APP_ROUTES.LISTINGS.PLACE_INTRO()}>
      <BenefitsFilledRoundedButton my={15} py={10}>
        {button}
      </BenefitsFilledRoundedButton>
    </Link>
  </BenefitSectionWrapper>
);

BenefitSection.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default withNamespaces()(BenefitSection);
