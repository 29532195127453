import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import { GET_USER_INFO } from '../../../graphQL/queries/getCurrentUser';
import { initialValuesUpdateRule } from '../../../utils/formHelpers';
import OccupationPart from '../../bookings/personal/OccupationPart';
import { ProfilePart as ProfilePersonalPart } from '../../bookings/personal/ProfilePart';
import GovernmentIdentity from '../../bookings/verification/GovernmentIdentity';
import { Separator } from '../../common/form/Separator';
import TextInput from '../../common/form/TextInput';
import Body from '../../common/text/Body';
import Subtitle from '../../common/text/Subtitle';
import { FilledRoundedButton } from '../../common/wizard/Footer';
import AvatarPart from './AvatarPart';
import ProfileCompanyPart from './ProfileCompanyPart';
import ProfileEditPart from './ProfileEditPart';

class ProfileForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isTenant: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
  };

  shouldComponentUpdate({ initialValues, isTenant }) {
    return this.props.isTenant !== isTenant
      || initialValuesUpdateRule(initialValues, this.props.initialValues, ['avatar', 'documents']);
  }

  render() {
    const {
      isTenant, t, onSubmit, initialValues,
    } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{ values: true, errors: true, submitError: true }}
        render={({
          handleSubmit, form: { getState, change }, values, submitting,
        }) => {
          const kindOfJob = (get(getState(), 'values.person.kindOfJob'));
          return (
            <form onSubmit={handleSubmit}>
              <ProfileEditPart title={t('profile.photo.title')}>
                <Subtitle mb={20}>
                  Making you look good
                </Subtitle>
                <Body mb={20}>
                  {isTenant ? 'Your photo will only be displayed to hosts after you have made a booking' : 'Your photo will be displayed to applicants'}
                </Body>
                <AvatarPart avatar={values.avatar} change={change} />
                <Body>{t('profile.photo.text')}</Body>
              </ProfileEditPart>
              <Separator />
              <ProfilePersonalPart />
              { isTenant &&
                <>
                  <Separator />
                  <OccupationPart change={change} kindOfJob={kindOfJob} />
                </>
              }
              <Separator />
              <ProfileEditPart title={t('profile.description.title')} text={t('profile.description.text')}>
                <Box width={1}>
                  <TextInput textarea name="description" placeholder={t('profile.description.placeholder')} />
                </Box>
              </ProfileEditPart>
              <Separator />
              { !isTenant &&
                <>
                  <Separator />
                  <ProfileCompanyPart />
                </>
              }
              <ProfileEditPart title="Government Identity" text={t('profile.proof.text')}>
                <GovernmentIdentity
                  documentsDataPath="user.documents"
                  documentKind={values.documentKind}
                  query={GET_USER_INFO}
                />
              </ProfileEditPart>
              <Box>
                <FilledRoundedButton py={15} px={60} width={[1, 'initial', 'initial']} onClick={handleSubmit} disabled={submitting}>
                  Done
                </FilledRoundedButton>
              </Box>
            </form>
          );
        }}
      />
    );
  }
}

export default withNamespaces('account')(ProfileForm);
