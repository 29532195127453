import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import Helmet from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { compose } from 'recompose';
import { graphql, Query } from 'react-apollo';
import omit from 'lodash/omit';
import { showSuccess } from '../../../../utils/toast';
import { NarrowLayout } from '../../../common/Layout';
import Title from '../../../common/text/Title';
import { FilledRoundedButton } from '../../../common/wizard/Footer';
import ChangePasswordForm from '../../common/settings/ChangePasswordForm';
import ContactOptions from '../../common/settings/ContactOptions';
import PayoutMethods from '../../common/settings/PayoutMethods';
import { SAVE_USER_SETTINGS } from '../../../../graphQL/mutations/user';
import { mapApiErrors } from '../../../../utils/errorHandling';
import { GET_LANDLORD_SETTINGS } from '../../../../graphQL/queries/getCurrentUser';
import Loading from '../../../common/Loading';
import Error from '../../../common/Error';

const createOnSubmit = saveUserSettings => async (values) => {
  const { errors } = await saveUserSettings({ variables: values });
  if (errors) {
    return mapApiErrors(errors);
  }

  showSuccess('Settings successfully updated');
};

const getInitialValues = data => ({
  ...data.user,
  payoutMethod: omit(data.user.payoutMethod, '__typename'),
});

const Settings = ({ t, saveUserSettings }) => (
  <Flex flexDirection="column">
    <Helmet>
      <title>{t('settings.title')}</title>
    </Helmet>
    <NarrowLayout>
      <Title>{t('settings.title')}</Title>
      <ChangePasswordForm />
      <Query query={GET_LANDLORD_SETTINGS}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;
          return (
            <Form
              onSubmit={createOnSubmit(saveUserSettings)}
              initialValues={getInitialValues(data)}
            >
              {({ handleSubmit, submitting }) => (
                <Flex flexDirection="column">
                  <PayoutMethods />
                  <ContactOptions />
                  <Box>
                    <FilledRoundedButton py={15} px={60} width={[1, 'initial', 'initial']} onClick={handleSubmit} disabled={submitting}>
                      {t('settings.done')}
                    </FilledRoundedButton>
                  </Box>
                </Flex>
              )}
            </Form>
          );
        }}
      </Query>
    </NarrowLayout>
  </Flex>
);

Settings.propTypes = {
  t: PropTypes.func.isRequired,
  saveUserSettings: PropTypes.func.isRequired,
};

const enhance = compose(
  withNamespaces('account'),
  graphql(SAVE_USER_SETTINGS, { name: 'saveUserSettings' }),
);

export default enhance(Settings);
