import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Hide from 'hidden-styled';

import placeholderFlatmate from '../../../assets/img/placeholderFlatmate.png';
import { fontSize } from '../../../theme/styleUtils';
import { showUnhandledAction } from '../../../utils/toast';
import Avatar from '../../common/Avatar';
import Subtitle from '../../common/text/Subtitle';
import Title from '../../common/text/Title';
import { QuestionText } from './DetailsOwnerPart';
import { DetailsSubtitle } from './DetailsFacilityPart';

const FlatmateWrapper = styled(Box).attrs({
  width: [1, 1, '48%'],
})`
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #F9F9F9;
`;

const FlatmateNameText = styled(Title)`
  text-transform: uppercase;
`;

const FlatmateDescriptionText = styled(Text)`
  ${fontSize(15)}
  color: ${props => props.theme.colors.navInactiveGrey};
`;

const FlatmateMessageText = styled(QuestionText)`
  position: absolute;
  bottom: 5px;
  right: 10px;
`;

const FlatmateBox = ({
  flatmate: {
    fullName, jobTitle, profileAvailable, messagesAvailable, description, avatar,
  }, t,
}) => (
  <FlatmateWrapper>
    <Flex>
      <Avatar src={avatar || placeholderFlatmate} size={160} />
      <Box my={20} ml={25}>
        <Box mb={10}>
          <FlatmateNameText mb="0px">
            {profileAvailable ? fullName : t('details.flatmates.privateName')}
          </FlatmateNameText>
          <Subtitle>{profileAvailable ? jobTitle : t('details.flatmates.unavailable')}</Subtitle>
        </Box>
        <Hide xs>
          <FlatmateDescriptionText>
            {profileAvailable ? description : t('details.flatmates.nameNoScript')}
          </FlatmateDescriptionText>
        </Hide>
      </Box>
      <FlatmateMessageText
        onClick={() => {
          if (messagesAvailable) {
            showUnhandledAction();
          }
        }}
      >
        {t(`details.flatmates.${messagesAvailable ? 'message' : 'unavailable'}`)}
      </FlatmateMessageText>
    </Flex>
    <Hide sm md lg>
      <FlatmateDescriptionText py={15}>
        {profileAvailable ? description : t('details.flatmates.nameNoScript')}
      </FlatmateDescriptionText>
    </Hide>
  </FlatmateWrapper>
);


FlatmateBox.propTypes = {
  flatmate: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const DetailsFlatmatesPart = ({ t, flatmates }) => (
  <>
    {!isEmpty(flatmates) && (
      <>
        <DetailsSubtitle mt={70} mb={30}>{t('details.flatmates.title')}</DetailsSubtitle>
        <Flex flexWrap="wrap" justifyContent="space-between">
          {map(flatmates, flatmate => <FlatmateBox key={`flatmate-${flatmate.id}`} flatmate={flatmate} t={t} />)}
        </Flex>
      </>
    )}
  </>
);

DetailsFlatmatesPart.propTypes = {
  t: PropTypes.func.isRequired,
  flatmates: PropTypes.array,
};

export default withNamespaces('listings')(DetailsFlatmatesPart);
