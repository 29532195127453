import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import map from 'lodash/map';
import Lightbox from 'react-images';
import Hide from 'hidden-styled';
import VirtualTourThumb from './VirtualTourThumb';
import DetailsImage from './DetailsImage';
import { FONT_FAMILIES } from '../../../../theme/globalStyle';
import { RoundedButton } from '../../../common/wizard/Footer';

const FacilitiesTooltip = styled(Box).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 16,
})`
  visibility: hidden;
  color: #000;
  padding: 15px;
  opacity: 0;
  transition: opacity .3s ease-in;
  position: absolute;
  top: 10%;
  left: 0;
`;

export default class ListingDetailsImages extends Component {
  static propTypes = {
    thumbImages: PropTypes.array.isRequired,
    images: PropTypes.array.isRequired,
  };

  state = {
    lightboxIsOpen: false,
    currentImage: 0,
  };

  openLightbox = index => (event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrev = () => {
    const { thumbImages } = this.props;
    this.setState(state => ({
      currentImage: (state.currentImage + thumbImages.length - 1) % thumbImages.length,
    }));
  }

  gotoNext = () => {
    const { thumbImages } = this.props;
    this.setState(state => ({
      currentImage: (state.currentImage + 1) % thumbImages.length,
    }));
  }

  render() {
    const { thumbImages, images } = this.props;
    const { lightboxIsOpen, currentImage } = this.state;
    return (
      <>
        <Flex flexWrap="wrap">
          <VirtualTourThumb />
          {map(thumbImages, (image, idx) => (
            <DetailsImage
              key={`${image}-${idx}`}
              src={image.src}
              onClick={this.openLightbox(idx)}
              tooltipOpacity={image.caption && 0.9}
            >
              <FacilitiesTooltip>{image.caption}</FacilitiesTooltip>
            </DetailsImage>
          ))}
          <Lightbox
            isOpen={lightboxIsOpen}
            currentImage={currentImage}
            onClickNext={this.gotoNext}
            onClickPrev={this.gotoPrev}
            onClose={this.closeLightbox}
            imageCountSeparator="/"
            images={images}
          />
        </Flex>
        <Hide md lg>
          <RoundedButton mt={20} py={10} px={40} onClick={this.openLightbox(0)}>Tap to see the gallery</RoundedButton>
        </Hide>
      </>
    );
  }
}
