import get from 'lodash/get';
import writtenNumber from 'written-number';
import moment from 'moment';
import i18n from '../i18n';

writtenNumber.defaults.lang = i18n.language;

export const validateLength = length => value => (!value || value.length < length ? i18n.t('validations:errors.tooShort', { amount: writtenNumber(length) }) : null);

export const shorterThan = length => value => (!value || value.length > length ? i18n.t('validations:errors.tooLong', { amount: length }) : null);

export const validateChosenAmount = length => value => (!value || value.length < length ? i18n.t('validations:errors.tooLittleChosen', { amount: writtenNumber(length) }) : null);

export const required = value => (value ? undefined : i18n.t('validations:errors.required'));

export const positive = value => (value > 0 ? undefined : i18n.t('validations:errors.isPositive'));

export const chooseAutocomplete = value => (value ? undefined : i18n.t('validations:errors.autocomplete'));

export const requiredMsg = msgKey => value => (value ? undefined : i18n.t(msgKey));

export const emailFormat = (value) => {
  if (!value) {
    return;
  }
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'It is not en email address'
    : undefined;
};

export const composeValidators = (...validators) => (value, values) => validators.reduce((error, validator) => error || validator(value, values), undefined);

export const mustBeValidStripeElement = value => get(value, 'error.message');

export const isValidBirthday = (value) => {
  if (!moment(value).isValid()) {
    return i18n.t('validations:errors.isValidDate');
  }
  return undefined;
};

export const isDateInThePast = (value) => {
  if (moment(value).isAfter(moment())) {
    return 'Date is in the future';
  }

  return undefined;
};

export const passwordsMustMatch = (value, allValues) => (value === allValues.password || value === allValues.newPassword ? undefined : 'Password confirmation must match');
