import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import styled from 'styled-components';
import { required } from '../../../../../utils/formValidation';
import { isCompanyLegalOwner } from '../../../../../utils/legalOwner';
import Checkbox from '../../../../common/form/Checkbox';
import { FormPart, FormSubPart, FormWrapper } from '../../../../common/form/FormWrapper';
import Radio from '../../../../common/form/Radio';
import FieldError from '../../../../common/form/FieldError';
import { Separator } from '../../../../common/form/Separator';
import Company from '../../../../user/Company';
import ClientIndividualInfo from '../../../../user/ClientIndividualInfo';
import { fontMixin, fontSize, LIGHT } from '../../../../../theme/styleUtils';

export const SmallLink = styled.a`
  ${fontMixin(LIGHT)}
  ${fontSize(14)}
  letter-spacing: 0.05em;
  text-decoration: underline;
  color: ${props => props.theme.colors.purplePrimary};
  cursor: pointer;
  margin-left: 10px;
`;

const OwnerPart = ({ t, legalOwner, listingTypeCode }) => (
  <FormWrapper>
    <Separator mb={20} />

    <FormPart title={t('owner.title')}>
      {
        listingTypeCode === 'co_living' && (
        <FormSubPart subTitle={t('ownerLivesIn.title')}>
          <FieldError direction="column" name="ownerLivesIn">
            <Box mt={10}>
              <Radio
                name="ownerLivesIn"
                label={t('ownerLivesIn.yes')}
                value="1"
                validate={required}
              />
            </Box>
            <Box mt={10}>
              <Radio
                name="ownerLivesIn"
                label={t('ownerLivesIn.no')}
                value="0"
                validate={required}
              />
            </Box>
          </FieldError>
        </FormSubPart>
        )
      }
      <FormSubPart subTitle={t('owner.legalOwnerOfTheProperty.title')}>
        <FieldError direction="column" name="legalOwner.type">
          <Box mt={10}>
            <Radio
              name="legalOwner.type"
              label={t('owner.legalOwnerOfTheProperty.me')}
              value="me"
              validate={required}
            />
          </Box>
          <Box mt={10}>
            <Radio
              name="legalOwner.type"
              label={t('owner.legalOwnerOfTheProperty.myCompany')}
              value="my_company"
              validate={required}
            />
          </Box>
          <Box mt={10}>
            <Radio
              name="legalOwner.type"
              label={t('owner.legalOwnerOfTheProperty.myClient')}
              value="my_client"
              validate={required}
            />
          </Box>
          <Box mt={10}>
            <Radio
              name="legalOwner.type"
              label={t('owner.legalOwnerOfTheProperty.myClientCompany')}
              value="my_client_company"
              validate={required}
            />
          </Box>
        </FieldError>
        {
          legalOwner === 'my_client' && (
            <Box mt={20}>
              <ClientIndividualInfo legalOwnerType={legalOwner} />
            </Box>
          )
        }
        {
          isCompanyLegalOwner(legalOwner) && (
            <Box mt={20}>
              <Company legalOwnerType={legalOwner} />
            </Box>
          )
        }
      </FormSubPart>
    </FormPart>
    {
      legalOwner && legalOwner !== 'me' && (
        <FormSubPart subTitle={t('authorization.title')}>
          <Box mt={10}>
            <FieldError direction="column" name="legalOwner.authorisation">
              <Checkbox label={t('authorization.description')} name="legalOwner.authorisation" variant="light">
                <SmallLink>{t('authorization.link')}</SmallLink>
              </Checkbox>
            </FieldError>
          </Box>
        </FormSubPart>
      )
    }
  </FormWrapper>
);

OwnerPart.propTypes = {
  t: PropTypes.func.isRequired,
  legalOwner: PropTypes.oneOf(['me', 'my_company', 'my_client', 'my_client_company']),
  listingTypeCode: PropTypes.oneOf(['entire_place', 'co_living']),

};

export default withNamespaces('listingForm')(OwnerPart);
