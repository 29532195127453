import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import { withNamespaces } from 'react-i18next';
import queryString from 'query-string';
import { SEARCH_BAR_DEFAULT_VALUES } from '../search/SearchBar';
import BulletpointJumbo from './BulletpointJumbo';
import { FilledRoundedButton } from '../common/wizard/Footer';

class HomeBulletLists extends Component {
  render() {
    const { t } = this.props;
    return (
      <Flex
        pt={50}
        justifyContent="center"
        flexWrap="wrap"
        alignItems="stretch"
      >
        <Box px={15} py={20} width={[1, 1, 1 / 2]}>
          <BulletpointJumbo
            title={t('homeBulletLists.toRent.title')}
            listItems={t('homeBulletLists.toRent.points', {
              returnObjects: true,
            })}
            buttonText={t('homeBulletLists.toRent.button')}
          />
        </Box>
        <Box px={15} py={20} width={[1, 1, 1 / 2]}>
          <BulletpointJumbo
            title={t('homeBulletLists.toKnow.title')}
            listItems={t('homeBulletLists.toKnow.points', {
              returnObjects: true,
            })}
            buttonText={t('homeBulletLists.toKnow.button')}
          />
        </Box>
        <Link to={`/listings?${queryString.stringify(SEARCH_BAR_DEFAULT_VALUES)}`}>
          <FilledRoundedButton mt={30} mb={20} px={60} py={10}>
            Discover your perfect place
          </FilledRoundedButton>
        </Link>
      </Flex>
    );
  }
}

HomeBulletLists.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('home')(HomeBulletLists);
