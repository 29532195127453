import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Query } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';

import { GET_BUILDING_TYPES, GET_LISTING_TYPES, GET_UNIT_TYPES } from '../../../../../graphQL/queries/place';
import { positive } from '../../../../../utils/formValidation';
import Counter from '../../../../common/form/Counter';
import Dropdown from '../../../../common/form/Dropdown';
import {
  FormPart, FormSubPart, FormSubPartInline, FormWrapper,
} from '../../../../common/form/FormWrapper';
import { Separator } from '../../../../common/form/Separator';
import Loading from '../../../../common/Loading';
import Title from '../../../../common/text/Title';
import ListingTypePart from './ListingTypePart';

export const isUnitNameStudio = name => name === 'Studio';

const PropertyPart = ({
  t,
  listingTypeId,
  listingTypeCode,
  unitTypeId,
  buildingTypeId,
  form: { change },
  form,
  unitTypeName,
}) => {
  const [defaultBuildingTypeInitialized, setDefaultBuildingTypeInitialized] = useState(false);
  const [defaultBuildingType, setDefaultBuildingType] = useState(null);
  const [defaultUnitTypeInitialized, setDefaultUnitTypeInitialized] = useState(false);
  const [defaultUnitType, setDefaultUnitType] = useState(null);
  return (
    <FormWrapper>
      <Title>{t('title.place')}</Title>
      <FormSubPart subTitle={t('type.title')}>
        <Query query={GET_LISTING_TYPES}>
          {({ loading, data }) => (
            <ListingTypePart
              loading={loading}
              data={data}
              form={form}
              setDefaultUnitTypeInitialized={setDefaultUnitTypeInitialized}
              listingTypeId={listingTypeId}
              t={t}
            />
          )}
        </Query>
        <Separator />
      </FormSubPart>
      {
        listingTypeId && (
          <FormPart title="Property">
            <FormSubPart subTitle={t('unitType.title')}>
              <Query
                query={GET_UNIT_TYPES}
                fetchPolicy="network-only"
                variables={{ listingTypeId }}
                onCompleted={(data) => {
                  if (!defaultUnitTypeInitialized) {
                    const defaultUnit = find(data.unitTypes, 'default');
                    setDefaultUnitTypeInitialized(true);
                    setDefaultUnitType(get(defaultUnit, 'id'));

                    setDefaultBuildingTypeInitialized(false);
                  }
                }}
              >
                {({ loading, data }) => {
                  if (loading) {
                    return <Loading />;
                  }

                  return (
                    <Box width="100%">
                      <Dropdown
                        name="unitTypeId"
                        options={data.unitTypes.map(u => ({
                          label: u.name,
                          value: u.id,
                        }))}
                        onChange={(selected) => {
                          change('unitTypeName', selected.label);
                          setDefaultBuildingTypeInitialized(false);
                        }}
                        defaultValue={defaultUnitType}
                      />
                    </Box>
                  );
                }}
              </Query>
            </FormSubPart>
            {
              listingTypeId && unitTypeId && (
                <Fragment>
                  <FormSubPart subTitle={t('buildingType.title')}>
                    <Query
                      query={GET_BUILDING_TYPES}
                      fetchPolicy="network-only"
                      variables={{ unitTypeId }}
                      onCompleted={(data) => {
                        if (!defaultBuildingTypeInitialized) {
                          setDefaultBuildingTypeInitialized(true);
                          setDefaultBuildingType(get(find(data.buildingTypes, 'default'), 'id'));
                        }
                      }}
                    >
                      {({ loading, data }) => {
                        if (loading) {
                          return <Loading />;
                        }
                        return (
                          <Box width="100%">
                            <Dropdown
                              name="buildingTypeId"
                              options={data.buildingTypes.map(buildingType => ({
                                label: buildingType.name,
                                value: buildingType.id,
                              }))}
                              defaultValue={defaultBuildingType}
                            />
                          </Box>
                        );
                      }}
                    </Query>
                  </FormSubPart>
                </Fragment>
              )
            }
            { listingTypeId && unitTypeId && buildingTypeId && (
              <>
                {
                  listingTypeCode === 'entire_place' && (
                    <>
                      <FormSubPartInline subTitle="Total number of tenants allowed" subText="Only the main tenant will sign the lease">
                        <Counter validate={positive} name="tenantAllowedCount" />
                      </FormSubPartInline>
                      <FormSubPartInline subTitle="Total number of bathrooms" subText={listingTypeCode === 'entire_place' ? '' : 'Include any not being listed'}>
                        <Counter validate={positive} name="bathroomsCount" />
                      </FormSubPartInline>
                    </>
                  )
                }
                { !isUnitNameStudio(unitTypeName) && (
                  <FormSubPartInline subTitle="Total number of bedrooms" subText={listingTypeCode === 'entire_place' ? '' : 'Include any not being listed'}>
                    <Counter validate={positive} name="bedroomsCount" />
                  </FormSubPartInline>
                )}
                <Box mt={-20} />
              </>
            )}
          </FormPart>
        )
      }
    </FormWrapper>
  );
};

PropertyPart.propTypes = {
  t: PropTypes.func.isRequired,
  listingTypeCode: PropTypes.oneOf(['co_living', 'entire_place']),
  listingTypeId: PropTypes.string,
  buildingTypeId: PropTypes.string,
  unitTypeId: PropTypes.string,
  unitTypeName: PropTypes.string,
  form: PropTypes.object.isRequired,
};

export default withNamespaces('listingForm')(PropertyPart);
