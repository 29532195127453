import gql from 'graphql-tag';
import map from 'lodash/map';

export const CREATE_BOOKING = gql`
  mutation CreateBooking(
    $startDate: ISO8601DateTime
    $stayMonths: Int
    $bookedObject: BookedObjectInput
  ) {
    createOrUpdateBooking(payload: {
      startDate: $startDate
      stayMonths: $stayMonths
      bookedObject: $bookedObject
    }) {
      id
    }
  }
`;

export const UPDATE_BOOKING_BASICS = gql`
  mutation UpdatBookingBasics(
    $id: ID
    $depositOptionId: ID
    $moveOutGuarantee: Boolean
    $studentGuarantor: Boolean
  ) {
    createOrUpdateBooking(payload: {
      id: $id
      depositOptionId: $depositOptionId
      moveOutGuarantee: $moveOutGuarantee
      studentGuarantor: $studentGuarantor
      currentSection: terms
    }) {
      id
    }
  }
`;

export const UPDATE_BOOKING_PERSONAL = gql`
  mutation UpdateBookingPersonal(
    $id: ID
    $tenantsNumber: Int
    $people: [PersonInput]
  ) {
    createOrUpdateBooking(payload: {
      id: $id
      tenantsNumber: $tenantsNumber
      people: $people
      currentSection: personal
    }) {
      id
    }
  }
`;

export const UPDATE_BOOKING_PAYMENT = gql`
  mutation UpdateBookingPayment(
    $id: ID
    $stripeToken: String
    $paymentMethod: PaymentMethod
  ) {
    createOrUpdateBooking(payload: {
      id: $id
      stripeToken: $stripeToken
      paymentMethod: $paymentMethod
      currentSection: payment
    }) {
      id
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation UploadDocument(
    $file: Upload!
    $personId: ID
    $kind: IdentityEnum
  ) {
    createOrUpdatePersonIdentity(
      payload: { file: $file, personId: $personId, kind: $kind }
    ) {
      file
      id
      kind
      name
    }
  }
`;

export const DYN_UPLOAD_MULTIPLE_DOCUMENTS = photos => gql`
  mutation UploadDocument(
    ${map(photos, (photo, idx) => (`
      $photo${idx}: Upload
    `))}
    $personId: ID
    $kind: IdentityEnum
  ) {
  ${map(photos, (photo, idx) => (`
    upload${idx}: createOrUpdatePersonIdentity(
      payload: { file: $photo${idx}, personId: $personId, kind: $kind }
    ) {
      file
      id
      kind
      name
    }
  `))}
  }
`;

export const UPDATE_BOOKING_VERIFICATION = gql`
  mutation UpdatBookingVerification(
    $id: ID
    $people: [PersonInput]
    $backgroundCheckConsent: Boolean
  ) {
    createOrUpdateBooking(payload: {
      id: $id
      currentSection: verification
      people: $people
      backgroundCheckConsent: $backgroundCheckConsent
    }) {
      id
    }
  }
`;

export const BOOK = gql`
  mutation Book(
    $id: ID!
  ) {
    book(id: $id) {
      id
    }
  }
`;

export const DESTROY_PERSON_IDENTITY = gql`
  mutation DestroyPersonIdentity(
    $id: ID!
    $personId: ID
  ) {
    destroyPersonIdentity(id: $id, personId: $personId) {
      id
    }
  }
`;

export const ACCEPT_BOOKING = gql`
  mutation AcceptBooking($id: ID!) {
    acceptBooking(id: $id) {
      id
      status
    }
  }
`;

export const SIGN_LEASE = gql`
  mutation SignLease($id: ID!) {
    signLease(id: $id) {
      id
    }
  }
`;

export const DECLINE_BOOKING = gql`
  mutation DeclineBooking($id: ID!) {
    declineBooking(id: $id) {
      id
    }
  }
`;
