import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import downrootsDLogo from '../../../../assets/img/Downroots-D-logo.png';
import crossClose from '../../../../assets/img/cross-close.svg';
import { LightBoxWrapper } from '../../../common/Modal';
import { FilledRoundedButton } from '../../../common/wizard/Footer';

const TourBookedModalContent = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.darkBrown};
  padding: 20px;
  height: 100%;
`;

const LogoImage = styled(Image)`
  position: relative;
  left: -15px;
  top: -10px;
`;

const CloseImage = styled(Image)`
  cursor: pointer;
`;

const OnOurWay = styled(Box)`
  border: 1px solid rgba(216, 223, 230, 1);
  background-color: rgba(216, 223, 230, 0.3);
`;

const TourBookedModal = ({ redirect, onClose, t }) => (
  <LightBoxWrapper>
    <TourBookedModalContent flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
      <Flex justifyContent="space-between" width={1}>
        <LogoImage src={downrootsDLogo} height={45} />
        <CloseImage src={crossClose} height={20} onClick={onClose} />
      </Flex>
      <Text fontSize={22} color="darkBrown" fontWeight={500} mb={20}>
        {t('tourBookedModal.title')}
      </Text>
      <OnOurWay p="5px" width={['100%', 460, 460]} mb={20}>
        <Text fontSize={16} color="darkBrown">
          {t('tourBookedModal.onOurWay')}
        </Text>
      </OnOurWay>
      <Text fontSize={17} color="darkBrown" width={['100%', 460, 460]} mb={20}>
        {t('tourBookedModal.text')}
      </Text>
      <FilledRoundedButton onClick={redirect} px={31} py="8px" fontSize={17}>
        {t('tourBookedModal.viewListing')}
      </FilledRoundedButton>
    </TourBookedModalContent>
  </LightBoxWrapper>
);


TourBookedModal.propTypes = {
  redirect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listings')(TourBookedModal);
