import React from 'react';
import { Flex, Text, Image } from 'rebass';
import Panel from '../../common/Panel';
import { required } from '../../../utils/formValidation';
import Checkbox from '../../common/form/Checkbox';
import Body from '../../common/text/Body';
import Tooltip from '../../common/Tooltip';
import questionMark from '../../../assets/img/question-icon.svg';
import FieldError from '../../common/form/FieldError';

const renderTitleWithTooltip = title => (
  <Flex alignItems="center">
    <Text mr={15}>{title}</Text>
    <Tooltip content="Lorem ipsum">
      <Image src={questionMark} width={15} />
    </Tooltip>
  </Flex>
);

const BackgroundChecks = () => (
  <Panel title={renderTitleWithTooltip('Background checks')}>
    <Body mb={20}>
      We may need to carry out further background checks on residents to make
      sure everyone gets the safest experience. These checks may include
      identity or credit checks as well as county court judgement clearance. We
      may use third parties to carry out the checks.
    </Body>
    <Body mb={20}>
      Where further information is required we may request more documentation
      from you.The listing owner may accept or decline your application for any
      reason.
    </Body>

    <FieldError direction="column" name="backgroundCheckConsent">
      <Checkbox
        label="I consent for background checks to be made if required for this tenancy"
        name="backgroundCheckConsent"
        validate={required}
      />
    </FieldError>
  </Panel>
);

export default BackgroundChecks;
