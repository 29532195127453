import styled from 'styled-components';
import { Box } from 'rebass';

export const Separator = styled(Box).attrs(props => ({
  mb: props.mb || 20,
}))`
  width: 100%;
  border-bottom: 1px solid ${({ color, theme }) => (color ? (theme.colors[color] || color) : theme.colors.navInactiveGrey)};
  opacity: ${props => (props.opaque ? 1 : 0.38)};
`;
