import get from 'lodash/get';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { fontMixin, fontSize, LIGHT } from '../../theme/styleUtils';
import AutoSave from '../common/form/AutoSave';
import DatePicker from '../common/form/DatePicker';
import Dropdown from '../common/form/Dropdown';
import RangeSlider from '../common/form/RangeSlider';
import { LISTING_TYPE_LABELS } from './FiltersForm';
import FiltersModal from './FiltersModal';

const FiltersWrapper = styled(Flex)`
  border-bottom: 1px solid ${props => props.theme.colors.pearlGray};
  margin-bottom: 20px;
`;

const AvailabilityText = styled(Text)`
  ${props => fontSize(props.fontSize || 16)}
  text-align: center;
`;

const FilterInfo = styled(Text).attrs({
  color: 'purplePrimary',
})`
  ${fontMixin(LIGHT)}
  ${fontSize(14)}
  cursor: pointer;
`;

const SliderWrapper = styled(Flex)`
  height: 24px;
  align-items: center;
`;

const DISTRICTS = [
  {
    label: 'All',
    value: 'any',
  },
  {
    label: 'North West',
    value: 'NW',
  },
];

const CITIES = [{
  label: 'London',
  value: 'London',
}];

const LENGTHS = [
  {
    label: '3 months',
    value: 3,
  },
  {
    label: '6 months',
    value: 6,
  },
  {
    label: '9 months',
    value: 9,
  },
  {
    label: '12 months',
    value: 12,
  },
];

const getBedroomCountText = (getState) => {
  const min = get(getState(), 'values.bedroomsMinNumber');
  const max = get(getState(), 'values.bedroomsMaxNumber');
  return min === max
    ? pluralize('Bedroom', +min, true)
    : `${min} to ${max} bedrooms`;
};

const SearchForm = ({
  initialValues, onSubmit, priceRangeDomain,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [minMinPrice, maxMaxPrice] = priceRangeDomain;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { getState } }) => (
        <form onSubmit={handleSubmit}>
          <AutoSave debounce={1000} save={handleSubmit} />
          <FiltersWrapper alignItems="center" justifyContent="space-between" flexWrap="wrap" alignContent="space-between">
            <Flex flexDirection={['column', 'column', 'row']} width={1}>
              <Flex flexDirection="column" mb={[10, 10, 0]} mr={[0, 10, 10]} width={1}>
                <Dropdown name="city" options={CITIES} disabled placeholder="City" />
              </Flex>
              <Flex flexDirection="column" mb={[10, 10, 0]} mr={[0, 10, 10]} width={1}>
                <Dropdown name="district" options={DISTRICTS} placeholder="District" />
              </Flex>
              <Flex flexDirection="column" width={1} mb={[10, 10, 0]} mr={[0, 10, 10]}>
                <DatePicker
                  name="dateFrom"
                  placeholder="Available from"
                  disabledModifiers={{ before: moment().add(2, 'days').toDate() }}
                />
              </Flex>
              <Flex flexDirection="column" width={1} mb={[10, 10, 0]} mr={[0, 10, 10]}>
                <Dropdown name="leaseLength" options={LENGTHS} placeholder="Lease length" />
              </Flex>
              <Flex alignItems="center" flexDirection="column" width={1} mb={[20, 20, 0]}>
                <SliderWrapper width={1} px="6px" mb={10}>
                  <RangeSlider onAfterChange={priceRange => onSubmit({ ...initialValues, priceRange })} name="priceRange" min={minMinPrice} max={maxMaxPrice} />
                </SliderWrapper>
                <Flex>
                  <AvailabilityText>{`£${get(getState(), 'values.priceRange.0') || minMinPrice} - £${get(getState(), 'values.priceRange.1') || maxMaxPrice} monthly`}</AvailabilityText>
                </Flex>
              </Flex>
            </Flex>
          </FiltersWrapper>

          <Flex justifyContent="flex-start">
            <FilterInfo mr={20} onClick={() => setIsModalOpened(true)}>
              {LISTING_TYPE_LABELS[get(getState(), 'values.type.0')] || 'Any space'}
            </FilterInfo>
            <FilterInfo mr={20} onClick={() => setIsModalOpened(true)}>
              {get(getState(), 'values.building', []).join(', ') || 'Any building'}
            </FilterInfo>
            <FilterInfo mr={20} onClick={() => setIsModalOpened(true)}>
              {getBedroomCountText(getState)}
            </FilterInfo>
            {isModalOpened && (
              <FiltersModal
                initialValues={initialValues}
                onSubmit={onSubmit}
                onClose={() => setIsModalOpened(false)}
              />
            )}
          </Flex>
        </form>
      )}
    />
  );
};

SearchForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  priceRangeDomain: PropTypes.array.isRequired,
};

export default SearchForm;
