import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Text } from 'rebass';
import styled, { css } from 'styled-components';
import { desaturate, lighten } from 'polished';
import check from '../../../assets/img/checkbox-check.png';
import { fontMixin, fontSize, LIGHT } from '../../../theme/styleUtils';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

const disableMixin = props => props.isDisabled && css`
  color: ${props.theme.colors.lightGray};
`;

const CustomCheckbox = styled.label`
  transition: border .3s ease-in-out;
  position: relative;
  ${disableMixin};
  cursor: pointer;
  &::before {
    content: ' ';
    position: absolute;
    left: -40px;
    top: -3px;
    width: 24px;
    height: 24px;
    border: 1px solid ${props => props.theme.colors.transparentGray};
  }
  &::after {
    content: ' ';
    position:absolute;
    width: 24px;
    height: 24px;
    top:-3px;
    left:-38px;
    transition: opacity .3s ease-in-out;

    visibility: hidden;
    input:checked ~ & {
      visibility: visible;

      background: url(${check}) no-repeat 50% 50% / contain;
    }
  }
`;

const DashCheckbox = styled.label`
  transition: border .3s ease-in-out background-color .3s ease-in-out;
  position: relative;
  cursor: pointer;
  ${fontMixin(LIGHT)}
  ${fontSize(16)}
  color: ${props => props.theme.colors.darkBrown};
  ${disableMixin}
  &::before {
    box-sizing: border-box;
    content: ' ';
    position: absolute;
    left: -30px;
    top: 2px;
    width: 18px;
    height: 17px;
    border: 1px solid ${props => props.theme.colors.darkBrown};
    background-color: #fff;
    input:checked:hover ~ & {
      background-color: ${props => props.theme.colors.purplePrimary};
      border: 1px solid black;
    }
    input:checked ~ & {
      background-color: ${props => props.theme.colors.purplePrimary};
      border: none;
    }
    input:hover ~ & {
      background-color: black;
    }
    ${props => props.isDisabled && css`
      input:checked:hover ~ & {
        background-color: ${lighten(0.1, desaturate(0.05, props.theme.colors.lightGray))};
        border: none;
      }
      input:checked ~ & {
        background-color: ${props.theme.colors.lightGray};
      }
      input:hover ~ & {
        background-color: ${props.theme.colors.pearlGray};
      }
    `};
  }
  &::after {
    position:absolute;
    width: 18px;
    height: 18px;
    left: -30px;
    top: 2px;
    transition: opacity .3s ease-in-out;
    content: '\\\\';
    text-align: center;
    ${fontSize(13)}
    font-weight: 700;
    color: white;
    visibility: hidden;
    input:checked ~ & {
      visibility: visible;
    }
  }
`;

const LightCheckbox = styled(DashCheckbox)`
  ${fontMixin(LIGHT)}
  ${fontSize(14)}
  line-height: 1.1em;
  &::after {
    ${fontSize(11)}
    top: 3px;
  }
`;

const checkboxVariant = (variant = '', name, value, label, children, disabled) => {
  let props = {};
  if (disabled) {
    props = {
      onClick: (e) => {
        e.preventDefault();
        return false;
      },
    };
  }

  if (variant === 'dash') {
    return (
      <DashCheckbox htmlFor={name + value} isDisabled={disabled} {...props}>
        {label}
        {children}
      </DashCheckbox>
    );
  }
  if (variant === 'light') {
    return (
      <LightCheckbox htmlFor={name + value} isDisabled={disabled} {...props}>
        {label}
        {children}
      </LightCheckbox>
    );
  }
  return (
    <CustomCheckbox htmlFor={name + value} isDisabled={disabled} {...props}>
      {label}
      {children}
    </CustomCheckbox>
  );
};

const Checkbox = ({
  label, name, value, variant, disabled, validate, children, format, subLabel,
}) => (
  <Box style={{ position: 'relative' }} ml={variant === 'bigTick' ? 40 : 30} my="5px">
    <Field format={format} disabled={disabled} name={name} id={name + value} component="input" type="checkbox" value={value} style={{ display: 'none' }} validate={validate} />
    { checkboxVariant(variant, name, value, label, children, disabled) }
    <Text color="darkBrown" fontSize={15} fontFamily={FONT_FAMILIES.NUNITO}>
      {subLabel}
    </Text>
  </Box>
);

Checkbox.defaultProps = {
  variant: 'dash',
  disabled: false,
};

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  label: PropTypes.string,
  subLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['dash', 'bigTick', 'light']),
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  format: PropTypes.func,
};

export default Checkbox;
