import gql from 'graphql-tag';

export const TENANCY_PARTS = gql`
  fragment TenancyParts on TenancyType {
    id
    progress
    bookingId
    name
    endDate
    location
    endDate
    mainImageUrl
    status
    statusDate
    unitType
  }
`;
