import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router';
import { compose } from 'recompose';
import queryString from 'query-string';
import { Query, graphql } from 'react-apollo';
import { EDIT_PROFILE } from '../../graphQL/mutations/editProfile';
import { GET_CURRENT_USER } from '../../graphQL/queries/getCurrentUser';
import { setAuthTokens, isLandlord } from '../../utils/auth';

class Welcome extends Component {
  state = { isAuthSet: false };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const values = queryString.parse(search);
    const auth = {
      ...values,
      accessToken: values['access-token'],
      tokenType: 'Bearer',
    };
    if (values['access-token']) {
      setAuthTokens(auth);
    }
    this.setState({ isAuthSet: true });
  }

  render() {
    const { isAuthSet } = this.state;
    if (isAuthSet) {
      return (
        <Query query={GET_CURRENT_USER}>
          {({ loading, data, error }) => {
            if (loading) return null;
            if (error) return <Redirect to="/" />;
            if (isLandlord(data.user)) {
              return (
                <Redirect
                  to={{
                    pathname: '/listings/place/intro',
                    isModalOpened: true,
                  }}
                />
              );
            }
            return (
              <Redirect
                to={{
                  pathname: '/',
                  isModalOpened: true,
                }}
              />
            );
          }}
        </Query>
      );
    }
    return null;
  }
}

Welcome.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const enhance = compose(
  withRouter,
  graphql(EDIT_PROFILE),
);

export default enhance(Welcome);
