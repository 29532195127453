import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box, Flex, Text } from 'rebass';
import { compose } from 'recompose';

import { FB_SIGN_IN, REGISTER_USER, SET_FB_DATA_FOR_REGISTARTION } from '../../graphQL/mutations';
import { GET_FB_DATA_FOR_REGISTARTION } from '../../graphQL/queries';
import { setAuthTokens } from '../../utils/auth';
import { mapApiErrors } from '../../utils/errorHandling';
import {
  composeValidators, passwordsMustMatch, required, validateLength,
} from '../../utils/formValidation';
import { FormButton } from '../auth/AuthLayout';
import Checkbox from '../common/form/Checkbox';
import FieldError from '../common/form/FieldError';
import Radio from '../common/form/Radio';
import TextInput from '../common/form/TextInput';
import Loading from '../common/Loading';
import Body from '../common/text/Body';

const validation = validateLength(5);

class RegisterForm extends Component {
  _renderRoles = () => {
    const { t } = this.props;
    return (
      <Box mb={25}>
        <FieldError name="role" direction="column">
          <Radio name="role" value="tenant" id="role-tenant" label={t('signup.roleTenant')} />
          <Radio name="role" value="landlord" id="role-landlord" label={t('signup.roleLandlord')} />
        </FieldError>
        <FieldError name="roles" />
      </Box>
    );
  };

  _renderTermsOfService = () => {
    const { t } = this.props;
    return (
      <Box my={15}>
        <Checkbox name="termsOfService" label={t('signup.termsOfService')} />
        <FieldError name="termsOfService" />
      </Box>
    );
  };

  createOnSubmit = signUp => async (variables) => {
    try {
      const { errors } = await signUp({ variables });
      if (errors) {
        return mapApiErrors(errors);
      }
    } catch (e) {
      console.error(e);
    }
  }

  _renderForm = () => {
    const { t, history } = this.props;
    return (
      <Mutation
        refetchQueries={() => ['GetCurrentUser']}
        mutation={REGISTER_USER}
        update={(cache, { data: { signUp } }) => {
          setAuthTokens(signUp);
          history.push('/signup/success');
        }}
      >
        {(signUp, { data, error: apiErrors, loading }) => (
          <Flex width={1} flexDirection="column">
            { loading && <Loading />}
            <Form
              onSubmit={this.createOnSubmit(signUp)}
              render={({ handleSubmit, submitError: errors, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Flex flexDirection="column" justifyContent="space-evenly">
                    {this._renderRoles(errors, apiErrors)}
                    <Box mb={15}>
                      <FieldError name={FORM_ERROR} />
                      <TextInput
                        name="email"
                        id="email"
                        placeholder={t('signup.email')}
                        validate={composeValidators(required, validation)}
                        validateOnChange
                        bgcolor="pearlGray"
                      />
                    </Box>
                    <Box mb={15}>
                      <TextInput
                        name="password"
                        password
                        id="password"
                        placeholder={t('signup.password')}
                        type="password"
                        validateOnBlur
                        validate={composeValidators(required, validation)}
                        bgcolor="pearlGray"
                      />
                    </Box>
                    <Box mb={10}>
                      <TextInput
                        name="confirmPassword"
                        password
                        placeholder={t('signup.confirmPassword')}
                        type="password"
                        validateOnBlur
                        validate={passwordsMustMatch}
                        bgcolor="pearlGray"
                      />
                    </Box>
                    {this._renderTermsOfService()}
                    <FormButton
                      id="register-button"
                      type="submit"
                      disabled={submitting}
                    >
                      {t('signup.button')}
                    </FormButton>
                  </Flex>

                </form>
              )}
            />
            <Box mt={15}>
              {this._handleSuccessRegistration(get(data, 'signUp', null))}
            </Box>
          </Flex>
        )}
      </Mutation>
    );
  }

  _handleSuccessRegistration = (data) => {
    const { t } = this.props;
    if (data) {
      return (
        <Text id="confirm-register">
          {`${t('signup.confirmation')}`}
        </Text>
      );
    }
    return null;
  }

  _onContinueWithFacebook = (signUpByFacebook, signUpFBData) => async (values) => {
    const { data: { signInByFacebook } } = await signUpByFacebook({ variables: { ...values, accessToken: signUpFBData.accessToken, userID: signUpFBData.uid } });
    if (signInByFacebook) {
      setAuthTokens(signInByFacebook);
      this.props.history.push('/listings');
    }
  }

  _onCancelWithFacebook = setSignUpFBData => () => setSignUpFBData({
    variables: {
      email: null,
      firstName: null,
      lastName: null,
      uid: null,
      pictureUrl: null,
      accessToken: null,
    },
  });

  _renderFBForm = (signUpFBData) => {
    const { history, t } = this.props;
    return (
      <Mutation
        refetchQueries={() => ['GetCurrentUser']}
        mutation={FB_SIGN_IN}
        update={({ data: { signInByFacebook } }) => {
          setAuthTokens(signInByFacebook);
          history.push('/listings');
        }}
      >
        {(signInByFacebook, { data, error, loading }) => (
          <Flex width={1} flexDirection="column">
            { loading && <Loading />}
            {this._handleSuccessRegistration(get(data, 'signUpByFacebook', null))}
            <Form
              id="form-with-fb"
              onSubmit={values => this._onContinueWithFacebook(signInByFacebook, signUpFBData)(values)}
              render={({ handleSubmit, errors, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Body mb={30}>
                    Registration with facebook
                  </Body>
                  {this._renderRoles(errors, error)}
                  {this._renderTermsOfService(errors, error)}
                  <Flex width={1} flexDirection="column">
                    <FormButton type="submit" disabled={submitting}>
                      {t('signup.withFacebookButton')}
                    </FormButton>
                  </Flex>

                  <Mutation mutation={SET_FB_DATA_FOR_REGISTARTION}>
                    {setSignUpFBData => (
                      <Text width={1} textAlign="center" mt={15} onClick={this._onCancelWithFacebook(setSignUpFBData)} type="submit">
                        {t('signup.resetFB')}
                      </Text>
                    )}
                  </Mutation>
                </form>
              )}
            />
          </Flex>
        )}
      </Mutation>
    );
  }

  render = () => (
    <Query query={GET_FB_DATA_FOR_REGISTARTION}>
      {({ loading, data: { signUpFBData } }) => {
        if (loading) return null;
        const isFromFB = !isEmpty(get(signUpFBData, 'uid', null));
        if (isFromFB) {
          return this._renderFBForm(signUpFBData);
        }
        return this._renderForm();
      }}
    </Query>
  );
}

RegisterForm.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};


export default compose(
  withNamespaces('auth'),
  withRouter,
)(RegisterForm);
