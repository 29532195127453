import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { scrollToFirstError } from '../../../../utils/formHelpers';
import { getMonthlyRentalPriceInitialValues } from '../../../../utils/prices';
import ExposeState from '../../../common/form/ExposeState';
import { FormPart, FormSubPart, FormWrapper } from '../../../common/form/FormWrapper';
import TextInput from '../../../common/form/TextInput';
import PricesAndPromotions from '../PricesAndPromotions';

const PricesForm = ({
  place,
  onSubmit,
  exposeState,
  onNext,
}) => (
  <Form
    initialValues={{
      yieldCalculator: get(place, 'yieldCalculator'),
      discount: get(place, 'discount'),
      monthlyUtilityPrice: get(place, 'monthlyUtilityPrice') || 0,
      promotionMonths: get(place, 'promotionMonths'),
      monthlyRentalPriceValues: getMonthlyRentalPriceInitialValues(place),
    }}
    onSubmit={onSubmit}
    render={({ handleSubmit, submitErrors, errors }) => {
      onNext(async () => {
        await handleSubmit();
        scrollToFirstError(submitErrors, errors);
      });
      return (
        <form onSubmit={handleSubmit}>
          <ExposeState exposeState={exposeState} />
          <FormWrapper>
            <PricesAndPromotions />
            <FormPart title="Yield">
              <FormSubPart subText="Calculate your yield by entering the total purchase cost of your listing. For co-living apportion a fair amount to this room.">
                <TextInput name="yieldCalculator" prefix="£" suffix="Monthly" />
              </FormSubPart>
            </FormPart>
          </FormWrapper>
        </form>
      );
    }}
  />
);

PricesForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  exposeState: PropTypes.func.isRequired,
  place: PropTypes.object.isRequired,
};

export default PricesForm;
