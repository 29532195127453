import gql from 'graphql-tag';

export const GET_ROOM = gql`
  query GetRoom($id: ID!) {
    room(id: $id) {
      id
      place {
        unitType { id name }
        listingType {
          id
          code
        }
        photos {
          id
          photo
          roomId
        }
        id
      }
      bedKind {
        id
        name
      }
      bathKind {
        id
        name
      }
      roomFacilities {
        id
        name
      }
      roomNiceTouches {
        id
        name
      }
      monthlyUtilityPrice
      promotionMonths
      discount
      monthlyRentalPriceValues {
        id
        monthlyRentalPriceId
        placeId
        value
      }
      photos {
        id
        photo
      }
    }
  }
`;

export const GET_ROOM_DETAILS_PHOTOS = gql`
  query GetRoomDetailsPhotos($id: ID!) {
    room(id: $id) {
      id
      photos {
        id
        photo(version: details)
        roomId
      }
    }
  }
`;

export const GET_ROOM_DETAILS = gql`
  query GetRoomDetails($id: ID!) {
    room(id: $id) {
      id
      landlord {
        id
        avatar
        fullName
        firstName
        lastName
        address
        description
        jobTitle
        email
        messagesAvailable
      }
      tenants {
        id
        jobTitle
        fullName
        avatar
        profileAvailable
        messagesAvailable
        description
      }
      place {
        id
        virtualTourUrl
        title
        downrootsApproved
        moveOutGuarantee
        location
        latitude
        longitude
        bedroomsCount
        bathroomsCount
        chosenFacilities {
          id
          facilityId
        }
        listingType {
          id
          code
        }
        photos {
          id
          photo(version: listing_square)
          roomId
        }
      }
      bedKind {
        id
        name
      }
      bathKind {
        id
        name
      }
      roomFacilities {
        id
        name
      }
      roomNiceTouches {
        id
        name
      }
      monthlyUtilityPrice
      promotionMonths
      discount
      monthlyRentalPriceValues {
        id
        monthlyRentalPriceId
        placeId
        value
      }
      photos {
        id
        photo
      }
      place {
        availableComfort: placeFacilities {
          id 
          name
          description
        }
        availableNiceTouches: niceTouches {
          id 
          name
          description
        }
        availableUtilities: utilities {
          id 
          name
          description
        }
      }
    }
  }
`;
