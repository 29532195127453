import React from 'react';
import { Switch } from 'react-router';
import { Box } from 'rebass';
import { renderNothing } from 'recompose';
import { APP_ROUTES } from '../../../constants';
import AppRoute from '../../common/AppRoute';
import Profile from '../../user/profile/Profile';
import Transactions from '../common/Transactions';
import Bookings from './Bookings';
import BookingStatus from './BookingStatus';
import Settings from './Settings';

const Tenant = () => (
  <Switch>
    <AppRoute exact path={APP_ROUTES.DASHBOARD.TENANT.BOOKINGS()} layout={Box} component={Bookings} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.TENANT.BOOKINGS(':id')} layout={Box} component={BookingStatus} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.TENANT.TRANSACTIONS()} layout={Box} component={Transactions} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.TENANT.FAVOURITES()} layout={Box} component={renderNothing()} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.TENANT.INBOX()} layout={Box} component={renderNothing()} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.TENANT.PROFILE()} layout={Box} component={Profile} navBar={renderNothing()} />
    <AppRoute exact path={APP_ROUTES.DASHBOARD.TENANT.SETTINGS()} layout={Box} component={Settings} navBar={renderNothing()} />
  </Switch>
);

export default Tenant;
