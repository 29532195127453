const setAuthState = (_, { isAuthenticated }, { cache }) => {
  cache.writeData({ data: { auth: { isAuthenticated, __typename: 'Auth' } } });
  return null;
};

const setSignUpFBData = (_, {
  firstName, lastName, email, pictureUrl, uid, accessToken,
}, { cache }) => {
  cache.writeData({
    data:
    {
      signUpFBData:
      {
        firstName,
        lastName,
        email,
        pictureUrl,
        uid,
        accessToken,
        __typename: 'FBData',
      },
    },
  });
  return null;
};

export default {
  Mutation: {
    setAuthState,
    setSignUpFBData,
  },
};
