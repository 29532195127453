import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { Box, Flex } from 'rebass';
import { scrollToFirstError } from '../../../utils/formHelpers';
import { required } from '../../../utils/formValidation';
import Condition from '../../common/form/Condition';
import ExposeState from '../../common/form/ExposeState';
import FieldError, { ErrorBoxContainer } from '../../common/form/FieldError';
import { FormPart, FormSubPart } from '../../common/form/FormWrapper';
import Radio from '../../common/form/Radio';
import { Separator } from '../../common/form/Separator';
import Body from '../../common/text/Body';
import { FilledRoundedButton } from '../../common/wizard/Footer';
import CreditCardFormFields from './common/CreditCardFormFields';

const PaymentForm = ({
  initialValues,
  onSubmit,
  onNext,
  exposeState,
  creditCard,
  isDefaultCreditCard,
  setIsDefaultCreditCard,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, errors }) => {
      onNext(async () => {
        await handleSubmit();
        scrollToFirstError(submitError, errors);
      });
      return (
        <form onSubmit={handleSubmit}>
          <ExposeState exposeState={exposeState} />
          <FormPart title="How will you pay your rent?">
            <FormSubPart
              subText="The reservation fee is payable today to reserve the property. You will not be charged until after you have reviewed the lease and booked. When you sign your lease the total payable in the booking box will be changed"
            >
              {submitError && <ErrorBoxContainer my={20}>{submitError}</ErrorBoxContainer>}
              {(!isDefaultCreditCard || !creditCard) && (
                <Box mb={40}>
                  <CreditCardFormFields />
                </Box>
              )}
              {creditCard && isDefaultCreditCard && (
              <Flex flexDirection="column" my={20}>
                <Body>
                  {'Card number: '}
                  {`**** **** **** ${creditCard.last4}`}
                </Body>
                <Body>
                  {'Expires on: '}
                  {`${creditCard.expMonth}/${creditCard.expYear}`}
                </Body>
              </Flex>
              )}
              <Box>
                {isDefaultCreditCard && creditCard && (
                  <FilledRoundedButton onClick={() => setIsDefaultCreditCard(false)}>
                    Change card
                  </FilledRoundedButton>
                )}
                {!isDefaultCreditCard && creditCard && (
                  <FilledRoundedButton onClick={() => setIsDefaultCreditCard(true)}>
                    Use current card
                  </FilledRoundedButton>
                )}
              </Box>
            </FormSubPart>
          </FormPart>
          <Separator />
          <FormPart title="Monthly recurring rent">
            <FormSubPart
              subText="Your monthly rent will be charged on the payment date each month for the duration of your lease beginning with the second months rent. You can change your payment method from your dashboard."
            >
              <FieldError direction="column" name="paymentMethod">
                <Box mt={15}>
                  <Radio
                    name="paymentMethod"
                    label="Credit / Debit Card"
                    value="credit_card"
                    validate={required}
                  />
                </Box>
                <Box mt={15}>
                  <Radio
                    name="paymentMethod"
                    label="Bank Transfer"
                    value="bank_transfer"
                    validate={required}
                  />
                </Box>
              </FieldError>
            </FormSubPart>
          </FormPart>
          <Condition when="paymentMethod" is="bank_transfer">
            <Separator />
            <Flex flexDirection="column" mt={40}>
              <Body mb={20}>You must set up a monthly standing order to:</Body>
              <Body>Bank: Barclays Bank</Body>
              <Body>Account Name: Downroots Client Account</Body>
              <Body>Account Number: 2234567</Body>
              <Body mb={20}>Sort Code: 20-29-87</Body>
              <Body mb={20}>If you are sending from a non UK bank account you will need the following:</Body>
              <Body>IBAN: 445RJFIERJOIRTJEOIRTJ45</Body>
              <Body mb={20}>SWIFT: 665500D</Body>
              <Body mb={20}>Add the following reference: DR22345</Body>
              <Separator />
              <Body mb={20}>
If your rent remains unpaid for 7 days after the rent due date, the amount owed will be
                  collected from your credit/debit card. You may be charged an administration fee.
              </Body>
            </Flex>
          </Condition>
        </form>
      );
    }}
  />
);

PaymentForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  creditCard: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  exposeState: PropTypes.func.isRequired,
  isDefaultCreditCard: PropTypes.bool.isRequired,
  setIsDefaultCreditCard: PropTypes.bool.isRequired,
};

export default PaymentForm;
