import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import styled from 'styled-components';
import Hide from 'hidden-styled';
import {
  fontMixin, LIGHT, transitionBackgroundColor,
} from '../../../theme/styleUtils';
import Avatar from '../../common/Avatar';
import Body from '../../common/text/Body';
import Subtitle from '../../common/text/Subtitle';
import Title from '../../common/text/Title';
import avatar from '../../../assets/img/avatar.svg';
import envelope from '../../../assets/img/envelope-icon.svg';

const OwnerInfoBox = styled(Flex)`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.darkBrown};
  padding: 30px;
`;

export const QuestionText = styled(Text)`
  ${fontMixin(LIGHT)}
  ${fontMixin(15)}
  color: ${props => props.theme.colors.lightPurple};
  cursor: pointer;
  ${transitionBackgroundColor('.3s')}
  &:hover {
    color: ${props => props.theme.colors.purplePrimary};
  }
`;

const DEFAULT_DESCRIPTION = 'Hello and thanks for taking an interest in my rental. If you would like any further information on it please go ahead and reach out to the Downroots concierge team, who can help you further.';

const DetailsOwnerPart = ({ t, landlord }) => (
  <>
    <Title my={30}>
      {t('details.owner.title')}
    </Title>
    <OwnerInfoBox>
      <Box>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box mb={25} style={{ flex: '0 0 160px' }}>
            <Avatar src={landlord.avatar || avatar} size={160} />
          </Box>
          <Flex ml={30} flexDirection="column">
            <Title mb="0px">
              {landlord.firstName}
              {' '}
              {(landlord.lastName || '').charAt(0)}
            </Title>
            <Flex alignItems="center" mb={30}>
              <Subtitle mr={10}>{landlord.jobTitle}</Subtitle>
              {landlord.messagesAvailable && landlord.email && (
                <a href={`mailto:${landlord.email}`}>
                  <Image src={envelope} width="auto" height={36} />
                </a>
              )}
            </Flex>
            <Hide xs>
              <Body>{landlord.description || DEFAULT_DESCRIPTION}</Body>
            </Hide>
          </Flex>
        </Flex>
        <Hide sm md lg>
          <Body>{landlord.description || DEFAULT_DESCRIPTION}</Body>
        </Hide>
      </Box>
    </OwnerInfoBox>
  </>
);

DetailsOwnerPart.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('listings')(DetailsOwnerPart);
