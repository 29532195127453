import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router';
import { Flex, Text, Box } from 'rebass';
import { compose } from 'recompose';
import styled from 'styled-components';
import { CREATE_BLOCK_OUT, DELETE_BLOCK_OUT } from '../../../../graphQL/mutations/blockOuts';
import { fontMixin, fontSize, LIGHT } from '../../../../theme/styleUtils';
import { mapApiErrors } from '../../../../utils/errorHandling';
import CalendarPreview from '../../../common/CalendarPreview';
import DateRange from '../../../common/form/DateRange';
import FieldError from '../../../common/form/FieldError';
import { FormPart, FormSubPart, FormWrapper } from '../../../common/form/FormWrapper';
import { Separator } from '../../../common/form/Separator';
import { RoundedButton } from '../../../common/wizard/Footer';
import ExposeState from '../../../common/form/ExposeState';

const StyledText = styled(Text)`
  ${fontMixin(LIGHT)}
  ${fontSize(15)}
  text-align: left;
  color: ${props => props.theme.colors.darkBrown};
`;

class BlockOutsForm extends Component {
  deleteBlockOut = (blockOutId) => {
    try {
      const isRoomBlockOut = this.isRoomBlockOut();
      this.props.deleteBlockOut({
        variables: {
          blockOutId,
        },
        refetchQueries: () => (isRoomBlockOut ? ['GetRoomBlockOuts'] : ['GetPlaceBlockOuts']),
      });
    } catch (e) {
      console.error(e);
    }
  };

  isRoomBlockOut = () => this.props.match.path === '/listings/place/:placeId/rooms/:id/calendar'

  onSubmit = async (values, { reset }) => {
    try {
      const isRoomBlockOut = this.isRoomBlockOut();
      const { match, createBlockOut } = this.props;
      const { errors } = await createBlockOut({
        variables: {
          startDate: moment(get(values, 'from')).format('YYYY-MM-DD'),
          endDate: moment(get(values, 'to')).format('YYYY-MM-DD'),
          resourceId: match.params.id,
          resourceType: isRoomBlockOut ? 'Room' : 'Place',
        },
        refetchQueries: () => (isRoomBlockOut ? ['GetRoomBlockOuts'] : ['GetPlaceBlockOuts']),
      });

      if (errors) {
        return mapApiErrors(errors);
      }

      reset();
    } catch (e) {
      console.error(e);
    }
  };

  getBlockedDays = () => this.props.blockOuts.map(blockOut => ({
    after: moment(blockOut.startDate).subtract(1, 'day').toDate(),
    before: moment(blockOut.endDate).add(1, 'day').toDate(),
  }));

  render() {
    const { exposeState } = this.props;
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, submitting }) => {
          this.onNext = handleSubmit;
          return (
            <form onSubmit={handleSubmit}>
              { exposeState && <ExposeState exposeState={exposeState} /> }
              <FormWrapper>
                <FormPart title="Block outs">
                  <FormSubPart subText="Block out periods when your property won't be available. Lease lengths that conflict with a blocked out period will not be made available for booking.">
                    {this.props.blockOuts.map(blockOut => (
                      <Flex alignItems="center" mb={10} key={blockOut.id}>
                        <StyledText pr={10}>
                          {`${moment(blockOut.startDate).format('DD/MM/YYYY')} - ${moment(blockOut.endDate).format('DD/MM/YYYY')}`}
                        </StyledText>
                        <RoundedButton
                          ml={['auto', 0, 0]}
                          onClick={(e) => {
                            this.deleteBlockOut(blockOut.id);
                            e.preventDefault();
                          }}
                        >
                          X
                        </RoundedButton>
                      </Flex>
                    ))}
                    <FieldError name="blockOut" />
                    <Box width={[1, 450, 450]}>
                      <DateRange blockedDays={this.getBlockedDays()} />
                    </Box>
                    <RoundedButton onClick={handleSubmit} disabled={submitting}>
                      Add to diary
                    </RoundedButton>
                  </FormSubPart>
                </FormPart>
                <Separator />
                <FormPart title="Calendar">
                  <CalendarPreview blocked={this.getBlockedDays()} />
                </FormPart>
              </FormWrapper>
            </form>
          );
        }}
      />
    );
  }
}

BlockOutsForm.propTypes = {
  match: PropTypes.object.isRequired,
  createBlockOut: PropTypes.func.isRequired,
  deleteBlockOut: PropTypes.func.isRequired,
  blockOuts: PropTypes.array.isRequired,
  exposeState: PropTypes.func,
};

const enhance = compose(
  withRouter,
  graphql(CREATE_BLOCK_OUT, { name: 'createBlockOut' }),
  graphql(DELETE_BLOCK_OUT, { name: 'deleteBlockOut' }),
);

export default enhance(BlockOutsForm);
