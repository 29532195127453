import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled from 'styled-components';
import placeholderImage from '../../../../assets/img/bedroom.png';
import Modal from '../../../common/Modal';
import DetailsImage from './DetailsImage';
import { ReactComponent as PlayButton } from '../../../../assets/img/tour-play-button.svg';
import { mediaQuery } from '../../../../theme/globalStyle';

const TourThumb = styled(DetailsImage)`
  &:hover {
    background-color: rgba(200,200,200,1);
    background-blend-mode: multiply;
    background-size: auto 120%;
    transition: background-size .15s ease-in;
    div {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const TourTooltip = styled.div`
  position: absolute;
  fill-opacity: 0.42;
  stroke-width: 1;
  stroke-opacity: 1;
  opacity: 1;
  stroke: rgb(255, 255, 255);
  fill: rgb(33, 33, 33);
  :hover {
    fill: rgb(99, 99, 99);
    fill-opacity: 0.7;
  }
  width: 60px;
  transition: opacity .2s ease-in fill-opacity .2s ease-in fill .2s ease-in;
  top: 50%;
  left: 50%;
  transform: rotateZ(90deg) translate(-50%, 50%);
  ${mediaQuery.mobile`
    width: 45px;
    height: 45px;
    svg {
      width: 45px;
      height: 45px;
    }
  `};
`;

const VirtualTourThumb = ({ virtualTourUrl }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  return (
    <>
      <TourThumb
        src={placeholderImage}
        onClick={() => setIsModalOpened(true)}
      >
        <TourTooltip tooltipOpacity={0.2}>
          <PlayButton />
        </TourTooltip>
      </TourThumb>
      { isModalOpened && (
        <Modal onClose={() => setIsModalOpened(false)}>
          <Box width="80vw" mt={15}>
            <iframe
              title="Sample Tour"
              width="100%"
              height="600"
              src={virtualTourUrl}
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

VirtualTourThumb.propTypes = {
  virtualTourUrl: PropTypes.string,
};

VirtualTourThumb.defaultProps = {
  virtualTourUrl: 'https://livetour.istaging.com/c951a5af-603f-4003-9d1c-707657febe95',
};

export default VirtualTourThumb;
