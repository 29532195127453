import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import map from 'lodash/map';

import TitleDescription from '../text/TitleDescription';
import FlowElement from './FlowElement';

import check from '../../assets/img/flow-check.png';
import action from '../../assets/img/flow-action.png';
import bed from '../../assets/img/flow-bed.png';
import { FONT_FAMILIES } from '../../theme/globalStyle';

const BorderFlex = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.pearlGray};
  position: relative;
  //height: 410px;
`;

const BackgroundBox = styled(Flex)`
  z-index: -1;
  position: relative;
  border: 1px solid ${props => props.theme.colors.pearlGray};
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
  padding-top: 350px;
  top: -300px;
`;

const CaptionTextTitle = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 15,
  color: 'dustGray',
})`
  text-transform: uppercase;
`;

const CaptionText = styled(Text).attrs({
  fontSize: 14,
  color: 'dustGray',
})``;

const flowItemImages = [check, action, bed];

class DownrootsFlow extends Component {
  render() {
    const { t } = this.props;
    return (
      <Flex flexDirection="column" alignItems="flex-start" width={1} mb={-300}>
        <TitleDescription
          title={t('flow.title')}
          desc={t('flow.desc')}
        />
        <Flex width={1} mt={50} justifyContent="center" alignItems="center" flexDirection="column" style={{ position: 'relative' }}>
          <BorderFlex
            bg="white"
            width={[0.8, 0.7, 13 / 16]}
            alignItems={['stretch', 'stretch', 'baseline']}
            flexDirection={['column', 'column', 'row', 'row']}
            justifyContent="space-around"
            py={30}
          >
            {map(t('flow.points', { returnObjects: true }), (point, idx) => (
              <FlowElement
                key={`${point.title}-${idx}`}
                title={point.title}
                desc={point.desc}
                imgSrc={flowItemImages[idx]}
                imgWidth={idx === 2 ? 85 : 70}
              />
            ))}
          </BorderFlex>
          <BackgroundBox
            width={1}
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CaptionTextTitle textAlign="center">{t('flow.no-gos.title')}</CaptionTextTitle>
            <CaptionText
              pb={10}
              px={20}
              pt={10}
              textAlign="left"
              lineHeight={2}
            >
              {t('flow.no-gos.desc')}
            </CaptionText>
          </BackgroundBox>
        </Flex>
      </Flex>
    );
  }
}

DownrootsFlow.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces('owners')(DownrootsFlow);
