import { Text } from 'rebass';
import styled from 'styled-components';
import { FONT_FAMILIES } from '../../../theme/globalStyle';

const MenuText = styled(Text).attrs({
  fontFamily: FONT_FAMILIES.AVENIR_LIGHT,
  fontSize: '16px',
})``;

export default MenuText;
