import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { required } from '../../utils/formValidation';
import TextInput from '../common/form/TextInput';

const ClientIndividualInfo = ({ legalOwnerType }) => (
  <Box>
    <Flex mb={15}>
      <Box width={1 / 2} pr="10px">
        <TextInput p={15} name={`legalOwner.${legalOwnerType}.firstName`} placeholder="First name" validate={required} />
      </Box>
      <Box width={1 / 2} pl="10px">
        <TextInput name={`legalOwner.${legalOwnerType}.lastName`} placeholder="Last name" validate={required} />
      </Box>
    </Flex>
  </Box>
);

ClientIndividualInfo.propTypes = {
  legalOwnerType: PropTypes.oneOf(['me', 'my_company', 'my_client', 'my_client_company']),
};

export default ClientIndividualInfo;
