import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import { FONT_WEIGHTS } from '../../../../theme/globalStyle';
import ListingItem from '../common/ListingItem';
import RenderIfNotEmpty from '../../common/RenderIfNotEmpty';


const TenancyList = ({
  items, title, history: { push }, emptyMessage,
}) => (
  <>
    <Text fontSize={30} fontWeight={FONT_WEIGHTS.BOLD} mt={50} mb={15}>
      {title}
    </Text>
    <RenderIfNotEmpty array={items} name={title} message={emptyMessage}>
      {items.map(item => (
        <ListingItem
          title={item.location}
          subTitle={item.name}
          status
          percentage={item.progress}
          progress={item.status}
          btnLabel="View"
          redirect={() => push(`${item.bookingId}`)}
          mainImageUrl={item.mainImageUrl}
          has3DTour={false}
          virtualTourBookedAt=""
          show3DTour={false}
          key={`tenancy-item-${item.id}`}
        />
      ))}
    </RenderIfNotEmpty>
  </>
);


TenancyList.propTypes = {
  history: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  emptyMessage: PropTypes.string,
};

export default withRouter(TenancyList);
