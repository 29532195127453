import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import get from 'lodash/get';
import { APP_ROUTES } from '../../../constants';
import { ASSIGN_CHOSEN_FACILITIES } from '../../../graphQL/mutations/assignChoosenFacilities';
import { GET_PLACE_AMENITIES } from '../../../graphQL/queries/getPlace';
import Footer from '../../common/wizard/Footer';
import WizardStep from '../../common/wizard/WizardStep';
import AmenitiesForm from './forms/AmenitiesForm';
import { mapApiErrors } from '../../../utils/errorHandling';
import { NarrowLayout } from '../../common/Layout';

class Amenities extends Component {
  state = {
    submitting: false,
  };

  onNext = noop;

  onPrev = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.LISTINGS.PLACE_BASICS(match.params.id));
  };

  createOnSubmit = filledStep => async (values) => {
    try {
      const { mutate, match, history } = this.props;
      const { data, errors } = await mutate({
        variables: {
          ...values,
          id: match.params.id,
        },
      });
      if (errors) {
        return mapApiErrors(errors);
      }
      if (filledStep === 3) {
        return history.push(APP_ROUTES.LISTINGS.PLACE_INTRO(data.assignChosenFacilities.id));
      }

      history.push(APP_ROUTES.LISTINGS.PLACE_MEDIA(data.assignChosenFacilities.id));
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <NarrowLayout>
        <WizardStep query={GET_PLACE_AMENITIES}>
          {({ data }) => (
            <AmenitiesForm
              initialValues={{
                facilityIds: data.place.chosenFacilities.map(({ facilityId }) => facilityId),
              }}
              onNext={(onNext) => {
                this.onNext = onNext;
              }}
              exposeState={({ submitting }) => this.setState({ submitting })}
              onSubmit={this.createOnSubmit(get(data, 'place.filledStep'))}
            />
          )}
        </WizardStep>
        <Footer
          onPrev={this.onPrev}
          onNext={() => this.onNext()}
          disabledNext={this.state.submitting}
          isPlace
        />
      </NarrowLayout>
    );
  }
}

Amenities.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  graphql(ASSIGN_CHOSEN_FACILITIES),
);

export default enhance(Amenities);
