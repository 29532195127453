import get from 'lodash/get';
import noop from 'lodash/noop';
import toNumber from 'lodash/toNumber';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, Query } from 'react-apollo';
import { Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import { compose } from 'recompose';

import { APP_ROUTES } from '../../constants';
import { UPDATE_ROOM } from '../../graphQL/mutations/room';
import { GET_FACILITIES_RANSACK_SEARCH } from '../../graphQL/queries/facilities';
import { GET_ROOM } from '../../graphQL/queries/room';
import { required } from '../../utils/formValidation';
import { isCoLiving } from '../../utils/listingType';
import { getMonthlyRentalPriceInitialValues, getMonthlyRentalPriceSubmitValues } from '../../utils/prices';
import Checkbox from '../common/form/Checkbox';
import Dropdown from '../common/form/Dropdown';
import FieldError from '../common/form/FieldError';
import { FormPart, FormSubPart, FormWrapper } from '../common/form/FormWrapper';
import { Separator } from '../common/form/Separator';
import Loading from '../common/Loading';
import { FilledRoundedButton, RoundedButton } from '../common/wizard/Footer';
import WizardStep from '../common/wizard/WizardStep';
import RoomPhotos from './place/RoomPhotos';
import { mapApiErrors } from '../../utils/errorHandling';
import PricesAndPromotions from './pricesAndCalendar/PricesAndPromotions';
import { isUnitNameStudio } from './place/forms/basics/PropertyPart';
import { NarrowLayout } from '../common/Layout';

class Room extends Component {
  onNext = noop;

  onSubmit = async ({ values, room }) => {
    try {
      const { history, match, mutate } = this.props;
      const priceValues = {
        discount: toNumber(values.discount) || null,
        monthlyUtilityPrice: toNumber(values.monthlyUtilityPrice) || 0,
        monthlyRentalPriceValues: getMonthlyRentalPriceSubmitValues({
          values,
          match,
          placeOrRoom: room,
        }, false),
      };
      const { data, errors } = await mutate({
        variables: {
          ...omit(values, 'memoizedPhotoIds'),
          id: match.params.id,
          ...isCoLiving(room) ? priceValues : {},
        },
      });
      if (errors) {
        return mapApiErrors(errors);
      }
      const listingTypeCode = get(data, 'createOrUpdateRoom.place.listingType.code');
      const unitTypeName = get(data, 'createOrUpdateRoom.place.unitType.name');

      if (listingTypeCode === 'entire_place') {
        if (isUnitNameStudio(unitTypeName)) {
          history.push(APP_ROUTES.LISTINGS.PLACE_BOOKING_RULES(data.createOrUpdateRoom.place.id));
        } else {
          history.push(APP_ROUTES.LISTINGS.PLACE_BEDS_AND_BATHS(data.createOrUpdateRoom.place.id));
        }
      } else {
        history.push(APP_ROUTES.LISTINGS.PLACE_ROOMS(data.createOrUpdateRoom.place.id));
      }
    } catch (e) {
      console.error(e);
    }
  };

  onCancel = (room) => {
    const { history } = this.props;
    const placeId = get(room, 'place.id');
    const listingTypeCode = get(room, 'place.listingType.code');
    const unitTypeName = get(room, 'place.unitType.name');
    if (listingTypeCode === 'entire_place') {
      if (isUnitNameStudio(unitTypeName)) {
        history.push(APP_ROUTES.LISTINGS.PLACE_MEDIA(placeId));
      } else {
        history.push(APP_ROUTES.LISTINGS.PLACE_BEDS_AND_BATHS(placeId));
      }
    } else {
      history.push(APP_ROUTES.LISTINGS.PLACE_ROOMS(placeId));
    }
  };

  renderDropdownSection = ({
    title, facilityType, name, isDropdown,
  }) => (
    <FormSubPart subTitle={title}>
      <Query
        query={GET_FACILITIES_RANSACK_SEARCH}
        variables={{
          queries: [
            { condition: 'type_eq', value: facilityType }],
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return <Loading />;
          }

          if (isDropdown) {
            return (
              <Box width="100%">
                <Dropdown
                  name={name}
                  options={data.facilitiesRansackSearch.map(facility => ({
                    label: facility.name,
                    value: facility.id,
                  }))}
                  validate={required}
                />
              </Box>
            );
          }

          return (
            <FieldError name={name}>
              <Flex flexWrap="wrap" width={1}>
                {
                  data.facilitiesRansackSearch.map(facility => (
                    <Box width={1 / 2} mt={10} key={facility.id}>
                      <Checkbox label={facility.name} name={name} value={facility.id} />
                    </Box>
                  ))
                }
              </Flex>
            </FieldError>
          );
        }}
      </Query>
    </FormSubPart>
  );

  renderForm = ({ room }) => (
    <Form
      initialValues={{
        bedKindId: get(room, 'bedKind.id'),
        bathKindId: get(room, 'bathKind.id'),
        roomFacilityIds: get(room, 'roomFacilities', []).map(f => f.id),
        roomNiceTouchIds: get(room, 'roomNiceTouches', []).map(f => f.id),
        discount: get(room, 'discount'),
        monthlyUtilityPrice: get(room, 'monthlyUtilityPrice') || 0,
        promotionMonths: get(room, 'promotionMonths'),
        monthlyRentalPriceValues: getMonthlyRentalPriceInitialValues(room),
        photoIds: get(room, 'photos', []).map(f => f.id),
      }}
      onSubmit={values => this.onSubmit({ values, room })}
      render={({ handleSubmit, form: { getState, change }, submitting }) => {
        this.onNext = handleSubmit;
        return (
          <form onSubmit={handleSubmit}>
            <FormWrapper>
              <FormPart title="Bedroom">
                {this.renderDropdownSection({
                  facilityType: 'BedKind',
                  title: 'The bed',
                  name: 'bedKindId',
                  isDropdown: true,
                })}
                {this.renderDropdownSection({
                  facilityType: 'BathKind',
                  title: 'The bath',
                  name: 'bathKindId',
                  isDropdown: true,
                })}
                <Separator />
              </FormPart>
              <FormPart title="Bedroom area amenities">
                {this.renderDropdownSection({
                  facilityType: 'RoomFacility',
                  title: 'Comfort',
                  name: 'roomFacilityIds',
                })}
                {this.renderDropdownSection({
                  facilityType: 'RoomNiceTouch',
                  title: 'Nice touches',
                  name: 'roomNiceTouchIds',
                })}
              </FormPart>
              <RoomPhotos room={room} getState={getState} formOnChange={change} />
              {isCoLiving(room) && <PricesAndPromotions />}
              <Box>
                {false && <RoundedButton mr={30} onClick={() => this.onCancel(room)}>Cancel</RoundedButton>}
                <FilledRoundedButton
                  onClick={handleSubmit}
                  disabled={submitting}
                  px={30}
                  py={10}
                >
                  Done
                </FilledRoundedButton>
              </Box>
            </FormWrapper>
          </form>
        );
      }}
    />
  );

  render() {
    return (
      <NarrowLayout>
        <WizardStep query={GET_ROOM}>
          {({ data }) => this.renderForm(data)}
        </WizardStep>
      </NarrowLayout>
    );
  }
}

Room.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withNamespaces('listingForm'),
  graphql(UPDATE_ROOM),
);

export default enhance(Room);
