import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { FormSpy } from 'react-final-form';
import { diff } from 'deep-diff';

const AutoSave = ({ values, save, debounce }) => {
  const [timeoutObject, setTimeoutObject] = useState(null);
  const [stateValues, setStateValues] = useState(values);

  const valuesToOmit = ['minPrice', 'maxPrice', 'priceRange'];

  useEffect(() => {
    if (timeoutObject) {
      clearTimeout(timeoutObject);
    }
    setTimeoutObject(setTimeout(
      () => {
        const difference = diff(omit(stateValues, valuesToOmit), omit(values, valuesToOmit));

        if (get(difference, 'length') > 0) {
          setStateValues(values);
          save(values);
        }
      },
      debounce,
    ));
    return () => clearTimeout(timeoutObject);
  }, [values]);
  return null;
};

AutoSave.propTypes = {
  values: PropTypes.object,
  debounce: PropTypes.number.isRequired,
  save: PropTypes.func.isRequired,
};

export default props => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
);
