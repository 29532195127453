import styled from 'styled-components';
import { Button } from 'rebass';
import { transitionBackgroundColor, fontSize } from '../../../theme/styleUtils';

export default styled(Button)`
  ${fontSize(17)}
  min-width: 237px;
  height: 46px;
  border-radius: 0;
  padding: 0;
  background-color: ${props => props.theme.colors.darkBrown};
  color: white;
  font-stretch: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${transitionBackgroundColor('.3s')}
  &:hover{
    background-color: black;
  }
`;
