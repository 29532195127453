import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import words from 'lodash/words';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from 'rebass';
import { JOB_KINDS } from '../../../constants';
import { GET_JOB_NAMES, GET_UNIVERSITY_NAMES } from '../../../graphQL/queries/bookings';
import Checkbox from '../../common/form/Checkbox';
import Dropdown from '../../common/form/Dropdown';
import { FormPart } from '../../common/form/FormWrapper';
import QueryDropdown from '../../common/form/QueryDropdown';
import TextInput from '../../common/form/TextInput';

const EmployedOccupation = () => (
  <>
    <Box width={[1, 1 / 2, 1 / 2]} px="5px" mb="5px">
      <QueryDropdown
        query={GET_JOB_NAMES}
        dataPath="dictionary"
        name="person.jobTitle"
        label="Job title"
      />
    </Box>
    <Box width={1} px="5px">
      <TextInput name="person.businessName" label="Employer name" />
    </Box>
  </>
);

const SelfEmployedOccupation = () => (
  <>
    <Box width={1} px="5px" mb="5px">
      <QueryDropdown
        query={GET_JOB_NAMES}
        dataPath="dictionary"
        name="person.jobTitle"
        label="Job title"
      />
    </Box>
    <Box width={1} px="5px">
      <TextInput name="person.businessName" label="Business name" />
    </Box>
  </>
);

const StudentOccupation = () => (
  <>
    <Box width={[1, 1 / 2, 1 / 2]} px="5px" mb="5px">
      <QueryDropdown
        query={GET_UNIVERSITY_NAMES}
        dataPath="dictionary"
        name="person.university"
        label="Where"
      />
    </Box>
    <Box width={1} px="5px">
      <Checkbox name="person.studentLoan" label="I have a student loan" variant="bigTick" />
    </Box>
  </>
);

const partByJobKind = {
  [JOB_KINDS[0]]: EmployedOccupation,
  [JOB_KINDS[1]]: SelfEmployedOccupation,
  [JOB_KINDS[2]]: StudentOccupation,
  null: () => null,
};

const OccupationPart = ({ kindOfJob = null, subTitle, change }) => {
  const SpecificJobPart = partByJobKind[kindOfJob];
  return (
    <FormPart title="What you do" subTitle={subTitle}>
      <Flex mx="-5px" flexWrap="wrap" justifyContent="space-between" mb={20}>
        <Box width={[1, 1 / 2, 1 / 2]} px="5px" mb="5px">
          <Dropdown
            name="person.kindOfJob"
            options={map(JOB_KINDS, kind => ({ value: kind, label: capitalize(words(kind).join(' ')) }))}
            label="I am"
            onChange={({ value }) => {
              if (value === 'student') {
                change('person.jobTitle', null);
                change('person.businessName', null);
              } else {
                change('person.studentLoan', null);
                change('person.university', null);
              }
            }}
          />
        </Box>
        <SpecificJobPart />
      </Flex>
    </FormPart>
  );
};

OccupationPart.propTypes = {
  kindOfJob: PropTypes.oneOf(JOB_KINDS),
  subTitle: PropTypes.string,
  change: PropTypes.func.isRequired,
};

export default OccupationPart;
