import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import {
  fontSize,
} from '../../../theme/styleUtils';
import Body from '../../common/text/Body';
import Title from '../../common/text/Title';

export const ProfileEditSubtitle = styled(Text)`
  ${fontSize(16)}
  text-align: center;
  color: ${props => props.theme.colors.darkBrown};
`;

export const ProfileEditText = styled(Text)`
  ${fontSize(14)}
  line-height: 1.6rem;
  color: ${props => props.theme.colors.darkBrown};
`;

const ProfileEditPart = ({ title, text, children }) => (
  <Flex flexDirection="column" alignItems="left" mb={20}>
    <Title mb={20}>{title}</Title>
    {text && <Body mb={20}>{text}</Body> }
    {children}
  </Flex>
);

ProfileEditPart.defaultProps = {
  children: null,
  text: undefined,
};

ProfileEditPart.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
};

export default ProfileEditPart;
