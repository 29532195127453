import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import noop from 'lodash/noop';
import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';

import { theme } from '../../../theme/globalStyle';
import { ErrorBox } from './FieldError';
import { TextInputLabel } from './TextInput';

const handleStyle = {
  backgroundColor: '#fff',
  border: `1px solid ${theme.colors.purplePrimary}`,
  marginTop: '-10px',
  height: '24px',
  borderRadius: 0,
};

const sliderStyles = {
  trackStyle: [{
    backgroundColor: theme.colors.purplePrimary,
  }],
  handleStyle: [
    handleStyle, handleStyle,
  ],
  railStyle: {
    backgroundColor: 'black',
  },
  dotStyle: {
    borderColor: theme.colors.purplePrimary,
  },
};

const RangeSlider = ({
  name, validate, label, min, max, onAfterChange,
}) => (
  <>
    { label && <TextInputLabel>{label}</TextInputLabel> }
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <>
          <Range
            value={input.value}
            onChange={input.onChange}
            onAfterChange={onAfterChange}
            defaultValue={meta.initial}
            min={min}
            max={max}
            {...sliderStyles}
          />
          <ErrorBox meta={meta} />
        </>
      )}
    </Field>
  </>
);

RangeSlider.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  label: PropTypes.string,
  min: PropTypes.number,
  onAfterChange: PropTypes.func.isRequired,
  max: PropTypes.number,
};

RangeSlider.defaultProps = {
  validate: noop,
};

export default RangeSlider;
