import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import map from 'lodash/map';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import Loading from '../Loading';
import Dropdown from './Dropdown';
import Error from '../Error';
import { HalfDropdown } from '../../bookings/personal/ProfilePart';

const DropdownMemo = ({
  mapData, data, dataPath, half, ...dropdownProps
}) => {
  const options = useMemo(() => mapData(get(data, dataPath)), [data]);
  const DropdownComponent = half ? HalfDropdown : Dropdown;
  return (
    <DropdownComponent
      options={options}
      {...dropdownProps}
    />
  );
};

const QueryDropdown = ({
  query, variables, ...dropdownProps
}) => (
  <Query query={query} variables={variables}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;
      return <DropdownMemo data={data} {...dropdownProps} />;
    }}
  </Query>
);

QueryDropdown.propTypes = {
  query: PropTypes.object.isRequired,
  dataPath: PropTypes.string,
  mapData: PropTypes.func,
  half: PropTypes.bool,
  variables: PropTypes.object,
};

QueryDropdown.defaultProps = {
  dataPath: '',
  mapData: data => sortBy(map(data, ({ name }) => ({ value: name, label: name })), 'label'),
};

export default QueryDropdown;
