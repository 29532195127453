import get from 'lodash/get';
import head from 'lodash/head';
import noop from 'lodash/noop';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { compose } from 'recompose';
import { GET_OFFERED_OBJECT_PRICES } from '../../../graphQL/queries/offeredObjectPrices';
import { fontMixin, fontSize, LIGHT } from '../../../theme/styleUtils';
import { formatPrice } from '../../../utils/prices';
import Dropdown from '../../common/form/Dropdown';
import { Separator } from '../../common/form/Separator';
import Modal from '../../common/Modal';
import { theme } from '../../../theme/globalStyle';
import Body from '../../common/text/Body';
import Caption from '../../common/text/Caption';
import Subtitle from '../../common/text/Subtitle';
import { FilledRoundedButton } from '../../common/wizard/Footer';

const CloseButtonWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 15px;
`;

const CloseButton = styled.a`
  ${fontMixin(LIGHT)}
  ${fontSize(35)}
  color: #000;
  text-decoration: none;
  cursor: pointer;
`;

const DayPickerContainer = styled(Box)`
  height: 320px;
`;

const modifiersStyles = {
  blocked: {
    opacity: 1,
    color: theme.colors.lightGray,
    textDecoration: 'line-through',
  },
  free: {
    color: theme.colors.lightGray,
    opacity: 0.5,
  },
  available: {
    color: theme.colors.green,
  },
  selected: {
    opacity: 1,
    color: 'white',
    textDecoration: 'none',
    backgroundColor: theme.colors.lightGreen,
    borderRadius: 0,
  },
  outside: {
    backgroundColor: 'transparent',
  },
};

const PropTypeNameText = styled(Caption)`
  ${fontSize(12)}
  text-transform: uppercase;
`;

const Wrapper = styled(Flex)`
  max-height: 809px;
`;

const ListingBreakdownModal = ({
  onClose, location, t, history,
}) => {
  const params = queryString.parse(location.search);
  const [selectedDay, setSelectedDay] = useState(moment(params.dateFrom).toDate());

  return (
    <Modal bg="transparentGray" onClose={onClose}>
      <Query
        query={GET_OFFERED_OBJECT_PRICES}
        variables={{
          dateFrom: params.dateFrom,
          leaseLength: +params.leaseLength,
          indexableId: params.indexableId,
          objectType: params.type,
        }}
      >
        {({ data }) => {
          const availableFrom = get(data, 'offeredObjectPrices.offeredObject.availableFrom');

          return (
            <Form
              initialValues={{
                leaseLength: +params.leaseLength || +head(get(data, 'offeredObjectPrices.offeredObject.availablePrices')),
                startDate: moment(availableFrom || params.dateFrom).format(),
              }}
              onSubmit={noop}
            >
              {({ handleSubmit, form: { change } }) => (
                <Wrapper py={30} px={[30, 30, 60]} flexDirection="column" bg="white" style={{ position: 'relative' }}>
                  <form onSubmit={handleSubmit}>
                    <CloseButtonWrapper>
                      <CloseButton onClick={onClose}>x</CloseButton>
                    </CloseButtonWrapper>
                    <Subtitle>
                      {get(data, 'offeredObjectPrices.offeredObject.objectAddress')}
                    </Subtitle>
                    <PropTypeNameText mb={30}>
                      {get(data, 'offeredObjectPrices.offeredObject.objectType')}
                    </PropTypeNameText>
                    <Dropdown
                      name="leaseLength"
                      label="Lease Length"
                      onChange={({ value }) => {
                        const newParams = {
                          ...queryString.parse(location.search),
                          leaseLength: value,
                        };
                        history.push(`/listings/details?${queryString.stringify(newParams)}`);
                      }}
                      options={
                        get(data, 'offeredObjectPrices.offeredObject.availablePrices', []).map(i => ({
                          value: +i,
                          label: `${i} ${t('months')}`,
                        }))
                      }
                    />
                    <Body mt={30}>Move in</Body>
                    <Body>{moment(selectedDay).format('DD/MM/YYYY')}</Body>
                    <Field name="startDate" component="input" type="hidden" />
                    <DayPickerContainer>
                      <DayPicker
                        modifiers={{
                          available: [
                            {
                              after: moment(availableFrom).subtract(1, 'day').toDate(),
                              before: moment().add(1, 'day').add(18, 'months').toDate(),
                            },
                          ],
                          free: [
                            ...get(data, 'offeredObjectPrices.offeredObject.leaseBlockOuts', []).map(blockOut => ({
                              after: moment(blockOut.startDate)
                                .subtract(1, 'day')
                                .toDate(),
                              before: moment(blockOut.endDate)
                                .add(1, 'day')
                                .toDate(),
                            })),
                          ],
                          blocked: [
                            {
                              before: moment(availableFrom).toDate(),
                              after: moment().add(18, 'months').toDate(),
                            },
                            ...get(data, 'offeredObjectPrices.offeredObject.blockOuts', []).map(blockOut => ({
                              after: moment(blockOut.startDate)
                                .subtract(1, 'day')
                                .toDate(),
                              before: moment(blockOut.endDate)
                                .add(1, 'day')
                                .toDate(),
                            })),
                          ],
                        }}
                        modifiersStyles={modifiersStyles}
                        numberOfMonths={1}
                        initialMonth={moment(params.dateFrom).toDate()}
                        selectedDays={{
                          after: moment(selectedDay).subtract(1, 'day').toDate(),
                          before: moment(selectedDay).add(1, 'day').add(+(params.leaseLength || +head(get(data, 'offeredObject.availablePrices'))), 'month').toDate(),
                        }}
                        onDayClick={(day, {
                          disabled, blocked, free,
                        }) => {
                          if ((!disabled && !free && !blocked)) {
                            setSelectedDay(day);
                            change('startDate', moment(day).format());
                            const newParams = {
                              ...queryString.parse(location.search),
                              dateFrom: moment(day).format(),
                            };
                            history.push(`/listings/details?${queryString.stringify(newParams)}`);
                          }
                        }}
                      />
                    </DayPickerContainer>
                    <Separator mb={15} />
                    <Flex justifyContent="space-between">
                      <Body>Monthly rent</Body>
                      <Body>
                        {formatPrice(get(data, 'offeredObjectPrices.monthlyRentValue'))}
                      </Body>
                    </Flex>
                    <Flex mb={10} justifyContent="space-between">
                      <Body>Monthly utilities</Body>
                      <Body>
                        {formatPrice(data.offeredObjectPrices.monthlyUtilitiesValue)}
                      </Body>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Body fontWeight="bold">Monthly total</Body>
                      <Body fontWeight="bold">
                        {formatPrice(data.offeredObjectPrices.monthlyTotal)}
                      </Body>
                    </Flex>
                    <Flex justifyContent="flex-end">
                      <FilledRoundedButton mt={15} onClick={onClose}>Save</FilledRoundedButton>
                    </Flex>
                  </form>
                </Wrapper>
              )}
            </Form>
          );
        }}
      </Query>
    </Modal>
  );
};

ListingBreakdownModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const enhance = compose(
  withNamespaces('booking'),
  withRouter,
);

export default enhance(ListingBreakdownModal);
