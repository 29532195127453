import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import isFunction from 'lodash/isFunction';
import { Box } from 'rebass';
import styled from 'styled-components';

export const LightBoxWrapper = styled(Box)`
  background-color: #fff;
  
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    width: 100vw;
    height: 100vh;  
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100vw;
  overflow-y: scroll;
  height: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.colors[props.bg] || props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.styles};
  @media screen and (max-height: 809px) {
    align-items: flex-start;
  }
`;

const Modal = ({
  children, bg = 'rgba(42, 42, 42, 0.9)', onClose, styles,
}) => {
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (isFunction(onClose)) onClose();
    }
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.body.style.overflowY = 'visible';
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  return (
    <Portal>
      <ModalWrapper bg={bg} styles={styles}>
        <span ref={ref}>
          {children}
        </span>
      </ModalWrapper>
    </Portal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  bg: PropTypes.string,
  onClose: PropTypes.func,
  styles: PropTypes.array,
};

export default Modal;
