/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withProps, compose } from 'recompose';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';
import get from 'lodash/get';
import { Marker, withScriptjs } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import map from 'lodash/map';
import { withNamespaces } from 'react-i18next';

import config from '../../configLoader';
import { fontSize } from '../../theme/styleUtils';
import MapWithReference from './MapWithReference';
import { FONT_FAMILIES } from '../../theme/globalStyle';

const SelectPlacesButton = styled.a`
  ${fontSize(16)}
  color: ${props => props.theme.colors.lightPurple};
  padding: 20px 30px 20px 2px;
  cursor: pointer;
`;

const PlaceItem = styled(Flex).attrs({
  width: [1, 1, 1 / 2, 1 / 3],
  fontFamily: FONT_FAMILIES.AVENIR_BOLD,
  fontSize: 13,
  color: 'midDarkBrown',
  px: 25,
  py: 10,
})`
  //padding: 10px 10px 10px 10px;
  //width: calc(33% - 70px);
  cursor: pointer;
`;

const PlaceItemName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 7px;
`;

const DistanceText = styled.span`
  color: ${props => props.theme.colors.dustGray};
`;

class GoogleMapWithPlaces extends Component {
  state = {
    currentPlaces: [],
    selectedPlace: null,
  };

  mapReference = null;

  setSelectedPlace = selectedPlace => this.setState({ selectedPlace });

  distanceToListing = location => google.maps.geometry.spherical.computeDistanceBetween(
    new google.maps.LatLng(this.props.locationCoords.lat, this.props.locationCoords.lng),
    new google.maps.LatLng(location.lat(), location.lng()),
  );

  onTypeClick = placeType => () => {
    const { lat, lng } = this.props.locationCoords;
    const mapInstance = this.mapReference.context[MAP];
    const placesService = new google.maps.places.PlacesService(mapInstance);
    const location = new google.maps.LatLng(lat, lng);
    const request = {
      location,
      // radius: '2000', //to discuss what is the better way of querying
      rankBy: google.maps.places.RankBy.DISTANCE, // j.w.
      type: placeType,
    };
    placesService.nearbySearch(request, (res, status) => {
      if (status === 'OK') {
        const currentPlaces = map(res, place => ({
          name: place.name,
          types: place.types,
          distance: this.distanceToListing(place.geometry.location) * 1.6 / 60,
          location: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          icon: place.icon,
        }));
        this.setState({ currentPlaces });
      }
    });
  };

  render() {
    const { placeTypes, t, isMarkerShown } = this.props;
    const { currentPlaces } = this.state;
    return (
      <>
        <Flex flexWrap="wrap">
          {map(placeTypes, (type, idx) => (
            <SelectPlacesButton key={`${type}-${idx}`} onClick={this.onTypeClick(type)}>{t(`details.location.placeTypes.${type}`)}</SelectPlacesButton>
          ))}
        </Flex>
        <MapWithReference
          defaultZoom={15}
          defaultCenter={this.props.locationCoords}
          onRef={(ref) => { this.mapReference = ref; }}
          isMarkerShown={isMarkerShown}
        >
          {this.state.selectedPlace && (
            <Marker position={this.state.selectedPlace.location} />
          )}
        </MapWithReference>
        <Flex width={1} flexWrap="wrap" justifyContent="flex-start" mt={30}>
          {map(currentPlaces, (place, idx) => {
            const distance = get(place, 'distance', 0.0).toFixed(0);
            return (
              <PlaceItem
                key={`${place}-${idx}`}
                justifyContent="space-between"
                onClick={() => this.setSelectedPlace(place)}
              >
                <Flex style={{ overflow: 'hidden' }} pr="5px">
                  <Image src={place.icon} width="auto" height={16} />
                  <PlaceItemName>{place.name}</PlaceItemName>
                </Flex>
                <DistanceText>{`${distance} ${distance === 1 ? 'min' : 'mins'}`}</DistanceText>
              </PlaceItem>
            );
          })}
        </Flex>
      </>
    );
  }
}

GoogleMapWithPlaces.defaltProps = {
  isMarkerShown: false,
};

GoogleMapWithPlaces.propTypes = {
  isMarkerShown: PropTypes.bool,
  placeTypes: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  locationCoords: PropTypes.object.isRequired,
};

const enhance = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&libraries=places,geometry`,
    loadingElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withNamespaces('listings'),
);

export default enhance(GoogleMapWithPlaces);
