import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';

export const getMonthlyRentalPriceInitialValues = placeOrRoom => get(placeOrRoom, 'monthlyRentalPriceValues', [])
  .reduce((acc, val) => {
    acc[val.monthlyRentalPriceId] = val.value;
    return acc;
  }, []);

export const getMonthlyRentalPriceSubmitValues = ({ values, match, placeOrRoom }, isPlace = true) => values.monthlyRentalPriceValues.map((v, index) => {
  const monthlyRentalPriceValue = get(placeOrRoom, 'monthlyRentalPriceValues', []).find(({ monthlyRentalPriceId }) => monthlyRentalPriceId === toString(index));

  return ({
    [isPlace ? 'placeId' : 'roomId']: match.params.id,
    monthlyRentalPriceId: toString(index),
    value: toNumber(v) || null,
    id: monthlyRentalPriceValue ? monthlyRentalPriceValue.id : undefined,
  });
}).filter(v => !isEmpty(v));

export const formatPrice = price => (price ? `${toNumber(price).toFixed(2)} £` : '0.00 £');
