import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';
import some from 'lodash/some';
import { APP_ROUTES } from '../../../constants';
import { GET_PLACE_ROOMS } from '../../../graphQL/queries/getPlace';
import Footer, { FilledRoundedButton } from '../../common/wizard/Footer';
import BedsDashboard from './forms/BedsDashboard';
import Loading from '../../common/Loading';
import Error from '../../common/Error';
import { NarrowLayout } from '../../common/Layout';
import Modal from '../../common/Modal';
import BookVirtualTourForm from './forms/BookVirtualTourForm';

const areNoRoomsActive = rooms => !some(rooms, room => room.monthlyRentalPriceValues.length > 0);

class BedsAndBaths extends Component {
  state = {
    showBookMyVirtualTour: false,
  };

  _showBookMyVirtualTourClicked = () => this.setState({ showBookMyVirtualTour: true });

  _closeBookMyVirtualTourClicked = () => this.setState({ showBookMyVirtualTour: false });

  onPrev = () => {
    const { history, match } = this.props;
    if (match.path === '/listings/place/:id/rooms') {
      history.push(APP_ROUTES.LISTINGS.PLACE_BOOKING_RULES(match.params.id));
    } else {
      history.push(APP_ROUTES.LISTINGS.PLACE_MEDIA(match.params.id));
    }
  };

  onNext = () => {
    const { history, match } = this.props;
    history.push(APP_ROUTES.LISTINGS.PLACE_INTRO(match.params.id));
  };

  render() {
    const {
      t, match, data: { loading, error }, data,
    } = this.props;
    const isCoLiving = get(data, 'place.listingType.code') === 'co_living';
    if (loading) return <Loading />;
    if (error) return <Error />;
    return (
      <NarrowLayout>
        <BedsDashboard
          onNext={(onNext) => {
            this.onNext = onNext;
          }}
          data={data}
        />
        { this.state.showBookMyVirtualTour &&
          (
            <Modal bg="rgba(0, 0, 0, 0.5)" onClose={this._closeBookMyVirtualTourClicked}>
              <BookVirtualTourForm placeId={match.params.id} onClose={this._closeBookMyVirtualTourClicked} bookedNote={get(data, 'place.virtualTourBookedNote')} bookedAt={get(data, 'place.virtualTourBookedAt')} />
            </Modal>
          )
        }
        <Footer
          onPrev={this.onPrev}
          onNext={() => this.onNext()}
          virtualTourNeededForDone={isCoLiving}
          isPlace
          renderNext={isCoLiving && (
            <FilledRoundedButton
              onClick={this._showBookMyVirtualTourClicked}
              disabled={areNoRoomsActive(get(data, 'place.rooms', []))}
            >
              {t('bedrooms.bookTour')}
            </FilledRoundedButton>
          )}
        />
      </NarrowLayout>
    );
  }
}

BedsAndBaths.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
};

const enhance = compose(
  withNamespaces('listingForm'),
  withRouter,
  withProps(({ match }) => ({ id: match.params.id })),
  graphql(GET_PLACE_ROOMS),
);

export default enhance(BedsAndBaths);
