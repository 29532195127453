import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';

import noop from 'lodash/noop';
import { composeValidators, isDateInThePast, required } from '../../../utils/formValidation';
import { FormPart } from '../../common/form/FormWrapper';
import TextInput from '../../common/form/TextInput';
import DatePicker from '../../common/form/DatePicker';
import Dropdown from '../../common/form/Dropdown';
import PhoneNumberPrefix from '../../common/form/PhoneNumberPrefix';
import Body from '../../common/text/Body';
import Subtitle from '../../common/text/Subtitle';

export const HalfTextInput = props => <Box width="48%" mb={20}><TextInput {...props} /></Box>;
export const HalfDatePicker = props => <Box width="48%" mb={20}><DatePicker {...props} /></Box>;
export const HalfDropdown = props => <Box width="48%" mb={20}><Dropdown {...props} /></Box>;

export const ProfilePart = ({ fieldsRequired }) => (
  <FormPart title="About You">
    <Subtitle mb={20}>Personal</Subtitle>
    <Body mb={20}>Only your first name will show in your public profile</Body>
    <Flex justifyContent="space-between" flexWrap="wrap" mb={20}>
      <Flex mx="-5px" flexWrap="wrap" justifyContent="space-between">
        <Box width={[1, 1 / 2, 1 / 2]} px="5px">
          <TextInput name="person.firstName" label="First name" placeholder="Add your name" validate={fieldsRequired && required} />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px="5px">
          <TextInput name="person.lastName" label="Last name" placeholder="Add your name" validate={fieldsRequired && required} />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px="5px">
          <PhoneNumberPrefix name="person.phone" validate={fieldsRequired && required} label="Phone number" placeholder="Add your number" />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px="5px">
          <TextInput name="person.email" label="Email" validate={fieldsRequired && required} />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px="5px">
          <TextInput name="person.address" label="Address" placeholder="Start typing your address" validate={fieldsRequired && required} />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px="5px">
          <DatePicker name="person.birthday" label="Birthday" validate={composeValidators(isDateInThePast, fieldsRequired ? required : noop)} />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px="5px">
          <Dropdown
            name="person.sex"
            label="Gender"
            options={[
              { label: 'Male', value: 'male' },
              { label: 'Female', value: 'female' },
              { label: 'Other', value: 'other' },
            ]}
            validate={fieldsRequired && required}
          />
        </Box>
      </Flex>
    </Flex>
  </FormPart>
);

ProfilePart.propTypes = {
  fieldsRequired: PropTypes.bool,
};

export default ProfilePart;
